/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InitialContributionSource = 'Savings' | 'PropertySell' | 'RelativesHelp' | 'Other';

export const InitialContributionSource = {
    Savings: 'Savings' as InitialContributionSource,
    PropertySell: 'PropertySell' as InitialContributionSource,
    RelativesHelp: 'RelativesHelp' as InitialContributionSource,
    Other: 'Other' as InitialContributionSource
};

