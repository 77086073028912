/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManualReviewTaskStatus = 'New' | 'InProgress' | 'Suspended' | 'Done';

export const ManualReviewTaskStatus = {
    New: 'New' as ManualReviewTaskStatus,
    InProgress: 'InProgress' as ManualReviewTaskStatus,
    Suspended: 'Suspended' as ManualReviewTaskStatus,
    Done: 'Done' as ManualReviewTaskStatus
};

