/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomeDocumentMonthlyIncomeVerificationDto } from './incomeDocumentMonthlyIncomeVerificationDto';


export interface UpdateIncomeStatementTaskRequestData { 
    fileId?: number;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    organizationName?: string | null;
    organizationTaxNumber?: string | null;
    incomes?: Array<IncomeDocumentMonthlyIncomeVerificationDto> | null;
}

