import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputNumberModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputNumberComponent } from './reactive-input-number.component';

const TUI_MODULES = [TuiInputNumberModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveFieldErrorModule, ReactiveLabelModule, ReactiveFormsModule, TUI_MODULES],
  declarations: [ReactiveInputNumberComponent],
  exports: [ReactiveInputNumberComponent],
})
export class ReactiveInputNumberModule {}
