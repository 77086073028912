import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguageSwitcherComponent } from '@lib-features/translate';
import { TranslateModule } from '@ngx-translate/core';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import { TuiTooltipModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { NotificationListComponent } from '@lib-widgets/notification-list';
import { provideMortgageNotificationSource } from './notification-source';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ExecuteWithPipeModule,
    NotificationListComponent,
    RouterModule,
    TuiLetModule,
    TuiTooltipModule,
    TuiSidebarModule,
    TuiActiveZoneModule,
    LanguageSwitcherComponent,
    TranslateModule,
  ],
  providers: [provideMortgageNotificationSource()],
  exports: [ToolbarComponent],
  declarations: [ToolbarComponent],
})
export class ToolbarModule {}
