import { booleanAttribute, Directive, forwardRef, Input } from '@angular/core';
import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';
import { TUI_DATE_VALUE_TRANSFORMER } from '@taiga-ui/kit';
import { ToDateTransformer } from './to-date-transformer';
import { ToUtcDateTransformer } from './to-utc-date-transformer';

type From = TuiDay | undefined;
type To = TuiDay | Date | undefined;
@Directive({
  selector: `tui-input-date[fnipToDate])`,
  providers: [
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useExisting: forwardRef(() => ToDateDirective),
    },
  ],
})
export class ToDateDirective implements AbstractTuiValueTransformer<From, To> {
  @Input({ transform: booleanAttribute, alias: 'fnipToDate' }) isTransformerEnabled = true;

  @Input() set isUtcDate(value: boolean) {
    if (value) this.transformer = new ToUtcDateTransformer();
  }

  transformer = new ToDateTransformer();

  fromControlValue(controlValue: To): From {
    return this.isControlValueSameType(controlValue) ? controlValue : this.transformer.fromControlValue(controlValue);
  }

  toControlValue(componentValue: From): To {
    return this.isTransformerEnabled ? this.transformer.toControlValue(componentValue) : componentValue;
  }

  isControlValueSameType(controlValue: To): controlValue is From {
    return !this.isTransformerEnabled;
  }
}
