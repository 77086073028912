import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LOGO_SRC, NEW_LOGO_SRC, NotificationService } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveInputModule } from '@lib-widgets/reactive-fields';
import { UserApiService } from '@lib-mortgage/api';

@Component({
  selector: 'fnip-recover-email',
  templateUrl: './recover-email.component.html',
  styleUrls: ['./recover-email.component.scss'],
  imports: [ReactiveInputModule, ButtonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverEmailComponent {
  private readonly userApiService = inject(UserApiService);
  private readonly notificationService = inject(NotificationService);
  protected readonly isNew = inject(ActivatedRoute).snapshot.data.isNew;
  protected readonly logo = this.isNew ? NEW_LOGO_SRC : LOGO_SRC;

  form = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });

  submitFormCallback$ = () => {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return null;
    }

    return this.userApiService
      .apiUserEmailPost({ email: this.form.value.email!, hostUrl: window.location.origin })
      .pipe(
        tap(this.notificationService.onSuccess('Ссылка на восстановление направлена на почту')),
        tap(this.notificationService.onError()),
      );
  };
}
