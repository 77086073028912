<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
>
  <div tuiGroup [collapsed]="true">
    <tui-radio-block
      *ngFor="let o of options"
      fnipTestAttributes
      [testId]="fieldId + '-' + o.value"
      [testLabel]="o.label"
      [nativeId]="fieldId"
      [size]="btnSize"
      [style.width.px]="radioWidth"
      [formControl]="$any(control)"
      [item]="o.value"
      [hideRadio]="true"
      [readOnly]="isReadOnly"
      [disabled]="!!o.disabled"
      [contentAlign]="contentAlign"
      (click)="o.value === control.value && canClear && control.enabled ? control.reset() : true"
      (tuiValueChanges)="valueChange.emit($event)"
    >
      {{ o.label }}
    </tui-radio-block>
  </div>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
