/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface DeveloperInfo { 
    name?: string | null;
    inn?: string | null;
    ogrn?: string | null;
    registrationDate?: DateOnly;
    registrationOrgan?: string | null;
    registrationAddress?: string | null;
    face?: string | null;
    reason?: string | null;
    ownershipDate?: DateOnly;
    ownershipRecord?: string | null;
    buildingPermit?: string | null;
    buildingPermitDate?: DateOnly;
}

