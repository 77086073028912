import { isDevMode } from '@angular/core';
import { BeApiEnum } from '@lib-utils';
import { FeHttpError } from '@lib-utils';
import { TypedHttpErrorResponse } from '../error-interceptor.models';
import { ErrorMapperAbstract } from '../error-mappers';

export function mapBeError<T = unknown>(
  apiModule: BeApiEnum | null,
  mappers: ErrorMapperAbstract<T>[],
  error: TypedHttpErrorResponse<T>,
) {
  const mapper = mappers.find((mapper) => apiModule !== null && mapper.forApiModules.includes(apiModule));
  if (!mapper && isDevMode()) {
    // eslint-disable-next-line no-console
    console.error(`Error mapper for ${apiModule} not found from ${error.url}`);
    return FeHttpError.fromText<T>('Неизвестная ошибка', 'UNKNOWN');
  }
  if (!mapper) {
    // eslint-disable-next-line no-console
    console.warn(`Error mapper for ${apiModule} not found from ${error.url}`);
    return FeHttpError.fromText<T>('Неизвестная ошибка', 'UNKNOWN');
  }

  return mapper?.mapError(apiModule, error);
}
