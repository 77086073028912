/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CalculationType = 'RealEstatePrice' | 'MonthlyPayment' | 'CreditAmount';

export const CalculationType = {
    RealEstatePrice: 'RealEstatePrice' as CalculationType,
    MonthlyPayment: 'MonthlyPayment' as CalculationType,
    CreditAmount: 'CreditAmount' as CalculationType
};

