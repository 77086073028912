/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationDocumentResolution = 'ReadableScan' | 'NotReadableScan' | 'CroppedScan' | 'NotFullScan' | 'NotSigned' | 'DoesNotMeetTheRequirements' | 'NotFullData' | 'DateMissing' | 'NameMismatch' | 'RedundantMarks' | 'PageTorn60Percent' | 'BrokenLamination' | 'PhotoIsPasted' | 'ImageEdited' | 'ClientSignMissing' | 'ExecutiveSignMissing' | 'StampNotReadable' | 'NotReadable' | 'NotCertified' | 'PagesCut' | 'Incomplete' | 'InnMismatch' | 'OgrnMismatch' | 'NoData' | 'WrongDucumentType' | 'DocumentNeedsReUpload' | 'Other' | 'Pass_Missing' | 'Pass_FieldsNotReadable' | 'Pass_PersonalMarksMissing' | 'Pass_AddressNotReadable' | 'Pass_StreetOrBuildingMissing' | 'Pass_NoActiveRegistration' | 'Pass_FMSStampMissing' | 'Pass_IssueDateFieldNotReadable' | 'Pass_ScanIsOverexposed' | 'IS_BankRequirementsNotMet' | 'IS_DataIncomplete' | 'IS_60DaysDelta' | 'InsufficientInformationToCalculate' | 'IS_BorrowerNameCannotEqualSignerName' | 'IS_DuplicateInquery' | 'TK_SeriesMismath' | 'TK_EmploymentDateMissing' | 'TK_EmploymentDateNotReadable' | 'TK_CurrentPlaceOfWorkMissing' | 'MainPageMissing' | 'TK_NotSertifiedPagesPresent' | 'TK_CompanyRegistrationDateMismatch' | 'TD_EmployerNameMismatch' | 'TD_DateMissing' | 'TD_DateError' | 'TD_InnMissing' | 'ETK_ExperienceLessThan3Years' | 'ETK_CopyExpired' | 'ETK_WrongType' | 'ETK_CurrentPlaceOfWorkMissing' | 'SOPD_ConsentMissing' | 'SOPD_IncorrectPassportData' | 'SOPD_AgreementSignedMoreThanSixMonthsAgo' | 'SOPD_FormPageCut' | 'PFR_NoEmployersInfo' | 'PFR_OwnerIsNotCurrentPerson';

export const VerificationDocumentResolution = {
    ReadableScan: 'ReadableScan' as VerificationDocumentResolution,
    NotReadableScan: 'NotReadableScan' as VerificationDocumentResolution,
    CroppedScan: 'CroppedScan' as VerificationDocumentResolution,
    NotFullScan: 'NotFullScan' as VerificationDocumentResolution,
    NotSigned: 'NotSigned' as VerificationDocumentResolution,
    DoesNotMeetTheRequirements: 'DoesNotMeetTheRequirements' as VerificationDocumentResolution,
    NotFullData: 'NotFullData' as VerificationDocumentResolution,
    DateMissing: 'DateMissing' as VerificationDocumentResolution,
    NameMismatch: 'NameMismatch' as VerificationDocumentResolution,
    RedundantMarks: 'RedundantMarks' as VerificationDocumentResolution,
    PageTorn60Percent: 'PageTorn60Percent' as VerificationDocumentResolution,
    BrokenLamination: 'BrokenLamination' as VerificationDocumentResolution,
    PhotoIsPasted: 'PhotoIsPasted' as VerificationDocumentResolution,
    ImageEdited: 'ImageEdited' as VerificationDocumentResolution,
    ClientSignMissing: 'ClientSignMissing' as VerificationDocumentResolution,
    ExecutiveSignMissing: 'ExecutiveSignMissing' as VerificationDocumentResolution,
    StampNotReadable: 'StampNotReadable' as VerificationDocumentResolution,
    NotReadable: 'NotReadable' as VerificationDocumentResolution,
    NotCertified: 'NotCertified' as VerificationDocumentResolution,
    PagesCut: 'PagesCut' as VerificationDocumentResolution,
    Incomplete: 'Incomplete' as VerificationDocumentResolution,
    InnMismatch: 'InnMismatch' as VerificationDocumentResolution,
    OgrnMismatch: 'OgrnMismatch' as VerificationDocumentResolution,
    NoData: 'NoData' as VerificationDocumentResolution,
    WrongDucumentType: 'WrongDucumentType' as VerificationDocumentResolution,
    DocumentNeedsReUpload: 'DocumentNeedsReUpload' as VerificationDocumentResolution,
    Other: 'Other' as VerificationDocumentResolution,
    PassMissing: 'Pass_Missing' as VerificationDocumentResolution,
    PassFieldsNotReadable: 'Pass_FieldsNotReadable' as VerificationDocumentResolution,
    PassPersonalMarksMissing: 'Pass_PersonalMarksMissing' as VerificationDocumentResolution,
    PassAddressNotReadable: 'Pass_AddressNotReadable' as VerificationDocumentResolution,
    PassStreetOrBuildingMissing: 'Pass_StreetOrBuildingMissing' as VerificationDocumentResolution,
    PassNoActiveRegistration: 'Pass_NoActiveRegistration' as VerificationDocumentResolution,
    PassFmsStampMissing: 'Pass_FMSStampMissing' as VerificationDocumentResolution,
    PassIssueDateFieldNotReadable: 'Pass_IssueDateFieldNotReadable' as VerificationDocumentResolution,
    PassScanIsOverexposed: 'Pass_ScanIsOverexposed' as VerificationDocumentResolution,
    IsBankRequirementsNotMet: 'IS_BankRequirementsNotMet' as VerificationDocumentResolution,
    IsDataIncomplete: 'IS_DataIncomplete' as VerificationDocumentResolution,
    Is60DaysDelta: 'IS_60DaysDelta' as VerificationDocumentResolution,
    InsufficientInformationToCalculate: 'InsufficientInformationToCalculate' as VerificationDocumentResolution,
    IsBorrowerNameCannotEqualSignerName: 'IS_BorrowerNameCannotEqualSignerName' as VerificationDocumentResolution,
    IsDuplicateInquery: 'IS_DuplicateInquery' as VerificationDocumentResolution,
    TkSeriesMismath: 'TK_SeriesMismath' as VerificationDocumentResolution,
    TkEmploymentDateMissing: 'TK_EmploymentDateMissing' as VerificationDocumentResolution,
    TkEmploymentDateNotReadable: 'TK_EmploymentDateNotReadable' as VerificationDocumentResolution,
    TkCurrentPlaceOfWorkMissing: 'TK_CurrentPlaceOfWorkMissing' as VerificationDocumentResolution,
    MainPageMissing: 'MainPageMissing' as VerificationDocumentResolution,
    TkNotSertifiedPagesPresent: 'TK_NotSertifiedPagesPresent' as VerificationDocumentResolution,
    TkCompanyRegistrationDateMismatch: 'TK_CompanyRegistrationDateMismatch' as VerificationDocumentResolution,
    TdEmployerNameMismatch: 'TD_EmployerNameMismatch' as VerificationDocumentResolution,
    TdDateMissing: 'TD_DateMissing' as VerificationDocumentResolution,
    TdDateError: 'TD_DateError' as VerificationDocumentResolution,
    TdInnMissing: 'TD_InnMissing' as VerificationDocumentResolution,
    EtkExperienceLessThan3Years: 'ETK_ExperienceLessThan3Years' as VerificationDocumentResolution,
    EtkCopyExpired: 'ETK_CopyExpired' as VerificationDocumentResolution,
    EtkWrongType: 'ETK_WrongType' as VerificationDocumentResolution,
    EtkCurrentPlaceOfWorkMissing: 'ETK_CurrentPlaceOfWorkMissing' as VerificationDocumentResolution,
    SopdConsentMissing: 'SOPD_ConsentMissing' as VerificationDocumentResolution,
    SopdIncorrectPassportData: 'SOPD_IncorrectPassportData' as VerificationDocumentResolution,
    SopdAgreementSignedMoreThanSixMonthsAgo: 'SOPD_AgreementSignedMoreThanSixMonthsAgo' as VerificationDocumentResolution,
    SopdFormPageCut: 'SOPD_FormPageCut' as VerificationDocumentResolution,
    PfrNoEmployersInfo: 'PFR_NoEmployersInfo' as VerificationDocumentResolution,
    PfrOwnerIsNotCurrentPerson: 'PFR_OwnerIsNotCurrentPerson' as VerificationDocumentResolution
};

