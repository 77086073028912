/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealRegistrationType = 'EquityAgreement' | 'PartialEquityAgreement' | 'EquityAgreementWithAd' | 'ContractOfSale' | 'BeforeContractOfSale' | 'BeforeContractOfSaleWithAd' | 'Undefined';

export const DealRegistrationType = {
    EquityAgreement: 'EquityAgreement' as DealRegistrationType,
    PartialEquityAgreement: 'PartialEquityAgreement' as DealRegistrationType,
    EquityAgreementWithAd: 'EquityAgreementWithAd' as DealRegistrationType,
    ContractOfSale: 'ContractOfSale' as DealRegistrationType,
    BeforeContractOfSale: 'BeforeContractOfSale' as DealRegistrationType,
    BeforeContractOfSaleWithAd: 'BeforeContractOfSaleWithAd' as DealRegistrationType,
    Undefined: 'Undefined' as DealRegistrationType
};

