import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '../../interfaces/types';

export function plural(number: number, words: string[]) {
  return words[
    number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
}

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  /**
   * Склонение под заданную численность
   *
   * @example
   * {{ 20 | plural : ['год', 'года', 'лет'] }} -> 'лет'
   *
   * @param number Число объектов
   * @param array Названия для разной численности
   */
  transform(number: Nullable<number>, array: string[]): string {
    return plural(Number(number), array);
  }
}
