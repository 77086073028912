/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FmsUnitType } from './fmsUnitType';


export interface FmsUnitDto { 
    code: string;
    name: string;
    regionCode: string;
    type?: FmsUnitType;
}
export namespace FmsUnitDto {
}


