/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { VerificationDocumentFalsificationFlag } from './verificationDocumentFalsificationFlag';
import { ClientModel } from './clientModel';
import { VerificationTaskChangesModel } from './verificationTaskChangesModel';
import { VerificationDocumentResolution } from './verificationDocumentResolution';
import { VerificationSupervisionDataModel } from './verificationSupervisionDataModel';
import { VerificationDocumentStatus } from './verificationDocumentStatus';
import { ApplicationModel } from './applicationModel';
import { VerificatorModel } from './verificatorModel';
import { EmploymentModel } from './employmentModel';
import { DateTime } from '@lib-utils';
import { VerificationTaskType } from './verificationTaskType';


export interface VerificationTaskModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    verificatorId?: number | null;
    verificator?: VerificatorModel;
    applicationId?: number;
    application?: ApplicationModel;
    clientId?: number;
    client?: ClientModel;
    employmentId?: number | null;
    employment?: EmploymentModel;
    type?: VerificationTaskType;
    documentStatus?: VerificationDocumentStatus;
    corrections?: boolean;
    documentResolutions?: Array<VerificationDocumentResolution> | null;
    falsificationFlags?: Array<VerificationDocumentFalsificationFlag> | null;
    isActive?: boolean;
    comment?: string | null;
    fileId?: number | null;
    tryCount?: number;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataModel> | null;
    changes?: Array<VerificationTaskChangesModel> | null;
}
export namespace VerificationTaskModel {
}


