/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FalsificationFlagInfoDto } from './falsificationFlagInfoDto';
import { VerificationTaskType } from './verificationTaskType';


export interface SignsOfFalsificationInfoItemDto { 
    verificationTaskType?: VerificationTaskType;
    verificationTaskName?: string | null;
    falsificationFlags?: Array<FalsificationFlagInfoDto> | null;
}
export namespace SignsOfFalsificationInfoItemDto {
}


