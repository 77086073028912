import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Nullable } from '../../interfaces/types';

@Directive({
  selector: '[fnipCyrillicInput]',
})
export class CyrillicInputDirective {
  @Input() set fnipCyrillicInput(isCyrillicInput: Nullable<boolean | string>) {
    this.isCyrillicInput = typeof isCyrillicInput === 'boolean' ? isCyrillicInput : true;
  }

  private isCyrillicInput = true;

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  static matchPattern(str: Nullable<string>) {
    return !str || /^[а-яёА-ЯЁ -]*$/g.test(str);
  }

  static transformToPattern(str: Nullable<string>): Nullable<string> {
    return str?.match(/[а-яёА-ЯЁ -]+/g)?.join('');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isCyrillicInput || this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value || '';
    const next: string = current.concat(event.key);
    if (!event.ctrlKey && !CyrillicInputDirective.matchPattern(next)) {
      event.preventDefault();
    }
  }
}
