import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiTooltipModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule, PluralPipeModule } from '@lib-utils';
import { FileComponent, FileListComponent } from '@lib-widgets/file-list';
import { DragNDropComponent } from './components';
import { FileDndDirective } from './directives';
import { ReactiveFileInputComponent } from './reactive-file-input.component';

@NgModule({
  imports: [CommonModule, FileComponent, FileListComponent, ExecuteWithPipeModule, TuiTooltipModule, PluralPipeModule],
  declarations: [ReactiveFileInputComponent, DragNDropComponent, FileDndDirective],
  exports: [ReactiveFileInputComponent, FileDndDirective],
})
export class ReactiveFileInputModule {}
