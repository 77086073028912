<fnip-reactive-label
  *ngIf="control"
  [class.inactive]="isInactive"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-input-count
    [nativeId]="fieldId"
    [formControl]="$any(control)"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [postfix]="postfix"
    [step]="step"
    [min]="min"
    [max]="max"
    [hideButtons]="hideButtons"
    [tuiTextfieldIconLeft]="textfieldIconLeft"
    [readOnly]="isReadOnly"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    {{ placeholder }}
  </tui-input-count>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
