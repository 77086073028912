<ng-container *ngIf="request$ | async" />

<fnip-loader
  [appearance]="appearance"
  [isLoading]="isLoading"
  [customLoadingText]="customLoadingText"
  [error]="error"
  [showContent]="hasFirstRs"
  [backButtonCallback$]="backButtonCallback$"
  (retry)="reload()"
>
  <ng-content />
</fnip-loader>
