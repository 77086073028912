/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PartnerPersonType = 'PhysicalPerson' | 'Organization';

export const PartnerPersonType = {
    PhysicalPerson: 'PhysicalPerson' as PartnerPersonType,
    Organization: 'Organization' as PartnerPersonType
};

