/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReceivingType = 'BeforeRegistrationWithoutNote' | 'BeforeRegistrationWithNote' | 'AfterRegistration' | 'Undefined';

export const ReceivingType = {
    BeforeRegistrationWithoutNote: 'BeforeRegistrationWithoutNote' as ReceivingType,
    BeforeRegistrationWithNote: 'BeforeRegistrationWithNote' as ReceivingType,
    AfterRegistration: 'AfterRegistration' as ReceivingType,
    Undefined: 'Undefined' as ReceivingType
};

