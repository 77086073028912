/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SourceType = 'Common' | 'Deal' | 'Insurance' | 'Owner';

export const SourceType = {
    Common: 'Common' as SourceType,
    Deal: 'Deal' as SourceType,
    Insurance: 'Insurance' as SourceType,
    Owner: 'Owner' as SourceType
};

