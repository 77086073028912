import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDropdownWidth, TuiHorizontalDirection, TuiValueContentContext } from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import isNil from 'lodash-es/isNil';
import { Nullable, reactiveTestAttributesHostDirective, SelectOption } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';

@Component({
  selector: 'fnip-reactive-select',
  templateUrl: './reactive-select.component.html',
  styleUrls: [`./reactive-select.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveSelectComponent<T = unknown, TVal = unknown> extends AbstractReactiveWithOptions<T, TVal> {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<TVal>>>;

  @Input() valueContent: Nullable<PolymorpheusContent<TuiValueContentContext<T>>>;

  @Input() optionContent: Nullable<PolymorpheusContent>;

  @Input() pseudoPressed = false;

  @Input() dropdownWidth: TuiDropdownWidth = 'fixed';

  @Input() dropdownAlign: TuiHorizontalDirection = 'right';

  @Input() noOptionsMessage: Nullable<string>;

  /**
   * FIXME: Временное решение задачи https://tracker.yandex.ru/FNARCH-211
   * До момента скролла компонент tui-data-list не видит изменений в cdkVirtualFor и рендерит "Ничего не найдено"
   */
  @Input() isVirtualScrollDisabled = false;

  // Не даем комбобоксу автоматически выбирать значение, совпадающее с поиском
  noSearchMatcher = () => false;

  handleControlReset = (loadedOptions: Nullable<SelectOption[]>) => {
    if (!this.control) return;
    if (isNil(this.isOptionsLoading)) return;
    const { value } = this.control;
    if (!value) return;
    if (this.isOptionsLoading) return;
    if (loadedOptions?.some((option) => option.value === value)) return;
    if (typeof this.options === 'function') return; //todo: При отсутствии загруженных опции значение может быть (инициализация контрола значением). Пример evaluation-report-form.component.html | address (control)

    this.control.reset();
    this.control.markAsTouched();
  };
}
