import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';

/**
 * Сервис для сохранения истории навигации
 * Исользуется для кнопки "Назад", когда нужно вернуться на предыдущую страницу с сохранением параметров
 * При этом, если истории не было (при первом заходе на страницу), то переходит на failback, чтобы не уходить из приложения
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public readonly history: string[] = [];

  private readonly router = inject(Router);
  private readonly location = inject(Location);

  public startSavingHistory$() {
    return this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        tap((event) => this.history.push(event.urlAfterRedirects)),
      )
      .subscribe();
  }

  public goBack(failBackUrl = '/') {
    this.history.pop();
    if (this.history.length > 0) return this.location.back();

    void this.router.navigateByUrl(failBackUrl);
  }
}
