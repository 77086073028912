/**
 * BFF
 * Octasoft NestJS service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExceptionError } from './exceptionError';

export interface ExceptionDto {
  error: ExceptionError;
}
