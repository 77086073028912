import flatten from 'lodash-es/flatten';
import uniq from 'lodash-es/uniq';
import { Nullable } from '@lib-utils';

export const ACCEPT_FILE_TYPES = {
  MSWORD_TYPES: 'MSWORD_TYPES',
  // https://learn.microsoft.com/en-us/previous-versions/office/office-2007-resource-kit/ee309278%28v=office.12%29
  MSWORD_TEMPLATE_TYPES: 'MSWORD_TEMPLATE_TYPES',
  MSWORD_WITH_MACROS_TYPE: 'MSWORD_WITH_MACROS_TYPE', // .docm
  OPEN_DOCUMENT_TYPES: 'OPEN_DOCUMENT_TYPES',
  EXCEL_TYPES: 'EXCEL_TYPES',
  EXCEL_WITH_MACROS_TYPES: 'EXCEL_WITH_MACROS_TYPES',
  EXCEL_TEMPLATE_TYPES: 'EXCEL_TEMPLATE_TYPES',
  CSV_TYPE: 'CSV_TYPE',
  PDF_TYPE: 'PDF_TYPE',
  RICH_TEXT_TYPE: 'RICH_TEXT_TYPE',
  SIGNATURE_TYPE: 'SIGNATURE_TYPE',
  IMAGE_TYPES: 'IMAGE_TYPES',
  IMAGE_BMP_TYPE: 'IMAGE_BMP_TYPE',
  IMAGE_PNG_TYPE: 'IMAGE_PNG_TYPE',
  IMAGE_JPEG_TYPE: 'IMAGE_JPEG_TYPE',
  IMAGE_HEIC_TYPE: 'IMAGE_HEIC_TYPE',
  IMAGE_TIF_TYPES: 'IMAGE_TIF_TYPES',
  IMAGE_GIF_TYPE: 'IMAGE_GIF_TYPE',
  IMAGE_SVG_TYPE: 'IMAGE_SVG_TYPE',
  IMAGE_ALL_TYPES: 'IMAGE_ALL_TYPES',
  ARCHIVE_TYPES: 'ARCHIVE_TYPES',
} as const satisfies Record<string, string>;

export type ACCEPT_FILE_TYPES = (typeof ACCEPT_FILE_TYPES)[keyof typeof ACCEPT_FILE_TYPES];

export const AcceptValueByType = {
  MSWORD_TYPES: [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  // https://learn.microsoft.com/en-us/previous-versions/office/office-2007-resource-kit/ee309278%28v=office.12%29
  MSWORD_TEMPLATE_TYPES: [
    'application/msword', // .dot
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template', //.dotx
    'application/vnd.ms-word.template.macroenabled.12', // .dotm
  ],
  MSWORD_WITH_MACROS_TYPE: ['application/vnd.ms-word.document.macroenabled.12'], // .docm
  OPEN_DOCUMENT_TYPES: [
    'application/vnd.oasis.opendocument.text', // .odt
    'application/vnd.oasis.opendocument.text-template', // .ott
    'application/vnd.oasis.opendocument.text-web', // .oth
    'application/vnd.oasis.opendocument.text-master', // .odm
  ],
  EXCEL_TYPES: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  EXCEL_WITH_MACROS_TYPES: [
    '.xlsm', // Для него filetype = ''
    'application/vnd.ms-excel.addin.macroenabled.12', // .xlam
    'application/vnd.ms-excel.sheet.binary.macroenabled.12', // .xlsb
  ],
  EXCEL_TEMPLATE_TYPES: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
    'application/vnd.ms-excel.template.macroenabled.12',
  ],
  CSV_TYPE: ['text/csv'],
  PDF_TYPE: ['application/pdf'],
  RICH_TEXT_TYPE: ['application/rtf'],
  SIGNATURE_TYPE: ['.sig'],
  IMAGE_TYPES: ['image/jpg', 'image/jpeg', 'image/jfif', 'image/png'],
  IMAGE_BMP_TYPE: ['image/bmp'],
  IMAGE_TIF_TYPES: ['image/tiff', 'image/tif'],
  IMAGE_PNG_TYPE: ['image/png'],
  IMAGE_HEIC_TYPE: ['image/heic', '.heic'],
  IMAGE_JPEG_TYPE: ['image/jpg', 'image/jpeg', 'image/jfif'],
  IMAGE_GIF_TYPE: ['image/gif'],
  IMAGE_SVG_TYPE: ['image/svg+xml'],
  IMAGE_ALL_TYPES: ['image/*', '.heic'],
  ARCHIVE_TYPES: [
    '.zip',
    '.rar',
    'application/octet-stream',
    'application/x-zip',
    'application/x-rar',
    'application/zip',
    'application/x-zip-compressed',
    'multipart/x-zip',
  ],
} as const satisfies Record<keyof typeof ACCEPT_FILE_TYPES, ReadonlyArray<Lowercase<string>>>;

export type AcceptFileValues = (typeof AcceptValueByType)[ACCEPT_FILE_TYPES];
export type FlattenAcceptFileValues = {
  [key in ACCEPT_FILE_TYPES]: (typeof AcceptValueByType)[key] extends ReadonlyArray<infer T> ? T : never;
}[ACCEPT_FILE_TYPES];

export const AcceptFileTypesMap: Record<ACCEPT_FILE_TYPES, string> = {
  MSWORD_TYPES: 'Microsoft Word',
  MSWORD_WITH_MACROS_TYPE: 'Microsoft Word с поддержкой макросов',
  MSWORD_TEMPLATE_TYPES: 'Шаблоны Microsoft Word',
  OPEN_DOCUMENT_TYPES: 'OpenDocument',
  EXCEL_TYPES: 'Microsoft Excel',
  EXCEL_WITH_MACROS_TYPES: 'Microsoft Excel с поддержкой макросов',
  EXCEL_TEMPLATE_TYPES: 'Шаблоны Microsoft Excel',
  CSV_TYPE: 'Таблица CSV',
  PDF_TYPE: 'PDF',
  RICH_TEXT_TYPE: 'Формат обогащённого текста (.RTF)',
  IMAGE_SVG_TYPE: 'Векторная графика (.SVG)',
  SIGNATURE_TYPE: 'Файл подписи (.SIG)',
  IMAGE_TYPES: 'Изображения в форматах JPEG и PNG',
  IMAGE_ALL_TYPES: 'Изображения',
  IMAGE_BMP_TYPE: 'Изображения в формате BMP',
  IMAGE_PNG_TYPE: 'Изображения в формате PNG',
  IMAGE_JPEG_TYPE: 'Изображения в формате JPEG',
  IMAGE_HEIC_TYPE: 'Изображения в формате HEIC',
  IMAGE_TIF_TYPES: 'Изображения в формате TIFF',
  IMAGE_GIF_TYPE: 'Изображения в формате GIF',
  ARCHIVE_TYPES: 'Архив (.ZIP, .RAR)',
};

export const getTypeByValue = (value: FlattenAcceptFileValues): ACCEPT_FILE_TYPES | undefined => {
  return Object.values(ACCEPT_FILE_TYPES).find((type) => AcceptValueByType[type].some((item) => item === value));
};

export const getAcceptFileTypeString = (types?: ACCEPT_FILE_TYPES[]) => {
  if (!types) return '';
  return types
    .map((type) => AcceptValueByType[type])
    .flat()
    .join(',');
};

export const getAcceptCaption = (...types: (ACCEPT_FILE_TYPES | ACCEPT_FILE_TYPES[])[]): string => {
  return uniq(flatten(types).map((key) => AcceptFileTypesMap[key])).join(', ');
};

export function checkFileType(file: File, accept: Nullable<ACCEPT_FILE_TYPES[]>) {
  if (!accept) return true;
  // can be .doc, application/msword, image/*
  const acceptTypes = accept.map((item) => AcceptValueByType[item]).flat();
  const fileExt = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  const fileType = file.type.toLowerCase();
  const [generalFileType] = fileType.split('/');

  return acceptTypes.some((acceptType) => {
    if (acceptType.startsWith('.')) return fileExt === acceptType;
    return acceptType === fileType || acceptType === `${generalFileType}/*`;
  });
}
