import { Observable } from 'rxjs';
import { FileInfo, Nullable } from '@lib-utils';
import { FileInfoUploadEvent } from './file-info-upload-event.interface';

/** Get list of uploaded files */
export type GetFileListCallback<T extends string | number = string | number> = () => Observable<Partial<FileInfo<T>>[]>;

/** Remove file by fileId */
export type RemoveFileCallback<T extends string | number = string | number> = (fileId: T) => Observable<unknown>;

/** Upload file */
export type UploadFileCallback<T extends string | number = string | number> = (
  userFile: File,
) => Observable<Nullable<Partial<FileInfo<T>> | FileInfoUploadEvent>>;

/** Get blob of thumbnail image */
export type GetFileThumbnailCallback<T extends string | number = string | number> = (fileId: T) => Observable<Blob>;
