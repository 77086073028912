import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TuiDay, TuiDayRange, TuiTime } from '@taiga-ui/cdk';
import { Nullable } from '../../interfaces/types';
import { FileInfo } from '../file-info';

/**
 * Примитивные типы, которые будут приведены к FormControl
 */
export type FormControlPrimitive = number | string | boolean | TuiDay | TuiTime | TuiDayRange | Date | FileInfo;

export type FormControlsOf<T> = {
  [key in keyof T]: FormControlOf<T[key], Required<T>[key]>;
};
export type FormControlOf<T, TR> =
  T extends Array<infer A>
    ? [A] extends [FormControlPrimitive]
      ? PrimitiveControl<T>
      : A extends object
        ? FormArray<FormGroup<FormControlsOf<A>>>
        : never
    : T extends Nullable<infer K>
      ? K extends FormControlPrimitive
        ? PrimitiveControl<TR>
        : K extends object
          ? FormGroup<FormControlsOf<K>>
          : never
      : never;

export type PrimitiveControl<T> = FormControl<Nullable<T>>;

/**
 * Тип нужен для создания правильного типа FormGroup из DTO с бекэнда
 * @example
 * // DTO
 * interface TransactionsDto {
 *   userId: string;
 *   transactions: Array<{
 *     id: string;
 *     amount: number;
 *   }>;
 * }
 *
 * Будет создан такой FormGroup:
 * @example
 * type result = FormGroup<{
 *   userId: FormControl<Nullable<string>>;
 *   transactions: FormArray<FormGroup<{
 *     id: FormControl<Nullable<string>>;
 *     amount: FormControl<Nullable<number>>;
 *   }>>;
 * }>;
 *
 * // аналогично записи
 * type result = FormGroupOf<TransactionsDto>
 *
 */
export type FormGroupOf<T> = FormGroup<FormControlsOf<T>>;

export type FormArrayOf<T> = FormArray<FormGroupOf<T>>;

export type FormControlsOfNew<T> = {
  [key in keyof T]: FormControlOfNew<T[key], Required<T>[key]>;
};

export type FormControlOfNew<T, TR> =
  T extends Array<infer A>
    ? [A] extends [FormControlPrimitive]
      ? PrimitiveControl<T>
      : A extends object
        ? PrimitiveControl<T> | FormArray<FormControl<A>> | FormArray<FormControl<A | null>> | FormArray<FormGroup>
        : PrimitiveControl<T> | FormArray<FormControl<A>> | FormArray<FormControl<A | null>>
    : T extends Nullable<infer K>
      ? K extends FormControlPrimitive
        ? PrimitiveControl<TR>
        : T extends object
          ? FormGroup | PrimitiveControl<T>
          : PrimitiveControl<T>
      : never;
