/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AgreementType } from './agreementType';
import { StaffNumber } from './staffNumber';
import { DateOnly } from '@lib-utils';
import { EmploymentPhoneModel } from './employmentPhoneModel';
import { DateTime } from '@lib-utils';
import { EmploymentIncomeModel } from './employmentIncomeModel';
import { IncomeSource } from './incomeSource';
import { IndustryType } from './industryType';
import { UserModel } from './userModel';
import { ClientModel } from './clientModel';
import { JobType } from './jobType';
import { OrganizationLifetime } from './organizationLifetime';
import { AddressModel } from './addressModel';
import { ClientFileModel } from './clientFileModel';
import { OccupationType } from './occupationType';
import { EmployeeBankRelation } from './employeeBankRelation';


export interface EmploymentModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    clientId?: number;
    client?: ClientModel;
    companyType?: string | null;
    industryType?: IndustryType;
    taxNumber?: string | null;
    name?: string | null;
    regNumber?: string | null;
    juristicAddressId?: number | null;
    juristicAddress?: AddressModel;
    factualAddressId?: number | null;
    factualAddress?: AddressModel;
    phone?: string | null;
    position?: string | null;
    salary?: number | null;
    occupationType?: OccupationType;
    businessPercent?: number | null;
    businessComment?: string | null;
    agreementType?: AgreementType;
    staffNumber?: StaffNumber;
    organizationLifetime?: OrganizationLifetime;
    site?: string | null;
    startDate?: DateOnly | null;
    startWorkingAgreement?: DateOnly | null;
    endWorkingAgreement?: DateOnly | null;
    salaryBank?: string | null;
    salaryCardNumber?: string | null;
    bankRelation?: EmployeeBankRelation;
    totalWorkExperience?: number | null;
    employerFirstName?: string | null;
    employerMiddleName?: string | null;
    employerLastName?: string | null;
    jobType?: JobType;
    tkSeriesNumber?: string | null;
    comment?: string | null;
    isNotStandardContract?: boolean | null;
    isEmployerIncome?: boolean | null;
    sprIncome?: number | null;
    sprIncomeSource?: IncomeSource;
    excludeReason?: string | null;
    files?: Array<ClientFileModel> | null;
    phones?: Array<EmploymentPhoneModel> | null;
    incomes?: Array<EmploymentIncomeModel> | null;
    isActive?: boolean;
}
export namespace EmploymentModel {
}


