/**
 * Семантический тип для генерации моделей `openapi-generator-cli`
 *
 * Пример: 2017-07-21
 */
export type DateOnly = string;

/**
 * Семантический тип для генерации моделей `openapi-generator-cli`
 *
 * Пример: 2017-07-21T17:32:28Z
 */
export type DateTime = string;
