/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';
import { DriversLicenseRecognitionDto } from './driversLicenseRecognitionDto';
import { AddressDto } from './addressDto';
import { ForeignPassportRecognitionDto } from './foreignPassportRecognitionDto';
import { Ndfl2RecognitionDto } from './ndfl2RecognitionDto';
import { DigitalEmploymentHistoryRecognitionDto } from './digitalEmploymentHistoryRecognitionDto';
import { PreviousPassportRecognitionDto } from './previousPassportRecognitionDto';
import { MarriageCertificateRecognitionDto } from './marriageCertificateRecognitionDto';
import { IncomeStatementBankFormatRecognitionDto } from './incomeStatementBankFormatRecognitionDto';
import { EmploymentContractRecognitionDto } from './employmentContractRecognitionDto';
import { ClientFileInfoDto } from './clientFileInfoDto';
import { EmploymentHistoryRecognitionDto } from './employmentHistoryRecognitionDto';


export interface RecognitionDto { 
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    sex?: Sex;
    birthday?: DateOnly | null;
    birthplace?: string | null;
    issuedBy?: string | null;
    issueDepCode?: string | null;
    issueDate?: DateOnly | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    registrationPageNumbers?: string | null;
    taxNumber?: string | null;
    snils?: string | null;
    registrationAddress?: AddressDto;
    ndfl2?: Ndfl2RecognitionDto;
    employmentHistory?: EmploymentHistoryRecognitionDto;
    digitalEmploymentHistoryStdR?: DigitalEmploymentHistoryRecognitionDto;
    digitalEmploymentHistoryStdPfr?: DigitalEmploymentHistoryRecognitionDto;
    marriageCertificate?: MarriageCertificateRecognitionDto;
    incomeStatementBankFormat?: IncomeStatementBankFormatRecognitionDto;
    employmentContract?: EmploymentContractRecognitionDto;
    driversLicense?: DriversLicenseRecognitionDto;
    foreignPassport?: ForeignPassportRecognitionDto;
    previousPassports?: Array<PreviousPassportRecognitionDto> | null;
    additionalFileModels?: Array<ClientFileInfoDto> | null;
    replacedByOtherJobs?: boolean;
}
export namespace RecognitionDto {
}


