import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map, of, switchMap, take } from 'rxjs';
import { CURRENT_USER, getLogout$ } from '../../utils';

export const authorizationGuardFn: CanActivateFn = () => {
  const logout$ = getLogout$();

  const isAuthorized$ = inject(CURRENT_USER).pipe(
    map((token) => Boolean(token)),
    take(1),
  );

  // Ждем получения юзер с ролями и пермисиями или null, если не авторизован
  return isAuthorized$.pipe(
    switchMap((isAuthorized) => {
      if (!isAuthorized) {
        return logout$(true).pipe(map(() => false));
      }

      return of(true);
    }),
  );
};
