import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputDateModule } from '@taiga-ui/kit';
import { ToDateModule } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputDateComponent } from './reactive-input-date.component';

const TUI_MODULES = [TuiInputDateModule, TuiLabelModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [
    CommonModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    ToDateModule,
    TUI_MODULES,
  ],
  declarations: [ReactiveInputDateComponent],
  exports: [ReactiveInputDateComponent],
})
export class ReactiveInputDateModule {}
