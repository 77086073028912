/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditProductDto } from './creditProductDto';
import { DateOnly } from '@lib-utils';
import { BankDecisionDto } from './bankDecisionDto';
import { NonStandardType } from './nonStandardType';


export interface CreditInfoShortDto { 
    id?: number;
    calculationResultId?: number | null;
    requestedAmount?: number;
    requestedRealEstateValue?: number;
    requestedFirstAmount?: number;
    requestedInitialPaymentSumPercent?: number;
    requestedCalculatedRate?: number;
    requestedPeriod?: number;
    requestedMonthlyPayment?: number;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    paymentDate?: DateOnly | null;
    creditProduct?: CreditProductDto;
    bankDecision?: BankDecisionDto;
}
export namespace CreditInfoShortDto {
}


