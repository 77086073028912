import { ConfigService } from '@lib-config';
import { Nullable, RoleType } from '@lib-utils';
import { PermissionOperationMapKeys } from '@lib-mortgage/features/authorization';

export type GetterParams = {
  configService: ConfigService;
  hasRoles: (roles: Nullable<RoleType[]>) => boolean;
};

export type GetterFn<TPropType> = (params: GetterParams) => TPropType;
type WithGetterFunction<TPropName extends string, TPropType> = Partial<
  Record<TPropName, TPropType> & Record<`get${Capitalize<TPropName>}`, GetterFn<TPropType>>
>;

export type ApplicationModulesMeta = WithGetterFunction<'name', string> &
  WithGetterFunction<'nameTranslate', string> &
  WithGetterFunction<'descriptionTranslate', string> &
  WithGetterFunction<'description', string> &
  WithGetterFunction<'link', string> &
  WithGetterFunction<'permissionOperation', PermissionOperationMapKeys | null>;

export interface ApplicationModulesMetaResoled {
  name: string;
  link: string;
  description: string;
  permissionOperation: PermissionOperationMapKeys | null;
}

export interface DefaultPageData extends Record<string, unknown> {
  moduleMeta?: Record<string, ApplicationModulesMeta>;
  openIssueUrl?: string;
  videoInstructionUrl?: string;
  hasTranslation?: boolean;
  logo?: string;

  /** Использование href вместо routerLink */
  useLinks?: boolean;
}

export const getModuleMeta = (
  moduleMeta: ApplicationModulesMeta,
  params: GetterParams,
): ApplicationModulesMetaResoled => ({
  name: moduleMeta.name ?? moduleMeta.getName?.(params) ?? '',
  link: moduleMeta.link ?? moduleMeta.getLink?.(params) ?? '',
  description: moduleMeta.description ?? moduleMeta.getDescription?.(params) ?? '',
  permissionOperation: moduleMeta.permissionOperation ?? moduleMeta?.getPermissionOperation?.(params) ?? null,
});
