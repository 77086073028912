import { inject, Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PermissionService } from '../permission';

@Injectable()
export class PreloadStrategyService implements PreloadingStrategy {
  private readonly permissionService = inject(PermissionService);

  preload(route: Route, fn: () => Observable<null>): Observable<null> {
    const { requiredPermission } = route.data || {};
    if (!requiredPermission) return fn();
    const hasPermission =
      this.permissionService.checkExactPermissionFor('AllModulesAvailable') ||
      this.permissionService.checkExactPermissionFor(requiredPermission);

    return hasPermission ? fn() : of(null);
  }
}
