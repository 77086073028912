/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { ClientType } from './clientType';
import { ClientScoringSummaryDto } from './clientScoringSummaryDto';
import { ManualReviewEmploymentDto } from './manualReviewEmploymentDto';
import { ExpenseDto } from './expenseDto';
import { DocumentDto } from './documentDto';
import { DateOnly } from '@lib-utils';
import { TaskDto } from './taskDto';
import { ClientPreviousNameDto } from './clientPreviousNameDto';
import { AdditionalIncomeDto } from './additionalIncomeDto';


export interface ManualReviewClientDto { 
    id?: number;
    type?: ClientType;
    fullName?: string | null;
    age?: number;
    phone?: string | null;
    email?: string | null;
    location?: string | null;
    registrationLocation?: string | null;
    sprIncome?: number | null;
    maxMonthlyPayment?: number | null;
    maxMonthlyPaymentTemp?: number | null;
    stateStatisticsIncome?: number | null;
    costOfLiving?: number | null;
    birthday?: DateOnly;
    birthplace?: string | null;
    employments?: Array<ManualReviewEmploymentDto> | null;
    tasks?: Array<TaskDto> | null;
    maritalStatus?: MaritalStatus;
    passport?: DocumentDto;
    negativeInfos?: Array<string> | null;
    isMainParticipant?: boolean;
    isIncomePart?: boolean;
    previousPassports?: Array<DocumentDto> | null;
    previousName?: ClientPreviousNameDto;
    scoringSummary?: ClientScoringSummaryDto;
    additionalIncomes?: Array<AdditionalIncomeDto> | null;
    expenses?: Array<ExpenseDto> | null;
}
export namespace ManualReviewClientDto {
}


