import { inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

export function getUrl$(router = inject(Router)) {
  return router.events.pipe(
    startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(({ urlAfterRedirects }) => urlAfterRedirects),
  );
}
