/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ndfl2IncomeModel } from './ndfl2IncomeModel';
import { DateOnly } from '@lib-utils';


export interface Ndfl2Model { 
    id?: number;
    fileId?: number;
    incomes?: Array<Ndfl2IncomeModel> | null;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    birthDate?: DateOnly | null;
    organizationName?: string | null;
    organizationTaxNumber?: string | null;
    date?: DateOnly | null;
    year?: number;
    isActive?: boolean;
    employerPhone?: string | null;
}

