/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationSortFields = 'Id' | 'DateCreated' | 'DateUpdated' | 'FullName' | 'CreditAmount' | 'Status' | 'Purpose' | 'ArchivalDate' | 'ArchiverFullName' | 'OldState';

export const ApplicationSortFields = {
    Id: 'Id' as ApplicationSortFields,
    DateCreated: 'DateCreated' as ApplicationSortFields,
    DateUpdated: 'DateUpdated' as ApplicationSortFields,
    FullName: 'FullName' as ApplicationSortFields,
    CreditAmount: 'CreditAmount' as ApplicationSortFields,
    Status: 'Status' as ApplicationSortFields,
    Purpose: 'Purpose' as ApplicationSortFields,
    ArchivalDate: 'ArchivalDate' as ApplicationSortFields,
    ArchiverFullName: 'ArchiverFullName' as ApplicationSortFields,
    OldState: 'OldState' as ApplicationSortFields
};

