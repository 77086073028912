/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DepartmentKind = 'Urm' | 'Filial' | 'AdditionalOffice' | 'Department' | 'OperationOffice';

export const DepartmentKind = {
    Urm: 'Urm' as DepartmentKind,
    Filial: 'Filial' as DepartmentKind,
    AdditionalOffice: 'AdditionalOffice' as DepartmentKind,
    Department: 'Department' as DepartmentKind,
    OperationOffice: 'OperationOffice' as DepartmentKind
};

