import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { tap } from 'rxjs/operators';
import { getUrl$ } from '@lib-utils';
import { PrivacyPolicyComponent } from '@lib-widgets/core';
import { BackgroundAnimationComponent } from './views/background-animation';

@Component({
  selector: 'fnip-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, BackgroundAnimationComponent, NgIf, PrivacyPolicyComponent, RouterOutlet],
})
export class AuthorizationComponent {
  private readonly activatedRoute = inject(ActivatedRoute);

  @HostBinding('class.new-auth') readonly isNew = this.activatedRoute.snapshot.data.isNew;
  @HostBinding('class.recover-page') isRecoverPage = false;

  readonly appName = this.activatedRoute.snapshot.data.appName;
  readonly version = this.activatedRoute.snapshot.data.version;
  readonly url$ = getUrl$().pipe(tap((url) => (this.isRecoverPage = url.includes('recover'))));
}
