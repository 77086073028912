/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface MemberInfo { 
    name?: string | null;
    sex?: string | null;
    birthDate?: DateOnly;
    birthPlace?: string | null;
    passport?: string | null;
    passportIssuing?: string | null;
    passportIssuingDate?: DateOnly;
    passportCode?: string | null;
    registrationAddress?: string | null;
    phone?: string | null;
}

