/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EvalContextType = 'Any' | 'Calculator';

export const EvalContextType = {
    Any: 'Any' as EvalContextType,
    Calculator: 'Calculator' as EvalContextType
};

