/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmploymentHistorySortFields = 'LastName' | 'FirstName' | 'MiddleName' | 'SerialEmploymentHistory' | 'NumberEmploymentHistory' | 'ApplicationId' | 'CreatorId' | 'Created' | 'UpdatorId' | 'Updated';

export const EmploymentHistorySortFields = {
    LastName: 'LastName' as EmploymentHistorySortFields,
    FirstName: 'FirstName' as EmploymentHistorySortFields,
    MiddleName: 'MiddleName' as EmploymentHistorySortFields,
    SerialEmploymentHistory: 'SerialEmploymentHistory' as EmploymentHistorySortFields,
    NumberEmploymentHistory: 'NumberEmploymentHistory' as EmploymentHistorySortFields,
    ApplicationId: 'ApplicationId' as EmploymentHistorySortFields,
    CreatorId: 'CreatorId' as EmploymentHistorySortFields,
    Created: 'Created' as EmploymentHistorySortFields,
    UpdatorId: 'UpdatorId' as EmploymentHistorySortFields,
    Updated: 'Updated' as EmploymentHistorySortFields
};

