import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLabelModule } from '@taiga-ui/core';
import { FnipFormRequiredHintComponent } from './fnip-form-required-hint.component';

@NgModule({
  declarations: [FnipFormRequiredHintComponent],
  exports: [FnipFormRequiredHintComponent],
  imports: [CommonModule, TuiLabelModule],
})
export class FnipFormRequiredHintModule {}
