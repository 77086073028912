import { ChangeDetectionStrategy, Component } from '@angular/core';
import { reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-password',
  templateUrl: './reactive-input-password.component.html',
  styleUrls: ['./reactive-input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputPasswordComponent extends AbstractReactive {}
