import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { inject, Provider } from '@angular/core';
import { map, merge, tap } from 'rxjs';
import { FNIP_DIALOG_REF } from '@lib-tokens';
import { FnipDialogRef } from './dialog-ref';
import { DialogService } from './dialog.service';

// DI Хелпер для сервиса диалогов
// Сервис необходимо инжектить в self, чтобы он использовал правильный injector
export const injectDialogService = () => inject(DialogService, { self: true });

// DI Хелпер для получения данных внутри диалога
export const injectDialogContext = <TResultData = unknown, TContextData = unknown>() => {
  return {
    ref: inject<FnipDialogRef<TResultData>>(FNIP_DIALOG_REF, { optional: true }),
    data: inject<TContextData>(DIALOG_DATA, { optional: true }),
  };
};

// Обработка блокировки скролла элемента body при открытии диалога
export const getBodyScrollLock$ = (dialogCdk = inject(Dialog)) =>
  merge(dialogCdk.afterOpened.pipe(map(() => true)), dialogCdk.afterAllClosed.pipe(map(() => false))).pipe(
    tap((isLocked) => {
      const body = document.body;
      if (body) body.style.overflow = isLocked ? 'hidden' : '';
    }),
  );

export function withLocationStrategy(): Provider {
  return { provide: LocationStrategy, useClass: PathLocationStrategy };
}
