/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { DepartmentKind } from './departmentKind';
import { DepartmentType } from './departmentType';
import { DateTime } from '@lib-utils';


export interface DepartmentModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    name?: string | null;
    parentDepartmentId?: number | null;
    parentDepartment?: DepartmentModel;
    departmentType?: DepartmentType;
    isActive?: boolean;
    departmentKind?: DepartmentKind;
    branchId?: string | null;
    filialId?: string | null;
    timeZone?: string | null;
    externalId?: number | null;
    region?: string | null;
    city?: string | null;
}
export namespace DepartmentModel {
}


