/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MortgageProgramType } from './mortgageProgramType';
import { AdditionalCondition } from './additionalCondition';
import { PaymentInfoModel } from './paymentInfoModel';
import { BankDecisionModel } from './bankDecisionModel';
import { CreditProductModel } from './creditProductModel';
import { InsuranceForm } from './insuranceForm';
import { InitialContributionSource } from './initialContributionSource';
import { ApplicationModel } from './applicationModel';
import { DateOnly } from '@lib-utils';
import { CalculationResultModel } from './calculationResultModel';
import { DateTime } from '@lib-utils';
import { NonStandardType } from './nonStandardType';
import { UserModel } from './userModel';
import { PartnerModel } from './partnerModel';
import { PaymentType } from './paymentType';
import { ResidentialComplexModel } from './residentialComplexModel';
import { ProofOfIncome } from './proofOfIncome';


export interface CreditInfoModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    application?: ApplicationModel;
    residentialComplexId?: number | null;
    residentialComplex?: ResidentialComplexModel;
    creditProductId?: number | null;
    creditProduct?: CreditProductModel;
    calculationResultId?: number | null;
    calculationResult?: CalculationResultModel;
    firstPaymentInfos?: Array<PaymentInfoModel> | null;
    requestedAmount?: number;
    firstAmount?: number;
    requestedInitialPaymentSumPercent?: number;
    length?: number;
    proofOfIncome?: ProofOfIncome;
    realEstateValue?: number;
    subsidy?: number;
    maternalCapital?: number;
    initialContributionSource?: InitialContributionSource;
    otherInitialContributionSource?: string | null;
    mortgageProgramType?: MortgageProgramType;
    additionalCondition?: Array<AdditionalCondition> | null;
    otherAdditionalCondition?: string | null;
    currentAccountNumber?: string | null;
    collateralAccountNumber?: string | null;
    paymentType?: PaymentType;
    paymentDate?: DateOnly | null;
    bankDecisionId?: number | null;
    bankDecision?: BankDecisionModel;
    creditTransferPeriod?: number | null;
    insuranceAmount?: number | null;
    insuranceForm?: InsuranceForm;
    partnerId?: number | null;
    partner?: PartnerModel;
    allCalculationResults?: Array<CalculationResultModel> | null;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    pskEffectiveRate?: number | null;
    annuityRepayment?: number | null;
    pskEffectiveRateSum?: number | null;
    pskAvgPayment?: number | null;
    requestedRealEstateValue?: number;
    requestedFirstAmount?: number;
    requestedCalculatedRate?: number;
    requestedPeriod?: number;
    requestedMonthlyPayment?: number;
    isActive?: boolean;
}
export namespace CreditInfoModel {
}


