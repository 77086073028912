/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface EncumbranceDto { 
    pledgeeName?: string | null;
    pledgeeTaxNumber?: string | null;
    encumbranceAgreementName?: string | null;
    encumbranceAgreementDate?: DateOnly | null;
    encumbranceRegistrarName?: string | null;
    encumbranceRegisterDate?: DateOnly | null;
    encumbranceRegisterNumber?: string | null;
}

