export interface RectangleAnimationInfo {
  id: number;
  class: string;
  layer: number;
  left: number;
  width: number;
  height: number;
  bottom: number;
  enterStart: number;
  stretchStart: number;
}

export const BG_IMAGE_WIDTH = 1900;
export const BG_IMAGE_HEIGHT = 1182;

export const ORIGINAL_RECTANGLES: RectangleAnimationInfo[] = [
  {
    id: 1,
    class: 'purple-color',
    layer: 4,
    left: 1027,
    width: 350,
    height: 710,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 0,
    stretchStart: 18.68,
  },
  {
    id: 2,
    class: 'green-color',
    layer: 5,
    left: 2,
    width: 1600,
    height: 300,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 1,
    stretchStart: 19.5,
  },
  {
    id: 3,
    class: 'red-color',
    layer: 1,
    left: 890,
    width: 250,
    height: 1030,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 2,
    stretchStart: 18.12,
  },
  {
    id: 4,
    class: 'rose-color',
    layer: 4,
    left: 500,
    width: 450,
    height: 750,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 2,
    stretchStart: 17.8,
  },
  {
    id: 5,
    class: 'brown-color',
    layer: 2,
    left: 690,
    width: 310,
    height: 940,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 3,
    stretchStart: 14,
  },
  {
    id: 6,
    class: 'purple-color',
    layer: 2,
    left: 270,
    width: 280,
    height: 980,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 3,
    stretchStart: 15.24,
  },
  {
    id: 7,
    class: 'green-color',
    layer: 3,
    left: 470,
    width: 320,
    height: 805,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 4,
    stretchStart: 13.8,
  },
  {
    id: 8,
    class: 'green-color',
    layer: 5,
    left: 1840,
    width: 60,
    height: 910,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 5,
    stretchStart: 15.24,
  },
  {
    id: 9,
    class: 'red-color',
    layer: 4,
    left: 1450,
    width: 400,
    height: 780,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 6,
    stretchStart: 15.72,
  },
  {
    id: 10,
    class: 'brown-color',
    layer: 3,
    left: 1340,
    width: 300,
    height: 900,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 7,
    stretchStart: 14.36,
  },
  {
    id: 11,
    class: 'red-color',
    layer: 3,
    left: 20,
    width: 280,
    height: 720,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 8,
    stretchStart: 17.6,
  },
  {
    id: 12,
    class: 'rose-color',
    layer: 4,
    left: 0,
    width: 20,
    height: 680,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 9,
    stretchStart: 14.8,
  },
  {
    id: 13,
    class: 'rose-color',
    layer: 2,
    left: 1550,
    width: 350,
    height: 1040,
    bottom: BG_IMAGE_HEIGHT,
    enterStart: 10,
    stretchStart: 19,
  },
];

export function getActualRectangles() {
  // Определяем размеры, относительно которых строить анимацию
  // Доработать логику при необходимости обрабатывать динамическое изменение размеров экрана
  let imageWidth: number;
  let imageHeight: number;
  const ratioWidth = (window.innerHeight / BG_IMAGE_HEIGHT) * BG_IMAGE_WIDTH;
  const ratioHeight = (window.innerWidth / BG_IMAGE_WIDTH) * BG_IMAGE_HEIGHT;
  if (ratioWidth < window.innerWidth) {
    imageWidth = window.innerWidth;
    imageHeight = ratioHeight;
  } else {
    imageWidth = ratioWidth;
    imageHeight = window.innerHeight;
  }
  const scale = imageWidth / BG_IMAGE_WIDTH;
  return ORIGINAL_RECTANGLES.map((rectangleInfo) => ({
    ...rectangleInfo,
    left: (rectangleInfo.left * scale * 100) / window.innerWidth,
    width: (rectangleInfo.width * scale * 100) / window.innerWidth,
    height: (rectangleInfo.height * scale * 100) / window.innerHeight,
    bottom: (imageHeight * 100) / window.innerHeight,
  }));
}
