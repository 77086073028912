import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { getRouteParams, LOGO_SRC, NEW_LOGO_SRC, NotificationService, Nullable, SharedValidators } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveInputPasswordModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { UserApiService } from '@lib-mortgage/api';

@Component({
  selector: 'fnip-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  imports: [ReactiveInputPasswordModule, ButtonModule, RequestWrapperModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverPasswordComponent {
  protected readonly isNew = inject(ActivatedRoute).snapshot.data.isNew;
  protected readonly logo = this.isNew ? NEW_LOGO_SRC : LOGO_SRC;

  newPassword = new FormControl('', [
    SharedValidators.password({
      number: true,
      upper: true,
      lower: true,
      maxLength: 128,
      minLength: 8,
    }),
  ]);

  form = new FormGroup({
    newPassword: this.newPassword,
    repeatPassword: new FormControl('', [SharedValidators.equalValue(this.newPassword)]),
  });

  token = getRouteParams<{ token: string }>().token;

  recoverUserId: Nullable<number>;

  checkToken$ = this.userApiService
    .apiUserByTokenGet(this.token)
    .pipe(tap((res) => (this.recoverUserId = res?.data?.id)));

  navigateBack = () => {
    void this.router.navigate(['/auth/login']);
  };

  constructor(
    private readonly notificationService: NotificationService,
    private readonly userApiService: UserApiService,
    private readonly router: Router,
  ) {}

  submitFormCallback$ = () => {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return null;
    }

    return this.userApiService
      .apiUserPassPost({
        userId: this.recoverUserId!,
        password: this.form.value.newPassword!,
        token: this.token,
      })
      .pipe(
        tap(this.notificationService.onSuccess('Пароль успешно применен')),
        tap(this.notificationService.onError('Ошибки при смене пароля')),
        tap(() => this.router.navigate(['/auth/login'])),
      );
  };
}
