import { ApplicationModulesMeta } from '@lib-mortgage/widgets/default';
import { ApplicationModules, getRemoteLink, LAWYER_SPECIAL_ROLES } from './utils';

export const ApplicationModulesAbsoluteMetaMap: Record<ApplicationModules, ApplicationModulesMeta> = {
  [ApplicationModules.Admin]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.ADMIN',
    getLink: getRemoteLink('mortgage', 'admin'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.ADMIN',
    permissionOperation: 'AdminModuleAvailable',
  },
  [ApplicationModules.Archive]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.ARCHIVE',
    getLink: getRemoteLink('archive'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.ARCHIVE',
    permissionOperation: 'ArchiveModuleAvailable',
  },
  [ApplicationModules.Orders]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.APPLICATIONS',
    getLink: getRemoteLink('mortgage', 'orders'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.APPLICATIONS',
    permissionOperation: 'ordersModuleAvailable',
  },
  [ApplicationModules.Verificator]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.VERIFICATOR',
    getLink: getRemoteLink('verification', 'verificator'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.VERIFICATOR',
    permissionOperation: 'VerificatorModuleAvailable',
  },
  [ApplicationModules.VerificatorManager]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.VERIFICATOR_MANAGER',
    getLink: getRemoteLink('verification', 'verificator-manager'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.VERIFICATOR_MANAGER',
    permissionOperation: 'VerificatorManagerModuleAvailable',
  },
  [ApplicationModules.Authorizer]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.AUTHORIZER',
    getLink: getRemoteLink('mortgage', 'authorizer'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.AUTHORIZER',
    permissionOperation: 'AuthorizerModuleAvailable',
  },
  [ApplicationModules.Underwriter]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.UNDERWRITER',
    getLink: getRemoteLink('mortgage', 'underwriter'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.UNDERWRITER',
    permissionOperation: 'UnderwriterModuleAvailable',
  },
  [ApplicationModules.CrmCollection]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.CRM_COLLECTION',
    getLink: getRemoteLink('crm-collection'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.CRM_COLLECTION',
    permissionOperation: 'CrmCollectionModuleAvailable',
  },
  [ApplicationModules.Lawyer]: {
    getName: ({ hasRoles }) =>
      hasRoles(LAWYER_SPECIAL_ROLES) ? 'BASE.APPLICATION_MODULES.NAMES.LAWYER' : 'BASE.APPLICATION_MODULES.NAMES.URZ',
    getLink: getRemoteLink('mortgage', 'lawyer'),
    getDescription: ({ hasRoles }) =>
      hasRoles(LAWYER_SPECIAL_ROLES)
        ? 'BASE.APPLICATION_MODULES.DESCRIPTIONS.LAWYER'
        : 'BASE.APPLICATION_MODULES.DESCRIPTIONS.URZ',
    permissionOperation: 'LawyerModuleAvailable',
  },
  [ApplicationModules.Auto]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.AUTO',
    getLink: getRemoteLink('auto', 'applications'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.AUTO',
    permissionOperation: 'AutoModuleAvailable',
  },
  [ApplicationModules.AutoRisks]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.AUTO_RISKS',
    getLink: getRemoteLink('auto', 'risks'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.AUTO_RISKS',
    permissionOperation: 'AutoRiskModuleAvailable',
  },
  [ApplicationModules.AutoInspector]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.AUTO_INSPECTOR',
    getLink: getRemoteLink('auto', 'inspector'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.AUTO_INSPECTOR',
    permissionOperation: 'AutoInspectorModuleAvailable',
  },
  [ApplicationModules.AutoAdmin]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.AUTO_ADMIN',
    getLink: getRemoteLink('auto', 'admin'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.AUTO_ADMIN',
    permissionOperation: 'AutoAdminModuleAvailable',
  },
  [ApplicationModules.DealDocumentsVerificator]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.DEAL_DOCUMENTS_VERIFICATOR',
    getLink: getRemoteLink('mortgage', 'deal-documents-verificator'),
    description: 'BASE.APPLICATION_MODULES.DESCRIPTIONS.DEAL_DOCUMENTS_VERIFICATOR',
    permissionOperation: 'DealDocumentsVerificatorModuleAvailable',
  },
  [ApplicationModules.Gozo]: {
    name: 'BASE.APPLICATION_MODULES.NAMES.GOZO',
    description: '',
    permissionOperation: null,
  },
};
