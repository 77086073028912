/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { CallVerificationTaskDtoResponseData } from '../model/callVerificationTaskDtoResponseData';
// @ts-ignore
import { CallVerificationTaskUpdateRequest } from '../model/callVerificationTaskUpdateRequest';
// @ts-ignore
import { EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask } from '../model/employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { EmploymentDocumentVerificationTaskDtoResponseData } from '../model/employmentDocumentVerificationTaskDtoResponseData';
// @ts-ignore
import { EmploymentDtoResponseData } from '../model/employmentDtoResponseData';
// @ts-ignore
import { FileType } from '../model/fileType';
// @ts-ignore
import { FileTypificationTaskDtoResponseData } from '../model/fileTypificationTaskDtoResponseData';
// @ts-ignore
import { IdInfoDtoResponseData } from '../model/idInfoDtoResponseData';
// @ts-ignore
import { IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask } from '../model/incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { IncomeDocumentVerificationTaskDtoResponseData } from '../model/incomeDocumentVerificationTaskDtoResponseData';
// @ts-ignore
import { Int64IReadOnlyCollectionResponseData } from '../model/int64IReadOnlyCollectionResponseData';
// @ts-ignore
import { Int64ResponseData } from '../model/int64ResponseData';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { PassportAddressVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportAddressVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportAddressVerificationTaskDtoResponseData } from '../model/passportAddressVerificationTaskDtoResponseData';
// @ts-ignore
import { PassportVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportVerificationTaskDtoResponseData } from '../model/passportVerificationTaskDtoResponseData';
// @ts-ignore
import { PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask } from '../model/pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PensionCertificateVerificationTaskDtoResponseData } from '../model/pensionCertificateVerificationTaskDtoResponseData';
// @ts-ignore
import { PensionFundVerificationTaskDtoBaseUpdateVerificationTask } from '../model/pensionFundVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PensionFundVerificationTaskDtoResponseData } from '../model/pensionFundVerificationTaskDtoResponseData';
// @ts-ignore
import { PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask } from '../model/previousPassportVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PreviousPassportVerificationTaskDtoResponseData } from '../model/previousPassportVerificationTaskDtoResponseData';
// @ts-ignore
import { ProcessTypificationTaskRequest } from '../model/processTypificationTaskRequest';
// @ts-ignore
import { ProfessionalIncomeCertificateTaskDtoResponseData } from '../model/professionalIncomeCertificateTaskDtoResponseData';
// @ts-ignore
import { ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask } from '../model/professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask';
// @ts-ignore
import { ResponseData } from '../model/responseData';
// @ts-ignore
import { SnilsVerificationTaskDtoBaseUpdateVerificationTask } from '../model/snilsVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SnilsVerificationTaskDtoResponseData } from '../model/snilsVerificationTaskDtoResponseData';
// @ts-ignore
import { SopdVerificationTaskDtoBaseUpdateVerificationTask } from '../model/sopdVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SopdVerificationTaskDtoResponseData } from '../model/sopdVerificationTaskDtoResponseData';
// @ts-ignore
import { SupervisorUpdateTaskRequest } from '../model/supervisorUpdateTaskRequest';
// @ts-ignore
import { TaxNumberVerificationTaskDtoBaseUpdateVerificationTask } from '../model/taxNumberVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { TaxNumberVerificationTaskDtoResponseData } from '../model/taxNumberVerificationTaskDtoResponseData';
// @ts-ignore
import { UpdateIncomeStatementTaskRequest } from '../model/updateIncomeStatementTaskRequest';
// @ts-ignore
import { UpdateRedirectedToSupervisionEnploymentHistoryTaskRequest } from '../model/updateRedirectedToSupervisionEnploymentHistoryTaskRequest';
// @ts-ignore
import { UpdateRedirectedToSupervisionPassportTaskRequest } from '../model/updateRedirectedToSupervisionPassportTaskRequest';
// @ts-ignore
import { UpdateRedirectedToSupervisionPensionFundTaskRequest } from '../model/updateRedirectedToSupervisionPensionFundTaskRequest';
// @ts-ignore
import { UpdateVerificationTaskRequest } from '../model/updateVerificationTaskRequest';
// @ts-ignore
import { VerificaitonTaskResolutionDtoIReadOnlyCollectionResponseData } from '../model/verificaitonTaskResolutionDtoIReadOnlyCollectionResponseData';
// @ts-ignore
import { VerificationClientDtoIListResponseData } from '../model/verificationClientDtoIListResponseData';
// @ts-ignore
import { VerificationPagedResultDtoResponseData } from '../model/verificationPagedResultDtoResponseData';
// @ts-ignore
import { VerificationStatisticDtoResponseData } from '../model/verificationStatisticDtoResponseData';
// @ts-ignore
import { VerificationTaskDeleteRequestModel } from '../model/verificationTaskDeleteRequestModel';
// @ts-ignore
import { VerificationTaskFalsificationFlagsDtoIListResponseData } from '../model/verificationTaskFalsificationFlagsDtoIListResponseData';
// @ts-ignore
import { VerificationTaskFalsificationFlagsDtoResponseData } from '../model/verificationTaskFalsificationFlagsDtoResponseData';
// @ts-ignore
import { VerificationTaskInfoDtoIReadOnlyCollectionResponseData } from '../model/verificationTaskInfoDtoIReadOnlyCollectionResponseData';
// @ts-ignore
import { VerificationTaskModelIListResponseData } from '../model/verificationTaskModelIListResponseData';
// @ts-ignore
import { VerificationTaskResolutionTypesDtoIListResponseData } from '../model/verificationTaskResolutionTypesDtoIListResponseData';
// @ts-ignore
import { VerificationTaskResolutionTypesDtoResponseData } from '../model/verificationTaskResolutionTypesDtoResponseData';
// @ts-ignore
import { VerificationTaskSortFields } from '../model/verificationTaskSortFields';
// @ts-ignore
import { VerificationTaskType } from '../model/verificationTaskType';
// @ts-ignore
import { VerificationTasksFilterTypeFilterValuesRequest } from '../model/verificationTasksFilterTypeFilterValuesRequest';
// @ts-ignore
import { VerificatorInfoDtoIListResponseData } from '../model/verificatorInfoDtoIListResponseData';
// @ts-ignore
import { VerificatorInfoDtoResponseData } from '../model/verificatorInfoDtoResponseData';
// @ts-ignore
import { VerificatorType } from '../model/verificatorType';
// @ts-ignore
import { VerificatorUpdateRequest } from '../model/verificatorUpdateRequest';
// @ts-ignore
import { VerificatorsFilterTypeFilterValuesRequest } from '../model/verificatorsFilterTypeFilterValuesRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { MortgageConfiguration }                                     from '../configuration';



const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class VerificationApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new MortgageConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: MortgageConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Получить основную информацию по клиентам заявки (заемщику и созаемщикам).
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationApplicationIdClientsGet(applicationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationClientDtoIListResponseData>;
    public apiVerificationApplicationIdClientsGet(applicationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationClientDtoIListResponseData>>;
    public apiVerificationApplicationIdClientsGet(applicationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationClientDtoIListResponseData>>;
    public apiVerificationApplicationIdClientsGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/clients`;
        return this.httpClient.request<VerificationClientDtoIListResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создать задачи типификации для заявки.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationApplicationIdTasksFileTypificationPost(applicationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ResponseData>;
    public apiVerificationApplicationIdTasksFileTypificationPost(applicationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ResponseData>>;
    public apiVerificationApplicationIdTasksFileTypificationPost(applicationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ResponseData>>;
    public apiVerificationApplicationIdTasksFileTypificationPost(applicationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/tasks/fileTypification`;
        return this.httpClient.request<ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationApplicationIdTasksGet(applicationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskModelIListResponseData>;
    public apiVerificationApplicationIdTasksGet(applicationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskModelIListResponseData>>;
    public apiVerificationApplicationIdTasksGet(applicationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskModelIListResponseData>>;
    public apiVerificationApplicationIdTasksGet(applicationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/tasks`;
        return this.httpClient.request<VerificationTaskModelIListResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Проверить наличие задач верификации дли клиента.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки.
     * @param clientId Идентификатор клиента.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationCheckActiveTasksForClientGet(applicationId: number, clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiVerificationCheckActiveTasksForClientGet(applicationId: number, clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiVerificationCheckActiveTasksForClientGet(applicationId: number, clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiVerificationCheckActiveTasksForClientGet(applicationId: number, clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/checkActiveTasksForClient`;
        return this.httpClient.request<BooleanResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить информацию о верификаторе.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Statistics.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationStatisticGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationStatisticDtoResponseData>;
    public apiVerificationStatisticGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationStatisticDtoResponseData>>;
    public apiVerificationStatisticGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationStatisticDtoResponseData>>;
    public apiVerificationStatisticGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/statistic`;
        return this.httpClient.request<VerificationStatisticDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Определить, что задача для созаёмщика.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки.
     * @param clientId Идентификатор клиента.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskCheckIsCoborrowerTaskGet(applicationId: number, clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiVerificationTaskCheckIsCoborrowerTaskGet(applicationId: number, clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiVerificationTaskCheckIsCoborrowerTaskGet(applicationId: number, clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiVerificationTaskCheckIsCoborrowerTaskGet(applicationId: number, clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/checkIsCoborrowerTask`;
        return this.httpClient.request<BooleanResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список задач верификатора с вынесенным решением.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.Resolution.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationIds Список идентификаторов заявок.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskResolutionsGet(applicationIds?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificaitonTaskResolutionDtoIReadOnlyCollectionResponseData>;
    public apiVerificationTaskResolutionsGet(applicationIds?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificaitonTaskResolutionDtoIReadOnlyCollectionResponseData>>;
    public apiVerificationTaskResolutionsGet(applicationIds?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificaitonTaskResolutionDtoIReadOnlyCollectionResponseData>>;
    public apiVerificationTaskResolutionsGet(applicationIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationIds) {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationIds, 'applicationIds');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/taskResolutions`;
        return this.httpClient.request<VerificaitonTaskResolutionDtoIReadOnlyCollectionResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу звонка.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CallVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdCallGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CallVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdCallGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CallVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdCallGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/call`;
        return this.httpClient.request<CallVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу звонка.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param callVerificationTaskUpdateRequest Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallPut(taskId: number, callVerificationTaskUpdateRequest?: CallVerificationTaskUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdCallPut(taskId: number, callVerificationTaskUpdateRequest?: CallVerificationTaskUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdCallPut(taskId: number, callVerificationTaskUpdateRequest?: CallVerificationTaskUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdCallPut(taskId: number, callVerificationTaskUpdateRequest?: CallVerificationTaskUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/call`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: callVerificationTaskUpdateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу документа о занятости.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param fileType Тип файла занятости.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdEmploymentDocumentGet(taskId: number, fileType: FileType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<EmploymentDocumentVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(taskId: number, fileType: FileType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<EmploymentDocumentVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(taskId: number, fileType: FileType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<EmploymentDocumentVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(taskId: number, fileType: FileType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employmentDocument`;
        return this.httpClient.request<EmploymentDocumentVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу трудовой книжки (employmentHistory) / трудового договора (employmentContract).
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdEmploymentDocumentPut(taskId: number, employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(taskId: number, employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(taskId: number, employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(taskId: number, employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employmentDocument`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу верификации справки о доходах (2-НДФЛ или справки по форме банка).
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param fileType Тип файла справки о доходе.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIncomeStatementGet(taskId: number, fileType: FileType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IncomeDocumentVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdIncomeStatementGet(taskId: number, fileType: FileType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IncomeDocumentVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdIncomeStatementGet(taskId: number, fileType: FileType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IncomeDocumentVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdIncomeStatementGet(taskId: number, fileType: FileType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/incomeStatement`;
        return this.httpClient.request<IncomeDocumentVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу 2-НДФЛ.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIncomeStatementPut(taskId: number, incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask?: IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdIncomeStatementPut(taskId: number, incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask?: IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdIncomeStatementPut(taskId: number, incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask?: IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdIncomeStatementPut(taskId: number, incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask?: IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/incomeStatement`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdPassportGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passport`;
        return this.httpClient.request<PassportVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param passportVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportPut(taskId: number, passportVerificationTaskDtoBaseUpdateVerificationTask?: PassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPassportPut(taskId: number, passportVerificationTaskDtoBaseUpdateVerificationTask?: PassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportPut(taskId: number, passportVerificationTaskDtoBaseUpdateVerificationTask?: PassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportPut(taskId: number, passportVerificationTaskDtoBaseUpdateVerificationTask?: PassportVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passport`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу адреса паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportRegistrationGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportAddressVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportAddressVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportAddressVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportRegistration`;
        return this.httpClient.request<PassportAddressVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу адреса паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param passportAddressVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportRegistrationPut(taskId: number, passportAddressVerificationTaskDtoBaseUpdateVerificationTask?: PassportAddressVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(taskId: number, passportAddressVerificationTaskDtoBaseUpdateVerificationTask?: PassportAddressVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(taskId: number, passportAddressVerificationTaskDtoBaseUpdateVerificationTask?: PassportAddressVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(taskId: number, passportAddressVerificationTaskDtoBaseUpdateVerificationTask?: PassportAddressVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportRegistration`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportAddressVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Поставить Задачу на Паузу
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.P&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор Задачи
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPausePost(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiVerificationTaskTaskIdPausePost(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiVerificationTaskTaskIdPausePost(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiVerificationTaskTaskIdPausePost(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pause`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу документа о пенсии.
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionCertificateGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PensionCertificateVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdPensionCertificateGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PensionCertificateVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionCertificateGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PensionCertificateVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionCertificateGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionCertificate`;
        return this.httpClient.request<PensionCertificateVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу по документу о пенсии.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionCertificatePut(taskId: number, pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask?: PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPensionCertificatePut(taskId: number, pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask?: PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionCertificatePut(taskId: number, pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask?: PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionCertificatePut(taskId: number, pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask?: PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionCertificate`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу верификации выписки из ПФР
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionFundGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PensionFundVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdPensionFundGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PensionFundVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionFundGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PensionFundVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionFundGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionFund`;
        return this.httpClient.request<PensionFundVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу верификации выписки из ПФР
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи
     * @param pensionFundVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionFundPut(taskId: number, pensionFundVerificationTaskDtoBaseUpdateVerificationTask?: PensionFundVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPensionFundPut(taskId: number, pensionFundVerificationTaskDtoBaseUpdateVerificationTask?: PensionFundVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionFundPut(taskId: number, pensionFundVerificationTaskDtoBaseUpdateVerificationTask?: PensionFundVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPensionFundPut(taskId: number, pensionFundVerificationTaskDtoBaseUpdateVerificationTask?: PensionFundVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionFund`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pensionFundVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ранее выданного паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPreviousPassportGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PreviousPassportVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdPreviousPassportGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PreviousPassportVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPreviousPassportGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PreviousPassportVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdPreviousPassportGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/previousPassport`;
        return this.httpClient.request<PreviousPassportVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ранее выданного паспорта.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param previousPassportVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPreviousPassportPut(taskId: number, previousPassportVerificationTaskDtoBaseUpdateVerificationTask?: PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPreviousPassportPut(taskId: number, previousPassportVerificationTaskDtoBaseUpdateVerificationTask?: PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPreviousPassportPut(taskId: number, previousPassportVerificationTaskDtoBaseUpdateVerificationTask?: PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPreviousPassportPut(taskId: number, previousPassportVerificationTaskDtoBaseUpdateVerificationTask?: PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/previousPassport`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: previousPassportVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу cправки о профессиональном доходе.
     * @param taskId Идентификатор задачи.
     * @param fileType Тип файла справки о доходе.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(taskId: number, fileType: FileType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ProfessionalIncomeCertificateTaskDtoResponseData>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(taskId: number, fileType: FileType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ProfessionalIncomeCertificateTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(taskId: number, fileType: FileType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ProfessionalIncomeCertificateTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(taskId: number, fileType: FileType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/professionalIncomeCertificate`;
        return this.httpClient.request<ProfessionalIncomeCertificateTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу cправки о профессиональном доходе.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(taskId: number, professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask?: ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(taskId: number, professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask?: ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(taskId: number, professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask?: ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(taskId: number, professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask?: ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/professionalIncomeCertificate`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param updateVerificationTaskRequest Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPut(taskId: number, updateVerificationTaskRequest?: UpdateVerificationTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdPut(taskId: number, updateVerificationTaskRequest?: UpdateVerificationTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPut(taskId: number, updateVerificationTaskRequest?: UpdateVerificationTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdPut(taskId: number, updateVerificationTaskRequest?: UpdateVerificationTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateVerificationTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу СНИЛС.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSnilsGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SnilsVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdSnilsGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SnilsVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdSnilsGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SnilsVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdSnilsGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/snils`;
        return this.httpClient.request<SnilsVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу СНИЛС.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param snilsVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSnilsPut(taskId: number, snilsVerificationTaskDtoBaseUpdateVerificationTask?: SnilsVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdSnilsPut(taskId: number, snilsVerificationTaskDtoBaseUpdateVerificationTask?: SnilsVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdSnilsPut(taskId: number, snilsVerificationTaskDtoBaseUpdateVerificationTask?: SnilsVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdSnilsPut(taskId: number, snilsVerificationTaskDtoBaseUpdateVerificationTask?: SnilsVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/snils`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: snilsVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу СОПД.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSopdGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SopdVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdSopdGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SopdVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdSopdGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SopdVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdSopdGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/sopd`;
        return this.httpClient.request<SopdVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу СОПД.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param sopdVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSopdPut(taskId: number, sopdVerificationTaskDtoBaseUpdateVerificationTask?: SopdVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdSopdPut(taskId: number, sopdVerificationTaskDtoBaseUpdateVerificationTask?: SopdVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdSopdPut(taskId: number, sopdVerificationTaskDtoBaseUpdateVerificationTask?: SopdVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdSopdPut(taskId: number, sopdVerificationTaskDtoBaseUpdateVerificationTask?: SopdVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/sopd`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: sopdVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ИНН.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTaxNumberGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TaxNumberVerificationTaskDtoResponseData>;
    public apiVerificationTaskTaskIdTaxNumberGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TaxNumberVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdTaxNumberGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TaxNumberVerificationTaskDtoResponseData>>;
    public apiVerificationTaskTaskIdTaxNumberGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/taxNumber`;
        return this.httpClient.request<TaxNumberVerificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ИНН.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param taxNumberVerificationTaskDtoBaseUpdateVerificationTask Данные обновления задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTaxNumberPut(taskId: number, taxNumberVerificationTaskDtoBaseUpdateVerificationTask?: TaxNumberVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTaskTaskIdTaxNumberPut(taskId: number, taxNumberVerificationTaskDtoBaseUpdateVerificationTask?: TaxNumberVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdTaxNumberPut(taskId: number, taxNumberVerificationTaskDtoBaseUpdateVerificationTask?: TaxNumberVerificationTaskDtoBaseUpdateVerificationTask, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTaskTaskIdTaxNumberPut(taskId: number, taxNumberVerificationTaskDtoBaseUpdateVerificationTask?: TaxNumberVerificationTaskDtoBaseUpdateVerificationTask, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/taxNumber`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: taxNumberVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Снять Задачу с Паузы
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.P&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор Задачи
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdUnPausePost(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiVerificationTaskTaskIdUnPausePost(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiVerificationTaskTaskIdUnPausePost(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiVerificationTaskTaskIdUnPausePost(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/unPause`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаление задач верификации.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.D&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param verificationTaskDeleteRequestModel Запрос с фильтрами.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksDeleteDelete(verificationTaskDeleteRequestModel?: VerificationTaskDeleteRequestModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64IReadOnlyCollectionResponseData>;
    public apiVerificationTasksDeleteDelete(verificationTaskDeleteRequestModel?: VerificationTaskDeleteRequestModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64IReadOnlyCollectionResponseData>>;
    public apiVerificationTasksDeleteDelete(verificationTaskDeleteRequestModel?: VerificationTaskDeleteRequestModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64IReadOnlyCollectionResponseData>>;
    public apiVerificationTasksDeleteDelete(verificationTaskDeleteRequestModel?: VerificationTaskDeleteRequestModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/delete`;
        return this.httpClient.request<Int64IReadOnlyCollectionResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verificationTaskDeleteRequestModel,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список признаков фальсификаций по документу с определёнными типом задачи верификации.
     * @param taskType Тип задачи верификации.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskFalsificationFlagsDtoResponseData>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskFalsificationFlagsDtoResponseData>>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskFalsificationFlagsDtoResponseData>>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(taskType?: VerificationTaskType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (taskType !== undefined && taskType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taskType, 'taskType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/falsificationFlags/forTaskType`;
        return this.httpClient.request<VerificationTaskFalsificationFlagsDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список признаков фальсификаций по документам для всех видов задач верификации.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskFalsificationFlagsDtoIListResponseData>;
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskFalsificationFlagsDtoIListResponseData>>;
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskFalsificationFlagsDtoIListResponseData>>;
    public apiVerificationTasksFalsificationFlagsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/falsificationFlags`;
        return this.httpClient.request<VerificationTaskFalsificationFlagsDtoIListResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачи с фильтрацией по параметрам запроса.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param page 
     * @param perPage 
     * @param sortFieldsOrderBy &lt;p&gt;&lt;/p&gt;
     * @param sortFieldsFieldBy &lt;p&gt;&lt;/p&gt;
     * @param filters Фильтры.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksGet(page?: number, perPage?: number, sortFieldsOrderBy?: OrderByTypes, sortFieldsFieldBy?: VerificationTaskSortFields, filters?: VerificationTasksFilterTypeFilterValuesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationPagedResultDtoResponseData>;
    public apiVerificationTasksGet(page?: number, perPage?: number, sortFieldsOrderBy?: OrderByTypes, sortFieldsFieldBy?: VerificationTaskSortFields, filters?: VerificationTasksFilterTypeFilterValuesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationPagedResultDtoResponseData>>;
    public apiVerificationTasksGet(page?: number, perPage?: number, sortFieldsOrderBy?: OrderByTypes, sortFieldsFieldBy?: VerificationTaskSortFields, filters?: VerificationTasksFilterTypeFilterValuesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationPagedResultDtoResponseData>>;
    public apiVerificationTasksGet(page?: number, perPage?: number, sortFieldsOrderBy?: OrderByTypes, sortFieldsFieldBy?: VerificationTaskSortFields, filters?: VerificationTasksFilterTypeFilterValuesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {






        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }
        if (sortFieldsOrderBy !== undefined && sortFieldsOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldsOrderBy, 'sortFields.orderBy');
        }
        if (sortFieldsFieldBy !== undefined && sortFieldsFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldsFieldBy, 'sortFields.fieldBy');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'filters');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks`;
        return this.httpClient.request<VerificationPagedResultDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список вариантов решений по документу с определёнными типом задачи верификации.
     * @param taskType Тип задачи верификации.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksResolutionTypesForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskResolutionTypesDtoResponseData>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskResolutionTypesDtoResponseData>>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(taskType?: VerificationTaskType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskResolutionTypesDtoResponseData>>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(taskType?: VerificationTaskType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (taskType !== undefined && taskType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taskType, 'taskType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/resolutionTypes/forTaskType`;
        return this.httpClient.request<VerificationTaskResolutionTypesDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список вариантов решений по документам для всех видов задач верификации.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksResolutionTypesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskResolutionTypesDtoIListResponseData>;
    public apiVerificationTasksResolutionTypesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskResolutionTypesDtoIListResponseData>>;
    public apiVerificationTasksResolutionTypesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskResolutionTypesDtoIListResponseData>>;
    public apiVerificationTasksResolutionTypesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/resolutionTypes`;
        return this.httpClient.request<VerificationTaskResolutionTypesDtoIListResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить количество задач по статусам документа.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksStatusesCountGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationPagedResultDtoResponseData>;
    public apiVerificationTasksStatusesCountGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationPagedResultDtoResponseData>>;
    public apiVerificationTasksStatusesCountGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationPagedResultDtoResponseData>>;
    public apiVerificationTasksStatusesCountGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasksStatusesCount`;
        return this.httpClient.request<VerificationPagedResultDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу типификации файла по идентификатору.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdFileTypificationGet(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileTypificationTaskDtoResponseData>;
    public apiVerificationTasksTaskIdFileTypificationGet(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileTypificationTaskDtoResponseData>>;
    public apiVerificationTasksTaskIdFileTypificationGet(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileTypificationTaskDtoResponseData>>;
    public apiVerificationTasksTaskIdFileTypificationGet(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/fileTypification`;
        return this.httpClient.request<FileTypificationTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обработать задачу типификации.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи типификации.
     * @param processTypificationTaskRequest Запрос.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdFileTypificationPut(taskId: number, processTypificationTaskRequest: ProcessTypificationTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileTypificationTaskDtoResponseData>;
    public apiVerificationTasksTaskIdFileTypificationPut(taskId: number, processTypificationTaskRequest: ProcessTypificationTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileTypificationTaskDtoResponseData>>;
    public apiVerificationTasksTaskIdFileTypificationPut(taskId: number, processTypificationTaskRequest: ProcessTypificationTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileTypificationTaskDtoResponseData>>;
    public apiVerificationTasksTaskIdFileTypificationPut(taskId: number, processTypificationTaskRequest: ProcessTypificationTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/fileTypification`;
        return this.httpClient.request<FileTypificationTaskDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: processTypificationTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Назначение задачи в статусе Pending на себя
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsAssignTaskToMyselfPost(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToMyselfPost(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToMyselfPost(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToMyselfPost(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/assignTaskToMyself`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Назначения задачи верификации на супервайзера.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.V&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsAssignTaskToSupervisorPost(taskId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToSupervisorPost(taskId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToSupervisorPost(taskId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToSupervisorPost(taskId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/assignTaskToSupervisor`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Назначение задачи в статусе Pending на Верификатора
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Verifier.A&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор Задачи
     * @param verificatorId Идентификатор Верификатора
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsAssignTaskToVerificatorVerificatorIdPost(taskId: number, verificatorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToVerificatorVerificatorIdPost(taskId: number, verificatorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToVerificatorVerificatorIdPost(taskId: number, verificatorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsAssignTaskToVerificatorVerificatorIdPost(taskId: number, verificatorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/assignTaskToVerificator/${this.configuration.encodeParam({name: "verificatorId", value: verificatorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Подтвердить/отклонить перевод задачи в статус RedirectedToSupervision.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId 
     * @param supervisorUpdateTaskRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionReviewPut(taskId: number, supervisorUpdateTaskRequest: SupervisorUpdateTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionReviewPut(taskId: number, supervisorUpdateTaskRequest: SupervisorUpdateTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionReviewPut(taskId: number, supervisorUpdateTaskRequest: SupervisorUpdateTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionReviewPut(taskId: number, supervisorUpdateTaskRequest: SupervisorUpdateTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/review`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supervisorUpdateTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу верификации 2-НДФЛ супервайзером
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи
     * @param updateIncomeStatementTaskRequest Запрос
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateIncomeStatementPut(taskId: number, updateIncomeStatementTaskRequest: UpdateIncomeStatementTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ResponseData>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateIncomeStatementPut(taskId: number, updateIncomeStatementTaskRequest: UpdateIncomeStatementTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateIncomeStatementPut(taskId: number, updateIncomeStatementTaskRequest: UpdateIncomeStatementTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateIncomeStatementPut(taskId: number, updateIncomeStatementTaskRequest: UpdateIncomeStatementTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/update-income-statement`;
        return this.httpClient.request<ResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateIncomeStatementTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу верификации паспорта супервайзером
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи.
     * @param updateRedirectedToSupervisionPassportTaskRequest Запрос.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePassportPost(taskId: number, updateRedirectedToSupervisionPassportTaskRequest: UpdateRedirectedToSupervisionPassportTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePassportPost(taskId: number, updateRedirectedToSupervisionPassportTaskRequest: UpdateRedirectedToSupervisionPassportTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePassportPost(taskId: number, updateRedirectedToSupervisionPassportTaskRequest: UpdateRedirectedToSupervisionPassportTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePassportPost(taskId: number, updateRedirectedToSupervisionPassportTaskRequest: UpdateRedirectedToSupervisionPassportTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/update-passport`;
        return this.httpClient.request<IdInfoDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateRedirectedToSupervisionPassportTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу верификации выписки из ПФР супервайзером
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи
     * @param updateRedirectedToSupervisionPensionFundTaskRequest Запрос
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePensionFundPut(taskId: number, updateRedirectedToSupervisionPensionFundTaskRequest: UpdateRedirectedToSupervisionPensionFundTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ResponseData>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePensionFundPut(taskId: number, updateRedirectedToSupervisionPensionFundTaskRequest: UpdateRedirectedToSupervisionPensionFundTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePensionFundPut(taskId: number, updateRedirectedToSupervisionPensionFundTaskRequest: UpdateRedirectedToSupervisionPensionFundTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdatePensionFundPut(taskId: number, updateRedirectedToSupervisionPensionFundTaskRequest: UpdateRedirectedToSupervisionPensionFundTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/update-pension-fund`;
        return this.httpClient.request<ResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateRedirectedToSupervisionPensionFundTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу верификации ТК / ЭТК супервайзером
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param taskId Идентификатор задачи
     * @param updateRedirectedToSupervisionEnploymentHistoryTaskRequest Запрос обновления данных задачи ТК / ЭТК Супервайзером
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateTkPost(taskId: number, updateRedirectedToSupervisionEnploymentHistoryTaskRequest: UpdateRedirectedToSupervisionEnploymentHistoryTaskRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<EmploymentDtoResponseData>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateTkPost(taskId: number, updateRedirectedToSupervisionEnploymentHistoryTaskRequest: UpdateRedirectedToSupervisionEnploymentHistoryTaskRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<EmploymentDtoResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateTkPost(taskId: number, updateRedirectedToSupervisionEnploymentHistoryTaskRequest: UpdateRedirectedToSupervisionEnploymentHistoryTaskRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<EmploymentDtoResponseData>>;
    public apiVerificationTasksTaskIdTransitionsRedirectedToSupervisionUpdateTkPost(taskId: number, updateRedirectedToSupervisionEnploymentHistoryTaskRequest: UpdateRedirectedToSupervisionEnploymentHistoryTaskRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/update-tk`;
        return this.httpClient.request<EmploymentDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateRedirectedToSupervisionEnploymentHistoryTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить информацию о верификаторе по текущему пользователю.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificatorInfoDtoResponseData>;
    public apiVerificationVerificatorGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificatorInfoDtoResponseData>>;
    public apiVerificationVerificatorGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificatorInfoDtoResponseData>>;
    public apiVerificationVerificatorGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verificator`;
        return this.httpClient.request<VerificatorInfoDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список задач верификатора.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param verificatorId Идентификатор верификатора.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorIdTaskGet(verificatorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskInfoDtoIReadOnlyCollectionResponseData>;
    public apiVerificationVerificatorIdTaskGet(verificatorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskInfoDtoIReadOnlyCollectionResponseData>>;
    public apiVerificationVerificatorIdTaskGet(verificatorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskInfoDtoIReadOnlyCollectionResponseData>>;
    public apiVerificationVerificatorIdTaskGet(verificatorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "verificatorId", value: verificatorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/task`;
        return this.httpClient.request<VerificationTaskInfoDtoIReadOnlyCollectionResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создание оператора.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.C&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param userId Идентификатор пользователя.
     * @param type Тип Верификатора.&lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorUserIdPost(userId: number, type?: VerificatorType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationVerificatorUserIdPost(userId: number, type?: VerificatorType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationVerificatorUserIdPost(userId: number, type?: VerificatorType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationVerificatorUserIdPost(userId: number, type?: VerificatorType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verificator/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<IdInfoDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить информацию о верификаторе.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param verificatorId Идентификатор верификатора, если не задан то текущий пользователя.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorVerificatorIdGet(verificatorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificatorInfoDtoResponseData>;
    public apiVerificationVerificatorVerificatorIdGet(verificatorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificatorInfoDtoResponseData>>;
    public apiVerificationVerificatorVerificatorIdGet(verificatorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificatorInfoDtoResponseData>>;
    public apiVerificationVerificatorVerificatorIdGet(verificatorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verificator/${this.configuration.encodeParam({name: "verificatorId", value: verificatorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<VerificatorInfoDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить оператора.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.U&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param verificatorId Идентификатор верификатора.
     * @param verificatorUpdateRequest Объект обновления верификатора.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorVerificatorIdPut(verificatorId: number, verificatorUpdateRequest?: VerificatorUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiVerificationVerificatorVerificatorIdPut(verificatorId: number, verificatorUpdateRequest?: VerificatorUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiVerificationVerificatorVerificatorIdPut(verificatorId: number, verificatorUpdateRequest?: VerificatorUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiVerificationVerificatorVerificatorIdPut(verificatorId: number, verificatorUpdateRequest?: VerificatorUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/verificator/${this.configuration.encodeParam({name: "verificatorId", value: verificatorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<IdInfoDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verificatorUpdateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список операторов.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;VER.L&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param filters Фильтры               &#x60;&#x60;&#x60;                             Доступные фильтры                                 \&quot;filters\&quot;: {                       \&quot;VerifierType\&quot;: \&quot;General,Supervisor\&quot; // Типы Верификатора, через запятую                       \&quot;MoreThanTasksCount\&quot;: \&quot;0\&quot; // Задач больше чем                       \&quot;LessThanTasksCount\&quot;: \&quot;3\&quot; // Задач меньше чем                   }                             &#x60;&#x60;&#x60;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerificatorsGet(filters?: VerificatorsFilterTypeFilterValuesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificatorInfoDtoIListResponseData>;
    public apiVerificationVerificatorsGet(filters?: VerificatorsFilterTypeFilterValuesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificatorInfoDtoIListResponseData>>;
    public apiVerificationVerificatorsGet(filters?: VerificatorsFilterTypeFilterValuesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificatorInfoDtoIListResponseData>>;
    public apiVerificationVerificatorsGet(filters?: VerificatorsFilterTypeFilterValuesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'filters');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verificators`;
        return this.httpClient.request<VerificatorInfoDtoIListResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
