import { Signal, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import {
  OptionsProvider,
  OptionsProviderExact,
  OptionsProviderStatic,
  OptionsProviderTerm,
  OptionsProviderType,
} from './options.model';

export type LoadingState = 'loading' | 'idle' | 'error';

export type OptionSortType = 'default' | 'termFirst';

export function getOptionsProvider(
  optionsProviders: OptionsProvider[],
  type: OptionsProviderType.Static,
): OptionsProviderStatic | null;
export function getOptionsProvider(
  optionsProviders: OptionsProvider[],
  type: OptionsProviderType.Exact,
): OptionsProviderExact | null;
export function getOptionsProvider(
  optionsProviders: OptionsProvider[],
  type: OptionsProviderType.Term,
): OptionsProviderTerm | null;
export function getOptionsProvider(optionsProviders: OptionsProvider[], type: OptionsProviderType) {
  return optionsProviders.find((provider) => provider.type === type) ?? null;
}

export const DEFAULT_MIN_TERM_LENGTH = 3;
export const DEFAULT_LOADING_MESSAGE = 'Загрузка...';

export function getTermOptionsSignal(
  selectedProviderStrategy: Signal<OptionsProviderType>,
  term: Signal<string | null>,
  optionsProviders: OptionsProvider[],
  loadingStateCb: (state: LoadingState, error?: unknown) => unknown,
) {
  const { options: providerOptionsFn } = getOptionsProvider(optionsProviders, OptionsProviderType.Term) ?? {};

  if (!providerOptionsFn) return signal(null);

  return toSignal(
    combineLatest([toObservable(term), toObservable(selectedProviderStrategy)]).pipe(
      tap(([_termValue, strategy]) => strategy === OptionsProviderType.Term && loadingStateCb('loading')),
      debounceTime(300),
      distinctUntilChanged(),
      filter(([_termValue, strategy]) => {
        if (strategy !== OptionsProviderType.Term) loadingStateCb('idle');
        return strategy === OptionsProviderType.Term;
      }),
      switchMap(([termValue]) =>
        providerOptionsFn(termValue!).pipe(
          tap(() => loadingStateCb('idle')),
          catchError((err) => {
            loadingStateCb('error', err);
            return of([]);
          }),
        ),
      ),
    ),
  );
}

export function getStaticOptionsSignal(
  selectedProviderStrategy: Signal<OptionsProviderType>,
  optionsProviders: OptionsProvider[],
  loadingStateCb: (state: LoadingState, error?: unknown) => unknown,
) {
  const { options: providerOptions } = getOptionsProvider(optionsProviders, OptionsProviderType.Static) ?? {};

  if (!providerOptions) return signal(null);

  return toSignal(
    combineLatest([providerOptions, toObservable(selectedProviderStrategy)]).pipe(
      tap(([_options, strategy]) => strategy === OptionsProviderType.Static && loadingStateCb('loading', null)),
      debounceTime(300),
      distinctUntilChanged(),
      filter(([_options, strategy]) => {
        if (strategy !== OptionsProviderType.Static) loadingStateCb('idle');
        return strategy === OptionsProviderType.Static;
      }),
      switchMap(([options]) =>
        options.pipe(
          tap(() => loadingStateCb('idle')),
          catchError((err) => {
            loadingStateCb('error', err);
            return of([]);
          }),
        ),
      ),
    ),
  );
}
