/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApplicationFilterTypeFilterValuesRequest } from '../model/applicationFilterTypeFilterValuesRequest';
// @ts-ignore
import { ApplicationSortFields } from '../model/applicationSortFields';
// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { DateTime } from '@lib-utils';
// @ts-ignore
import { FileStreamDtoResponseData } from '../model/fileStreamDtoResponseData';
// @ts-ignore
import { GetRecognitionJobsReportFilters } from '../model/getRecognitionJobsReportFilters';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { ResponseData } from '../model/responseData';
// @ts-ignore
import { RiskReportRequest } from '../model/riskReportRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { MortgageConfiguration }                                     from '../configuration';



const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class ReportApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new MortgageConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: MortgageConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Получить отчет по Заявкам
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.APL.Orders.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param query Запрос фильтрации.              &#x60;&#x60;&#x60;              Доступные фильтры (параметр query):                  \&quot;filters\&quot;: {                      \&quot;fullName\&quot;: \&quot;Иванов Иван\&quot; // ФИО заемщика                      \&quot;managerName\&quot;: \&quot;Иванов Иван\&quot; // Создатель заявки                      \&quot;personalManagerId\&quot;: \&quot;1,2,-1\&quot; // Идентификаторы Персональных менеджеров (-1 - \&quot;Не назначено\&quot;)                      \&quot;partnerId\&quot;: \&quot;132,186\&quot; // Идентификаторы партнёров                      \&quot;occupationType\&quot;: \&quot;Pensioner,Commercial\&quot; // Тип занятости                      \&quot;creditAmountFrom\&quot;: 150000 // Сумма кредита (от)                      \&quot;creditAmountTo\&quot;: 5000000 // Сумма кредита (до)                      \&quot;stateStatuses\&quot;: \&quot;Form:Draft\&quot; // Статус заявки                      \&quot;dateCreated\&quot;: \&quot;2022-01-01/2023-01-01\&quot; // Дата создания (Даты в ISO формате через слеш)                      \&quot;dateUpdated\&quot;: \&quot;2022-01-01/2023-01-01\&quot; // Дата обновления                  }              &#x60;&#x60;&#x60;
     * @param orderBy &lt;p&gt;&lt;/p&gt;
     * @param fieldBy &lt;p&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportApplicationOrdersReportGet(query?: ApplicationFilterTypeFilterValuesRequest, orderBy?: OrderByTypes, fieldBy?: ApplicationSortFields, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportApplicationOrdersReportGet(query?: ApplicationFilterTypeFilterValuesRequest, orderBy?: OrderByTypes, fieldBy?: ApplicationSortFields, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportApplicationOrdersReportGet(query?: ApplicationFilterTypeFilterValuesRequest, orderBy?: OrderByTypes, fieldBy?: ApplicationSortFields, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportApplicationOrdersReportGet(query?: ApplicationFilterTypeFilterValuesRequest, orderBy?: OrderByTypes, fieldBy?: ApplicationSortFields, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (fieldBy !== undefined && fieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fieldBy, 'fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/applicationOrdersReport`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет по переходам статусов заявок, отправленных в CRE.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.CRE.ApplicationChanges&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param from Дата начала периода отправки заявок в CRE.
     * @param to Дата окончания периода отправки заявок в CRE.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportChangesForCreGet(from?: DateTime, to?: DateTime, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportChangesForCreGet(from?: DateTime, to?: DateTime, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportChangesForCreGet(from?: DateTime, to?: DateTime, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportChangesForCreGet(from?: DateTime, to?: DateTime, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/changesForCre`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет Cronos
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.SPR.Cronos.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки
     * @param clientId Идентификатор клиента
     * @param accept Тип содержимого файла отчета: HTML (по умолчанию) или RTF
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportCronosGet(applicationId: number, clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}, accept?: string): Observable<Blob>;
    public apiReportCronosGet(applicationId: number, clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}, accept?: string): Observable<HttpResponse<Blob>>;
    public apiReportCronosGet(applicationId: number, clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}, accept?: string): Observable<HttpEvent<Blob>>;
    public apiReportCronosGet(applicationId: number, clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}, accept: string = 'text/html'): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (accept !== undefined && accept !== null) {
            localVarHeaders = localVarHeaders.set('accept', String(accept));
        }

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/cronos`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить полную анкету
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.CLI.FullForm.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки
     * @param coborrowerId Идентификатор созаемщика, если анкета для него
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportGetClientFullFormGet(applicationId: number, coborrowerId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportGetClientFullFormGet(applicationId: number, coborrowerId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportGetClientFullFormGet(applicationId: number, coborrowerId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportGetClientFullFormGet(applicationId: number, coborrowerId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (coborrowerId !== undefined && coborrowerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>coborrowerId, 'coborrowerId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/getClientFullForm`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет Grifid
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.SPR.Grifid.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки
     * @param clientId Идентификатор клиента
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportGrifidGet(applicationId: number, clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportGrifidGet(applicationId: number, clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportGrifidGet(applicationId: number, clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportGrifidGet(applicationId: number, clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/grifid`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет по заявкам с электронной закладной.
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.CLI.FullForm.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportKvartaGet(applicationId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportKvartaGet(applicationId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportKvartaGet(applicationId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportKvartaGet(applicationId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/kvarta`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчёт по задачам распознавания
     * @param getRecognitionJobsReportFilters Фильтры
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportRecognitionJobsPost(getRecognitionJobsReportFilters: GetRecognitionJobsReportFilters, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportRecognitionJobsPost(getRecognitionJobsReportFilters: GetRecognitionJobsReportFilters, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportRecognitionJobsPost(getRecognitionJobsReportFilters: GetRecognitionJobsReportFilters, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportRecognitionJobsPost(getRecognitionJobsReportFilters: GetRecognitionJobsReportFilters, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Report/recognitionJobs`;
        return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getRecognitionJobsReportFilters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет по рискам
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.APL.Deals.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param start С какой даты нужны заявки
     * @param end До какой даты нужны заявки
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportRiskReportGet(start?: DateTime, end?: DateTime, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportRiskReportGet(start?: DateTime, end?: DateTime, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportRiskReportGet(start?: DateTime, end?: DateTime, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportRiskReportGet(start?: DateTime, end?: DateTime, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (end !== undefined && end !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>end, 'end');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/riskReport`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Сгенерировать и отправить отчет по рискам
     * @param riskReportRequest Параметры генерации отчета
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportRunRiskReportUploadPost(riskReportRequest?: RiskReportRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiReportRunRiskReportUploadPost(riskReportRequest?: RiskReportRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiReportRunRiskReportUploadPost(riskReportRequest?: RiskReportRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiReportRunRiskReportUploadPost(riskReportRequest?: RiskReportRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Report/runRiskReportUpload`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: riskReportRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить отчет Verus
     * &lt;p&gt;Required permission (&lt;b&gt;Any&lt;/b&gt;):&lt;ul&gt;&lt;li&gt;&lt;b&gt;REP.SPR.Verus.R&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;
     * @param applicationId Идентификатор заявки
     * @param clientId Идентификатор клиента
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportVerusGet(applicationId: number, clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportVerusGet(applicationId: number, clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportVerusGet(applicationId: number, clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportVerusGet(applicationId: number, clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Report/verus`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
