/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';
import { AddressDto } from './addressDto';


export interface EvaluateReportDto { 
    id?: number | null;
    appraisalCompanyId?: number | null;
    appraisalCompanyName?: string | null;
    addressId?: number | null;
    address?: AddressDto;
    evaluateValue?: number | null;
    reportDate?: DateOnly | null;
    evaluationDate?: DateOnly | null;
    deviation?: number | null;
    reportNumber?: string | null;
    gozoValue?: number | null;
    reportCost?: number | null;
}

