/**
 * Определяет какой BE API используется
 * Должен быть равен кол-ву разных сгенерированных модулей API
 */
export const enum BeApiEnum {
  Auto = 'Auto',
  AutoLookup = 'AutoLookup',
  AutoSpr = 'AutoSpr',
  Mortgage = 'Mortgage',
  Archive = 'Archive',
  Collection = 'Collection',
  Verification = 'Verification',
  Bff = 'Bff',
}
