import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TuiDayRange, TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import { addDays, startOfDay, subMonths, subWeeks } from 'date-fns';
import { startWith } from 'rxjs/operators';
import {
  ExecuteWithPipeModule,
  Nullable,
  reactiveTestAttributesHostDirective,
  TestAttributesDirective,
  toTuiRange,
} from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { AbstractReactive } from '../abstract-reactive';
import { RangeSelect, RangeSelectNameMap } from './reactive-input-date-range-select.model';

@Component({
  selector: 'fnip-reactive-input-date-range-select',
  standalone: true,
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    TuiLetModule,
    ButtonModule,
    ReactiveFormsModule,
    TuiValueChangesModule,
    TestAttributesDirective,
  ],
  templateUrl: './reactive-input-date-range-select.component.html',
  styleUrls: ['./reactive-input-date-range-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputDateRangeSelectComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<TuiDayRange>>>;
  @Input() ranges: RangeSelect[] = [RangeSelect.Today, RangeSelect.Weak, RangeSelect.Month];

  RangeSelectNameMap = RangeSelectNameMap;

  currentValue$ = (control: Nullable<FormControl<Nullable<TuiDayRange>>>) =>
    control?.valueChanges.pipe(startWith(this.control?.value));

  isActive = (range: RangeSelect, value: Nullable<TuiDayRange>) => {
    const rangeDate = this.generateRangeDate(range);
    return rangeDate.toString() === value?.toString();
  };

  setRange = (range: RangeSelect, control: Nullable<FormControl<Nullable<TuiDayRange>>>) => () => {
    const rangeDate = this.generateRangeDate(range);
    control?.patchValue(rangeDate);
  };

  private generateRangeDate(range: RangeSelect) {
    let from = startOfDay(new Date());
    const to = startOfDay(addDays(from, 1));

    if (range === RangeSelect.Weak) from = subWeeks(from, 1);
    if (range === RangeSelect.Month) from = subMonths(from, 1);

    return toTuiRange(from, to);
  }
}
