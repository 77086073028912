import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ConfigService } from '@lib-config';
import { ExecuteWithPipeModule, getRouteContextData, NotificationService } from '@lib-utils';
import { ApplicationModuleCardComponent } from '@lib-widgets/core';
import { AuthorizationStorageService, getLogout$, PermissionService } from '@lib-mortgage/features/authorization';
import { ToolbarModule } from '@lib-mortgage/widgets/layout';
import { ApplicationModulesMetaResoled, DefaultPageData, getModuleMeta } from './application-modules-meta.map';

@Component({
  selector: 'fnip-home-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    ToolbarModule,
    ExecuteWithPipeModule,
    TuiLetModule,
    AsyncPipe,
    TranslateModule,
    ApplicationModuleCardComponent,
  ],
})
export class DefaultComponent {
  private readonly configService = inject(ConfigService);
  private readonly notificationService = inject(NotificationService);
  private readonly authorizationStorageService = inject(AuthorizationStorageService);
  private readonly permissionService = inject(PermissionService);
  private readonly router = inject(Router);

  protected readonly pageData = getRouteContextData<DefaultPageData>();

  constructor() {
    this.notificationService.allClose();
  }

  readonly logout$ = getLogout$();

  getModulesMeta = () => {
    const result = Object.values(this.pageData.moduleMeta ?? {})
      .map((module) =>
        getModuleMeta(module, {
          hasRoles: this.authorizationStorageService.hasRoles,
          configService: this.configService,
        }),
      )
      .filter(this.isCurrentUserHasCabinetPermission.bind(this));

    if (result.length === 1) {
      if (this.pageData.useLinks) {
        location.href = result[0].link;
      } else {
        void this.router.navigate([result[0].link]);
      }
    }

    return result;
  };

  isCurrentUserHasCabinetPermission(moduleInfo: ApplicationModulesMetaResoled): boolean {
    if (!moduleInfo?.permissionOperation) return false;
    if (this.permissionService.checkExactPermissionFor('AllModulesAvailable')) return true;
    return this.permissionService.checkExactPermissionFor(moduleInfo.permissionOperation);
  }
}
