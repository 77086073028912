/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceProductType } from './insuranceProductType';
import { EaFileInfo } from './eaFileInfo';
import { EaClient } from './eaClient';
import { EaUserModel } from './eaUserModel';
import { RegistrationType } from './registrationType';
import { PaymentType } from './paymentType';
import { CreditPurpose } from './creditPurpose';
import { EaSignatoryModel } from './eaSignatoryModel';
import { DateTime } from '@lib-utils';
import { NonStandardType } from './nonStandardType';
import { ProofOfIncome } from './proofOfIncome';


export interface EaCreditDossierInfo { 
    loanAgreementNumber?: string | null;
    loanApplicationNumber?: string | null;
    interestRate?: number;
    issueLoanDate?: DateTime;
    plannedEndDate?: DateTime | null;
    incomeVerificationMethod?: ProofOfIncome;
    initialPaymentMethod?: PaymentType;
    creditProgramId?: number | null;
    creditProgramName?: string | null;
    borrowers?: Array<EaClient> | null;
    applicationCreatorId?: number;
    applicationCreator?: EaUserModel;
    issuedLoanId?: number;
    issuedLoan?: EaSignatoryModel;
    mortgageLendingCenterId?: number | null;
    registrationType?: RegistrationType;
    insuranceInfo?: { [key: string]: InsuranceProductType; } | null;
    files?: Array<EaFileInfo> | null;
    loanAgreementFirstVersionLoaderId?: number;
    loanAgreementFirstVersionLoader?: EaUserModel;
    verificationInitiatorId?: number;
    verificationInitiator?: EaUserModel;
    dealDepartmentName?: string | null;
    cftDepartment?: string | null;
    cftCreditAgreementId?: number | null;
    purpose?: CreditPurpose;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    city?: string | null;
    assignee?: string | null;
    signatureType?: string | null;
    withInsurance?: boolean;
}
export namespace EaCreditDossierInfo {
}


