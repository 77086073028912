import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { Nullable, SharedValidators } from '@lib-utils';

@Directive()
export abstract class AbstractReactive implements OnChanges, OnDestroy {
  @Input({ required: true }) control: Nullable<AbstractControl>;
  @Input({ required: true }) fieldId!: string;
  @Input() label: Nullable<string>;
  @Input() isLabelBold = false;
  @Input() labelSize: TuiSizeS | TuiSizeM | TuiSizeL = 's';
  @Input() hint: Nullable<string>;
  @Input() noBottomHint: Nullable<boolean>;
  @Input() hintPosition: 'top' | 'bottom' = 'top';
  @Input() hintStyle: { [prop: string]: unknown } | null = null;
  @Input() placeholder?: Nullable<string>;
  @Input() size: TuiSizeM | TuiSizeL = 'm';
  @Input() textFieldSize: TuiSizeS | TuiSizeM | TuiSizeL = 'm';
  @Input() textfieldLabelOutside = true;
  @Input() textfieldCustomContent?: PolymorpheusContent;
  @Input() textfieldCleaner = false;
  @Input() noValidationMark?: boolean;
  @Input() errorLabel?: Nullable<string>;
  @Input() isReadOnly = false;
  @Input() isDisabled?: boolean;
  @Input() isInactive = false;
  @Input() textMask: Nullable<MaskitoOptions>;
  @Input() requiredIf?: boolean;
  @Input() inputClass: Nullable<string>;

  @Output() valueChange = new EventEmitter(true);

  get hasRequiredValidator() {
    if (!this.control?.validator) return false;
    return this.control.validator({} as AbstractControl)?.['required'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) return;

    if (('requiredIf' in changes || 'control' in changes) && typeof this.requiredIf === 'boolean') {
      if (this.requiredIf) this.control.addValidators(SharedValidators.requiredNonEmpty);
      else this.control.removeValidators(SharedValidators.requiredNonEmpty);
      this.control.updateValueAndValidity({ emitEvent: !changes?.['requiredIf']?.firstChange, onlySelf: false });
    }

    if (('isDisabled' in changes || 'control' in changes) && typeof this.isDisabled === 'boolean') {
      const isDisabled = this.isDisabled || this.control.parent?.disabled;
      if (isDisabled && this.control.enabled) this.control.disable({ emitEvent: false, onlySelf: true });
      if (!isDisabled && this.control.disabled) this.control.enable({ emitEvent: false, onlySelf: true });
    }
  }

  ngOnDestroy(): void {
    if (this.requiredIf) {
      this.control?.removeValidators(SharedValidators.requiredNonEmpty);
      this.control?.updateValueAndValidity();
    }
  }

  public setFocus = () => setTimeout(() => this.fieldId && document.getElementById(this.fieldId)?.focus());
}
