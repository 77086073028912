import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Возвращает массив невалидных контролов формы
 * @param form контрол, форма или массив
 * @returns массив невалидны FormControl, пустой массив, если таких нет
 */
export function getInvalidControls(form: AbstractControl): FormControl[] {
  if (form.valid) return [];
  if (form instanceof FormControl) return form.invalid ? [form] : [];
  else if (form instanceof FormGroup) return Object.values(form.controls).map(getInvalidControls).flat();
  else return (form as FormArray).controls.map(getInvalidControls).flat();
}
