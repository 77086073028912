<ng-container *ngIf="updateFormFromControl$ | executeWith: (getValueChanges | executeWith: control | async)" />

<tui-hosted-dropdown
  tuiDropdownAlign="left"
  [style.display]="'block'"
  [tuiDropdownAlign]="dropdownAlign"
  [tuiDropdownLimitWidth]="dropdownLimitWidth"
  [content]="dropdownTmp"
  [canOpen]="canOpen"
  [(open)]="isDropdownVisible"
  (openChange)="dropdownStateChange.emit($event)"
>
  <tui-badged-content
    *tuiLet="getValueChanges | executeWith: control | async as value"
    class="d-block"
    [colorTop]="value?.[0] || value?.[1] ? 'var(--tui-primary)' : ''"
  >
    <fnip-button
      [label]="label"
      [isDisabled]="!canOpen"
      [btnSize]="size"
      [appearance]="appearance"
      [icon]="labelIconAlign === 'left' ? iconTmp : null"
      [iconRight]="labelIconAlign === 'right' ? iconTmp : null"
    />
  </tui-badged-content>
</tui-hosted-dropdown>

<ng-template #dropdownTmp>
  <div class="d-flex p-4 gap-3" [style.width]="dropdownWidth + 'px'">
    <fnip-reactive-input-number
      class="flex-1"
      fieldId="from"
      [min]="minFrom"
      [max]="maxFrom"
      [size]="btnSize"
      [label]="labelFrom"
      [placeholder]="placeholderFrom"
      [control]="form.controls.from"
      (valueChange)="updateControl()"
    />
    <fnip-reactive-input-number
      class="flex-1"
      fieldId="to"
      [min]="minTo"
      [max]="maxTo"
      [size]="btnSize"
      [label]="labelTo"
      [placeholder]="placeholderTo"
      [control]="form.controls.to"
      (valueChange)="updateControl()"
    />
  </div>
</ng-template>

<ng-template #iconTmp>
  <tui-svg src="tuiIconChevronDownLarge" class="icon" [class.icon_rotated]="isDropdownVisible" />
</ng-template>
