/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InsuranceType = 'Life' | 'Property' | 'PropertyHouse' | 'Title' | 'TitleHouse' | 'TitleLandPlot';

export const InsuranceType = {
    Life: 'Life' as InsuranceType,
    Property: 'Property' as InsuranceType,
    PropertyHouse: 'PropertyHouse' as InsuranceType,
    Title: 'Title' as InsuranceType,
    TitleHouse: 'TitleHouse' as InsuranceType,
    TitleLandPlot: 'TitleLandPlot' as InsuranceType
};

