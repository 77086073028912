import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalErrorHandler, LoggerService } from '@lib-utils';
import { ConfigService } from './config.service';
import { getCurrentEnv } from './utils';

@NgModule()
export class InitConfigModule {
  static forRoot(appName: string, appVersion: string): ModuleWithProviders<InitConfigModule> {
    return {
      ngModule: InitConfigModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (configService: ConfigService, loggerService: LoggerService) => () => {
            // ставим favicon в зависимости от стенда
            const link = document.querySelector(`link[rel*='icon']`) as HTMLLinkElement;
            if (link) {
              link.type = 'image/x-icon';
              link.rel = 'shortcut icon';
              link.href = `/assets/icons/logo_${getCurrentEnv(window.location.hostname)}.svg`;
              document.getElementsByTagName('head')[0].appendChild(link);
            }

            if (!configService.logCollectorUrl) return;
            loggerService.initialize({
              url: configService.logCollectorUrl,
              apiKey: configService.logCollectorKey,
              app: {
                name: appName,
                version: appVersion,
                environment: configService.currentEnv,
              },
            });
          },
          deps: [ConfigService, LoggerService],
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
      ],
    };
  }
}
