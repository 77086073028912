import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FeHttpError, Nullable } from '@lib-utils';
import { ButtonComponent } from '@lib-widgets/core';
import {
  LOADER_DEFAULT_ERROR_MESSAGE,
  LOADER_LOADING_TEXT,
  LOADER_RETRY_TEXT,
  LoaderAppearance,
} from './loader.constants';

@Component({
  selector: 'fnip-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() appearance: LoaderAppearance = LoaderAppearance.Normal;

  @Input({ required: true }) isLoading: Nullable<boolean> = false;

  @Input() customLoadingText: Nullable<string>;

  @Input() error: Nullable<HttpErrorResponse | FeHttpError> = null;

  @Input() showContent: Nullable<boolean> = false;

  @Input() backButtonCallback$: ButtonComponent['actionCallback$'];

  @Output() retry = new EventEmitter<void>();

  get errorMessage() {
    if (this.error && this.error instanceof FeHttpError) {
      return this.error.message;
    }

    return this.error?.error?.message || this.error?.message || LOADER_DEFAULT_ERROR_MESSAGE;
  }

  readonly retryText = LOADER_RETRY_TEXT;

  readonly loadingText = LOADER_LOADING_TEXT;

  // Return type from TuiLoaderComponent['size']
  getLoaderSize = (appearance: LoaderAppearance): 'm' | 's' | 'xs' | 'l' | 'xl' | 'xxl' => {
    if (appearance === LoaderAppearance.Inline) return 'm';
    if (appearance === LoaderAppearance.Fullscreen) return 'xxl';

    return 'xl';
  };

  getButtonSize = (appearance: LoaderAppearance): ButtonComponent['btnSize'] => {
    if (appearance === LoaderAppearance.Inline) return 's';
    if (appearance === LoaderAppearance.Fullscreen) return 'm';

    return 'm';
  };

  retryCallback = () => this.retry.next();
}
