/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';
import { ClientDto } from './clientDto';
import { EmploymentDto } from './employmentDto';


export interface ClientFileDto { 
    client?: ClientDto;
    employment?: EmploymentDto;
    replacedFile?: ClientFileDto;
    name?: string | null;
    type?: FileType;
}
export namespace ClientFileDto {
}


