/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductFormulaType = 'Rate' | 'MinInitialPaymentPercentOwnFunds' | 'MaternalCapital' | 'MinInitialPaymentPercent' | 'MinCreditSum' | 'MaxCreditSum' | 'MinCreditPeriod' | 'MaxCreditPeriod';

export const ProductFormulaType = {
    Rate: 'Rate' as ProductFormulaType,
    MinInitialPaymentPercentOwnFunds: 'MinInitialPaymentPercentOwnFunds' as ProductFormulaType,
    MaternalCapital: 'MaternalCapital' as ProductFormulaType,
    MinInitialPaymentPercent: 'MinInitialPaymentPercent' as ProductFormulaType,
    MinCreditSum: 'MinCreditSum' as ProductFormulaType,
    MaxCreditSum: 'MaxCreditSum' as ProductFormulaType,
    MinCreditPeriod: 'MinCreditPeriod' as ProductFormulaType,
    MaxCreditPeriod: 'MaxCreditPeriod' as ProductFormulaType
};

