import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { TuiAutoFocusModule, TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiHintModule, TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiDataListWrapperModule, TuiInputModule, TuiUnmaskHandlerModule } from '@taiga-ui/kit';
import { CyrillicInputModule, ExecuteWithPipeModule } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { NumberInputModule } from './directives/number-input';
import { UppercaseInputModule } from './directives/uppercase-input';
import { ReactiveInputComponent } from './reactive-input.component';

const TUI_MODULES = [
  TuiAutoFocusModule,
  TuiHintModule,
  TuiInputModule,
  TuiLabelModule,
  TuiLetModule,
  TuiTextfieldControllerModule,
  TuiDataListWrapperModule,
  TuiValueChangesModule,
  TuiUnmaskHandlerModule,
];

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    CyrillicInputModule,
    UppercaseInputModule,
    TUI_MODULES,
    NumberInputModule,
    TuiTextfieldControllerModule,
    MaskitoModule,
  ],
  declarations: [ReactiveInputComponent],
  exports: [ReactiveInputComponent],
})
export class ReactiveInputModule {}
