import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import isArray from 'lodash-es/isArray';
import { Nullable } from '../../interfaces/types';
import { toDate } from '../to-date';
import { fromTuiDateTime } from '../tui-helpers';

export const toDateTimeFormat = (input: Nullable<Date | TuiDay | [TuiDay, TuiTime] | string>) => {
  if (!input) return undefined;
  if (isArray(input)) return fromTuiDateTime(input[0], input[1])?.toISOString();
  return toDate(input).toISOString();
};
