import { NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TuiAppearance, TuiSvgModule } from '@taiga-ui/core';
import { PolymorpheusContent, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import isNil from 'lodash-es/isNil';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { EllipsisHintComponent } from '../ellipsis-hint';

@Component({
  selector: 'fnip-labeled-content',
  templateUrl: './labeled-content.component.html',
  styleUrls: ['./labeled-content.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PolymorpheusOutlet, NgIf, EllipsisHintComponent, ExecuteWithPipeModule, TuiSvgModule],
})
export class LabeledContentComponent {
  @Input() label?: string;

  @Input() icon?: Nullable<SafeHtml | string>;

  @HostBinding('class.multi-row')
  @Input({ transform: booleanAttribute })
  disableEllipsis = false;

  @HostBinding('class.inline')
  @Input({ transform: booleanAttribute })
  inline = false;

  @Input() labelAlign: 'start' | 'end' = 'start';
  @Input() labelWidth: Nullable<string> = '50%';

  @Input() content: PolymorpheusContent;
  @Input() contentAlign: 'start' | 'center' | 'end' = 'start';

  @Input() hintAppearance: TuiAppearance.Primary | 'onDark' = TuiAppearance.Primary;

  @Input() set contentContext(value: unknown) {
    if (typeof value !== 'object') {
      this.context = { $implicit: value };
      return;
    }

    // FNMRG-1567: если в context записывался null, то последующие $implicit игнорировались
    // вероятно связано с https://github.com/taiga-family/ng-polymorpheus/issues/173
    this.context = value ?? {};
  }

  context: object = {};

  isNil = isNil;
}
