<form class="auth-form d-flex flex-column gap-10" (keyup.enter)="submitButton.onClick()">
  <img alt="logo" [class.logo]="!isNew" [class.logo-new]="isNew" [src]="logo" />

  <h2 class="m-auto text-center line-height-30">Введите Email для восстановления пароля</h2>

  <div class="d-flex flex-column gap-8">
    <fnip-reactive-input
      fieldId="email"
      label="E-mail"
      textFieldSize="l"
      [control]="form.controls.email"
      [requiredIf]="true"
    />

    <fnip-button label="Я вспомнил пароль" appearance="link-primary" btnRouterLink="../login" />
  </div>

  <fnip-button
    #submitButton
    class="ml-auto"
    appearance="primary"
    label="Далее"
    btnSize="l"
    [actionCallback$]="submitFormCallback$"
    [isDisabled]="form.invalid"
  />
</form>
