/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationTaskInfoDto } from './verificationTaskInfoDto';
import { PageResult } from './pageResult';


export interface VerificationPagedResultDto { 
    data?: Array<VerificationTaskInfoDto> | null;
    page?: PageResult;
    documentStatusesCount?: { [key: string]: number; } | null;
}

