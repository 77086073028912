import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { getActualRectangles, RectangleAnimationInfo } from './background-animation.utils';

@Component({
  selector: 'fnip-background-animation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './background-animation.component.html',
  styleUrls: ['./background-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rectangleAnimation', [
      transition(
        ':enter',
        [
          style({ top: '{{ bottom }}%', height: 0 }),
          animate('1s {{ enterDelay }}s ease-out', style({ top: '{{ top }}%', height: '{{ height }}%' })),
          animate('2.5s {{ stretchDelay }}s', style({ top: 0, height: '100%' })),
          animate('2s {{ endDelay }}s ease-in', style({ top: 0, height: 0 })),
        ],
        { params: { enterDelay: 0, stretchDelay: 0, endDelay: 0, top: 0, bottom: 0, height: 0 } },
      ),
    ]),
  ],
})
export class BackgroundAnimationComponent {
  rectangles = getActualRectangles();

  trackById = (_index: number, item: RectangleAnimationInfo) => item.id;
}
