/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculationAdditionalInfo } from './calculationAdditionalInfo';
import { ObjectType } from './objectType';
import { CreditProductDto } from './creditProductDto';
import { PartnerDto } from './partnerDto';
import { InsuranceForm } from './insuranceForm';
import { RealEstateRegion } from './realEstateRegion';
import { MaternalCapitalType } from './maternalCapitalType';
import { UserInfoDto } from './userInfoDto';
import { CreditPurpose } from './creditPurpose';
import { ResidentialComplexDto } from './residentialComplexDto';
import { DateTime } from '@lib-utils';
import { ProofOfIncome } from './proofOfIncome';


export interface CalculationResultDto { 
    id?: number;
    objectType?: ObjectType;
    creditPurpose?: CreditPurpose;
    proofOfIncome?: ProofOfIncome;
    realEstatePrice?: number;
    creditAmount?: number;
    creditMin?: number | null;
    creditMax?: number | null;
    manualCreditMax?: number | null;
    sprCreditMax?: number | null;
    sprCreditMaxTemp?: number | null;
    interestAmount?: number;
    interestPlusCreditAmount?: number;
    requiredIncome?: number;
    monthlyPayment?: number;
    sprMonthlyPaymentMax?: number | null;
    sprMonthlyPaymentMaxTemp?: number | null;
    interestRate?: number | null;
    flexPrice?: number | null;
    absoluteRate?: number | null;
    absoluteRateCreditPercent?: number | null;
    absoluteRateCost?: number | null;
    initialPaymentSum?: number;
    initialPaymentSumPercent?: number;
    minInitialPaymentSumPercent?: number | null;
    manualMinInitialPaymentPercent?: number | null;
    initialPaymentOwnFunds?: number;
    initialPaymentPercentOwnFunds?: number;
    minInitialPaymentPercentOwnFunds?: number | null;
    maternalCapitalType?: MaternalCapitalType;
    maternalCapital?: number | null;
    stateSubsidy?: number | null;
    creditPeriod?: number;
    periodMin?: number | null;
    periodMax?: number | null;
    manualPeriodMax?: number | null;
    sprPeriodMax?: number | null;
    insuranceForm?: InsuranceForm;
    insurancePremia?: number;
    realEstateRegion?: RealEstateRegion;
    residentialComplexId?: number | null;
    residentialComplex?: ResidentialComplexDto;
    creditProductId?: number;
    creditProduct?: CreditProductDto;
    partnerId?: number | null;
    partner?: PartnerDto;
    creditInfoId?: number;
    partnerManagerId?: number | null;
    partnerManager?: UserInfoDto;
    additionalInfo?: CalculationAdditionalInfo;
    creatorId?: number;
    creator?: UserInfoDto;
    created?: DateTime;
    updatorId?: number;
    updator?: UserInfoDto;
    updated?: DateTime;
}
export namespace CalculationResultDto {
}


