<div
  *tuiLet="!!(breakpointService.isMobile$ | async) as isMobile"
  class="toolbar d-flex align-items-center py-3"
  [class]="isMobile ? 'px-5 gap-6' : 'px-9 gap-8'"
  [style.height.px]="isMobile ? 80 : 100"
>
  <ng-container *ngIf="closeSidebarsWhenNotMobile$ | async" />

  <fnip-button
    *ngIf="isMobile && (toolbarService.hasSidebar$ | async)"
    icon="tuiIconMenu"
    (click)="toolbarService.changeOpenState(true)"
  />
  <a routerLink="/">
    <img [style.width.px]="112" [style.height]="'auto'" [src]="logo" />
  </a>

  <div *ngIf="!isMobile" class="d-flex flex-column content-center align-items-start gap-1">
    <div class="font-size-16 font-weight-bold line-height-22">{{ application }}</div>
    <ng-container [ngTemplateOutlet]="userInfoTmp" />
  </div>

  <fnip-notification-list *ngIf="showNotification" [compactMode]="compactNotifications || isMobile" />

  <div class="d-flex gap-2 ml-auto">
    <ng-content />
    <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="userActionsTmp" />

    <ng-container *ngIf="isMobile">
      <fnip-button
        #parent="tuiActiveZone"
        appearance="primary"
        icon="tuiIconUser"
        (click)="changeUserInfoSidebarState(true)"
        (tuiActiveZoneChange)="changeUserInfoSidebarState($event)"
      />
      <ng-container
        *tuiSidebar="isInfoSidebarOpen; direction: 'right'; autoWidth: true"
        [ngTemplateOutlet]="sidebar"
        [ngTemplateOutletContext]="{ parent }"
      />
    </ng-container>
  </div>
</div>

<ng-template #sidebar let-parent="parent">
  <div class="d-flex flex-column content-space-between h-100" [style.overflow]="'auto'" [tuiActiveZoneParent]="parent">
    <div class="d-flex flex-column align-items-center gap-6 p-3">
      <ng-container [ngTemplateOutlet]="userInfoTmp" />
      <ng-container [ngTemplateOutlet]="userActionsTmp" [ngTemplateOutletContext]="{ isVertical: true }" />
    </div>

    <fnip-button
      iconRight="tuiIconChevronRight"
      appearance="primary"
      class="d-flex align-self-end w-100"
      [btnStyle]="{ 'border-radius': 0 }"
      (click)="changeUserInfoSidebarState(false)"
    />
  </div>
</ng-template>

<ng-template #userActionsTmp let-isVertical="isVertical">
  <ng-container *ngIf="authorized$ | async">
    <fnip-language-switcher *ngIf="hasTranslation" />
    <fnip-button
      icon="tuiIconHelpCircleLarge"
      appearance="secondary-light-grey"
      btnLinkTarget="_blank"
      [label]="hasTranslation ? ('BASE.BTN_LABELS.HELP' | translate) : 'Помощь'"
      [class.w-100]="isVertical"
      [btnHref]="openIssueUrl"
    />
    <fnip-button
      appearance="secondary-light-grey"
      btnLinkTarget="_blank"
      [label]="hasTranslation ? ('BASE.BTN_LABELS.VIDEO_INSTRUCTION' | translate) : 'Видеоинструкция'"
      [class.w-100]="isVertical"
      [btnHref]="videoInstructionUrl"
    />
  </ng-container>
  <fnip-button
    appearance="secondary-light-grey"
    icon="assets/icons/logout.svg"
    [label]="hasTranslation ? ('BASE.BTN_LABELS.LOG_OUT' | translate) : 'Выйти'"
    [class.w-100]="isVertical"
    [actionCallback$]="logoutCallBack$"
  />
</ng-template>

<ng-template #userInfoTmp>
  <div
    *tuiLet="getUserWithRoleNames | executeWith: (user$ | async) as userWithRolesNames"
    class="font-size-15 line-height-19"
    [style.max-width.px]="280"
  >
    <div>{{ userWithRolesNames.firstName }}</div>
    <span *ngIf="userWithRolesNames.name" class="font-size-13 color-primary">
      {{ hasTranslation ? (userWithRolesNames.nameTranslateKey | translate) : userWithRolesNames.name }}
    </span>
    <tui-tooltip
      *ngIf="userWithRolesNames.toolTipNames"
      [content]="userWithRolesNames.toolTipNames"
      direction="right"
    />
  </div>
</ng-template>
