import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TuiAppearance, TuiHorizontalDirection, TuiSizeL, TuiSizeM } from '@taiga-ui/core';
import { TuiDropdownWidth } from '@taiga-ui/core/types';
import { startWith } from 'rxjs/operators';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';

type InputRangeValue = [number | null, number | null];

@Component({
  selector: 'fnip-reactive-button-dropdown-input-range',
  templateUrl: './reactive-button-dropdown-input-range.component.html',
  styleUrls: ['./reactive-button-dropdown-input-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveButtonDropdownInputRangeComponent {
  @Input({ required: true }) control: Nullable<FormControl<Nullable<InputRangeValue>>>;
  @Input() canOpen = true;
  @Input() dropdownAlign: TuiHorizontalDirection = 'right';
  @Input() dropdownLimitWidth: TuiDropdownWidth = 'auto';
  @Input() dropdownWidth = 400;
  @Input() appearance: string = TuiAppearance.Outline;
  @Input() labelIconAlign: 'left' | 'right' = 'right';
  @Input() label?: string;
  @Input() labelFrom?: string;
  @Input() labelTo?: string;
  @Input() placeholderFrom = 'От';
  @Input() placeholderTo = 'До';
  @Input() minFrom = -Infinity;
  @Input() maxFrom = Infinity;
  @Input() minTo = -Infinity;
  @Input() maxTo = Infinity;
  @Input() size: TuiSizeM | TuiSizeL = 'm';
  @Input() btnSize: TuiSizeM | TuiSizeL = 'm';
  @Output() dropdownStateChange = new EventEmitter<boolean>();

  isDropdownVisible = false;

  form = new FormGroup({
    from: new FormControl<Nullable<number>>(null),
    to: new FormControl<Nullable<number>>(null),
  });

  getValueChanges = (control: typeof this.control) => control?.valueChanges.pipe(startWith(control?.value));

  updateFormFromControl$ = (value: Nullable<InputRangeValue>) => {
    const { from: oldFrom, to: oldTo } = this.form.value;
    // Изменений нет
    if (Array.isArray(value) && value[0] === oldFrom && value[1] === oldTo) return;

    const [from, to] = value || [];
    this.form.patchValue({ from, to }, { emitEvent: false });
  };

  updateControl() {
    const { from, to } = this.form.value;

    this.control?.patchValue(from === null && to === null ? null : [from ?? null, to ?? null]);
  }
}
