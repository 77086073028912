/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { GroupCompanyType } from './groupCompanyType';
import { PhysicalPersonModel } from './physicalPersonModel';
import { OrganizationModel } from './organizationModel';
import { PartnerPersonType } from './partnerPersonType';
import { DateTime } from '@lib-utils';


export interface PartnerModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    coefficient?: number;
    organizationId?: number | null;
    organization?: OrganizationModel;
    physicalPersonId?: number | null;
    physicalPerson?: PhysicalPersonModel;
    partnerType?: GroupCompanyType;
    isActive?: boolean;
    type?: PartnerPersonType;
    externalId?: number | null;
    readonly name?: string | null;
}
export namespace PartnerModel {
}


