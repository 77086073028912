import { DialogHostOptions } from './dialog.model';

export const DIALOG_CLOSE_ANIMATION_DELAY = 310; // ms, Немного больше чем длится анимация закрытия диалога

export const DEFAULT_DIALOG_HOST_OPTIONS: DialogHostOptions = {
  size: 'm',
  isCloseable: true,
  isDismissible: true,
  autoFocus: true,
};
