import { Injectable } from '@angular/core';
import { BeApiEnum, FeHttpError, FeHttpErrorMessage } from '@lib-utils';
import { BEErrorInfo } from '../error-interceptor.interfaces';
import { TypedHttpErrorResponse } from '../error-interceptor.models';
import { ErrorMapperAbstract } from './error-mapper.abstract';

interface WrappedError {
  error: BEErrorInfo;
}

@Injectable()
export class MortgageLikeErrorMapper extends ErrorMapperAbstract<WrappedError, BEErrorInfo> {
  readonly forApiModules: BeApiEnum[] = [
    BeApiEnum.Verification,
    BeApiEnum.Mortgage,
    BeApiEnum.Collection,
    BeApiEnum.Archive,
  ];

  mapError(beApi: BeApiEnum | null, errorResp: TypedHttpErrorResponse<WrappedError>): FeHttpError<BEErrorInfo> {
    const validationMessages = Object.entries(errorResp.error?.error?.validationErrors ?? {}).map(([key, value]) => ({
      code: key,
      message: value.join(', '),
    }));

    // Если нет ошибок валидации, то пытаемся найти общую ошибку, которую отдал backend
    // Обычно, если есть validationErrors, то это и есть первая validationError
    const inferredMessage: FeHttpErrorMessage[] = errorResp.error?.error?.message
      ? [
          {
            message: errorResp.error.error.message,
            code: errorResp.error.error.code ?? FeHttpError.DEFAULT_ERROR_CODE,
          },
        ]
      : [];

    return new FeHttpError<BEErrorInfo>({
      beApi,
      url: errorResp.url,
      headers: errorResp.headers,
      originalError: errorResp.error?.error,
      status: errorResp.status,
      exceptionCode: errorResp.error?.error?.code,
      messages: validationMessages.length ? validationMessages : inferredMessage,
      technicalMessages: (errorResp?.error?.error?.technicalErrors ?? []).map((item) => ({
        code: item.exceptionType ?? FeHttpError.DEFAULT_ERROR_CODE,
        message: item.message ?? FeHttpError.DEFAULT_ERROR_MESSAGE,
      })),
    });
  }
}
