/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalCreditInfoDto } from './additionalCreditInfoDto';
import { CalculationResultDto } from './calculationResultDto';
import { ApplicationState } from './applicationState';
import { ClientDto } from './clientDto';
import { DealInfoDto } from './dealInfoDto';
import { BankDecisionDto } from './bankDecisionDto';
import { CoborrowerDto } from './coborrowerDto';
import { ApplicationRelationDto } from './applicationRelationDto';
import { DateTime } from '@lib-utils';
import { GuarantorDto } from './guarantorDto';
import { AdditionalIncomeDto } from './additionalIncomeDto';
import { CreditInfoDto } from './creditInfoDto';


export interface ApplicationFullInfoDto { 
    id?: number;
    clientId?: number;
    client?: ClientDto;
    creditInfo?: CreditInfoDto;
    additionalCreditInfo?: AdditionalCreditInfoDto;
    bankDecision?: BankDecisionDto;
    status?: string | null;
    comment?: string | null;
    created?: DateTime;
    relations?: Array<ApplicationRelationDto> | null;
    coborrowers?: Array<CoborrowerDto> | null;
    guarantors?: Array<GuarantorDto> | null;
    dealInfo?: DealInfoDto;
    state?: ApplicationState;
    calculationResult?: CalculationResultDto;
    uid?: string | null;
    additionalIncomes?: Array<AdditionalIncomeDto> | null;
    externalId?: string;
}
export namespace ApplicationFullInfoDto {
}


