import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Nullable } from '../../interfaces/types';

export function getFileName(contentDisposition: Nullable<string>): Nullable<string> {
  if (!contentDisposition) return null;
  const hasUtf8FileName = contentDisposition?.includes("filename*=UTF-8''");
  const fileName = contentDisposition
    ?.split(hasUtf8FileName ? "filename*=UTF-8''" : 'filename=')[1]
    ?.split(';')[0]
    ?.replace(/"/g, '');
  return hasUtf8FileName && fileName ? decodeURIComponent(fileName) : fileName;
}

export function saveFile(res: HttpResponse<Blob | string>, fileName?: Nullable<string>) {
  if (!res?.body) return;
  const contentDisposition = res.headers?.get('content-disposition');
  const contentType = res.headers?.get('content-type') ?? 'text/plain';
  const data =
    typeof res.body === 'string' ? new Blob([res.body], { type: `data:${contentType}; charset=utf-8` }) : res.body;
  saveAs(data, fileName || getFileName(contentDisposition) || '');
}
