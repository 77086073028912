<div *ngIf="control" class="d-flex align-items-center">
  <ng-container ngDefaultControl [formControl]="$any(control)" (tuiValueChanges)="valueChange.emit($event)" />
  <fnip-button
    btnSize="s"
    icon="tuiIconCheckLarge"
    class="mr-1"
    [class.check-btn-success]="control.value === true"
    [isDisabled]="control.disabled"
    [actionCallback$]="setValue | executeWith: true"
  />
  <fnip-button
    btnSize="s"
    icon="tuiIconCloseLarge"
    class="mr-3"
    [class.check-btn-fail]="control.value === false"
    [isDisabled]="control.disabled"
    [actionCallback$]="setValue | executeWith: false"
  />
  <span class="label">{{ label }}</span>
</div>
<fnip-reactive-field-error *ngIf="!noValidationMark" [control]="$any(control)" [errorLabel]="errorLabel" />
