/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';
import { VerificationDocumentStatus } from './verificationDocumentStatus';
import { VerificatorInfoDto } from './verificatorInfoDto';


export interface FileTypificationTaskDto { 
    id?: number;
    verificatorId?: number;
    verificator?: VerificatorInfoDto;
    applicationId?: number;
    status?: VerificationDocumentStatus;
    fileId?: number;
    determinedFileType?: FileType;
}
export namespace FileTypificationTaskDto {
}


