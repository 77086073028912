const RANGE = 1000000;

export function randomNumber(from?: number, to?: number): number {
  const min = from ?? 0;
  const max = to ?? min + RANGE;
  return min + (max - min) * Math.random();
}

export function randomIntNumber(from?: number, to?: number): number {
  return Math.round(randomNumber(from, to));
}
