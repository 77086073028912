/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ObjectInfo { 
    cadastralNumber?: string | null;
    totalArea?: number;
    address?: string | null;
    buildingNumber?: string | null;
    sections?: Array<number> | null;
    floorCount?: number;
    buildingTotalArea?: number;
    materialExteriorWall?: string | null;
    materialFloor?: string | null;
    energyEfficiencyClass?: string | null;
    seismicResistance?: string | null;
}

