<ng-container *ngIf="action$ | async" />
<tui-hosted-dropdown [content]="content">
  <fnip-button [icon]="btnIcon" [label]="btnLabel" />
</tui-hosted-dropdown>
<ng-template #content>
  <tui-data-list *ngIf="items?.length">
    <ng-container *ngFor="let option of items">
      <button tuiOption *ngIf="!option.hidden" [disabled]="!!option.disabled" (click)="onClick(option.actionCallback$)">
        {{ option.label }}
      </button>
    </ng-container>
  </tui-data-list>
</ng-template>
