<ng-container *ngIf="observeResize | executeWith: clampRef | async" />

<span
  #clampRef
  class="clamp"
  [class.disabled]="isDisabled"
  tuiHintPointer
  [tuiHint]="(isTooltipShown | executeWith: clampRef.offsetWidth : clampRef.scrollWidth) ? tooltip : null"
  [tuiHintAppearance]="hintAppearance"
>
  <ng-container *polymorpheusOutlet="content as content">
    {{ content }}
  </ng-container>
</span>

<ng-template #tooltip>
  <ng-container *polymorpheusOutlet="content as content">
    {{ content }}
  </ng-container>
</ng-template>
