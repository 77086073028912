import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TuiDay } from '@taiga-ui/cdk';
import { addYears, subDays } from 'date-fns';
import toString from 'lodash-es/toString';
import { toDate } from '../functions';
import { Nullable } from '../interfaces';

const ISSUE_DATE_FROM_BIRTHDAY_ERROR = 'issueDateFromBirthday';
const ISSUE_DATE_FROM_BIRTHDAY_ERROR_MESSAGE = 'Оформление первого паспорта производится по достижении 14 лет';

interface PasswordValidatorConfig {
  upper: boolean;
  lower: boolean;
  number: boolean;
  maxLength: number;
  minLength: number;
}

export class SharedValidators {
  /**
   * RMORTGAGE-239, RMORTGAGE-244, RVERIFICATION-43
   * 2. Даты выдачи должны быть раньше, чем дата рождения + 14 лет
   */
  static passportIssueDateFromBirthdayValidator =
    (birthdayControl: FormControl<Nullable<string>>): ValidatorFn =>
    (issueDateControl: AbstractControl) => {
      const birthday = birthdayControl.value;

      if (!birthday) return null;
      if (!issueDateControl.value) return null;

      const isValid = toDate(issueDateControl.value) > subDays(addYears(toDate(birthday), 14), 1);
      if (!isValid)
        return {
          [ISSUE_DATE_FROM_BIRTHDAY_ERROR]: ISSUE_DATE_FROM_BIRTHDAY_ERROR_MESSAGE,
        };

      return null;
    };

  /**
   * RMORTGAGE-41:
   *
   * Валидация ФИО по требованиям ФНС
   * Источник - https://www.consultant.ru/document/cons_doc_LAW_410788/23eb169c5b2a7ec5dc531c16e052094f721b6aaa/
   * @param type - Фамилия или Имя-Отчество
   */
  static fnsNameValidator = (type: 'lastName' | 'middleOrFirstName'): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (typeof value !== 'string') return null;

      // Проверяем на доступные символы из документа https://www.consultant.ru/document/cons_doc_LAW_410788/763145d8bce1267a0501117c28ebd122104c1b8e/
      if (/[^А-яёЁIV\-_\.',()\s]+/.test(value))
        return {
          customMessage: 'Используются недопустимые символы',
        };

      // 1, 1a
      const lastFirstOnlySymbols = type === 'lastName' ? ['.', '-', "'", ' ', ','] : ['-', "'", ' ', ','];
      if (
        (value.length === 1 && lastFirstOnlySymbols.includes(value)) ||
        lastFirstOnlySymbols.includes(value[0]) ||
        lastFirstOnlySymbols.includes(value[value.length - 1])
      ) {
        return {
          customMessage: `Недопустимо использование (${lastFirstOnlySymbols.join(
            '',
          )}) в качестве первого, последнего или единственного символа`,
        };
      }

      if (type === 'middleOrFirstName' && value[0] === '.')
        return {
          customMessage: 'Недопустимо наличие символа "." (точка) в качестве первого или единственного символа',
        };

      if (value[0] === ')')
        return { customMessage: 'Недопустимо использование ) в качестве первого или единственного символа' };
      if (value[value.length - 1] === '(')
        return { customMessage: 'Недопустимо использование ( в качестве последнего или единственного символа' };

      // 2
      if (/([.\s\-',()\1])\1/.test(value))
        return {
          customMessage: "Недопустимо использование 2 или более символов .-'() подряд",
        };

      // 3
      if (/[.\-',()]{2,}/.test(value))
        return {
          customMessage: "Недопустимо наличие подряд символов .-'()",
        };

      // 4
      let parenthesesBalance = 0;
      for (const char of value) {
        if (char === '(') parenthesesBalance += 1;
        if (char === ')') {
          // не было ни одной открывающей скобки
          if (parenthesesBalance === 0) {
            parenthesesBalance -= 1;
            break;
          }

          parenthesesBalance -= 1;
        }
      }

      if (parenthesesBalance !== 0)
        return {
          customMessage: 'Не допускается наличие непарных скобок',
        };

      // 5
      if (/[iv]+/.test(value) || /^[IV]+/.test(value))
        return {
          customMessage:
            'Не допускается наличие строчных букв латинского алфавита (I, V), а также использование этих букв в качестве первого или единственного символа',
        };

      return null;
    };
  };

  static equalValue(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && otherControl.value) {
        return otherControl.value === control.value ? null : { equalValue: true };
      }
      return null;
    };
  }

  static password(config: PasswordValidatorConfig): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (!value) {
        return null;
      }

      const upperCaseCharacters = /[A-Z]+/g;
      const lowerCaseCharacters = /[a-z]+/g;
      const numberCharacters = /[0-9]+/g;

      if (config.upper && !upperCaseCharacters.test(value)) {
        return { customMessage: 'Пароль должен содержать символы верхнего регистра!' };
      }

      if (config.lower && !lowerCaseCharacters.test(value)) {
        return { customMessage: 'Пароль должен содержать символы нижнего регистра!' };
      }

      if (config.number && !numberCharacters.test(value)) {
        return { customMessage: 'Пароль должен содержать цифры!' };
      }

      if (config?.maxLength && value.length > config?.maxLength) {
        return { customMessage: `Пароль должен содержать меньше ${config.maxLength} символов!` };
      }

      if (config?.minLength && value.length < config?.minLength) {
        return { customMessage: `Пароль должен содержать больше ${config.minLength} символов!` };
      }

      return null;
    };
  }

  static numberOnly(): ValidatorFn {
    return (): ValidationErrors | null => Validators.pattern('^[0-9]*$');
  }

  /** Валидатор наличия дробной части числа
   * @returns null or { fraction: true }
   */
  static withoutFraction(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = Number(control.value);
      if (value && value % 1 !== 0) {
        return { fraction: true };
      }
      return null;
    };
  }

  /** Валидатор required, который срабатыват, если значние otherControl === value
   * @returns null or { required: true }
   */
  static requiredIfOtherControlValue(otherControl: AbstractControl, value: unknown): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null =>
      otherControl.value === value ? Validators.required(c) : null;
  }

  static requiredIfTrue(predicate: (parent: FormGroup) => boolean): ValidatorFn {
    return (control) =>
      !control.value && control.value !== 0 && predicate(control.parent as FormGroup) ? { required: true } : null;
  }

  static validateWhenSelf(
    predicate: (control: AbstractControl) => boolean,
    validator?: ValidatorFn | null,
  ): ValidatorFn {
    return (c) => (predicate(c) ? validator?.(c) ?? null : null);
  }

  /** Валидатор процентов (%), валидное значние - от min до 100
   * @returns null or { min: { min, actual: percent } } or { max: { max: 100, actual: percent } }
   */
  static percent(min = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (value) {
        const percent = Number(value);
        if (percent < min) {
          return { min: { min, actual: percent } };
        }
        if (percent > 100) {
          return { max: { max: 100, actual: percent } };
        }
      }
      return null;
    };
  }

  static wordsCount(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (value) {
        const words = (value as string).split(' ').filter(Boolean);
        if (words.length < min) {
          return {
            minWords: {
              min,
              actual: words.length,
            },
          };
        }
      }
      return null;
    };
  }

  static dateMax(maxDate: Date | TuiDay): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const max = toDate(maxDate);
      const actual = toDate(control.value);
      if (!max || !actual || actual.getTime() <= max.getTime()) return null;
      return { dateMax: { max, actual } };
    };
  }

  static dateMin(minDate: Date | TuiDay): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const min = toDate(minDate);
      const actual = toDate(control.value);
      if (!min || !actual || actual.getTime() >= min.getTime()) return null;
      return { dateMin: { min, actual } };
    };
  }

  static phoneNumber(countryCode: string, numberLength: number): ValidatorFn {
    return (control) => {
      const value = control.value as string | null;
      if (!value) return null;
      if (
        !value.startsWith(countryCode) ||
        value.length - countryCode.length !== numberLength ||
        isNaN(+value.substring(countryCode.length))
      ) {
        return {
          customMessage: 'Некорректный номер телефона',
        };
      }
      return null;
    };
  }

  /**
   * Validator function that checks if the control value is required and non-empty.
   * @param control The form control to validate.
   * @returns An object with the `required` property set to `true` if the value is empty, otherwise `null`.
   */
  static requiredNonEmpty: ValidatorFn = (control) => {
    const value = toString(control.value);
    return value && value.trim() ? null : { required: true };
  };
}
