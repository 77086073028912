import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TuiActiveZoneModule, TuiObscuredModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import uniqBy from 'lodash-es/uniqBy';
import { EMPTY, exhaustMap, Observable, of, scan, tap, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ExecuteWithPipeModule, NotificationService } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { NotificationHistoryComponent } from './notification-history';
import { NotificationListItemComponent } from './notification-list-item';
import { FnipNotification } from './notification-list.models';
import { NotificationSource } from './sources';

const UPDATE_INTERVAL = 10_000;

@Component({
  selector: 'fnip-notification-list',
  standalone: true,
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgForOf,
    RequestWrapperModule,
    ExecuteWithPipeModule,
    ButtonModule,
    TuiSvgModule,
    TuiDropdownModule,
    TuiActiveZoneModule,
    TuiObscuredModule,
    TuiDataListModule,
    NotificationListItemComponent,
    NotificationHistoryComponent,
  ],
})
export class NotificationListComponent {
  @Input() compactMode = false;

  // Точка отсчета для получения истории уведомлений
  private readonly componentCreatedDate = new Date();

  private readonly destroyRef = inject(DestroyRef);
  public readonly notificationSource = inject(NotificationSource, { optional: true });
  private readonly notificationService = inject(NotificationService);

  public readonly isDropdownOpen = signal(false);
  public readonly markAsReadIds = signal<(string | number)[]>([]);

  // Количество уведомлений в истории
  public readonly historyCount = signal(0);
  public readonly historyPage = signal(1);
  // История уведомлений
  public readonly historyNotifications = signal<FnipNotification[]>([]);
  public readonly hasLoadMoreButton = computed(() => this.historyCount() > this.historyNotifications().length);

  // Новые уведомления
  public readonly newNotifications = toSignal(this.startNotificationsPolling(), {
    initialValue: [] as FnipNotification[],
  });

  // Список уведомлений для отображения
  public readonly notificationList = computed(() =>
    [...this.newNotifications(), ...this.historyNotifications()].map((notification) => ({
      ...notification,
      isRead: this.markAsReadIds().includes(notification.id) || notification.isRead,
    })),
  );

  public readonly unreadNotifications = computed(() =>
    this.notificationList().filter((notification) => !notification.isRead),
  );

  // Последнее непрочитанное уведомление
  public readonly mostRecentUnreadNotification = computed(() => {
    return this.unreadNotifications()?.[0] ?? null;
  });

  public readonly loadMoreHistory$ = () => {
    const nextPage = this.historyPage() + 1;
    return this.loadHistory$(nextPage);
  };

  public readonly loadHistory$ = (page = 1, perPage = 10): Observable<FnipNotification[]> => {
    if (this.notificationSource === null) return EMPTY;
    return this.notificationSource.getNotificationListBefore(this.componentCreatedDate, { page, perPage }).pipe(
      tap(({ count, page }) => {
        this.historyCount.set(count);
        this.historyPage.set(page);
      }),
      map(({ data }) => data),
      tap((notifications) => this.setHistoryNotifications(notifications, page === 1)),
    );
  };

  public readonly markAsRead = (notification: FnipNotification) => {
    this.markAsReadIds.update((ids) => [...ids, notification.id]);
    this.notificationSource?.markNotificationAsRead(notification).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  };

  public onObscured(obscured: boolean) {
    if (!obscured) return;
    this.isDropdownOpen.set(false);
  }

  public onActiveZone(active: boolean) {
    this.isDropdownOpen.update((open) => active && open);
  }

  /**
   * Устанавливает историю уведомлений
   * Ручка для тестов, так как из вне нельзя вызвать set у signal
   */
  public setHistoryNotifications(notifications: FnipNotification[], reset = false) {
    if (reset) return this.historyNotifications.set(notifications);
    this.historyNotifications.update((items) => [...items, ...notifications]);
  }

  private startNotificationsPolling(afterDate = new Date()) {
    let lastDate = afterDate;
    return timer(0, UPDATE_INTERVAL).pipe(
      // Получаем новые уведомления, у которых дата создания больше чем у последнего полученного
      exhaustMap(
        () =>
          this.notificationSource?.getNotificationsAfter(lastDate)?.pipe(
            tap(this.notificationService.onError('Ошибка при получении уведомлений')),
            catchError(() => of([])),
          ) ?? EMPTY,
      ),
      scan((acc, notifications) => {
        // Если пришло новое уведомление, берем самое позднее и обновляем фильтр
        if (notifications.length) lastDate = notifications[0].createdAt;
        return uniqBy([...notifications, ...acc], 'id');
      }, [] as FnipNotification[]),
    );
  }
}
