/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationChangeType } from './applicationChangeType';
import { ApplicationChangeUserModel } from './applicationChangeUserModel';
import { ApplicationModel } from './applicationModel';
import { DateTime } from '@lib-utils';


export interface ApplicationChangeModel { 
    id?: number;
    type?: ApplicationChangeType;
    applicationId?: number;
    application?: ApplicationModel;
    time?: DateTime;
    userId?: number;
    user?: ApplicationChangeUserModel;
    additionalData?: any | null;
    fieldName?: string | null;
    oldValue?: string | null;
    newValue?: string | null;
}
export namespace ApplicationChangeModel {
}


