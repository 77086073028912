import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[fnipScrollToElement]',
  standalone: true,
})
export class ScrollToElementDirective {
  @Input({ required: true }) fnipScrollToElement!: string;

  @HostListener('click') click() {
    const element = document.querySelector(this.fnipScrollToElement);
    if (!element) return false;
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    return false;
  }
}
