/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PaymentType = 'CashMoneyToSeller' | 'MoneyTransferToSellerFromAb' | 'MoneyTransferToSellerNotFromAb' | 'InclusionToLetterOfCredit' | 'IndividualBankSafe' | 'StateSubsidyIncludingMaternalCapital' | 'Escrow' | 'UsingANominalAccount';

export const PaymentType = {
    CashMoneyToSeller: 'CashMoneyToSeller' as PaymentType,
    MoneyTransferToSellerFromAb: 'MoneyTransferToSellerFromAb' as PaymentType,
    MoneyTransferToSellerNotFromAb: 'MoneyTransferToSellerNotFromAb' as PaymentType,
    InclusionToLetterOfCredit: 'InclusionToLetterOfCredit' as PaymentType,
    IndividualBankSafe: 'IndividualBankSafe' as PaymentType,
    StateSubsidyIncludingMaternalCapital: 'StateSubsidyIncludingMaternalCapital' as PaymentType,
    Escrow: 'Escrow' as PaymentType,
    UsingANominalAccount: 'UsingANominalAccount' as PaymentType
};

