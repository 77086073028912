/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceProductType } from './insuranceProductType';
import { InsuranceScheme } from './insuranceScheme';
import { InsuranceType } from './insuranceType';
import { InsuranceLifeDto } from './insuranceLifeDto';


export interface InsuranceDto { 
    id?: number;
    type?: InsuranceType;
    schema?: InsuranceScheme;
    companyId?: number;
    productId?: number;
    productName?: string | null;
    productType?: InsuranceProductType;
    insurantId?: number | null;
    rate?: number;
    amount?: number;
    life?: Array<InsuranceLifeDto> | null;
}
export namespace InsuranceDto {
}


