/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualReviewTaskTab } from './manualReviewTaskTab';


export interface ManualReviewTabCounterDto { 
    tab?: ManualReviewTaskTab;
    count?: number;
}
export namespace ManualReviewTabCounterDto {
}


