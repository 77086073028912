/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BasisStatus = 'Completed' | 'Pending' | 'InProgress' | 'Rejected';

export const BasisStatus = {
    Completed: 'Completed' as BasisStatus,
    Pending: 'Pending' as BasisStatus,
    InProgress: 'InProgress' as BasisStatus,
    Rejected: 'Rejected' as BasisStatus
};

