import { booleanAttribute, Component, Input } from '@angular/core';
import { Nullable, reactiveTestAttributesHostDirective, SelectOption } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-radio-group',
  templateUrl: './reactive-radio-group.component.html',
  styleUrls: ['./reactive-radio-group.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveRadioGroupComponent<T = unknown> extends AbstractReactive {
  @Input({ required: true }) options: Nullable<SelectOption<unknown, T>[]>;
  @Input() containerClass = '';
  @Input() btnSize: 'm' | 'l' = 'l';
  @Input() radioWidth: Nullable<string>;
  @Input() direction: 'vertical' | 'horizontal' = 'horizontal';
  @Input({ transform: booleanAttribute }) canClear = false;
}
