import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiTime } from '@taiga-ui/cdk';
import { reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-time',
  templateUrl: './reactive-input-time.component.html',
  styleUrls: ['./reactive-input-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputTimeComponent extends AbstractReactive {
  @Input() items: readonly TuiTime[] = [];
  @Input() strict = true;
}
