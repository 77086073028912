<router-outlet></router-outlet>
<ng-container *ngIf="url$ | async" />
<fnip-background-animation
  *ngIf="isNew && !isRecoverPage"
  class="w-100 h-100"
  [style.position]="'absolute'"
  [style.top]="0"
  [style.left]="0"
  [style.overflow]="'hidden'"
/>
<fnip-privacy-policy color="white" class="d-flex content-end" [appName]="appName" [version]="version" />
