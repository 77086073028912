import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { TuiContextWithImplicit, TuiHandler } from '@taiga-ui/cdk';
import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';
import { isObservable, Observable, of } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { getSelectOptions$, Nullable, SelectOption, SelectOptions, SelectOptionsMap } from '@lib-utils';
import { AbstractReactive } from './abstract-reactive';

@Directive()
export abstract class AbstractReactiveWithOptions<T = unknown, TVal = unknown> extends AbstractReactive {
  @Input() options: Nullable<SelectOptions<T, TVal>>;

  @Input() set hasSearch(search: boolean | string) {
    this._hasSearch = typeof search === 'boolean' ? search : true;
  }

  get hasSearch(): boolean {
    return this._hasSearch;
  }

  @Input() term: Nullable<string> = null;

  @Input() minTermLength = 1;

  @Input() emptyTermMessage: Nullable<string> = 'Введите первые буквы для поиска';

  @Input() initialOptions: Nullable<SelectOption<T>[]>;

  @Output() selectedOptionChange = new EventEmitter<Nullable<SelectOption<T>>>();

  selectedOption: Nullable<SelectOption<T>>;

  private _hasSearch = false;

  isOptionsLoading: Nullable<boolean>;

  optionsMap: SelectOptionsMap<T> = new Map();

  private savedResultGetSelectOptions$!: Observable<SelectOption<T>[]>;

  private savedResultWithLoading$!: Observable<SelectOption<T, TVal>[]>;

  withLoading$ = (
    options: Nullable<SelectOptions<T, TVal>>,
    value?: Nullable<string>,
    initialOptions?: typeof this.initialOptions,
  ): Observable<SelectOption<T, TVal>[]> => {
    if (!options) return of([]);
    const newResultGetSelectOptions$ = getSelectOptions$(
      options,
      value,
      this.minTermLength,
      initialOptions,
      this.control?.pristine,
    );
    if (isEqual(newResultGetSelectOptions$, this.savedResultGetSelectOptions$)) return this.savedResultWithLoading$;
    this.savedResultGetSelectOptions$ = newResultGetSelectOptions$;

    if (isObservable(this.options)) this.selectedOption = null;
    this.isOptionsLoading = true;
    this.savedResultWithLoading$ = newResultGetSelectOptions$.pipe(
      catchError(() => of([])),
      delay(0),
      map((res) =>
        this.selectedOption &&
        isEqual(this.selectedOption.value, this.control?.value) &&
        !res.some((item) => item.value === this.selectedOption?.value)
          ? ([...res, this.selectedOption] as SelectOption<T, TVal>[])
          : (res as SelectOption<T, TVal>[]),
      ),
      tap((res) => {
        this.isOptionsLoading = false;
        this.optionsMap = new Map((res ?? []).map((item) => [item.value, item]));
      }),
    );
    return this.savedResultWithLoading$;
  };

  setSelectedOption = (value: unknown, optionsMap: SelectOptionsMap<T>) => {
    const option = optionsMap.get(value);
    if (isEqual(this.selectedOption, option)) return;

    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
  };

  filterLoadedOptions = (
    loadedOptions: Nullable<SelectOption<T, TVal>[]>,
    term: Nullable<string>,
  ): Nullable<SelectOption<T, TVal>[]> => {
    // The callback is responsible for filtering by term
    if (isFunction(this.options) || !this._hasSearch || !term) return loadedOptions;
    return loadedOptions?.filter((option) => option.label.toLowerCase().includes(term.toLowerCase()));
  };

  getDefaultValueContent(
    optionsMap: SelectOptionsMap<T>,
  ): TuiHandler<string | number | TuiContextWithImplicit<string | number>, string> {
    return (e) => {
      if (!optionsMap) return '';
      const value = (e as TuiContextWithImplicit<number | string>)?.$implicit ?? e;
      return optionsMap.get(value)?.label ?? String(value ?? '');
    };
  }

  getLoadingValuePlaceholder = () => 'Загрузка...';
}
