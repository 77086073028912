import { AbstractControl } from '@angular/forms';
import { Nullable } from '../../interfaces/types';

export const setDisableOrEnable = (
  control: Nullable<AbstractControl<unknown>>,
  isVisible: Nullable<boolean>,
  isParentFormDisabled: Nullable<boolean>,
  preventResetForm = false,
) => {
  if (isParentFormDisabled) return;

  if (!isVisible && control?.enabled) {
    if (!preventResetForm) control.reset();
    control.disable({ emitEvent: false, onlySelf: false });
  }
  if (isVisible && control?.disabled) control.enable({ emitEvent: false, onlySelf: false });
};
