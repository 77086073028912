/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';
import { ApplicationFileCheckTypeDto } from './applicationFileCheckTypeDto';


export interface ApplicationFileActiveCheckDto { 
    id?: number;
    name?: string | null;
    type?: FileType;
    size?: number;
    contentType?: string | null;
    version?: number;
    fileCheck?: ApplicationFileCheckTypeDto;
}
export namespace ApplicationFileActiveCheckDto {
}


