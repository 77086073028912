/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { InsuranceForm } from './insuranceForm';
import { DateOnly } from '@lib-utils';
import { DateTime } from '@lib-utils';
import { ProofOfIncome } from './proofOfIncome';


export interface DataForFileChecksModel { 
    id?: number;
    contractNumber?: string | null;
    dealInfoDate?: DateOnly | null;
    fio?: string | null;
    maritalStatus?: MaritalStatus;
    currentAccountNumber?: string | null;
    address?: string | null;
    propertyValue?: number | null;
    firstAmount?: number;
    length?: number;
    requestedAmount?: number;
    rate?: number;
    creditProgramName?: string | null;
    monthlyPayment?: number;
    proofOfIncome?: ProofOfIncome;
    partnerName?: string | null;
    insuranceForm?: InsuranceForm;
    cssMsk?: string | null;
    ownershipType?: string | null;
    seriesPassport?: string | null;
    numberPassport?: string | null;
    issueDate?: DateTime;
    issuedBy?: string | null;
    letterOfCreditOpeningDate?: DateOnly | null;
    letterOfCreditEndDate?: DateOnly | null;
    letterOfCreditPeriod?: number | null;
    agreementName?: string | null;
    agreementDate?: DateOnly | null;
    propertyAddress?: string | null;
    recipientFunds?: string | null;
    accountNumberRecipientFunds?: string | null;
}
export namespace DataForFileChecksModel {
}


