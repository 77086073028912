/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressDto } from './addressDto';
import { ResidentialComplexDto } from './residentialComplexDto';
import { BuildingAccreditationStatus } from './buildingAccreditationStatus';


export interface BuildingAddressDto { 
    id?: number | null;
    resComplexId?: number | null;
    residentialComplex?: ResidentialComplexDto;
    addressId?: number | null;
    address?: AddressDto;
    cadastralNumber?: string | null;
    accreditationStatus?: BuildingAccreditationStatus;
    coefficient?: number | null;
    originalAddress?: string | null;
}
export namespace BuildingAddressDto {
}


