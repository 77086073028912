export enum MimeTypes {
  Csv = 'text/csv',
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpg',
  Bmp = 'image/bmp',
  Png = 'image/png',
  Gif = 'image/gif',
  Pdf = 'application/pdf',
  MsDoc = 'application/msword',
  MsDocx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MsXls = 'application/vnd.ms-excel',
  MsXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Rtf = 'text/rtf',
  Odf = 'application/vnd.oasis.opendocument.formula',
  Zip = 'application/zip',
  Xzip = 'application/x-zip-compressed',
  Odt = 'application/vnd.oasis.opendocument.text',
  Tiff = 'image/tiff',
  Heic = 'image/heic',
  Tif = 'image/tif',
}

export enum FileExtensions {
  Csv = 'CSV',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Bmp = 'BMP',
  Png = 'PNG',
  Gif = 'GIF',
  Pdf = 'PDF',
  MsDoc = 'DOC',
  MsDocx = 'DOCX',
  MsXls = 'XLS',
  MsXlsx = 'XLSX',
  Rtf = 'RTF',
  Odf = 'ODF',
  Zip = 'ZIP',
  Odt = 'ODT',
  Tiff = 'TIFF',
  Heic = 'HEIC',
  Tif = 'TIF',
}

export const MimeTypesExtensionsMap: Record<MimeTypes, FileExtensions> = {
  [MimeTypes.Csv]: FileExtensions.Csv,
  [MimeTypes.Jpeg]: FileExtensions.Jpeg,
  [MimeTypes.Jpg]: FileExtensions.Jpg,
  [MimeTypes.Bmp]: FileExtensions.Bmp,
  [MimeTypes.Png]: FileExtensions.Png,
  [MimeTypes.Gif]: FileExtensions.Gif,
  [MimeTypes.Pdf]: FileExtensions.Pdf,
  [MimeTypes.MsDoc]: FileExtensions.MsDoc,
  [MimeTypes.MsDocx]: FileExtensions.MsDocx,
  [MimeTypes.MsXls]: FileExtensions.MsXls,
  [MimeTypes.MsXlsx]: FileExtensions.MsXlsx,
  [MimeTypes.Rtf]: FileExtensions.Rtf,
  [MimeTypes.Odf]: FileExtensions.Odf,
  [MimeTypes.Zip]: FileExtensions.Zip,
  [MimeTypes.Xzip]: FileExtensions.Zip,
  [MimeTypes.Odt]: FileExtensions.Odt,
  [MimeTypes.Tiff]: FileExtensions.Tiff,
  [MimeTypes.Tif]: FileExtensions.Tif,
  [MimeTypes.Heic]: FileExtensions.Heic,
};

export function isKnownMimeType(fileType: string): fileType is MimeTypes {
  return Object.values(MimeTypes).includes(fileType as MimeTypes);
}
