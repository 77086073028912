import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import { FormControlPrimitive } from '../../interfaces/form-group-of';
import { Nullable } from '../../interfaces/types';

export type UnNullable<T> = T extends FormControlPrimitive
  ? T extends Nullable<infer K>
    ? K
    : never
  : T extends object
    ? { [P in keyof T]: UnNullable<T[P]> }
    : T extends Array<infer K>
      ? Array<UnNullable<K>>
      : T extends Nullable<T>
        ? never
        : T;

/**
 * Нужен для отправки на бекэнд ненулевого объекта
 * Пример:
 * У нас есть FormGroup
 * @example
 * const form = new FormGroup({ firstName: new FormControl<Nullable<string>>>(null) });
 *
 * Бекэндовый метод принимает только интерфейс вида
 * @example
 * interface UserDto { firstName?: string }
 *
 * Можно отфильтровать объект при помощи _.omitBy(form.value, _.isNil), но тогда пропадет безопасность типов,
 * метод omitBy вернет Dictionary<any>, и если форма уже не будет соответствовать интерфейсу, мы не получим ошибок
 *
 * > Если использовать omitNullable, возвращаемый тип будет правильный, а внутри будет та же логика lodash
 *
 * @example
 * this.backendService.PostUser(omitNullable(form.value));
 */
export const omitNullable = <T>(value: Nullable<T>) => omitBy(value ?? {}, isNil) as Partial<UnNullable<T>>;

export const omitNullableArray = <T>(value: Nullable<Nullable<T>[]>) => value?.map(omitNullable);

export const omitNullableBy = <T, K extends keyof T>(value: Nullable<T>, keys: Array<K>) =>
  omit(
    omitBy(value ?? {}, (value) => !(Array.isArray(value) ? value.length : Boolean(value))),
    keys,
  );
