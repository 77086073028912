import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiValidationError } from '@taiga-ui/cdk';
import { tuiHintOptionsProvider } from '@taiga-ui/core';
import { TuiStatus } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import isNil from 'lodash-es/isNil';
import { debounceTime, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Nullable, ResizeObservable } from '@lib-utils';

const DEBOUNCE_RESIZE_TIME_DEFAULT = 100;

@Component({
  selector: 'fnip-reactive-field-error',
  templateUrl: './reactive-field-error.component.html',
  styleUrls: ['./reactive-field-error.component.scss'],
  providers: [
    tuiHintOptionsProvider({
      icon: `tuiIconInfo`,
    }),
  ],
})
export class ReactiveFieldErrorComponent {
  @Input() control: Nullable<FormControl<unknown>>;

  /**
   * Отображает переданное сообщение только в лейбле ошибки. Имеет более высокий приоритет, чем свойство `error`.
   */
  @Input() errorLabel?: Nullable<string>;

  /**
   * Отображает переданное сообщение об ошибке в тултипе и лейбле. Указывается, если `fnip-reactive-field-error`
   * используется без передачи свойства `control`.
   */
  @Input() error?: Nullable<PolymorpheusContent>;

  @Input() status: Nullable<TuiStatus>;

  /**
   * Отображает ошибку вне контента, не меняя верстку
   */
  @Input({ transform: booleanAttribute }) absolutePosition = true;

  @Input({ transform: booleanAttribute }) absolutePositionWithoutControl = false;

  @Output() isErrorShow = new EventEmitter<Nullable<boolean>>();

  parentWidth = 0;

  errorWidth = 0;

  get getParentElementWidth() {
    return this.elementRef.nativeElement.offsetWidth ?? 0;
  }

  get getErrorElementWidth() {
    return this.elementRef.nativeElement.querySelector('tui-error > .t-message-text > span')?.offsetWidth ?? 0;
  }

  errorChanged$ = new Subject<void>();

  onChangeError$ = this.errorChanged$.pipe(
    tap(() => {
      this.parentWidth = this.getParentElementWidth;
      this.errorWidth = this.getErrorElementWidth;
      this.cdr.detectChanges();
    }),
  );

  onResizeParentElement$ = new ResizeObservable(this.elementRef.nativeElement).pipe(
    debounceTime(DEBOUNCE_RESIZE_TIME_DEFAULT),
    map((entris: ResizeObserverEntry[]) => entris[0].contentRect.width),
    tap((value: number) => {
      this.parentWidth = value;
      this.errorWidth = this.getErrorElementWidth;
      this.cdr.detectChanges();
    }),
  );

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  toIconErrorMessage = (template: TemplateRef<unknown>, error: PolymorpheusContent): TuiValidationError | null => {
    const result = error ? new TuiValidationError(template, { $implicit: error }) : null;
    this.isErrorShow.emit(!!result);
    setTimeout(() => this.errorChanged$.next());
    return result;
  };

  isNil = isNil;
}
