import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveSelectOptionsModule, ToStaticOptionsPipe, ToTermOptionsPipe } from './options';
import { ReactiveSelectNewComponent, ReactiveSelectTermNewComponent } from './widgets';

@NgModule({
  imports: [
    ReactiveSelectOptionsModule,
    ReactiveSelectNewComponent,
    ReactiveSelectTermNewComponent,
    ToStaticOptionsPipe,
    ToTermOptionsPipe,
  ],
  exports: [
    ReactiveSelectOptionsModule,
    ReactiveSelectNewComponent,
    ReactiveSelectTermNewComponent,
    ToStaticOptionsPipe,
    ToTermOptionsPipe,
    ReactiveFormsModule,
  ],
})
export class ReactiveSelectNewModule {}
