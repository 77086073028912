<ng-container *ngIf="control">
  <tui-hosted-dropdown
    *tuiLet="withLoading$ | executeWith: options : control.value | async as loadedOptions"
    #tuiHostedDropdownComponent
    tuiDropdownLimitWidth="fixed"
    [class.auto-height]="autoHeight"
    [content]="dropDownTmp"
    [canOpen]="!isReadOnly && !!tuiHostedDropdownComponent.focused && !!options"
    [(open)]="isDropDownVisible"
  >
    <fnip-reactive-label
      [hasRequiredValidator]="hasRequiredValidator"
      [label]="label"
      [size]="labelSize"
      [hint]="hint"
      [noBottomHint]="noBottomHint"
      [isBold]="isLabelBold"
    >
      <tui-text-area
        [nativeId]="fieldId"
        [formControl]="$any(control)"
        [tuiTextfieldLabelOutside]="textfieldLabelOutside"
        [tuiTextfieldSize]="textFieldSize"
        [tuiTextfieldCustomContent]="textfieldCustomContent"
        [expandable]="expandable"
        [readOnly]="isReadOnly"
        [style.min-height]="minHeight"
        (tuiValueChanges)="valueChange.emit($event); isDropDownVisible = true"
      >
        {{ placeholder }}
        <textarea *ngIf="control.disabled" tuiTextfield [style.pointer-events]="'auto'"></textarea>
      </tui-text-area>
      <fnip-reactive-field-error
        *ngIf="!noValidationMark"
        [control]="$any(control)"
        [errorLabel]="errorLabel"
        (isErrorShow)="noBottomHint = $event"
      />
    </fnip-reactive-label>

    <!-- Порядок вызыва важен 1 - setControlValue(item.value, optionsMap); 2 - setSelectedOption(item.value, optionsMap)" -->
    <ng-template #dropDownTmp>
      <tui-data-list *ngIf="options">
        <a *ngIf="isOptionsLoading" tuiOption class="loading-indicator">Поиск...</a>
        <a
          *ngFor="let option of loadedOptions"
          tuiOption
          (click)="
            setControlValue(option.value, optionsMap);
            setSelectedOption(option.value, optionsMap);
            isDropDownVisible = false
          "
        >
          <ng-container
            *ngIf="optionTmp; else noOptionTemplateTmp"
            [ngTemplateOutlet]="optionTmp || null"
            [ngTemplateOutletContext]="{ $implicit: option }"
          />
          <ng-template #noOptionTemplateTmp>{{ option.label }}</ng-template>
        </a>
      </tui-data-list>
    </ng-template>
  </tui-hosted-dropdown>
</ng-container>
