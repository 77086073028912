/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MortgageProgramType = 'Standart' | 'TwoDocuments' | 'Family' | 'Military' | 'StateSupport2020';

export const MortgageProgramType = {
    Standart: 'Standart' as MortgageProgramType,
    TwoDocuments: 'TwoDocuments' as MortgageProgramType,
    Family: 'Family' as MortgageProgramType,
    Military: 'Military' as MortgageProgramType,
    StateSupport2020: 'StateSupport2020' as MortgageProgramType
};

