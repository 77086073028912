import { HttpClient } from '@angular/common/http';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import { LocaleEnum } from '@lib-bff/api';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { ConfigService } from '@lib-config';

export const LANGUAGE_NAME_MAP = new Map<TuiCountryIsoCode, string>([
  [TuiCountryIsoCode.GB, 'BASE.LANGUAGE.GB'],
  [TuiCountryIsoCode.RU, 'BASE.LANGUAGE.RU'],
]);

export const TUI_LANGUAGE_CODE_TO_TRANSLATE_CODE = new Map<TuiCountryIsoCode, LocaleEnum>([
  [TuiCountryIsoCode.GB, LocaleEnum.En],
  [TuiCountryIsoCode.RU, LocaleEnum.Ru],
]);

export const TRANSLATE_CODE_TO_TUI_LANGUAGE_CODE = new Map<LocaleEnum, TuiCountryIsoCode>([
  [LocaleEnum.En, TuiCountryIsoCode.GB],
  [LocaleEnum.Ru, TuiCountryIsoCode.RU],
]);

export const moduleHttpLoaderFactory =
  (modules = ['base'], baseTranslateUrl = '/assets/i18n') =>
  (http: HttpClient) => {
    const options: IModuleTranslationOptions = {
      modules: modules.map((moduleName) => ({ baseTranslateUrl, moduleName })),
      disableNamespace: true,
    };

    return new ModuleTranslateLoader(http, options);
  };

export const enableTranslateOnDev = {
  deps: [ConfigService],
  useFactory: (config: ConfigService) => !config.isProdOrPreprod(),
};
