import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { BffConfiguration, LocaleApiService, LocaleEnum } from '@lib-bff/api';
import { TranslateModule as NgxTranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from '@lib-config';
import { createProvider, ValueOrFactoryProvider } from '@lib-utils';
import { TRANSLATE_ENABLED_TOKEN } from './translate-enabled.token';
import { TranslateStateService } from './translate-state.service';
import { moduleHttpLoaderFactory } from './translate.utils';
import { LanguageSwitcherComponent } from './widgets';

interface TranslateModuleConfig {
  isEnabled?: ValueOrFactoryProvider;
  baseTranslateUrl?: string;
  modules?: string[];
}

@NgModule({
  imports: [HttpClientModule, LanguageSwitcherComponent, NgxTranslateModule.forRoot({})],
  providers: [
    LocaleApiService,
    CookieService,
    TranslateStateService,
    {
      provide: BffConfiguration,
      useFactory: () =>
        new BffConfiguration({
          basePath: inject(ConfigService).bffUrl,
          withCredentials: true,
        }),
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateStateService, TRANSLATE_ENABLED_TOKEN],
      useFactory: (translateStateService: TranslateStateService, isEnabled: boolean) => () => {
        let key: LocaleEnum = LocaleEnum.Ru;
        if (isEnabled) {
          key = translateStateService.getCurrentLanguageFromStorage();
        }

        return translateStateService.setLanguage(key, false);
      },
    },
  ],
  exports: [NgxTranslateModule],
})
export class TranslateModule {
  static forRoot(config?: TranslateModuleConfig): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        createProvider(TRANSLATE_ENABLED_TOKEN, config?.isEnabled, { useValue: false }),
        {
          provide: TranslateLoader,
          useFactory: moduleHttpLoaderFactory(config?.modules, config?.baseTranslateUrl),
          deps: [HttpClient],
        },
      ],
    };
  }
}
