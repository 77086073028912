<h3 class="mb-4">{{ title }}</h3>

<fnip-reactive-select
  hasSearch
  fieldId="userId"
  [placeholder]="selectPlaceholder"
  class="mb-5"
  [textfieldCleaner]="true"
  [control]="userControl"
  [options]="options"
  [initialOptions]="initialOptions"
/>

<div class="d-flex gap-2 content-end">
  <fnip-button btnSize="l" label="Отмена" [actionCallback$]="close" />
  <fnip-button
    appearance="primary"
    btnSize="l"
    [label]="actionLabel"
    [isDisabled]="userControl.invalid"
    [actionCallback$]="setUser$"
  />
</div>
