/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionType } from './conditionType';


export interface DealConditionDto { 
    id?: number;
    conditionType?: ConditionType;
    condition?: string | null;
    ownerRealEstateMappingId?: number | null;
    ownerRealEstateFullName?: string | null;
    clientId?: number | null;
    clientFullName?: string | null;
    applicationId?: number;
}
export namespace DealConditionDto {
}


