/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExpenseType = 'Rent' | 'Education' | 'Insurance' | 'Other' | 'CreditObligationPayments';

export const ExpenseType = {
    Rent: 'Rent' as ExpenseType,
    Education: 'Education' as ExpenseType,
    Insurance: 'Insurance' as ExpenseType,
    Other: 'Other' as ExpenseType,
    CreditObligationPayments: 'CreditObligationPayments' as ExpenseType
};

