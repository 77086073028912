<div class="d-flex flex-column gap-8">
  <h3>{{ dialogContext.data?.title }}</h3>

  <ng-container [ngSwitch]="dialogContext.data?.type">
    <ng-template [ngSwitchCase]="ConfirmationDialogType.TextArea">
      <fnip-reactive-text-area
        fieldId="commentControl"
        [label]="dialogContext.data?.label ?? 'Введите текст'"
        [textFieldSize]="dialogContext.data?.fieldOptions?.size ?? 'm'"
        [requiredIf]="true"
        [control]="control"
        [options]="dialogContext.data?.options"
      />
    </ng-template>

    <ng-template [ngSwitchCase]="ConfirmationDialogType.Select">
      <fnip-reactive-select
        fieldId="selectControl"
        [label]="dialogContext.data?.label ?? 'Выберите опцию'"
        [textFieldSize]="dialogContext.data?.fieldOptions?.size ?? 'm'"
        [requiredIf]="true"
        [control]="control"
        [options]="dialogContext.data?.options"
        [hasSearch]="dialogContext.data?.hasSearch ?? false"
      />
    </ng-template>

    <ng-template [ngSwitchCase]="ConfirmationDialogType.MultiSelect">
      <fnip-reactive-multi-select
        fieldId="selectControl"
        [label]="dialogContext.data?.label ?? 'Выберите опцию'"
        [textFieldSize]="dialogContext.data?.fieldOptions?.size ?? 'm'"
        [requiredIf]="true"
        [control]="control"
        [options]="dialogContext.data?.options"
        [hasSearch]="dialogContext.data?.hasSearch ?? false"
      />
    </ng-template>

    <ng-template [ngSwitchCase]="ConfirmationDialogType.Input">
      <fnip-reactive-input
        fieldId="inputControl"
        [label]="dialogContext.data?.label ?? 'Введите текст'"
        [textFieldSize]="dialogContext.data?.fieldOptions?.size ?? 'm'"
        [requiredIf]="true"
        [control]="getInputControl | executeWith: control"
        [options]="getInputControlOptions | executeWith: dialogContext.data?.options ?? null"
      />
    </ng-template>

    <ng-template [ngSwitchCase]="ConfirmationDialogType.Question">
      <p class="text-pre-line">{{ dialogContext.data?.text }}</p>
    </ng-template>
  </ng-container>

  <div class="d-flex align-self-end gap-4">
    <fnip-button
      *ngIf="dialogContext.data?.showReset"
      appearance="flat"
      [isDisabled]="control.value === (dialogContext.data?.resetValue ?? null)"
      [label]="dialogContext.data?.resetText ?? 'Сбросить'"
      [isAutofocus]="dialogContext?.data?.focusOnReset"
      (click)="reset()"
    />
    <fnip-button
      *ngIf="dialogContext.data?.showCancel ?? true"
      appearance="flat"
      [label]="dialogContext.data?.cancelText ?? 'Отмена'"
      [isAutofocus]="dialogContext?.data?.focusOnCancel"
      (click)="cancel()"
    />
    <fnip-button
      appearance="primary"
      [label]="dialogContext.data?.okText ?? 'Ок'"
      [isDisabled]="control.invalid"
      [isAutofocus]="dialogContext?.data?.focusOnConfirm"
      (click)="confirm()"
    />
  </div>
</div>
