/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MortgageProgramType } from './mortgageProgramType';
import { AdditionalCondition } from './additionalCondition';
import { CreditProductDto } from './creditProductDto';
import { CalculationResultDto } from './calculationResultDto';
import { InsuranceForm } from './insuranceForm';
import { InitialContributionSource } from './initialContributionSource';
import { DateOnly } from '@lib-utils';
import { PaymentInfoDto } from './paymentInfoDto';
import { PaymentType } from './paymentType';
import { BankDecisionDto } from './bankDecisionDto';
import { NonStandardType } from './nonStandardType';
import { ProofOfIncome } from './proofOfIncome';


export interface CreditInfoDto { 
    id?: number;
    residentialComplexId?: number | null;
    creditProductId?: number | null;
    creditProduct?: CreditProductDto;
    calculationResult?: CalculationResultDto;
    firstPaymentInfos?: Array<PaymentInfoDto> | null;
    requestedAmount?: number;
    firstAmount?: number;
    requestedInitialPaymentSumPercent?: number;
    length?: number;
    proofOfIncome?: ProofOfIncome;
    realEstateValue?: number;
    subsidy?: number;
    maternalCapital?: number;
    initialContributionSource?: InitialContributionSource;
    otherInitialContributionSource?: string | null;
    mortgageProgramType?: MortgageProgramType;
    additionalCondition?: Array<AdditionalCondition> | null;
    otherAdditionalCondition?: string | null;
    currentAccountNumber?: string | null;
    collateralAccountNumber?: string | null;
    paymentType?: PaymentType;
    paymentDate?: DateOnly | null;
    bankDecisionId?: number | null;
    bankDecision?: BankDecisionDto;
    creditTransferPeriod?: number | null;
    insuranceForm?: InsuranceForm;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    requestedRealEstateValue?: number;
    requestedFirstAmount?: number;
    requestedCalculatedRate?: number;
    requestedPeriod?: number;
    requestedMonthlyPayment?: number;
}
export namespace CreditInfoDto {
}


