/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentRecognitionErrorDto } from './documentRecognitionErrorDto';
import { DateOnly } from '@lib-utils';


export interface EmploymentContractRecognitionDto { 
    errors?: Array<DocumentRecognitionErrorDto> | null;
    fullName?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    secondName?: string | null;
    stamp?: boolean | null;
    issueDate?: DateOnly | null;
    copyIsStitched?: boolean | null;
    stitchingCertified?: any | null;
    affirmationOnAllPages?: boolean | null;
}

