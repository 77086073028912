import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { ExpandableDirective } from '@lib-utils';

@Component({
  selector: 'fnip-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AccordionGroupComponent implements AfterContentInit {
  @ContentChildren(ExpandableDirective) items?: QueryList<ExpandableDirective>;

  _currentStep = 0;

  @Input() set currentStep(i: number) {
    this.setStep(i);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  setStep = (i: number) => {
    this._currentStep = i;
    this.items?.get(i - 1)?.setOpen(false);
    this.items?.get(i)?.setOpen(true);
    this.cdr.markForCheck();
  };

  ngAfterContentInit(): void {
    this.setStep(this._currentStep);
  }
}
