import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';

export interface ButtonMenuDropdownItem {
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  actionCallback$?: Nullable<() => Nullable<Observable<unknown> | void>>;
  routerLink?: Nullable<Array<number | string | undefined> | string | null>;
}
