import { Observable } from 'rxjs';

export interface LabelValueOption<TVal = unknown> {
  label: string;
  value: TVal;
  hide?: boolean;
  disabled?: boolean;
}

export type SelectOption<T = unknown, TVal = unknown> = T & LabelValueOption<TVal>;
export type SelectOptions<T = unknown, TVal = unknown> =
  | Observable<SelectOption<T, TVal>[]>
  | ((t: string) => Observable<SelectOption<T, TVal>[]>)
  | SelectOption<T, TVal>[];

export type SelectOptionsMap<T, TVal = unknown> = Map<TVal, SelectOption<T>>;
