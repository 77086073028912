/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Sex = 'Male' | 'Female' | 'Undefined';

export const Sex = {
    Male: 'Male' as Sex,
    Female: 'Female' as Sex,
    Undefined: 'Undefined' as Sex
};

