import { HttpErrorResponse } from '@angular/common/http';
import { FeHttpError } from '@lib-utils';

export type TypedHttpErrorResponse<T> = Omit<HttpErrorResponse, 'error'> & { error?: T };

export type ErrorCheckFn<T> = (
  url: string | null,
  feError: FeHttpError<T> | null,
  httpError: TypedHttpErrorResponse<T>,
) => boolean;
export type GetMessageFn<T> = (feError: FeHttpError<T> | null, httpError: TypedHttpErrorResponse<T>) => string;

export interface ErrorCheckMessage<T = unknown> {
  check: ErrorCheckFn<T>;
  message: string | GetMessageFn<T>;
}

export interface ErrorInterceptorConfig<T = unknown> {
  showErrors: boolean;
  // Transform error to notification message
  transformError?: (error: FeHttpError<T>) => string;
}
