import { Observable } from 'rxjs';

export class ResizeObservable extends Observable<ResizeObserverEntry[]> {
  constructor(element: HTMLElement) {
    super((subscriber) => {
      const resizeObserver = new ResizeObserver((entries) => subscriber.next(entries));

      resizeObserver.observe(element);

      return function unsubscribe() {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      };
    });
  }
}
