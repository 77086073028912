import { map, MonoTypeOperatorFunction, Observable, startWith, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Оператор RxJS, предотвращающий выполнение последующих событий в потоке,
 * если указанное в аргументах событие было вызвано недавно.
 *
 * @param trigger$ Поток событий, который триггерит действие, например, нажатие кнопки выхода.
 * @param [timeWindow=1000] Временной интервал (в миллисекундах), в течение которого повторные события будут игнорироваться.
 * @returns Оператор, который можно использовать в `pipe` для прерывания потока, если событие вызвано недавно.
 *
 * @example
 * const tokenRemoved$ = new Subject<void>();
 * const logoutButtonClicked$ = new Subject<void>();
 *
 * tokenRemoved$
 *   .pipe(preventIfRecent(logoutButtonClicked$, 1000))
 *   .subscribe(() => logout());
 */
export function preventIfRecent<T>(trigger$: Observable<unknown>, timeWindow = 1000): MonoTypeOperatorFunction<T> {
  const lastTriggerTime$ = trigger$.pipe(
    // Если событие не происходило, то последнее время будет равно 0, тогда разница во времени = Date.now()
    startWith(0),
    // Обновляем время последнего действия, если оно произошло
    map(() => Date.now()),
  );

  return (source: Observable<T>) =>
    source.pipe(
      withLatestFrom(lastTriggerTime$),
      // Если последнее действие произошло раньше на timeWindow (или не произошло совсем), то выполняем пайп дальше
      filter(([_, lastActionTime]) => Date.now() - lastActionTime > timeWindow),
      map(([value]) => value),
    );
}
