import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiBreakpointService } from '@taiga-ui/core';
import { TuiBreakpointMediaKey } from '@taiga-ui/core/services/breakpoint.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { Nullable } from '../../interfaces';

interface BreakpointState {
  mobile: boolean;
  desktopSmall: boolean;
  desktopLarge: boolean;
}

@Injectable()
export class BreakpointService {
  private readonly tuiBreakpointService = inject(TuiBreakpointService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly _state$ = new BehaviorSubject<BreakpointState>({
    mobile: false,
    desktopSmall: false,
    desktopLarge: false,
  });

  public set state(breakpoint: Nullable<TuiBreakpointMediaKey>) {
    this._state$.next(this.getBreakpointState(breakpoint));
  }

  public get state(): BreakpointState {
    return this._state$.value;
  }

  public get state$(): Observable<BreakpointState> {
    return this._state$.asObservable();
  }

  public get isMobile(): boolean {
    return this._state$.value.mobile;
  }

  public get isMobile$(): Observable<boolean> {
    return this._state$.asObservable().pipe(map(({ mobile }) => mobile));
  }

  constructor() {
    this.tuiBreakpointService
      .pipe(
        tap((breakpoint) => (this.state = breakpoint)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private getBreakpointState = (breakpoint: Nullable<TuiBreakpointMediaKey>): BreakpointState => ({
    mobile: breakpoint === 'mobile',
    desktopSmall: breakpoint === 'desktopSmall',
    desktopLarge: breakpoint === 'desktopLarge',
  });
}
