import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Nullable } from '@lib-utils';
import { PermissionOperationMapKeys, PermissionService } from '../../services/permission';

/**
 * Струкрутная директива, которая включает шаблон, при выполнении условия наличия указанной роли.
 *
 * Пример использования:
 * ```
 * *fnipAccess="'crmActivityCreate'"
 * ```
 */
@Directive({
  selector: '[fnipAccess]',
  exportAs: 'access',
})
export class AccessDirective {
  private hasView = false;

  /**
   * Роль, требуемая для доступа к темплейту
   */
  @Input() set fnipAccess(permission: Nullable<PermissionOperationMapKeys>) {
    const accessGranted = this.permissionService.checkExactPermissionFor(permission);

    if (accessGranted && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!accessGranted && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
  ) {}
}
