import { toSignal } from '@angular/core/rxjs-interop';
import { exhaustMap, merge, Observable, Subject, timer } from 'rxjs';

export function toIntervalDataSignal<T>(data$: Observable<T>, interval = 60_000) {
  const trigger$ = new Subject<void>();
  const timer$ = timer(0, interval);
  const data = toSignal(merge(timer$, trigger$).pipe(exhaustMap(() => data$)));
  return {
    data,
    refresh: () => trigger$.next(),
  };
}
