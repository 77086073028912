import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TuiLabelModule } from '@taiga-ui/core';
import { ReactiveLabelComponent } from './reactive-label.component';

const TUI_MODULES = [TuiLabelModule, TuiMapperPipeModule];

@NgModule({
  imports: [CommonModule, TUI_MODULES],
  declarations: [ReactiveLabelComponent],
  exports: [ReactiveLabelComponent],
})
export class ReactiveLabelModule {}
