/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankStatementDeductTaxType } from './bankStatementDeductTaxType';
import { DateOnly } from '@lib-utils';
import { IncomeDocumentMonthlyIncomeVerificationDto } from './incomeDocumentMonthlyIncomeVerificationDto';


export interface CalculateAverageIncomeStatementIncomeRequest { 
    startDate?: DateOnly | null;
    endDate?: DateOnly | null;
    deductTaxType?: BankStatementDeductTaxType;
    manualTaxAmount?: number | null;
    rate?: number | null;
    incomes?: Array<IncomeDocumentMonthlyIncomeVerificationDto> | null;
}
export namespace CalculateAverageIncomeStatementIncomeRequest {
}


