import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectOption } from '../../interfaces/select-option';
import { Nullable } from '../../interfaces/types';

export function createTranslatedOptionsForLocalizedEnum<T extends number | string>(
  localizeMap: Map<T, number | string> | Partial<Record<T, Nullable<number | string>>>,
  translateService = inject(TranslateService),
): Observable<SelectOption<{ value: T }, T>[]> {
  return translateService.onLangChange.pipe(
    startWith(() => translateService.currentLang),
    map(() => {
      const keyValuePairs =
        localizeMap instanceof Map ? Array.from(localizeMap) : Object.entries<Nullable<number | string>>(localizeMap);

      return keyValuePairs.map(([key, value]) => ({
        value: key as T,
        label: translateService.instant(value?.toString() || ''),
      }));
    }),
  );
}
