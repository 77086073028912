import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDropdownPortalService } from '@taiga-ui/cdk';
import { TuiAppearance, TuiHorizontalDirection, TuiVerticalDirection } from '@taiga-ui/core';
import uniq from 'lodash-es/uniq';
import { startWith } from 'rxjs/operators';
import { Nullable, reactiveTestAttributesHostDirective, SelectOption } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';

const GROUP_SEPARATOR_HEIGHT = 24;
const DROPDOWN_MAX_HEIGHT = 400;

@Component({
  selector: 'fnip-reactive-button-multi-dropdown',
  templateUrl: './reactive-button-multi-dropdown.component.html',
  styleUrls: ['./reactive-button-multi-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveButtonMultiDropdownComponent<T = unknown> extends AbstractReactiveWithOptions<unknown, T> {
  @Input() override control: Nullable<FormControl<Nullable<Array<T>>>>;
  @Input() dropdownAlign: TuiHorizontalDirection = 'right';
  @Input() dropdownDirection: TuiVerticalDirection | null = null;
  @Input({ transform: booleanAttribute }) fixedWidth: Nullable<boolean>;
  @Input() dropdownWidth = '400px';
  @Input() optionHeight = 44;
  @Input() appearance: string = TuiAppearance.Outline;
  @Input() labelIconAlign: 'left' | 'right' = 'right';
  @Output() dropdownStateChange = new EventEmitter<boolean>();

  readonly dropdownService = inject(TuiDropdownPortalService);

  open = false;

  readonly currentValue$ = (control?: FormControl<Nullable<Array<T>>>) =>
    control?.valueChanges.pipe(startWith(control.value));

  clearControl() {
    this.control?.reset();
  }

  // Пробуем определить максимальную высоту дропдауна тайги, т.к. не должны задавать значение, большее этого числа
  getDropDownMaxHeight = () => {
    const dropdown = document.querySelector('tui-dropdown');
    if (!dropdown) return DROPDOWN_MAX_HEIGHT;
    const maxHeightRawValue = document.defaultView?.getComputedStyle(dropdown).getPropertyValue('max-height');

    if (!maxHeightRawValue) return DROPDOWN_MAX_HEIGHT;
    const maxHeight = parseFloat(maxHeightRawValue);
    return isNaN(maxHeight) ? DROPDOWN_MAX_HEIGHT : maxHeight;
  };

  /**
   * Минимальная высота, которую неободимо задать контейнеру для корректной работы виртуального скролла с кастомным контентом перед списком
   */
  getContainerHeight = (itemsCount: Nullable<number>, itemHeight: number, hasSearch: boolean) =>
    Math.min(
      this.getDropDownMaxHeight(),
      itemHeight + GROUP_SEPARATOR_HEIGHT + (hasSearch ? itemHeight : 0) + (itemsCount ?? 0) * itemHeight,
    );

  onSelectOption(value: T, _controlValue: Nullable<Array<T>>): void {
    const controlValue = _controlValue || [];
    if (controlValue.includes(value)) return this.control?.patchValue(controlValue.filter((item) => item !== value));
    this.control?.patchValue([...controlValue, value]);
  }

  onMultiSelectOption(options: Nullable<SelectOption<unknown, T>[]>, optionsSelected: Nullable<boolean>) {
    const optionValues = options?.map((option) => option.value) ?? [];
    const controlValue = this.control?.value ?? [];
    this.control?.patchValue(
      optionsSelected
        ? controlValue.filter((item) => !optionValues.includes(item))
        : uniq([...optionValues, ...controlValue]),
    );
  }

  isSelected = (value: T, controlValue: Nullable<Array<T>>) => controlValue?.includes(value) ?? false;

  isMultiSelected = (options: Nullable<SelectOption<unknown, T>[]>, controlValue: Nullable<Array<T>>) => {
    if (options?.every((option) => controlValue?.includes(option.value))) return true;
    if (options?.some((option) => controlValue?.includes(option.value))) return null;
    return false;
  };
}
