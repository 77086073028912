/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualReviewTaskModel } from './manualReviewTaskModel';
import { ApplicationModel } from './applicationModel';


export interface ManualReviewTasksApplicationMapModel { 
    id?: number;
    applicationId?: number;
    application?: ApplicationModel;
    underwriterTask?: ManualReviewTaskModel;
    underwriterTaskId?: number | null;
    firstAuthorizerTaskId?: number | null;
    firstAuthorizerTask?: ManualReviewTaskModel;
    secondAuthorizerTaskId?: number | null;
    secondAuthorizerTask?: ManualReviewTaskModel;
}

