import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiPrimitiveCheckboxModule } from '@taiga-ui/core';
import { TuiCheckboxLabeledModule, TuiCheckboxModule, TuiFieldErrorPipeModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveCheckboxComponent } from './reactive-checkbox.component';

const TUI_MODULES = [
  TuiCheckboxLabeledModule,
  TuiFieldErrorPipeModule,
  TuiCheckboxModule,
  TuiPrimitiveCheckboxModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  declarations: [ReactiveCheckboxComponent],
  exports: [ReactiveCheckboxComponent],
})
export class ReactiveCheckboxModule {}
