<div
  *ngFor="let rectangle of rectangles; trackBy: trackById"
  [class]="rectangle.class"
  [style.position]="'absolute'"
  [style.left.%]="rectangle.left"
  [style.width.%]="rectangle.width"
  [style.z-index]="rectangle.layer"
  [@rectangleAnimation]="{
    value: undefined,
    params: {
      enterDelay: rectangle.enterStart + 1,
      stretchDelay: rectangle.stretchStart - rectangle.enterStart - 1,
      endDelay: 20 - rectangle.stretchStart,
      height: rectangle.height,
      top: rectangle.bottom - rectangle.height,
      bottom: rectangle.bottom,
    },
  }"
></div>
