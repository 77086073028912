import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TuiAlertModule } from '@taiga-ui/core';
import { DialogService } from '@lib-widgets/dialog';
import { AuthorizationInterceptor } from './authorization.interceptor';

@NgModule({
  imports: [TuiAlertModule],
  providers: [DialogService, { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true }],
})
export class AuthorizationInterceptorModule {}
