import { NgModule } from '@angular/core';
import {
  OptionsExactDirective,
  OptionsFromTermDirective,
  OptionsStaticDirective,
  ResetWhenNoOptionsDirective,
} from './directives';

@NgModule({
  imports: [OptionsExactDirective, OptionsStaticDirective, OptionsFromTermDirective, ResetWhenNoOptionsDirective],
  exports: [OptionsExactDirective, OptionsStaticDirective, OptionsFromTermDirective, ResetWhenNoOptionsDirective],
})
export class ReactiveSelectOptionsModule {}
