import { Optional, Provider } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroupName } from '@angular/forms';
import { TuiDay } from '@taiga-ui/cdk';
import startOfDay from 'date-fns/startOfDay';
import subYears from 'date-fns/subYears';
import { toTuiDate } from '../functions/tui-helpers';

export const PATCH_OPTIONS = { emitEvent: false, onlySelf: true };
export const DEBOUNCE_TIME_DEFAULT = 350;
export const MIN_VALID_DATE = startOfDay(new Date(1900, 0, 1));
export const MIN_VALID_TUI_DATE = new TuiDay(
  MIN_VALID_DATE.getFullYear(),
  MIN_VALID_DATE.getMonth(),
  MIN_VALID_DATE.getDate(),
);

/**
 * Минимальная дата рождения не раньше чем значение: `ТЕКУЩАЯ_ДАТА` - 100 лет
 */
export const MIN_VALID_BIRTHDAY_DATE = toTuiDate(subYears(new Date(), 100));
/**
 * Максимальная дата рождения не позднее чем значение: `ТЕКУЩАЯ_ДАТА` - 18 лет
 */
export const MAX_VALID_BIRTHDAY_DATE = toTuiDate(subYears(new Date(), 18));

// Граничные даты выдачи паспорта для заемщиков/созаемщиков ипотечного кредитования
export const MIN_VALID_PASSPORT_DATE = new TuiDay(1997, 9, 1);
export const MAX_VALID_PASSPORT_DATE = TuiDay.currentLocal();

export const MAX_AMOUNT_PER_MONTH = 2_000_000_000;

// Список паттернов
export const PATTERN = {
  URL: /^((http|https|ftp):\/\/)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i,
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  PASSPORT_SERIES: /\d{4}|[IVXLC]*-[А-Я]*/,
  PASSPORT_NUMBER: /\d{6}/,
  PASSPORT_ISSUE_DEP_CODE: /\d{3}-\d{3}/,
  OWNERSHIP_SHARE: /\d+\/\d+/,
};

// Карта сообщений для паттернов
export const PATTERN_MESSAGE_MAP = {
  [PATTERN.URL.toString()]: 'Некорректно указан вебсайт',
  [PATTERN.PASSPORT_SERIES.toString()]: 'Некорректно указана серия паспорта',
  [PATTERN.PASSPORT_NUMBER.toString()]: 'Некорректно указан номер паспорта',
  [PATTERN.PASSPORT_ISSUE_DEP_CODE.toString()]: 'Некорректно указан код подразделения',
  [PATTERN.OWNERSHIP_SHARE.toString()]: 'Некорректно указана доля',
};

export const INN_INVALID_ERR_MSG = 'Некорректный ИНН';
export const INN_10_LENGTH_ERR_MSG = 'ИНН должен содержать 10 цифр';
export const INN_12_LENGTH_ERR_MSG = 'ИНН должен содержать 12 цифр';
export const INN_10_OR_12_LENGTH_ERR_MSG = 'ИНН должен содержать 10 или 12 цифр';
export const INN_OWN_BUSINESS_ERR_MSG =
  'Если клиент является собственником бизнеса, ИНН должен принадлежать юридическому лицу';
export const INN_SOLE_PROPRIETOR_ERR_MSG = 'Если клиент является ИП, ИНН должен принадлежать физическому лицу';
export const INN_SELF_EMPLOYED_ERR_MSG = 'Если клиент является самозанятым, ИНН должен принадлежать физическому лицу';
export const ORGANIZATION_INN_LENGTH = 10;
export const PERSONAL_INN_LENGTH = 12;

export const FORWARD_CONTROL_CONTAINER: Provider = {
  provide: ControlContainer,
  useFactory: (fgn?: FormGroupName, fgd?: FormGroupDirective) => fgn ?? fgd,
  deps: [
    [new Optional(), FormGroupName],
    [new Optional(), FormGroupDirective],
  ],
};
