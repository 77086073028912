/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualReviewTaskDto } from './manualReviewTaskDto';
import { ManualReviewApplicationSourceDto } from './manualReviewApplicationSourceDto';
import { ApplicationStatusDto } from './applicationStatusDto';
import { DateTime } from '@lib-utils';
import { ManualReviewClientDto } from './manualReviewClientDto';


export interface ManualReviewApplicationDto { 
    id?: number;
    createdAt?: DateTime;
    updatedAt?: DateTime;
    state?: ApplicationStatusDto;
    source?: ManualReviewApplicationSourceDto;
    actualManualReviewTask?: ManualReviewTaskDto;
    isReturnedToWorkAgain?: boolean;
    clients?: Array<ManualReviewClientDto> | null;
}

