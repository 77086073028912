/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealVerificationTaskType } from './dealVerificationTaskType';
import { ApplicationFileCheckDto } from './applicationFileCheckDto';
import { UserInfoDto } from './userInfoDto';
import { DealVerificationDocumentStatus } from './dealVerificationDocumentStatus';
import { DateTime } from '@lib-utils';


export interface DealVerificationTaskFileCheckDto { 
    id?: number;
    dealVerificatorId?: number | null;
    applicationId?: number;
    type?: DealVerificationTaskType;
    documentStatus?: DealVerificationDocumentStatus;
    isActive?: boolean;
    created?: DateTime | null;
    updated?: DateTime | null;
    fileChecks?: Array<ApplicationFileCheckDto> | null;
    dealVerificator?: UserInfoDto;
    assigned?: DateTime | null;
}
export namespace DealVerificationTaskFileCheckDto {
}


