/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassportAddressVerificationTaskDto } from './passportAddressVerificationTaskDto';
import { VerificationDocumentFalsificationFlag } from './verificationDocumentFalsificationFlag';
import { VerificationDocumentResolution } from './verificationDocumentResolution';
import { VerificationDocumentStatus } from './verificationDocumentStatus';


export interface PassportAddressVerificationTaskDtoBaseUpdateVerificationTask { 
    comment?: string | null;
    documentStatus?: VerificationDocumentStatus;
    documentResolutions?: Array<VerificationDocumentResolution> | null;
    falsificationFlags?: Array<VerificationDocumentFalsificationFlag> | null;
    data?: PassportAddressVerificationTaskDto;
}
export namespace PassportAddressVerificationTaskDtoBaseUpdateVerificationTask {
}


