<tui-accordion-item
  [class.p-40]="size === 'm'"
  [ngClass]="accordionClass"
  [class.sticky-header]="stickyHeader"
  [id]="itemId"
  [showArrow]="isCollapsible"
  [disabled]="!isCollapsible"
  [disableHover]="!isCollapsible"
  [pseudoHover]="false"
  [borders]="borders"
  [attr.border-color]="borderColor()"
  [open]="expandable.openState()"
  (openChange)="expandable.setOpen($event)"
>
  <div class="d-flex align-items-center content-space-between">
    <div
      class="d-flex align-items-center"
      [ngClass]="size === 'm' ? 'font-size-24 gap-3' : 'gap-1'"
      [style.user-select]="'text'"
      [style.white-space]="'normal'"
    >
      <tui-svg
        *ngIf="!isReadOnly"
        [src]="(formValid ? validIcon : invalidIcon) ?? ''"
        [tuiHint]="invalidMessage"
        [ngClass]="formValid ? 'color-success' : 'color-red'"
      />
      <span class="color-black">{{ header }}</span>
      <span class="color-gray">{{ subheader }}</span>
    </div>
    <ng-content select="[fnipAccordionItemRightContent]" />
  </div>

  <ng-content *tuiAccordionItemContent />
</tui-accordion-item>
