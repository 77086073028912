import { Injectable } from '@angular/core';
import { Nullable } from '@lib-utils';
import { PermissionInfoDto } from '@lib-mortgage/api';
import { PermissionOperationMap, PermissionOperationMapKeys } from './permission.map';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permissionCodes = new Set<string>();

  setPermissions(permissions: Nullable<PermissionInfoDto[]>) {
    this.permissionCodes.clear();
    (permissions ?? []).forEach((permission) => this.permissionCodes.add(permission.operationCode));
  }

  /**
   * @deprecated использует hasPermissions
   */
  checkPermissionFor(operation: Nullable<PermissionOperationMapKeys>) {
    if (!operation) {
      return true;
    }

    const permissions = PermissionOperationMap[operation];
    return this.hasPermission(permissions) || this.hasPermission(PermissionOperationMap.AllModulesAvailable);
  }

  checkExactPermissionFor(operation: Nullable<PermissionOperationMapKeys>) {
    if (!operation) {
      return true;
    }

    const permissions = PermissionOperationMap[operation];
    return this.hasPermission(permissions);
  }

  private hasPermission(permissionCodes: string[]) {
    return permissionCodes.some((permissionCode) => this.permissionCodes.has(permissionCode));
  }
}
