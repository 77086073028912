import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  deactivate: (url: string) => Observable<boolean> | boolean;
}

export const deactivateGuardFn: CanDeactivateFn<CanComponentDeactivate> = (
  component,
  _currentRoute,
  _currentState,
  nextState,
) => (component.deactivate ? component.deactivate(nextState.url) : true);
