import { Directive, Input } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Nullable, SelectOption } from '@lib-utils';
import { OptionsProviderExact, OptionsProviderType } from '../options.model';
import { SELECT_OPTIONS } from '../options.token';

@Directive({
  standalone: true,
  selector: '[fnipOptionsExact]',
  providers: [
    {
      provide: SELECT_OPTIONS,
      multi: true,
      deps: [OptionsExactDirective],
      useFactory: (dir: OptionsExactDirective): OptionsProviderExact => ({
        type: OptionsProviderType.Exact,
        options: dir.options$,
      }),
    },
  ],
})
export class OptionsExactDirective {
  @Input({ required: true })
  set fnipOptionsExact(value: Nullable<SelectOption[] | Observable<SelectOption[]>>) {
    if (!value) {
      this.options$.next(of([]));
    } else {
      this.options$.next(value instanceof Observable ? value : of(value));
    }
  }

  options$ = new BehaviorSubject<Observable<SelectOption[]>>(of([]));
}
