import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { Nullable } from '@lib-utils';
import { ButtonComponent } from '@lib-widgets/core';
import { LoaderAppearance } from '../loader/loader.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-request-wrapper',
  templateUrl: './request-wrapper.component.html',
})
export class RequestWrapperComponent<T = unknown> {
  readonly cdr = inject(ChangeDetectorRef);

  @Input() appearance: LoaderAppearance = LoaderAppearance.Normal;
  @Input() customLoadingText: Nullable<string>;
  @Input() backButtonCallback$: ButtonComponent['actionCallback$'];

  @Input() set request$(value$: Nullable<Observable<T>>) {
    this._request$ = this.getUpdatedRequest$(value$);
  }

  get request$() {
    return this._request$;
  }

  @Output() response = new EventEmitter<Nullable<T>>();

  data: Nullable<T>;
  hasFirstRs: Nullable<boolean>;
  isLoading: Nullable<boolean>;
  error: Nullable<HttpErrorResponse>;

  private _request$: Nullable<Observable<T>>;

  getUpdatedRequest$(request$: Nullable<Observable<T>>): Nullable<Observable<T>> {
    this.error = null;
    if (request$) {
      this.isLoading = true;
      return request$.pipe(
        delay(0), // для корректной отработки синхронно эмитящих observable
        tap((data) => this.onDataTap(data)),
        catchError((err: HttpErrorResponse) => this.onCatchError(err)),
      );
    }
    return null;
  }

  public reload() {
    this._request$ = this.getUpdatedRequest$(this._request$);
    this.cdr.detectChanges();
  }

  private onDataTap(data: Nullable<T>) {
    this.data = data;
    this.response.emit(this.data);
    this.hasFirstRs = true;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private onCatchError(error: HttpErrorResponse) {
    this.isLoading = false;
    this.error = error;
    this.cdr.markForCheck();
    return throwError(() => error);
  }
}
