import { MaskitoOptions } from '@maskito/core';
import { ElementState } from '@maskito/core/lib/types/element-state';
import fill from 'lodash-es/fill';

export const getArrOfRegExpDigits = (numberOfDigits: number): RegExp[] => fill(Array(numberOfDigits), /\d/);

const romanLettersRegex = /[MDCLXVI]/;
const romanLettersRegexStrict = /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/;

export const PASSPORT_SERIES_MASK: MaskitoOptions = {
  mask: ({ value }) => {
    const dashIndex = value.indexOf('-');
    if (dashIndex > 0) {
      const subtractIndex = value.length - value.indexOf('-');
      const current = [...Array(value.length - subtractIndex).keys()].map(() => romanLettersRegex) as (
        | string
        | RegExp
      )[];
      current.push(...['-', /[А-Я]/, /[А-Я]/]);
      return current;
    }

    if (/^[A-zА-я]*$/.test(value)) return [...Array(value.length + 1).keys()].map(() => romanLettersRegexStrict);

    return getArrOfRegExpDigits(4);
  },
  postprocessors: [
    ({ selection, value }) => {
      if (value.indexOf('-') === -1 && romanLettersRegex.test(value) && !romanLettersRegexStrict.test(value)) {
        return { selection, value: value.slice(0, -1) };
      }

      return { selection, value };
    },
  ],
};

export const PASSPORT_NUMBER_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(6),
};

export const PASSPORT_MASK: MaskitoOptions = {
  mask: [...getArrOfRegExpDigits(4), ' ', ...getArrOfRegExpDigits(6)],
};

export const PASSPORT_ISSUE_DEP_CODE_MASK: MaskitoOptions = {
  mask: [...getArrOfRegExpDigits(3), '-', ...getArrOfRegExpDigits(3)],
};

export const SNILS_MASK: MaskitoOptions = {
  mask: [
    ...getArrOfRegExpDigits(3),
    '-',
    ...getArrOfRegExpDigits(3),
    '-',
    ...getArrOfRegExpDigits(3),
    '-',
    ...getArrOfRegExpDigits(2),
  ],
};

export const INN_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(12),
};

export const INN_ORGANIZATION_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(10),
};

export const INCOME_CODE_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(4),
};

export const YEAR_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(4),
};

export const DRIVER_LICENSE_MASKS = {
  series: {
    mask: getArrOfRegExpDigits(4),
  } as MaskitoOptions,
  number: {
    mask: getArrOfRegExpDigits(6),
  } as MaskitoOptions,
};

export const FOREIGN_PASSPORT_MASKS = {
  series: {
    mask: getArrOfRegExpDigits(2),
  } as MaskitoOptions,
  number: {
    mask: getArrOfRegExpDigits(7),
  } as MaskitoOptions,
};

export const MILITARY_ID_MASKS = {
  series: {
    mask: [/[а-яА-Я]/, /[а-яА-Я]/],
  } as MaskitoOptions,
  number: {
    mask: getArrOfRegExpDigits(7),
  } as MaskitoOptions,
};

export const ACCOUNT_NUMBER: MaskitoOptions = {
  mask: [
    ...getArrOfRegExpDigits(5),
    '-',
    ...getArrOfRegExpDigits(3),
    '-',
    ...getArrOfRegExpDigits(1),
    '-',
    ...getArrOfRegExpDigits(4),
    '-',
    ...getArrOfRegExpDigits(7),
  ],
};

export const EMPLOYMENT_HISTORY_SERIAL: MaskitoOptions = {
  mask: [/[A-Z]/, /[A-Z]/, '-', /[A-Z]/, /[A-Z]/, /[A-Z]/, ', '],
};

export const EMPLOYMENT_HISTORY_NUMBER: MaskitoOptions = {
  mask: getArrOfRegExpDigits(7),
};

export const EMPLOYMENT_HISTORY_SERIAL_AND_NUMBER: MaskitoOptions = {
  mask: ({ value }: ElementState) => {
    const letterPartRegExp = /^[a-zA-Zа-яА-Я]$/;
    const letterPartLength = value.replace(/[^a-zA-Zа-яА-Я]+/g, '').length;
    const hasNumberPart = !!value.replace(/[^0-9.]/g, '');
    const mask: Array<string | RegExp> = [letterPartRegExp, letterPartRegExp];

    if (letterPartLength > 2) {
      mask.push(
        '-',
        ...Array.from({ length: (letterPartLength <= 6 ? letterPartLength : 6) - 2 }, () => /^[mdclxviMDCLXVI]$/),
      );
    }
    if (hasNumberPart) {
      mask.push(' ', ...Array.from({ length: 7 }).map(() => /\d/));
    }

    return mask;
  },
};

export const ACCOUNT_NUMBER_MASK: MaskitoOptions = {
  mask: [
    /4/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const BANK_BIK_MASK: MaskitoOptions = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] };

export const SYMBOLS_AND_DIGITS_MASK: MaskitoOptions = { mask: /^[a-zA-Zа-яА-Я0-9]+$/ };
