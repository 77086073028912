<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-input-phone
    #input
    [formControl]="$any(control)"
    [readOnly]="isReadOnly"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    [tuiTextfieldCleaner]="textfieldCleaner"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    <ng-container *ngIf="setPhoneNumberValue | executeWith: control.value" />
    <ng-container
      *ngIf="setPhoneNumberValidator | executeWith: control : input.countryCode : input.phoneMaskAfterCountryCode"
    />
    <tui-svg *ngIf="placeholderIcon" class="icon-color" [src]="placeholderIcon" />
    {{ placeholder === null ? '' : placeholder ?? '+0 (000) 000-00-00' }}
    <input tuiTextfield autocomplete="off" [id]="fieldId" [style.pointer-events]="control.disabled ? 'all' : 'auto'" />
  </tui-input-phone>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
