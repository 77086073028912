/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditProgramType = 'Primary' | 'Secondary' | 'FromState' | 'ForFamily' | 'ForIt' | 'WithInitialContribution' | 'Perspective' | 'ForPIK';

export const CreditProgramType = {
    Primary: 'Primary' as CreditProgramType,
    Secondary: 'Secondary' as CreditProgramType,
    FromState: 'FromState' as CreditProgramType,
    ForFamily: 'ForFamily' as CreditProgramType,
    ForIt: 'ForIt' as CreditProgramType,
    WithInitialContribution: 'WithInitialContribution' as CreditProgramType,
    Perspective: 'Perspective' as CreditProgramType,
    ForPik: 'ForPIK' as CreditProgramType
};

