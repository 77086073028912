/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnerDto } from './ownerDto';
import { BankAccountType } from './bankAccountType';
import { AddressDto } from './addressDto';


export interface OwnerRequisitesDto { 
    id?: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    type: BankAccountType;
    accountNumber?: string | null;
    bankName: string;
    bankBik: string;
    bankAddress?: AddressDto;
    bankCorAccount: string;
    taxNumber: string;
    bankKpp: string;
    letterOfCreditAmount?: number | null;
    ownerId?: number | null;
    owner?: OwnerDto;
}
export namespace OwnerRequisitesDto {
}


