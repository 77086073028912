import { DialogRef } from '@angular/cdk/dialog';
import { Observable, of, tap } from 'rxjs';
import { DialogHostComponent } from './dialog-host/dialog-host.component';

export class FnipDialogRef<TResultData> {
  private _cdkRef?: DialogRef<TResultData, DialogHostComponent>;

  set cdkRef(value: DialogRef<TResultData, DialogHostComponent> | undefined) {
    this._cdkRef = value;
  }

  // Observable для получения результата после закрытия диалога, при отписке от него диалог закрывается (аналогично taiga-ui)
  get afterClosed$() {
    return new Observable<TResultData | undefined>((observer) => {
      let closed = false;

      // subscribe to close event and return result
      const subscription = this._cdkRef?.closed.subscribe((result) => {
        closed = true;
        observer.next(result);
        observer.complete();
      });

      // close if unsubscribe => close dialog and unsubscribe
      return () => {
        // close dialog if not closed yet
        if (!closed) this.close();
        subscription?.unsubscribe();
      };
    });
  }

  // Закрытие диалога с задержкой для анимации
  close(data?: TResultData) {
    (this._cdkRef?.componentInstance?.runCloseAnimation$() ?? of(0))
      .pipe(tap(() => this._cdkRef?.close(data)))
      .subscribe();
  }
}
