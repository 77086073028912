import { Route } from '@angular/router';
import { Breadcrumb } from '../../interfaces/breadcrumb';
import { Nullable } from '../../interfaces/types';

export interface ExtendedRoute<TExtra extends object> extends Route {
  data?: TExtra;

  children?: ExtendedRoute<TExtra>[];
}

export interface RouteBreadcrumbData {
  breadcrumb?: Nullable<Breadcrumb>;
}
