/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ForeignPassportPage { 
    issuer?: string | null;
    number?: string | null;
    fullName?: string | null;
    firstName?: string | null;
    secondName?: string | null;
    lastName?: string | null;
    birthDate?: string | null;
    issueDate?: string | null;
    expirationDate?: string | null;
}

