import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FnipNotification,
  FnipNotificationPaginationParams,
  FnipPaginatedNotificationList,
  NotificationSource,
} from '@lib-widgets/notification-list';
import {
  NotificationApiService,
  NotificationDto,
  ReadNotificationDto,
  UserNotificationDtoPageResultData,
} from '@lib-mortgage/api';

@Injectable()
export class MortgageNotificationSource extends NotificationSource<NotificationDto, number> {
  private notificationApiService = inject(NotificationApiService);

  getNotificationListBefore(
    date: Date,
    params: FnipNotificationPaginationParams,
  ): Observable<FnipPaginatedNotificationList<number, NotificationDto>> {
    const query = {
      filters: {
        IsReaded: 'false',
        ReceiveOnSite: 'true',
        CreatedBefore: date.toISOString().replace(/\..+/, ''),
      },
    };

    return this.notificationApiService
      ?.apiNotificationUserNotificationsGet(params.page, params.perPage, query, 'DESC', 'Created')
      .pipe(map((result) => this.mapMortgageResult(result.data)));
  }

  getNotificationsAfter(date: Date): Observable<FnipNotification<number, NotificationDto>[]> {
    const query = {
      filters: {
        IsReaded: 'false',
        ReceiveOnSite: 'true',
        CreatedAfter: date.toISOString().replace(/\..+/, ''),
      },
    };

    return this.notificationApiService?.apiNotificationUserNotificationsGet(1, 500, query, 'DESC', 'Created').pipe(
      map((result) => this.mapMortgageResult(result.data)),
      map((data) => data.data),
    );
  }

  markNotificationAsRead(notification: FnipNotification<number, NotificationDto>): Observable<unknown> {
    return this.notificationApiService?.apiNotificationUserMarkAsReadPost([notification.id!]);
  }

  private mapMortgageResult(
    mortgageResult?: UserNotificationDtoPageResultData,
  ): FnipPaginatedNotificationList<number, NotificationDto> {
    return {
      page: mortgageResult?.page?.page ?? 1,
      perPage: mortgageResult?.page?.perPage ?? 10,
      count: mortgageResult?.page?.items ?? 0,
      data:
        mortgageResult?.data?.map(
          (notification: ReadNotificationDto): FnipNotification<number, NotificationDto> => ({
            id: notification.id!,
            createdAt: new Date(notification.created!),
            isRead: notification.isReaded ?? false,
            shortText: notification.eventDescription ?? '',
            fullText: notification.notificationText ?? '',
            data: notification,
          }),
        ) ?? [],
    };
  }
}
