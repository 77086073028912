/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DigitalEmploymentHistoryPage } from './digitalEmploymentHistoryPage';
import { Problem } from './problem';


export interface DigitalEmploymentHistory { 
    errors?: Array<Problem> | null;
    present?: boolean | null;
    pages?: Array<DigitalEmploymentHistoryPage> | null;
}

