/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EncumbranceDto } from './encumbranceDto';
import { MaterialType } from './materialType';
import { BuildingAddressDto } from './buildingAddressDto';
import { LandCategory } from './landCategory';
import { PartnerDto } from './partnerDto';
import { RealEstateOwnerDto } from './realEstateOwnerDto';
import { DateOnly } from '@lib-utils';
import { RealEstateType } from './realEstateType';
import { AddressDto } from './addressDto';


export interface RealEstateDto { 
    id?: number | null;
    dealInfoId?: number | null;
    address?: AddressDto;
    buildingAddress?: BuildingAddressDto;
    partner?: PartnerDto;
    area?: number | null;
    numberEstate?: string | null;
    rooms?: string | null;
    floor?: number | null;
    materialType?: MaterialType;
    totalFloor?: number | null;
    dateTransfer?: DateOnly | null;
    dateMortgage?: DateOnly | null;
    isEncumbrance?: boolean | null;
    encumbrance?: EncumbranceDto;
    cadastral?: string | null;
    year?: number | null;
    dduPrice?: number | null;
    concessionCount?: number | null;
    landCategory?: LandCategory;
    permittedUse?: string | null;
    type?: RealEstateType;
    estimatedValue?: number | null;
    price?: number | null;
    owners?: Array<RealEstateOwnerDto> | null;
}
export namespace RealEstateDto {
}


