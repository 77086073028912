import { inject, Pipe, PipeTransform } from '@angular/core';
import { Nullable, RoleType } from '@lib-utils';
import { AuthorizationStorageService } from '../../services';

/**
 * Проверка на наличие роли
 *
 * Usage: *ngIf="[RoleType.SuperAdmin, RoleType.BankManager] | hasRoles"
 */
@Pipe({
  name: 'hasRoles',
  standalone: true,
})
export class HasRolesPipe implements PipeTransform {
  private authorizationStorageService = inject(AuthorizationStorageService);

  transform(userRoles: Nullable<RoleType[]>) {
    return this.authorizationStorageService.hasRoles(userRoles);
  }
}
