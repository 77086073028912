export interface Pagination {
  page: number;
  perPage: number;
}

export type DeepPartial<T> = T extends object
  ? T extends Array<infer U>
    ? Array<U>
    : {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
  : T;

export interface FilterState<TFilters> {
  filters: DeepPartial<TFilters>;
  pagination: Pagination;
  order?: FilterStateOrder;
}

export interface FilterStateOrder {
  orderBy: 'ASC' | 'DESC';
  fieldBy: string;
}
