/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientSocialInfoDto } from './clientSocialInfoDto';
import { ClientSprInfoDto } from './clientSprInfoDto';
import { ReasonForResidence } from './reasonForResidence';
import { Citizenship } from './citizenship';
import { EmploymentStatus } from './employmentStatus';
import { InsurancePackage } from './insurancePackage';
import { ClientFileDto } from './clientFileDto';
import { PhysicalPersonDto } from './physicalPersonDto';
import { ClientPreviousNameDto } from './clientPreviousNameDto';
import { EmploymentDto } from './employmentDto';
import { PersonalSubsidiaryPlotDto } from './personalSubsidiaryPlotDto';


export interface ClientDto { 
    id?: number;
    socialInfo?: ClientSocialInfoDto;
    physicalPerson?: PhysicalPersonDto;
    employmentStatus?: EmploymentStatus;
    citizenship?: Citizenship;
    homePhone?: string | null;
    isPhoneConfirmed?: boolean;
    hasNoRegistration?: boolean | null;
    communalApartment?: boolean | null;
    reasonForResidence?: ReasonForResidence;
    personalSubsidiaryPlotId?: number | null;
    personalSubsidiaryPlot?: PersonalSubsidiaryPlotDto;
    sprInfo?: ClientSprInfoDto;
    isPublicOfficial?: boolean | null;
    isBankrupt?: boolean | null;
    insuranceType?: InsurancePackage;
    smartDealSignatureRequestId?: number | null;
    employments?: Array<EmploymentDto> | null;
    files?: Array<ClientFileDto> | null;
    previousName?: ClientPreviousNameDto;
}
export namespace ClientDto {
}


