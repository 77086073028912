/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IndustryType = 'Lawyer' | 'SocialArea' | 'Transportation' | 'Agriculture' | 'Military' | 'Industry' | 'Fuel' | 'Building' | 'Government' | 'Consulting' | 'Medicine' | 'Education' | 'Science' | 'Tourism' | 'Notary' | 'Trading' | 'It' | 'Finance' | 'Security' | 'Service';

export const IndustryType = {
    Lawyer: 'Lawyer' as IndustryType,
    SocialArea: 'SocialArea' as IndustryType,
    Transportation: 'Transportation' as IndustryType,
    Agriculture: 'Agriculture' as IndustryType,
    Military: 'Military' as IndustryType,
    Industry: 'Industry' as IndustryType,
    Fuel: 'Fuel' as IndustryType,
    Building: 'Building' as IndustryType,
    Government: 'Government' as IndustryType,
    Consulting: 'Consulting' as IndustryType,
    Medicine: 'Medicine' as IndustryType,
    Education: 'Education' as IndustryType,
    Science: 'Science' as IndustryType,
    Tourism: 'Tourism' as IndustryType,
    Notary: 'Notary' as IndustryType,
    Trading: 'Trading' as IndustryType,
    It: 'It' as IndustryType,
    Finance: 'Finance' as IndustryType,
    Security: 'Security' as IndustryType,
    Service: 'Service' as IndustryType
};

