/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TransferType = 'LetterOfCredit' | 'ToTheSellerAccount' | 'IndividualBankSafe' | 'SecurityAccount' | 'NominalAccount' | 'Undefined';

export const TransferType = {
    LetterOfCredit: 'LetterOfCredit' as TransferType,
    ToTheSellerAccount: 'ToTheSellerAccount' as TransferType,
    IndividualBankSafe: 'IndividualBankSafe' as TransferType,
    SecurityAccount: 'SecurityAccount' as TransferType,
    NominalAccount: 'NominalAccount' as TransferType,
    Undefined: 'Undefined' as TransferType
};

