// TODO: minor. maybe need to get current values from the server
export enum CreditProductCode {
  PrimaryFlatApartments = 'PrimaryFlat/Apartments',
  FromState = 'FromState',
  ITSpecialist = 'ITSpecialist',
  ForFamily = 'ForFamily',
  PIKPreferential = 'PIKPreferential',
  PIKFamily = 'PIKFamily',
  PIKIT = 'PIKIT',
  Commercial = 'Commercial',
  ParkingPlace = 'ParkingPlace',
}
