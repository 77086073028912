import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputYearModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputYearComponent } from './reactive-input-year.component';

const TUI_MODULES = [TuiInputYearModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  exports: [ReactiveInputYearComponent],
  declarations: [ReactiveInputYearComponent],
})
export class ReactiveInputYearModule {}
