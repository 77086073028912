/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManualReviewDecisionType = 'Approved' | 'Rejection' | 'Postpone' | 'ForUnderwriterRevision' | 'RedirectToAnotherAuthorizer' | 'ForManagerRevision';

export const ManualReviewDecisionType = {
    Approved: 'Approved' as ManualReviewDecisionType,
    Rejection: 'Rejection' as ManualReviewDecisionType,
    Postpone: 'Postpone' as ManualReviewDecisionType,
    ForUnderwriterRevision: 'ForUnderwriterRevision' as ManualReviewDecisionType,
    RedirectToAnotherAuthorizer: 'RedirectToAnotherAuthorizer' as ManualReviewDecisionType,
    ForManagerRevision: 'ForManagerRevision' as ManualReviewDecisionType
};

