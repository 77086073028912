/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DaDataCheckType = 'Successful' | 'ExtraOrLackData' | 'EmptyOrJunk' | 'Ambiguous';

export const DaDataCheckType = {
    Successful: 'Successful' as DaDataCheckType,
    ExtraOrLackData: 'ExtraOrLackData' as DaDataCheckType,
    EmptyOrJunk: 'EmptyOrJunk' as DaDataCheckType,
    Ambiguous: 'Ambiguous' as DaDataCheckType
};

