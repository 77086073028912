/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationRelationType = 'CreatorRelation' | 'PartnerManagerRelation' | 'PartnerSignerRelation' | 'BankManagerRelation' | 'BankSignerRelation' | 'ClientRelation' | 'CoborrowerRelation' | 'ManagerVerificatorRelation' | 'UnderwriterRelation' | 'AuthorizerRelation' | 'PersonalManagerRelation';

export const ApplicationRelationType = {
    CreatorRelation: 'CreatorRelation' as ApplicationRelationType,
    PartnerManagerRelation: 'PartnerManagerRelation' as ApplicationRelationType,
    PartnerSignerRelation: 'PartnerSignerRelation' as ApplicationRelationType,
    BankManagerRelation: 'BankManagerRelation' as ApplicationRelationType,
    BankSignerRelation: 'BankSignerRelation' as ApplicationRelationType,
    ClientRelation: 'ClientRelation' as ApplicationRelationType,
    CoborrowerRelation: 'CoborrowerRelation' as ApplicationRelationType,
    ManagerVerificatorRelation: 'ManagerVerificatorRelation' as ApplicationRelationType,
    UnderwriterRelation: 'UnderwriterRelation' as ApplicationRelationType,
    AuthorizerRelation: 'AuthorizerRelation' as ApplicationRelationType,
    PersonalManagerRelation: 'PersonalManagerRelation' as ApplicationRelationType
};

