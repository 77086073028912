import {
  AfterViewInit,
  computed,
  DestroyRef,
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  Output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, fromEvent } from 'rxjs';
import { ResizeObservable } from '../../classes';

@Directive({
  standalone: true,
  exportAs: 'scrollObserver',
  selector: '[fnipScrollObserver]',
})
export class ScrollObserverDirective implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  #hostElement = inject<ElementRef<HTMLElement>>(ElementRef);

  @Input({ transform: numberAttribute }) scrollThreshold = 0;
  @Input({ transform: numberAttribute }) scrollDebounceTime = 0;

  @Output() scrolledToTop = new EventEmitter<boolean>(true);
  @Output() scrolledToBottom = new EventEmitter<boolean>(true);
  @Output() scrolled = new EventEmitter<number>(true);

  private readonly isScrolled = signal(false);
  private readonly scrollHeight = signal(0);
  private readonly scrollTop = signal(0);
  private readonly elementHeight = signal(0);

  readonly hasScroll = computed(() => this.scrollHeight() > this.elementHeight());
  readonly isScrolledToTop = computed(() => this.scrollTop() <= this.scrollThreshold);
  readonly isScrolledToBottom = computed(
    () => this.scrollTop() + this.elementHeight() + this.scrollThreshold >= this.scrollHeight(),
  );

  constructor() {
    effect(() => {
      const isScrolledToTop = this.isScrolledToTop();
      if (!untracked(this.hasScroll)) return;
      if (!untracked(this.isScrolled)) return;
      this.scrolledToTop.emit(isScrolledToTop);
    });

    effect(() => {
      const isScrolledToBottom = this.isScrolledToBottom();
      if (!untracked(this.hasScroll)) return;
      if (!untracked(this.isScrolled)) return;
      this.scrolledToBottom.emit(isScrolledToBottom);
    });

    effect(() => {
      const scrollTop = this.scrollTop();
      if (!untracked(this.hasScroll)) return;
      if (!untracked(this.isScrolled)) return;
      this.scrolled.emit(scrollTop);
    });
  }

  ngAfterViewInit() {
    const { nativeElement } = this.#hostElement;
    if (!nativeElement) return;

    const updateScrollSignals = () => {
      this.scrollTop.set(nativeElement.scrollTop);
      this.scrollHeight.set(nativeElement.scrollHeight);
      this.elementHeight.set(nativeElement.offsetHeight);
    };

    new ResizeObservable(nativeElement).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(updateScrollSignals);

    fromEvent(nativeElement, 'scroll')
      .pipe(debounceTime(this.scrollDebounceTime), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        const scrollTop = nativeElement.scrollTop;
        this.isScrolled.set(true);
        this.scrollTop.set(scrollTop);
      });

    updateScrollSignals();
  }
}
