import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';
import { toTuiDate } from '../../functions';

export class ToDateTransformer implements AbstractTuiValueTransformer<TuiDay | undefined, Date | undefined> {
  fromControlValue(controlValue: Date | undefined): TuiDay | undefined {
    return controlValue && toTuiDate(controlValue);
  }

  toControlValue(componentValue: TuiDay | undefined): Date | undefined {
    return componentValue?.toLocalNativeDate();
  }
}
