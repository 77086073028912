import { SelectOption } from '../../interfaces/select-option';
import { Nullable } from '../../interfaces/types';

export function createOptionsForLocalizedEnum<T extends number | string>(
  localizeMap: Map<T, number | string> | Partial<Record<T, Nullable<number | string>>>,
): SelectOption<{ value: T }, T>[] {
  const keyValuePairs =
    localizeMap instanceof Map ? Array.from(localizeMap) : Object.entries<Nullable<number | string>>(localizeMap);

  return keyValuePairs.map(([key, value]) => ({
    value: key as T,
    label: value?.toString() || '',
  }));
}
