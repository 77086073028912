@if (control) {
  <fnip-reactive-label
    [hasRequiredValidator]="hasRequiredValidator"
    [label]="label"
    [size]="labelSize"
    [hint]="hint"
    [noBottomHint]="noBottomHint"
    [isBold]="isLabelBold"
  >
    <div
      *tuiLet="direction === 'vertical' as isVertical"
      class="radio-labeled-container"
      [class.flex-column]="isVertical"
      [class.gap-4]="isVertical"
      [class.align-items-start]="isVertical"
      [ngClass]="containerClass"
    >
      @for (o of options; track o.value) {
        @if (!o.hide) {
          <tui-radio-labeled
            fnipTestAttributes
            [testId]="fieldId + '-' + o.value"
            [testLabel]="o.label"
            [nativeId]="fieldId"
            [pseudoDisabled]="!!o.disabled"
            [size]="btnSize"
            [style.width]="radioWidth"
            [formControl]="$any(control)"
            [item]="o.value"
            (click)="o.value === control.value && canClear && control.enabled ? control.reset() : true"
            (tuiValueChanges)="valueChange.emit($event)"
          >
            {{ o.label }}
          </tui-radio-labeled>
        }
      }
    </div>
    @if (!noValidationMark) {
      <fnip-reactive-field-error
        [control]="$any(control)"
        [errorLabel]="errorLabel"
        (isErrorShow)="noBottomHint = $event"
      />
    }
  </fnip-reactive-label>
}
