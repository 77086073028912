/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EmploymentContractPage { 
    fullName?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    secondName?: string | null;
    stamp?: string | null;
    issueDate?: string | null;
    copyIsStitched?: string | null;
    stitchingCertified?: any | null;
    affirmationOnAllPages?: string | null;
    taskId?: string | null;
    employerINN?: string | null;
    fileIds?: Array<string> | null;
}

