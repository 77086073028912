/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BlacklistCheckStatus = 'Refusal' | 'Authorizer2' | 'RemovedFromBlacklist';

export const BlacklistCheckStatus = {
    Refusal: 'Refusal' as BlacklistCheckStatus,
    Authorizer2: 'Authorizer2' as BlacklistCheckStatus,
    RemovedFromBlacklist: 'RemovedFromBlacklist' as BlacklistCheckStatus
};

