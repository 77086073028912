<fnip-button
  appearance="secondary-light-grey"
  btnSize="s"
  [label]="getLabel | executeWith: label : openedLabel : state"
  [icon]="state ? 'tuiIconChevronUp' : 'tuiIconChevronDown'"
  [actionCallback$]="toggleCb"
/>

<tui-expand [expanded]="state">
  <ng-content />
</tui-expand>
