import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UnNullable } from '../../../functions';
import { Nullable } from '../../../interfaces/types';
import { FilterStateService } from '../filter-state.abstract';
import { DeepPartial, FilterState, FilterStateOrder, Pagination } from '../filter-state.model';

// Получаемый объект из query params сохраняет вложенность и массивы, но все значения приводит к строке
export type Queried<T extends object> = {
  [key in keyof T]?: UnNullable<T[key]> extends object ? Queried<UnNullable<T[key]>> : string;
};

@Injectable()
export class QueryFilterStateService extends FilterStateService {
  protected router = inject(Router);

  /**
   * Очень важно передавать null в те поля, которые нужно удалить из query params
   */
  updateState<TFilters>(
    filters: Nullable<DeepPartial<TFilters>>,
    pagination: Nullable<Pagination>,
    order: Nullable<FilterStateOrder>,
  ) {
    void this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...filters,
        ...pagination,
        ...order,
      },
      preserveFragment: true,
    });
  }

  override resetState() {
    return void 0;
  }

  getState<TFilters>(defaultPagination: Pagination = { page: 1, perPage: 10 }): FilterState<TFilters> {
    const { queryParams } = this.router.routerState.snapshot.root.queryParams;
    const { page, perPage, orderBy, fieldBy, ...rest } = queryParams || {};

    const pagination = {
      page: page ? +page : defaultPagination.page,
      perPage: perPage ? +perPage : defaultPagination.perPage,
    };

    return {
      filters: rest as DeepPartial<TFilters>,
      pagination,
      order: fieldBy && orderBy ? { orderBy, fieldBy } : undefined,
    };
  }
}
