import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-no-page',
  templateUrl: './no-page.component.html',
  styleUrls: [`./no-page.component.scss`],
  standalone: true,
  imports: [RouterModule, TuiButtonModule],
})
export class NoPageComponent {
  onBack = () => window.history.back();
}
