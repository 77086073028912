import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputCountModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputCountComponent } from './reactive-input-count.component';

const TUI_MODULES = [TuiInputCountModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  exports: [ReactiveInputCountComponent],
  declarations: [ReactiveInputCountComponent],
})
export class ReactiveInputCountModule {}
