/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RecipientType = 'All' | 'User' | 'Group';

export const RecipientType = {
    All: 'All' as RecipientType,
    User: 'User' as RecipientType,
    Group: 'Group' as RecipientType
};

