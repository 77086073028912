/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { AddressDto } from './addressDto';


export interface PhysicalPersonInfoDto { 
    id?: number | null;
    birthplace?: string | null;
    registrationAddressId?: number | null;
    registrationAddress?: AddressDto;
    factualAddress?: AddressDto;
    residence?: string | null;
    phone?: string | null;
    email?: string | null;
    maritalStatus?: MaritalStatus;
}
export namespace PhysicalPersonInfoDto {
}


