import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TuiNotification } from '@taiga-ui/core';
import { jwtDecode } from 'jwt-decode';
import some from 'lodash-es/some';
import { catchError, EMPTY, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { NotificationService, Nullable } from '@lib-utils';
import { ConfirmationDialogData, ConfirmationDialogType, DialogService } from '@lib-widgets/dialog';
import { getLogout$ } from '../../utils/logout';
import { TOKEN_STORE } from '../../utils/token-store.provider';

const NOTIFICATION_LABEL = 'Ошибка авторизации';

const PASS_REQUEST_WITHOUT_TOKEN = [
  'user/email',
  'user/login',
  'user/pass',
  'user/bytoken',
  'api/auth/login',
  'api/auth/currentuser',
  'api/auth/logout',
  'assets/i18n',
];

const enum EAuthorizationError {
  AuthorizationRequired = 'AUTHORIZATION_REQUIRED',
  SessionInvalid = 'SESSION_INVALID',
  Forbidden = 'FORBIDDEN',
}

const AUTHORIZATION_ERRORS: Record<EAuthorizationError, string> = {
  [EAuthorizationError.AuthorizationRequired]: 'Необходимо авторизоваться',
  [EAuthorizationError.SessionInvalid]: 'Срок действия текущей сессии истек, необходимо авторизоваться',
  [EAuthorizationError.Forbidden]: 'У вас недостаточно прав для данной операции',
};

const AUTHORIZATION_ERRORS_MODALS: Record<EAuthorizationError, ConfirmationDialogData<void, boolean>> = {
  [EAuthorizationError.AuthorizationRequired]: {
    text: AUTHORIZATION_ERRORS['SESSION_INVALID'],
    type: ConfirmationDialogType.Question,
    okText: 'Войти в систему',
    cancelText: 'Закрыть',
  },
  [EAuthorizationError.SessionInvalid]: {
    type: ConfirmationDialogType.Question,
    text: AUTHORIZATION_ERRORS['SESSION_INVALID'],
    okText: 'Войти заново',
    cancelText: 'Закрыть',
  },
  [EAuthorizationError.Forbidden]: {
    type: ConfirmationDialogType.Question,
    text: AUTHORIZATION_ERRORS['FORBIDDEN'],
    okText: 'Сменить пользователя',
    cancelText: 'Закрыть',
  },
};

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private token$ = inject(TOKEN_STORE);
  private notificationService = inject(NotificationService);
  private dialogService = inject(DialogService);

  private authorizationErrorDialogSubscription: Subscription | null = null;
  private logout$ = getLogout$();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.token$.value;

    if (some(PASS_REQUEST_WITHOUT_TOKEN, (segment) => request.url.toLowerCase().includes(segment)))
      return next.handle(request);

    // Токена нет совсем
    if (!token) {
      this.notificationService.show(
        AUTHORIZATION_ERRORS[EAuthorizationError.AuthorizationRequired],
        TuiNotification.Warning,
        true,
        NOTIFICATION_LABEL,
      );

      return this.logout$().pipe(switchMap(() => EMPTY));
    }

    // Токен просрочен
    if (this.isTokenExpired(token)) {
      this.openErrorModal(EAuthorizationError.SessionInvalid);
      return throwError(
        () => new HttpErrorResponse({ error: { message: AUTHORIZATION_ERRORS[EAuthorizationError.SessionInvalid] } }),
      );
    }

    return next.handle(request).pipe(
      catchError((err) => {
        // Ошибка 401/403 с валидным токеном
        if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
          const errorType = err.status === 401 ? EAuthorizationError.SessionInvalid : EAuthorizationError.Forbidden;

          this.openErrorModal(errorType);
          throw new HttpErrorResponse({
            status: err.status,
            statusText: err.statusText,
            error: { message: AUTHORIZATION_ERRORS[errorType] },
          });
        }

        throw err;
      }),
    );
  }

  private openErrorModal(error: EAuthorizationError) {
    // Контролируем, чтобы диалог не открывался несколько раз
    if (this.authorizationErrorDialogSubscription) return;

    this.authorizationErrorDialogSubscription = this.dialogService
      .openConfirmation<boolean>({
        contextData: { ...AUTHORIZATION_ERRORS_MODALS[error], title: NOTIFICATION_LABEL },
      })
      .pipe(
        switchMap((isConfirmed) => {
          if (isConfirmed) return this.logout$(true);
          return of(null);
        }),
      )
      .subscribe(() => {
        this.authorizationErrorDialogSubscription = null;
      });
  }

  private isTokenExpired(token: Nullable<string>) {
    if (!token) return true;
    try {
      const { exp } = jwtDecode(token);
      return !!exp && exp * 1000 < Date.now();
    } catch (e) {
      return true;
    }
  }
}
