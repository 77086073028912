/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectType } from './objectType';
import { CreditPeriodOptionDto } from './creditPeriodOptionDto';
import { InsuranceForm } from './insuranceForm';
import { ObjectTypeAndCreditPurposesCombinationOptionDto } from './objectTypeAndCreditPurposesCombinationOptionDto';
import { CreditPurpose } from './creditPurpose';
import { MaternalCapitalOptionDto } from './maternalCapitalOptionDto';
import { AbsoluteRateOptionItemDto } from './absoluteRateOptionItemDto';
import { FlexPriceOptionDto } from './flexPriceOptionDto';
import { NonStandardType } from './nonStandardType';
import { ProofOfIncome } from './proofOfIncome';


export interface LoanCalculatorOptionsDto { 
    objectTypes?: Array<ObjectType> | null;
    creditPurposes?: Array<CreditPurpose> | null;
    objectTypeAndCreditPurposesCombinations?: Array<ObjectTypeAndCreditPurposesCombinationOptionDto> | null;
    insuranceForms?: Array<InsuranceForm> | null;
    proofOfIncomes?: Array<ProofOfIncome> | null;
    nonStandardTypes?: Array<NonStandardType> | null;
    absoluteRates?: Array<AbsoluteRateOptionItemDto> | null;
    flexPrice?: FlexPriceOptionDto;
    creditPeriod?: CreditPeriodOptionDto;
    maternalCapital?: MaternalCapitalOptionDto;
}

