<div *ngIf="appearance === 'default'; else horizontalTempl" class="file" [class.file_pending]="isPending">
  <tui-svg
    *ngIf="getStateIcon | executeWith: state as icon"
    class="file__icon file__icon_state"
    [className]="'file__icon_' + state"
    [src]="icon"
  />

  <fnip-button
    *ngIf="allowPreview; else fileIconTempl"
    appearance="icon"
    btnSize="s"
    class="file__btn file__btn_preview"
    icon="tuiIconEyeLarge"
    tuiHintAppearance="onDark"
    [class.file__btn_loading]="isPreviewLoading"
    [actionCallback$]="onFilePreview"
    [tuiHint]="previewHint"
    [tuiHintManual]="allowPreview && (isMouseOnEye || isMouseOnTooltip)"
    (mouseenter)="changeMouseFlag('eye', true)"
    (mouseleave)="changeMouseFlag('eye', false)"
  />

  <ng-template #fileIconTempl>
    <img
      *ngIf="thumbnailSrc; else iconTemplate"
      [src]="thumbnailSrc"
      [width]="32"
      [height]="32"
      [style.object-fit]="'contain'"
    />
    <ng-template #iconTemplate>
      <tui-svg src="tuiIconFileLarge" class="file__icon file__icon_file" />
    </ng-template>
  </ng-template>

  <ng-template #previewHint>
    <div (mouseenter)="changeMouseFlag('tooltip', true)" (mouseleave)="changeMouseFlag('tooltip', false)">
      <ng-container *ngIf="onFilePreview | executeWith: false" />
      <fnip-loader [isLoading]="isPreviewLoading" [showContent]="!!cacheBlob">
        <div *tuiLet="isFilePdf | executeWith: { name, cacheBlob } as isPdf" class="file__preview">
          <ng-container *tuiLet="getPreviewFileUrl | executeWith: { cacheBlob } : sanitizer : true as fileUrl">
            <img *ngIf="!isPdf" class="file__preview__content" [src]="fileUrl" [alt]="name" />
            <iframe *ngIf="isPdf" class="file__preview__content" [src]="fileUrl" [title]="name"></iframe>
          </ng-container>

          <fnip-button
            appearance="secondary-light-grey"
            btnSize="s"
            label="Полный предпросмотр"
            [actionCallback$]="openFullPreview"
          />
        </div>
      </fnip-loader>
    </div>
  </ng-template>

  <fnip-button
    *ngIf="allowDownload"
    appearance="icon"
    btnSize="s"
    class="file__btn"
    icon="tuiIconDownloadLarge"
    [actionCallback$]="onFileDownload"
  />

  <span class="file__title" [title]="name">{{ name }}</span>

  <span *ngIf="rejectedReason" class="file_rejected-reason">{{ rejectedReason }}</span>

  <span *ngIf="state === 'uploading' && !!progress; else sizeTempl" class="file__progress">
    {{ formatFileProgress | executeWith: progress }}%
  </span>

  <ng-template #sizeTempl>
    <span *ngIf="showSize" class="file__size">{{ getReadableSize | executeWith: size }}</span>
  </ng-template>

  <fnip-button
    *ngIf="allowRemove"
    appearance="icon"
    btnSize="s"
    icon="tuiIconCloseLarge"
    class="file__btn file__btn_remove"
    [actionCallback$]="onFileRemove"
  />
</div>

<ng-template #horizontalTempl>
  <div class="file">
    <div class="file__details">
      <div class="thumbnail">
        <img *ngIf="thumbnailSrc; else iconTemplate" [src]="thumbnailSrc" />
        <ng-template #iconTemplate>
          <tui-svg *ngIf="getThumbnailIcon | executeWith: name : state as src" class="thumbnail__icon" [src]="src" />
        </ng-template>
      </div>

      <div *ngIf="allowPreview || allowDownload" class="actions">
        <div *ngIf="allowPreview" class="action action_preview" (click)="onFilePreview()">
          <tui-svg class="action__icon" [src]="horizontalPreviewIcon" />
          <span class="action__name">Просмотреть</span>
        </div>

        <div *ngIf="allowDownload" class="action action_download" (click)="onFileDownload()">
          <tui-svg class="action__icon" [src]="horizontalDownloadIcon" />
          <span class="action__name">Скачать</span>
        </div>
      </div>

      <fnip-button
        *ngIf="allowRemove"
        appearance="icon"
        btnSize="s"
        icon="tuiIconCloseLarge"
        class="file__details__remove-btn"
        [actionCallback$]="onFileRemove"
      />
    </div>

    <span class="file__title" [title]="name">{{ name }}</span>
  </div>
</ng-template>
