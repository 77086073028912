/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaterialType } from './materialType';
import { UserModel } from './userModel';
import { LandCategory } from './landCategory';
import { PartnerModel } from './partnerModel';
import { AddressModel } from './addressModel';
import { BuildingAddressModel } from './buildingAddressModel';
import { DateOnly } from '@lib-utils';
import { RealEstateType } from './realEstateType';
import { OwnerRealEstateMappingModel } from './ownerRealEstateMappingModel';
import { DealInfoModel } from './dealInfoModel';
import { DateTime } from '@lib-utils';
import { EncumbranceModel } from './encumbranceModel';


export interface RealEstateModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    dealInfoId?: number;
    dealInfo?: DealInfoModel;
    addressId?: number | null;
    address?: AddressModel;
    buildingAddressId?: number | null;
    buildingAddress?: BuildingAddressModel;
    partnerId?: number | null;
    partner?: PartnerModel;
    area?: number | null;
    numberEstate?: string | null;
    rooms?: string | null;
    floor?: number | null;
    materialType?: MaterialType;
    totalFloor?: number | null;
    dateTransfer?: DateOnly | null;
    dateMortgage?: DateOnly | null;
    isEncumbrance?: boolean;
    encumbrance?: EncumbranceModel;
    isActive?: boolean;
    cadastral?: string | null;
    year?: number | null;
    dduPrice?: number | null;
    concessionCount?: number | null;
    landCategory?: LandCategory;
    permittedUse?: string | null;
    type?: RealEstateType;
    price?: number | null;
    estimatedValue?: number | null;
    ownerMappings?: Array<OwnerRealEstateMappingModel> | null;
}
export namespace RealEstateModel {
}


