<ng-container *ngIf="control">
  <fnip-reactive-label
    *tuiLet="withLoading$ | executeWith: options : term : initialOptions | async as loadedOptions"
    [hasRequiredValidator]="hasRequiredValidator"
    [label]="label"
    [size]="labelSize"
    [hint]="hint"
    [noBottomHint]="noBottomHint"
    [isBold]="isLabelBold"
  >
    <ng-container *ngIf="setSelectedOption | executeWith: control.value : optionsMap" />
    <ng-container *ngIf="handleControlReset | executeWith: loadedOptions" />
    <tui-select
      *ngIf="!hasSearch; else searchSelect"
      [formControl]="$any(control)"
      [tuiTextfieldLabelOutside]="textfieldLabelOutside"
      [tuiTextfieldSize]="textFieldSize"
      [tuiTextfieldCustomContent]="textfieldCustomContent"
      [tuiTextfieldCleaner]="textfieldCleaner"
      [tuiDropdownLimitWidth]="dropdownWidth"
      [tuiDropdownAlign]="dropdownAlign"
      [readOnly]="isReadOnly"
      [valueContent]="
        isOptionsLoading
          ? getLoadingValuePlaceholder
          : valueContent || $any(getDefaultValueContent | executeWith: optionsMap)
      "
      [pseudoActive]="pseudoPressed"
      [class.disabled-copy]="control.disabled"
      (tuiValueChanges)="valueChange.emit($event)"
    >
      {{ placeholder }}
      <ng-template tuiDataList>
        <tui-data-list *ngIf="!isOptionsLoading; else loadingTmp" [emptyContent]="noOptionsMessage">
          <ng-container *ngFor="let o of loadedOptions">
            <button
              *ngIf="!o.hide"
              tuiOption
              fnipTestAttributes
              [testId]="fieldId + '-' + o.value"
              [testLabel]="o.label"
              [value]="o.value"
              [disabled]="!!o.disabled"
            >
              <ng-container *polymorpheusOutlet="optionContent ?? o.label as content; context: { $implicit: o }">
                {{ content }}
              </ng-container>
            </button>
          </ng-container>
        </tui-data-list>
      </ng-template>
    </tui-select>
    <fnip-reactive-field-error
      *ngIf="!noValidationMark"
      [control]="$any(control)"
      [errorLabel]="errorLabel"
      (isErrorShow)="noBottomHint = $event"
    />

    <ng-template #searchSelect>
      <tui-combo-box
        [formControl]="$any(control)"
        [strictMatcher]="noSearchMatcher"
        [tuiTextfieldLabelOutside]="textfieldLabelOutside"
        [tuiTextfieldSize]="textFieldSize"
        [tuiTextfieldCustomContent]="textfieldCustomContent"
        [tuiTextfieldCleaner]="textfieldCleaner"
        [readOnly]="isReadOnly"
        [valueContent]="
          isOptionsLoading
            ? getLoadingValuePlaceholder
            : valueContent || $any(getDefaultValueContent | executeWith: optionsMap)
        "
        [pseudoActive]="pseudoPressed"
        [stringify]="isOptionsLoading ? getLoadingValuePlaceholder : (getDefaultValueContent | executeWith: optionsMap)"
        [maskito]="$any(textMask)"
        [class.disabled-copy]="control.disabled"
        [(search)]="term!"
        (focusedChange)="!selectedOption && (term = null)"
        (tuiValueChanges)="valueChange.emit($event)"
      >
        {{ placeholder }}
        <input tuiTextfield autocomplete="off" [id]="fieldId" />

        <ng-template *ngIf="isVirtualScrollDisabled" tuiDataList>
          <ng-container *tuiLet="filterLoadedOptions | executeWith: loadedOptions : term as filteredOptions">
            <tui-data-list
              *ngIf="!isOptionsLoading; else loadingTmp"
              [emptyContent]="(term?.length || 0) < minTermLength ? emptyTermMessage : noOptionsMessage"
            >
              <ng-container *ngFor="let o of filteredOptions">
                <button
                  *ngIf="!o.hide"
                  tuiOption
                  fnipTestAttributes
                  [testId]="fieldId + '-' + o.value"
                  [testLabel]="o.label"
                  [value]="o.value"
                  [disabled]="!!o.disabled"
                >
                  <ng-container *polymorpheusOutlet="optionContent ?? o.label as content; context: { $implicit: o }">
                    {{ content }}
                  </ng-container>
                </button>
              </ng-container>
            </tui-data-list>
          </ng-container>
        </ng-template>

        <ng-template *ngIf="!isVirtualScrollDisabled" tuiDataList>
          <ng-container *ngIf="!isOptionsLoading; else loadingTmp">
            <cdk-virtual-scroll-viewport
              *tuiLet="filterLoadedOptions | executeWith: loadedOptions : term as filteredOptions"
              tuiScrollable
              class="virtual-scroll"
              [style.height.px]="(filteredOptions?.length || 1) * 44"
              [itemSize]="44"
              (fnipIndexChange)="list.handleFocusLossIfNecessary()"
            >
              <tui-data-list
                #list
                [emptyContent]="(term?.length || 0) < minTermLength ? emptyTermMessage : noOptionsMessage"
              >
                <ng-container *cdkVirtualFor="let o of filteredOptions">
                  <button
                    *ngIf="!o.hide"
                    tuiOption
                    fnipTestAttributes
                    [testId]="fieldId + '-' + o.value"
                    [testLabel]="o.label"
                    [value]="o.value"
                    [disabled]="!!o.disabled"
                  >
                    <ng-container *polymorpheusOutlet="optionContent ?? o.label as content; context: { $implicit: o }">
                      {{ content }}
                    </ng-container>
                  </button>
                </ng-container>
              </tui-data-list>
            </cdk-virtual-scroll-viewport>
          </ng-container>
        </ng-template>
      </tui-combo-box>
    </ng-template>
  </fnip-reactive-label>
</ng-container>

<ng-template #loadingTmp>
  <div class="loader">
    <tui-loader />
    Опции загружаются
  </div>
</ng-template>
