<div class="d-flex gap-1" [class.flex-column]="buttonPosition() === 'bottom'">
  <tui-line-clamp
    [class.cursor-none]="!showHint()"
    [content]="content()"
    [linesLimit]="visibleLines()"
    (overflownChange)="overflown.set($event)"
  />
  @if (expandVisible()) {
    <div class="color-primary cursor-pointer d-flex" (click)="expanded.set(!expanded())">
      <span>{{ expandButtonName() }}</span> <tui-svg [src]="expandButtonIcon()"></tui-svg>
    </div>
  }
</div>
