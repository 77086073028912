import { HttpEventType } from '@angular/common/http';
import { FileInfo } from '@lib-utils';

export type FileInfoUploadEvent<T extends string | number = string | number> =
  | {
      type: HttpEventType.UploadProgress;
      progress: number;
    }
  | {
      type: HttpEventType.Response;
      body: Partial<FileInfo<T>>;
    };
