import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiButtonModule, TuiHintModule, TuiLoaderModule, TuiSvgModule } from '@taiga-ui/core';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { AutofocusModule, HandleHintDirective } from '@lib-utils';
import { EllipsisHintComponent } from '../ellipsis-hint';
import { ButtonComponent } from './button.component';
import { ToCallbackPipe } from './to-callback.pipe';

const TUI_MODULES = [TuiButtonModule, TuiLoaderModule, TuiHintModule, TuiSvgModule];

@NgModule({
  imports: [
    CommonModule,
    HandleHintDirective,
    TUI_MODULES,
    RouterModule,
    PolymorpheusOutlet,
    EllipsisHintComponent,
    AutofocusModule,
  ],
  declarations: [ButtonComponent, ToCallbackPipe],
  exports: [ButtonComponent, ToCallbackPipe],
})
export class ButtonModule {}
