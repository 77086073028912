/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RepresentativeDocumentType = 'PowerOfAttorney' | 'OrderOfAppointment' | 'ProtocolOfElection' | 'ExtractFromProtocolOfElection' | 'ProtocolOfGeneralMeeting' | 'Judgment' | 'CourtRuling' | 'ActOfAuthorityConfirmingAuthority' | 'OtherDocument';

export const RepresentativeDocumentType = {
    PowerOfAttorney: 'PowerOfAttorney' as RepresentativeDocumentType,
    OrderOfAppointment: 'OrderOfAppointment' as RepresentativeDocumentType,
    ProtocolOfElection: 'ProtocolOfElection' as RepresentativeDocumentType,
    ExtractFromProtocolOfElection: 'ExtractFromProtocolOfElection' as RepresentativeDocumentType,
    ProtocolOfGeneralMeeting: 'ProtocolOfGeneralMeeting' as RepresentativeDocumentType,
    Judgment: 'Judgment' as RepresentativeDocumentType,
    CourtRuling: 'CourtRuling' as RepresentativeDocumentType,
    ActOfAuthorityConfirmingAuthority: 'ActOfAuthorityConfirmingAuthority' as RepresentativeDocumentType,
    OtherDocument: 'OtherDocument' as RepresentativeDocumentType
};

