/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CronosInfoDto } from './cronosInfoDto';
import { EquifaxInfoDto } from './equifaxInfoDto';
import { FsspInfoDto } from './fsspInfoDto';
import { MailRuInfoDto } from './mailRuInfoDto';
import { NegativeCreditHistoryTriggeredRulesInfoDto } from './negativeCreditHistoryTriggeredRulesInfoDto';
import { OneFactorInfoDto } from './oneFactorInfoDto';
import { SignsOfFalsificationInfoItemDto } from './signsOfFalsificationInfoItemDto';
import { ScoringInfoDto } from './scoringInfoDto';
import { VerusGrifidReportInfoDto } from './verusGrifidReportInfoDto';


export interface ClientScoringSummaryDto { 
    scoringInfo?: ScoringInfoDto;
    cronosInfo?: CronosInfoDto;
    equifaxFpsInfo?: EquifaxInfoDto;
    fsspInfo?: FsspInfoDto;
    oneFactorInfo?: OneFactorInfoDto;
    mailRuInfo?: MailRuInfoDto;
    negativeCreditHistoryTriggeredRules?: Array<NegativeCreditHistoryTriggeredRulesInfoDto> | null;
    verusGrifidReport?: VerusGrifidReportInfoDto;
    signsOfFalsification?: Array<SignsOfFalsificationInfoItemDto> | null;
}

