/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentType } from './paymentType';
import { OwnerRequisitesDto } from './ownerRequisitesDto';
import { OwnerNominalAccountDto } from './ownerNominalAccountDto';


export interface PaymentInfoDto { 
    id?: number;
    paymentType: PaymentType;
    paymentSum: number;
    requisites?: OwnerRequisitesDto;
    nominalAccountId?: number | null;
    nominalAccount?: OwnerNominalAccountDto;
}
export namespace PaymentInfoDto {
}


