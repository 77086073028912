/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SignatorySortFields = 'FullName' | 'AttorneyNumber' | 'AttorneyDate' | 'AttorneyEndDate';

export const SignatorySortFields = {
    FullName: 'FullName' as SignatorySortFields,
    AttorneyNumber: 'AttorneyNumber' as SignatorySortFields,
    AttorneyDate: 'AttorneyDate' as SignatorySortFields,
    AttorneyEndDate: 'AttorneyEndDate' as SignatorySortFields
};

