/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DepartmentModel } from './departmentModel';
import { DateOnly } from '@lib-utils';
import { DealDocumentsModel } from './dealDocumentsModel';
import { SmartDealRequestStatus } from './smartDealRequestStatus';
import { RealEstateModel } from './realEstateModel';
import { SignatoryModel } from './signatoryModel';
import { TransferType } from './transferType';
import { DateTime } from '@lib-utils';
import { DealRegistrationType } from './dealRegistrationType';
import { UserModel } from './userModel';
import { EvaluateReportModel } from './evaluateReportModel';
import { OrganizationModel } from './organizationModel';
import { InseparableImprovementsType } from './inseparableImprovementsType';
import { ReceivingType } from './receivingType';
import { OwnerRequisitesModel } from './ownerRequisitesModel';
import { RegistrationType } from './registrationType';
import { BuyerInfoModel } from './buyerInfoModel';


export interface DealInfoModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    propertyValue?: number | null;
    date?: DateTime | null;
    contractNumber?: string | null;
    transferType?: TransferType;
    receivingType?: ReceivingType;
    smartDealRequestId?: number | null;
    assessmentPropertyValue?: number | null;
    cftDepartmentId?: number | null;
    cftDepartment?: DepartmentModel;
    dealDepartmentId?: number | null;
    dealDepartment?: DepartmentModel;
    assessmentDate?: DateOnly | null;
    isActive?: boolean;
    buyers?: Array<BuyerInfoModel> | null;
    agreementType?: DealRegistrationType;
    ownerRequisitesId?: number | null;
    ownerRequisites?: OwnerRequisitesModel;
    bankSignerId?: number | null;
    bankSigner?: SignatoryModel;
    registrationType?: RegistrationType;
    evaluateReportId?: number | null;
    evaluateReport?: EvaluateReportModel;
    dealDocumentsId?: number | null;
    dealDocuments?: DealDocumentsModel;
    gozoEstimateValue?: number | null;
    inseparableImprovements?: InseparableImprovementsType;
    inseparableImprovementsCost?: number | null;
    contractOfSaleCost?: number | null;
    conclusionContractOfSalePlanningDate?: DateOnly | null;
    builder?: OrganizationModel;
    electronicRegistrationCost?: number | null;
    smartDealRequestStatus?: SmartDealRequestStatus;
    smartDealRequestTime?: DateTime | null;
    realEstates?: Array<RealEstateModel> | null;
}
export namespace DealInfoModel {
}


