export interface Config {
  apiUrl: string;
  archiveApiUrl?: string;
  autoApiUrl?: string;
  autoLookupApiUrl?: string;
  autoReportsApiUrl?: string;
  autoSprApiUrl?: string;
  collectionApiUrl?: string;
  verificationApiUrl?: string;
  hostUrl?: string;
  logCollectorUrl?: string;
  logCollectorKey?: string;
  feedbackUrl?: string;
  bffUrl?: string;
  tokenCookieName?: string;
  remotes: {
    'crm-collection'?: string;
    auto?: string;
    archive?: string;
    verification?: string;
    mortgage?: string;
  };
}

export type RemoteKeys = keyof Config['remotes'];

export enum Env {
  localhost = 'localhost',
  dev = 'dev',
  stage = 'stage',
  preprod = 'preprod',
  prod = 'prod',
}
