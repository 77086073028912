/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ndfl2Income } from './ndfl2Income';


export interface Ndfl2Page { 
    fullName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    secondName?: string | null;
    birthDate?: string | null;
    inn?: string | null;
    sign?: string | null;
    year?: string | null;
    oktmo?: string | null;
    phone?: string | null;
    stamp?: string | null;
    fileId?: string | null;
    number?: string | null;
    taskId?: string | null;
    incomes1?: Array<Ndfl2Income> | null;
    incomes2?: Array<Ndfl2Income> | null;
    incomes3?: Array<Ndfl2Income> | null;
    issueDate?: string | null;
    nameAgent?: string | null;
    countryCode?: string | null;
    digitalSign?: string | null;
    innEmployer?: string | null;
    kppEmployer?: string | null;
    documentCode?: string | null;
    hasCorrections?: boolean | null;
    numberPassport?: string | null;
    statusTaxPayer?: string | null;
    incomesTaxBase1?: string | null;
    incomeTable1Rate?: string | null;
    incomeTable2Rate?: string | null;
    incomeTable3Rate?: string | null;
    incomeTable2Exists?: boolean | null;
    incomeTable3Exists?: boolean | null;
    reorganizationCode?: string | null;
    incomesTotalIncome1?: string | null;
    innKppReorganization?: string | null;
    incomeTable1LastMonth?: string | null;
    incomeTable2LastMonth?: string | null;
    incomeTable3LastMonth?: string | null;
    incomeTable1FirstMonth?: string | null;
    incomeTable2FirstMonth?: string | null;
    incomeTable3FirstMonth?: string | null;
    incomesCalculationTaxAmount1?: string | null;
    pageIndexesInFile?: Array<number> | null;
}

