import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiFlagPipeModule } from '@taiga-ui/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { Observable } from 'rxjs';
import { createTranslatedOptionsForLocalizedEnum, Nullable } from '@lib-utils';
import { ReactiveSelectNewModule } from '@lib-widgets/reactive-fields';
import { TRANSLATE_ENABLED_TOKEN } from '../../translate-enabled.token';
import { TranslateStateService } from '../../translate-state.service';
import { LANGUAGE_NAME_MAP, TUI_LANGUAGE_CODE_TO_TRANSLATE_CODE } from '../../translate.utils';

@Component({
  selector: 'fnip-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveSelectNewModule, TuiFlagPipeModule, AsyncPipe],
})
export class LanguageSwitcherComponent {
  readonly translateStateService = inject(TranslateStateService);
  readonly getLanguageOptions$ = createTranslatedOptionsForLocalizedEnum(LANGUAGE_NAME_MAP);

  languageFc = new FormControl<Nullable<TuiCountryIsoCode>>(null);
  isTranslateEnabled = inject(TRANSLATE_ENABLED_TOKEN, { optional: false });

  setLanguageObservable$?: Observable<unknown>;

  setLanguage = (countryCode: Nullable<TuiCountryIsoCode>) => {
    if (!countryCode) return;
    const language = TUI_LANGUAGE_CODE_TO_TRANSLATE_CODE.get(countryCode!);
    if (!language) return;

    this.setLanguageObservable$ = this.translateStateService.setLanguage(language);
  };

  constructor() {
    effect(() => {
      const countryCode = this.translateStateService.currentCountryCode();
      this.languageFc.patchValue(countryCode, { emitEvent: false });
    });
  }
}
