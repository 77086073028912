/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationState = 'Form' | 'InProgress' | 'OnDecision' | 'RealEstate' | 'RealEstateAnalyze' | 'DealPrepare' | 'DealVerification' | 'Deal' | 'DeliveryVerification' | 'DeliveredCredits' | 'OrderRegistration' | 'EncumbranceRegistration' | 'Archive';

export const ApplicationState = {
    Form: 'Form' as ApplicationState,
    InProgress: 'InProgress' as ApplicationState,
    OnDecision: 'OnDecision' as ApplicationState,
    RealEstate: 'RealEstate' as ApplicationState,
    RealEstateAnalyze: 'RealEstateAnalyze' as ApplicationState,
    DealPrepare: 'DealPrepare' as ApplicationState,
    DealVerification: 'DealVerification' as ApplicationState,
    Deal: 'Deal' as ApplicationState,
    DeliveryVerification: 'DeliveryVerification' as ApplicationState,
    DeliveredCredits: 'DeliveredCredits' as ApplicationState,
    OrderRegistration: 'OrderRegistration' as ApplicationState,
    EncumbranceRegistration: 'EncumbranceRegistration' as ApplicationState,
    Archive: 'Archive' as ApplicationState
};

