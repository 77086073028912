/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasisStatus } from './basisStatus';
import { Solution } from './solution';


export interface SolutionRecognitionResultDto { 
    status?: BasisStatus;
    solution?: Solution;
}
export namespace SolutionRecognitionResultDto {
}


