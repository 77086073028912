import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, TemplateRef } from '@angular/core';
import { Params } from '@angular/router';
import { TuiAppearance } from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { isObservable, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LinkTarget, Nullable, TestAttributesDirective } from '@lib-utils';

export enum ButtonAppearanceEnum {
  Default = 'default',
  SecondaryLightGray = 'secondary-light-grey',
  ActionButton = 'action-button',
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  hostDirectives: [
    {
      directive: TestAttributesDirective,
      inputs: ['defaultTestLabel: label', 'testLabel', 'testId'],
    },
  ],
})
export class ButtonComponent {
  @Input() label: Nullable<PolymorpheusContent> = '';
  @Input() icon: Nullable<PolymorpheusContent> = '';
  @Input() iconRight: PolymorpheusContent = '';
  @Input() shape: 'square' | 'rounded' | null = null;
  @Input() appearance: string = TuiAppearance.Outline;
  @Input() isDisabled: Nullable<boolean>;
  @Input() btnActive: Nullable<boolean>;
  @Input() btnHint: Nullable<string | TemplateRef<Record<string, unknown>>> = null;
  @Input() btnHintAppearance: TuiAppearance.Primary | 'onDark' = TuiAppearance.Primary;
  @Input() btnHref: Nullable<string>;
  @Input() btnRouterLink: Nullable<Array<number | string | undefined> | string | null>;
  @Input() btnLinkTarget: LinkTarget = '_self';
  @Input() btnRouterQueryParams: Nullable<Params>;
  @Input() btnRouterFragment?: string;
  @Input() btnSize: 's' | 'm' | 'l' = 'm';
  @Input() actionCallback$: Nullable<() => Nullable<Observable<unknown> | void>>;
  @Input() btnStyle: { [prop: string]: unknown } | null = null;
  @Input() btnClass: Nullable<string>;
  @Input() isSkeleton = false;
  @Input() isStopPropagation = false;
  @Input() isAutofocus: Nullable<boolean>;
  @Input() isIconLoader = false;

  isLoading = false;
  action$: Nullable<Observable<unknown>>;

  private readonly cdr = inject(ChangeDetectorRef);

  public onClick(event?: MouseEvent, preventDefault = true) {
    // FNIP-4933: Для фикса бага с релоудом всей страницы при клике на кнопку в reactive-file-input внутри диалога
    // FNARCH-201: Ссылки не открываются на новой странице при preventDefault
    if (preventDefault) event?.preventDefault();
    this.isStopPropagation && event?.stopPropagation();

    const actionResult$ = this.actionCallback$?.();
    if (!isObservable(actionResult$)) return;

    this.isLoading = true;

    // Если был вызов из вне по референсу, то не нужно вызывать markForCheck для срабатывания async pipe
    this.cdr.markForCheck();

    this.action$ = actionResult$.pipe(
      finalize(() => {
        this.action$ = null;
        this.isLoading = false;
        this.cdr.markForCheck();
      }),
    );
  }
}
