import { Component } from '@angular/core';
import { LoggerService, NavigationService } from '@lib-utils';
import { AuthorizationStorageService } from '@lib-mortgage/features/authorization';

@Component({
  selector: 'fnip-root',
  template: `
    <tui-root>
      <div class="layout">
        <router-outlet />
      </div>
    </tui-root>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly authorizationStorageService: AuthorizationStorageService,
    private readonly loggerService: LoggerService,
    private readonly navigationService: NavigationService,
  ) {
    this.navigationService.startSavingHistory$();
    this.loggerService.setActiveUser(this.authorizationStorageService.user);
  }
}
