/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealVerificationTaskType } from './dealVerificationTaskType';
import { ApplicationStatus } from './applicationStatus';
import { TransferType } from './transferType';
import { ApplicationRelationDto } from './applicationRelationDto';
import { DateTime } from '@lib-utils';
import { DepartmentInfoDto } from './departmentInfoDto';


export interface DealVerificationApplicationDto { 
    taskId?: number | null;
    applicationId?: number;
    verificator?: string | null;
    verificatorId?: number | null;
    fullName?: string | null;
    transferType?: TransferType;
    date?: DateTime | null;
    dealDepartment?: DepartmentInfoDto;
    relations?: Array<ApplicationRelationDto> | null;
    updated?: DateTime | null;
    type?: DealVerificationTaskType;
    state?: ApplicationStatus;
}
export namespace DealVerificationApplicationDto {
}


