import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiNotificationModule } from '@taiga-ui/core';
import { DividerModule } from '@lib-widgets/core';

@Component({
  standalone: true,
  selector: 'fnip-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, NgIf, DividerModule, TuiNotificationModule],
})
export class NotificationListItemComponent {
  @Input() isShort = false;
  @Input() isRead = false;
  @Input() shortText = '';
  @Input() fullText = '';
  @Input() date = new Date();

  isExpand = false;
}
