/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BlacklistCheckStatus } from './blacklistCheckStatus';


export interface BlacklistEmployerRequest { 
    taxNumber?: string | null;
    phone?: string | null;
    status?: BlacklistCheckStatus;
    comment?: string | null;
}
export namespace BlacklistEmployerRequest {
}


