/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationChangeDealVerificationTaskAssignDataDto } from './applicationChangeDealVerificationTaskAssignDataDto';
import { ApplicationChangesApproveDealVerificationFileCheckDataDto } from './applicationChangesApproveDealVerificationFileCheckDataDto';
import { ApplicationChangesApproveDealVerificationTaskDataDto } from './applicationChangesApproveDealVerificationTaskDataDto';


export interface ApplicationChangesDealVerificationTaskDataDto { 
    approveDealVerificationTaskData?: ApplicationChangesApproveDealVerificationTaskDataDto;
    dealVerificationTaskAssignData?: ApplicationChangeDealVerificationTaskAssignDataDto;
    approveDealVerificationFileCheckData?: ApplicationChangesApproveDealVerificationFileCheckDataDto;
}

