export interface BEErrorInfo {
  /**
   * Числовой код ошибки.
   */
  numericCode?: number;
  /**
   * Код ошибки.
   */
  code?: string | null;
  /**
   * Не обработанная.
   */
  unhandled?: boolean;
  /**
   * Сообщения для пользователя.
   */
  message?: string | null;
  /**
   * Техническая информация.
   */
  technicalErrors?: Array<BETechnicalError> | null;
  /**
   * Ошибки валидации.
   */
  validationErrors?: { [key: string]: Array<string> } | null;
}

export interface BETechnicalError {
  /**
   * Тип исключения.
   */
  exceptionType?: string | null;
  /**
   * Сообщение.
   */
  message?: string | null;
}
