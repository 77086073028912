/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualPtiChangeModel } from './manualPtiChangeModel';
import { CoborrowerModel } from './coborrowerModel';
import { ApplicationRelationModel } from './applicationRelationModel';
import { DepartmentModel } from './departmentModel';
import { GuarantorModel } from './guarantorModel';
import { ManualReviewTasksApplicationMapModel } from './manualReviewTasksApplicationMapModel';
import { ApplicationChangeModel } from './applicationChangeModel';
import { ManualReviewTaskModel } from './manualReviewTaskModel';
import { TextSpecialConditionModel } from './textSpecialConditionModel';
import { DealInfoModel } from './dealInfoModel';
import { DateTime } from '@lib-utils';
import { UserModel } from './userModel';
import { ClientModel } from './clientModel';
import { CreditInfoModel } from './creditInfoModel';
import { ApplicationStatus } from './applicationStatus';
import { ApplicationCreationType } from './applicationCreationType';
import { OccupationType } from './occupationType';
import { ReasonForArchivingModel } from './reasonForArchivingModel';


export interface ApplicationModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    clientId?: number;
    client?: ClientModel;
    creditInfoId?: number;
    creditInfo?: CreditInfoModel;
    state?: ApplicationStatus;
    comment?: string | null;
    dealInfoId?: number | null;
    dealInfo?: DealInfoModel;
    relations?: Array<ApplicationRelationModel> | null;
    coborrowers?: Array<CoborrowerModel> | null;
    guarantors?: Array<GuarantorModel> | null;
    uid?: string | null;
    isActive?: boolean;
    occupationType?: OccupationType;
    creationType?: ApplicationCreationType;
    tasks?: Array<ManualReviewTaskModel> | null;
    reasonForArchiving?: ReasonForArchivingModel;
    applicationChanges?: Array<ApplicationChangeModel> | null;
    externalId?: string;
    manualReviewTasksMap?: ManualReviewTasksApplicationMapModel;
    manualPtiChange?: ManualPtiChangeModel;
    textSpecialConditions?: Array<TextSpecialConditionModel> | null;
    departmentId?: number | null;
    department?: DepartmentModel;
}
export namespace ApplicationModel {
}


