import { tuiIsIos } from '@taiga-ui/cdk';
import { BrowserName } from './browser-name';

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLocaleLowerCase();
  switch (true) {
    case tuiIsIos(window.navigator) || agent.includes('android'):
      // Mozilla/5.0 (Linux; Android 9; SM-G970F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Mobile Safari/537.36
      return BrowserName.Mobile;
    case !!agent.match(/\bedge?/):
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36 Edg/112.0.1722.34
      return BrowserName.Edge;
    case !!agent.match(/\b(opr|opera)/):
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36 OPR/97.0.0.0
      return BrowserName.Opera;
    case agent.includes('yabrowser'):
      // Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.41 YaBrowser/21.2.0.1099 Yowser/2.5 Safari/537.36
      return BrowserName.Yandex;
    case agent.includes('chrome'):
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36
      return BrowserName.Chrome;
    case agent.includes('trident'):
      // Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko
      return BrowserName.IE;
    case agent.includes('firefox'):
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:100.0) Gecko/20100101 Firefox/100.0
      return BrowserName.Firefox;
    case agent.includes('safari'):
      // Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306
      return BrowserName.Safari;
    default:
      return BrowserName.Other;
  }
}
