import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputPhoneModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputPhoneComponent } from './reactive-input-phone.component';

const TUI_MODULES = [
  TuiInputPhoneModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiSvgModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    TUI_MODULES,
  ],
  declarations: [ReactiveInputPhoneComponent],
  exports: [ReactiveInputPhoneComponent],
})
export class ReactiveInputPhoneModule {}
