/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserInfoDto } from './userInfoDto';
import { DateTime } from '@lib-utils';


export interface TextSpecialConditionDto { 
    id?: number;
    comment?: string | null;
    createdAt?: DateTime;
    updator?: UserInfoDto;
    updatedAt?: DateTime;
}

