import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiGroupModule, TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiRadioBlockModule } from '@taiga-ui/kit';
import { TestAttributesDirective } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveRadioBlockComponent } from './reactive-radio-block.component';

const TUI_MODULES = [
  TuiGroupModule,
  TuiRadioBlockModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    TUI_MODULES,
    TestAttributesDirective,
    TuiDataListModule,
  ],
  declarations: [ReactiveRadioBlockComponent],
  exports: [ReactiveRadioBlockComponent],
})
export class ReactiveRadioBlockModule {}
