/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { ManualReviewTaskModel } from './manualReviewTaskModel';
import { EmploymentModel } from './employmentModel';
import { DateTime } from '@lib-utils';
import { IncomeSource } from './incomeSource';


export interface EmploymentIncomeModel { 
    id?: number;
    creatorId?: number | null;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number | null;
    updator?: UserModel;
    updated?: DateTime | null;
    employmentId?: number;
    employment?: EmploymentModel;
    source?: IncomeSource;
    income?: number | null;
    comment?: string | null;
    taskId?: number | null;
    manualReviewTask?: ManualReviewTaskModel;
}
export namespace EmploymentIncomeModel {
}


