import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { filter, Observable, pairwise } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  BreakpointService,
  IPOTEKA_OPEN_ISSUE_URL,
  IPOTEKA_VIDEO_INSTRUCTION_URL,
  LOGO_SRC,
  Nullable,
  RoleType,
  RoleTypeMap,
} from '@lib-utils';
import { AuthorizationStorageService, CURRENT_USER, UserInfoBff } from '@lib-mortgage/features/authorization';
import { ToolbarService } from './toolbar.service';

@Component({
  selector: 'fnip-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreakpointService],
})
export class ToolbarComponent {
  @Input() application?: string = '';
  @Input() compactNotifications = false;
  @Input() hasTranslation = false;
  @Input() leftMenuCb$: Nullable<() => void>;
  @Input() logoutCallBack$: Nullable<() => Nullable<Observable<unknown> | void>>;

  @Input({ transform: booleanAttribute }) showNotification = false;
  @Input({ transform: (value: Nullable<string>) => value ?? LOGO_SRC }) logo = LOGO_SRC;
  @Input({ transform: (value: Nullable<string>) => value ?? IPOTEKA_OPEN_ISSUE_URL }) openIssueUrl =
    IPOTEKA_OPEN_ISSUE_URL;
  @Input({ transform: (value: Nullable<string>) => value ?? IPOTEKA_VIDEO_INSTRUCTION_URL }) videoInstructionUrl =
    IPOTEKA_VIDEO_INSTRUCTION_URL;

  private readonly authorizationStorageService = inject(AuthorizationStorageService);
  readonly breakpointService = inject(BreakpointService);
  readonly toolbarService = inject(ToolbarService);

  readonly user$ = inject(CURRENT_USER);
  readonly authorized$ = this.authorizationStorageService.authorized$;

  isInfoSidebarOpen = false;

  changeUserInfoSidebarState = (state: Nullable<boolean>) => (this.isInfoSidebarOpen = !!state);

  closeSidebarsWhenNotMobile$ = this.breakpointService.isMobile$.pipe(
    pairwise(),
    filter(([prev, next]) => prev && !next),
    tap(() => {
      if (this.isInfoSidebarOpen) this.changeUserInfoSidebarState(false);
      if (this.toolbarService.isOpen) this.toolbarService.changeOpenState(false);
    }),
  );

  getUserWithRoleNames = (user: Nullable<UserInfoBff>) => {
    const roles = user?.roles?.map(({ name }) => name) || [];

    return {
      firstName: user?.firstName,
      name: user?.role?.name,
      nameTranslateKey: user?.role?.code ? RoleTypeMap[user?.role?.code as RoleType] ?? '' : '',
      toolTipNames:
        this.authorizationStorageService.userRoleType === RoleType.MultiRole ? roles.filter(Boolean).join('\n') : '',
    };
  };
}
