/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressDetails { 
    postalCode?: string | null;
    flat?: string | null;
    flatTypeFull?: string | null;
    block?: string | null;
    blockTypeFull?: string | null;
    house?: string | null;
    houseKladrId?: string | null;
    houseTypeFull?: string | null;
    country?: string | null;
    countryIsoCode?: string | null;
    kladrId?: string | null;
    areaKladrId?: string | null;
    areaWithType?: string | null;
    cityKladrId?: string | null;
    cityWithType?: string | null;
    regionKladrId?: string | null;
    regionWithType?: string | null;
    streetKladrId?: string | null;
    streetWithType?: string | null;
    settlementKladrId?: string | null;
    settlementWithType?: string | null;
}

