/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientType } from './clientType';


export interface EaClient { 
    clientId?: number;
    name?: string | null;
    clientType?: ClientType;
    email?: string | null;
    phone?: string | null;
}
export namespace EaClient {
}


