export enum BrowserName {
  Chrome = 'chrome',
  Firefox = 'firefox',
  Opera = 'opera',
  Yandex = 'yandex',
  Edge = 'edge',
  Safari = 'safari',
  IE = 'ie',
  Other = 'other',
  Mobile = 'mobile',
}

export interface BrowserInfo {
  name: string;
  version: number;
}

export const SUPPORTED_BROWSERS: Partial<Record<BrowserName, BrowserInfo>> = {
  [BrowserName.Chrome]: {
    name: 'Google Chrome',
    version: 101,
  },
  [BrowserName.Edge]: {
    name: 'Microsoft Edge',
    version: 101,
  },
  [BrowserName.Yandex]: {
    name: 'Яндекс Браузер',
    version: 23,
  },
  [BrowserName.Opera]: {
    name: 'Opera',
    version: 90,
  },
  [BrowserName.Safari]: {
    name: 'Safari',
    version: 16,
  },
  [BrowserName.Firefox]: {
    name: 'Mozilla Firefox',
    version: 100,
  },
  [BrowserName.Mobile]: {
    name: 'Мобильные устройства',
    version: 0, // отключаем проверку для мобильных устройств
  },
};

export const DISABLE_BROWSER_CHECK_KEY = 'disableBrowserCheck';
