import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS, TuiSizeXL } from '@taiga-ui/core';
import { Nullable } from '@lib-utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-reactive-label',
  templateUrl: './reactive-label.component.html',
  styleUrls: [`./reactive-label.component.scss`],
})
export class ReactiveLabelComponent {
  @Input() label: Nullable<string>;

  @Input() hasRequiredValidator: Nullable<boolean>;

  @Input() isBold: Nullable<boolean>;

  @Input() hint: Nullable<string>;

  /**
   * Скрывает `hint` с `hintPosition = 'bottom'`
   */
  @Input() noBottomHint: Nullable<boolean> = false;

  @Input() hintPosition: 'top' | 'bottom' = 'top';

  @Input() hintStyle: { [prop: string]: unknown } | null = null;

  @HostBinding('attr.label-size')
  @Input()
  size: TuiSizeS | TuiSizeM | TuiSizeL | TuiSizeXL = 's';
}
