/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RedirectConfirmation = 'Confirm' | 'Deny' | 'Return';

export const RedirectConfirmation = {
    Confirm: 'Confirm' as RedirectConfirmation,
    Deny: 'Deny' as RedirectConfirmation,
    Return: 'Return' as RedirectConfirmation
};

