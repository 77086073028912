/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OwnershipType = 'Joint' | 'Share' | 'Individual' | 'Use' | 'Possession' | 'Gratuitous' | 'Rent';

export const OwnershipType = {
    Joint: 'Joint' as OwnershipType,
    Share: 'Share' as OwnershipType,
    Individual: 'Individual' as OwnershipType,
    Use: 'Use' as OwnershipType,
    Possession: 'Possession' as OwnershipType,
    Gratuitous: 'Gratuitous' as OwnershipType,
    Rent: 'Rent' as OwnershipType
};

