/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';


export interface EaFileInfo { 
    name?: string | null;
    bucket?: string | null;
    objectName?: string;
    type?: FileType;
    clientId?: number | null;
    length?: number;
}
export namespace EaFileInfo {
}


