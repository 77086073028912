/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DueDiligenceTaskStatus = 'Pending' | 'Active' | 'Processed' | 'ForRevision' | 'Rejected';

export const DueDiligenceTaskStatus = {
    Pending: 'Pending' as DueDiligenceTaskStatus,
    Active: 'Active' as DueDiligenceTaskStatus,
    Processed: 'Processed' as DueDiligenceTaskStatus,
    ForRevision: 'ForRevision' as DueDiligenceTaskStatus,
    Rejected: 'Rejected' as DueDiligenceTaskStatus
};

