import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import isNumber from 'lodash-es/isNumber';
import { Nullable } from '../../interfaces';

@Pipe({
  name: 'localizedCurrency',
  pure: false,
  standalone: true,
})
export class LocalizedCurrencyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Nullable<number>, currency: string, currencyCode?: string, digitsInfo?: string): string | null {
    if (!isNumber(value)) return null;

    const locale = this.translateService.currentLang;

    return formatCurrency(value, locale, currency, currencyCode, digitsInfo);
  }
}
