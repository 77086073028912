import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated Используйте PluralPipe
 */
@Pipe({
  name: 'pluralMonth',
})
export class PluralMonthPipe implements PipeTransform {
  transform(month: number | null, caseType: CaseTypes = 'nominative'): string {
    let value = Number(month);
    if (!value || value <= 0) return '';
    value %= 100; // отбрасываем сотни
    const dozens = Math.floor(value / 10); // десятки
    const numbers = value % 10; // числа
    if (caseType === 'nominative') return this.getNominative(value, dozens, numbers);
    return this.getGenitive(value, numbers);
  }

  /** Именительный падеж */
  private getNominative(value: number, dozens: number, numbers: number): string {
    if (value === 0 || numbers === 0 || dozens === 1) return 'месяцев';
    if (numbers === 1) return 'месяц';
    if (numbers > 1 && numbers < 5) return 'месяца';
    if (numbers >= 5 && numbers <= 9) return 'месяцев';
    return 'месяцев';
  }

  /** Родительный падеж */
  private getGenitive(value: number, numbers: number): string {
    if (numbers === 1 && value !== 11) return 'месяца';
    return 'месяцев';
  }
}

export type CaseTypes = 'nominative' | 'genitive';
