/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationTaskSortFields = 'Fullname' | 'DocumentType' | 'DocumentStatus' | 'Created' | 'Id' | 'TaskType';

export const VerificationTaskSortFields = {
    Fullname: 'Fullname' as VerificationTaskSortFields,
    DocumentType: 'DocumentType' as VerificationTaskSortFields,
    DocumentStatus: 'DocumentStatus' as VerificationTaskSortFields,
    Created: 'Created' as VerificationTaskSortFields,
    Id: 'Id' as VerificationTaskSortFields,
    TaskType: 'TaskType' as VerificationTaskSortFields
};

