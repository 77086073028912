import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputTimeModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputTimeComponent } from './reactive-input-time.component';

const TUI_MODULES = [TuiInputTimeModule, TuiLabelModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  declarations: [ReactiveInputTimeComponent],
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  exports: [ReactiveInputTimeComponent],
})
export class ReactiveInputTimeModule {}
