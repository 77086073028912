/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface CreditObligationDto { 
    id?: string;
    uuid?: string | null;
    type?: string | null;
    businessCategory?: string | null;
    status?: string | null;
    currency?: string | null;
    monthlyPayment?: number;
    creditSum?: number | null;
    openDate?: DateOnly | null;
    repaymentDate?: DateOnly | null;
    scheduledRepaymentDate?: DateOnly | null;
    loansReceivable?: number | null;
    calculationDate?: DateOnly | null;
    infoSources?: Array<string> | null;
    isToClose?: boolean | null;
    jointCreditMarker?: number | null;
}

