import { Injectable } from '@angular/core';
import { ExceptionDto } from '@lib-bff/api';
import { ErrorMapperAbstract, TypedHttpErrorResponse } from '@lib-error-interceptor';
import { BeApiEnum, FeHttpError } from '@lib-utils';

@Injectable()
export class BffErrorMapper extends ErrorMapperAbstract<ExceptionDto, ExceptionDto> {
  readonly forApiModules: BeApiEnum[] = [BeApiEnum.Bff];

  mapError(beApi: BeApiEnum | null, errorResp: TypedHttpErrorResponse<ExceptionDto>): FeHttpError<ExceptionDto> {
    const { error } = errorResp.error ?? {};

    return new FeHttpError<ExceptionDto>({
      beApi,
      url: errorResp.url,
      headers: errorResp.headers,
      originalError: errorResp.error,
      status: errorResp.status,
      exceptionCode: error?.code ?? FeHttpError.DEFAULT_ERROR_CODE,
      messages: error
        ? [
            {
              message: error.message ?? FeHttpError.DEFAULT_ERROR_MESSAGE,
              code: error.code ?? FeHttpError.DEFAULT_ERROR_CODE,
            },
          ]
        : [],
      technicalMessages: [],
    });
  }
}
