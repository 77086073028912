import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { isObservable, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Nullable } from '@lib-utils';
import { ButtonMenuDropdownItem } from './button-menu-dropdown.model';

@Component({
  selector: 'fnip-button-menu-dropdown',
  templateUrl: './button-menu-dropdown.component.html',
  styleUrls: ['./button-menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuDropdownComponent {
  @Input() btnIcon: Nullable<PolymorpheusContent> = '';

  @Input() btnLabel: Nullable<string>;

  @Input() items: ButtonMenuDropdownItem[] = [];

  action$: Nullable<Observable<unknown>>;

  onClick(actionCallback$: Nullable<() => Nullable<Observable<unknown> | void>>) {
    const actionResult$ = actionCallback$?.();
    if (!isObservable(actionResult$)) return;
    this.action$ = actionResult$.pipe(finalize(() => (this.action$ = null)));
  }
}
