import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Nullable } from '@lib-utils';

interface ValueAccessor extends ControlValueAccessor {
  onChange: (value: Nullable<string>) => Nullable<string>;
}

@Directive({
  selector: '[fnipUppercaseInput]',
})
export class UppercaseInputDirective implements OnInit {
  @Input() set fnipUppercaseInput(isUppercaseInput: boolean | string) {
    this.isUppercaseInput = typeof isUppercaseInput === 'boolean' ? isUppercaseInput : true;
  }

  private isUppercaseInput = true;

  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    const accessor = this.ngControl.valueAccessor as unknown as ValueAccessor;
    const initialOnChange = accessor.onChange;
    accessor.onChange = (value: Nullable<string>) => initialOnChange(this.processInput(value));
  }

  private processInput(value: Nullable<string>): Nullable<string> {
    return this.isUppercaseInput ? (value ?? '').toUpperCase() : value;
  }

  @HostListener('ngModelChange', ['$event'])
  onValueChanged(value: string | undefined) {
    (this.ngControl.valueAccessor as ControlValueAccessor).writeValue(this.processInput(value));
  }
}
