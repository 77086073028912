/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { PhysicalPersonModel } from './physicalPersonModel';
import { DateOnly } from '@lib-utils';
import { DocumentType } from './documentType';
import { DateTime } from '@lib-utils';


export interface DocumentModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
    documentType?: DocumentType;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDepCode?: string | null;
    issueDate?: DateOnly | null;
    expiryDate?: DateTime | null;
    documentComment?: string | null;
    isInitial?: boolean;
    isActive?: boolean;
    hasPreviousDocumentRecord?: boolean;
}
export namespace DocumentModel {
}


