import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiLineClampModule } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';

@Component({
  selector: 'fnip-expandable-content',
  templateUrl: './expandable-content.component.html',
  styleUrls: ['./expandable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TuiLineClampModule, TuiSvgModule],
})
export class ExpandableContentComponent {
  content = input.required<PolymorpheusContent>();
  minLines = input(1);
  maxLines = input(9999);
  buttonPosition = input<'bottom' | 'right'>('bottom');
  buttonName = input('Показать полностью');

  protected expanded = signal(false);
  protected overflown = signal(false);

  protected expandVisible = computed(() => this.overflown() || this.expanded());
  protected visibleLines = computed(() => (this.expanded() ? this.maxLines() : this.minLines()));
  protected expandButtonName = computed(() => (this.expanded() ? 'Свернуть' : this.buttonName()));
  protected expandButtonIcon = computed(() => (this.expanded() ? 'tuiIconChevronUp' : 'tuiIconChevronDown'));
  protected showHint = computed(() => this.overflown() && this.expanded());
}
