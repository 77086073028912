import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiLoaderModule, TuiScrollbarModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiMultiSelectModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { IndexChangeDirective } from '../reactive-select/index-change.directive';
import { ReactiveMultiSelectComponent } from './reactive-multi-select.component';

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveFormsModule,
    ReactiveFieldErrorModule,
    ReactiveLabelModule,
    TuiDataListModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    OverlayModule,
    TuiScrollbarModule,
    TuiValueChangesModule,
    IndexChangeDirective,
    TestAttributesDirective,
  ],
  declarations: [ReactiveMultiSelectComponent],
  exports: [ReactiveMultiSelectComponent],
})
export class ReactiveMultiSelectModule {}
