import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiDataListModule, TuiHostedDropdownModule } from '@taiga-ui/core';
import { ButtonModule } from '../button';
import { ButtonMenuDropdownComponent } from './button-menu-dropdown.component';

@NgModule({
  declarations: [ButtonMenuDropdownComponent],
  exports: [ButtonMenuDropdownComponent],
  imports: [CommonModule, TuiHostedDropdownModule, TuiDataListModule, ButtonModule],
})
export class ButtonMenuDropdownModule {}
