<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-toggle
    #toggle
    class="flex-shrink-0"
    [formControl]="$any(control)"
    [size]="size"
    [singleColor]="singleColor"
    (tuiValueChanges)="valueChange.emit($event)"
  ></tui-toggle>
  <fnip-ellipsis-hint
    *ngIf="hasToggleLabel"
    class="toggle-label"
    [content]="staticToggleLabel || (getToggleLabel | executeWith: toggle.value)"
  />
</fnip-reactive-label>
