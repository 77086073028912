/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankDecisionStatus } from './bankDecisionStatus';
import { ApplicationStatus } from './applicationStatus';
import { ApplicationRelationDto } from './applicationRelationDto';
import { DateTime } from '@lib-utils';


export interface ApplicationItemDto { 
    id?: number;
    readonly fullName?: string | null;
    status?: ApplicationStatus;
    bankName?: string | null;
    decision?: BankDecisionStatus;
    comment?: string | null;
    created?: DateTime;
    updated?: DateTime;
    relations?: Array<ApplicationRelationDto> | null;
    creditAmount?: number | null;
    salePoint?: string | null;
}
export namespace ApplicationItemDto {
}


