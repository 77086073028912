import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { FeHttpError } from '../../classes';
import { LoggerService } from '../logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly loggerService = inject(LoggerService);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    if (error instanceof Error || error instanceof FeHttpError || error instanceof HttpErrorResponse) {
      this.loggerService.error(error);
    }
    console.error(error);
  }
}
