import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Nullable } from '../../interfaces';

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Nullable<Date | string | number>, format: string, timezone?: string): string | null {
    if (!value) return null;

    const locale = this.translateService.currentLang;

    return formatDate(value, format, locale, timezone);
  }
}
