export interface SidebarItem {
  type: string;
  title: string;
  count: number | null;
}

export interface SidebarGroup {
  type: string;
  title: string;
  items: SidebarItem[];
  expanded: boolean;
}
