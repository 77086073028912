import { Directive } from '@angular/core';
import { TUI_DATE_VALUE_TRANSFORMER } from '@taiga-ui/kit';
import { ToUtcDateTransformer } from './to-utc-date-transformer';

@Directive({
  selector: 'tui-input-date[fnipToUtcDate]',
  providers: [
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useClass: ToUtcDateTransformer,
    },
  ],
})
export class ToUtcDateDirective {}
