import { TuiDay } from '@taiga-ui/cdk';
import { format } from 'date-fns';
import { Nullable } from '../../interfaces/types/nullable';
import { toDate } from '../to-date';

export const toDateOnlyFormat = (input: Nullable<Date | TuiDay | string>) => {
  if (!input) return undefined;
  const date = toDate(input);

  return format(date, 'yyyy-MM-dd');
};
