/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { RepresentativeOrganizationMappingModel } from './representativeOrganizationMappingModel';
import { PhysicalPersonModel } from './physicalPersonModel';
import { DateOnly } from '@lib-utils';
import { RepresentativeType } from './representativeType';
import { RepresentativeDocumentType } from './representativeDocumentType';
import { DateTime } from '@lib-utils';
import { RepresentativePhysicalPersonMappingModel } from './representativePhysicalPersonMappingModel';


export interface RepresentativeModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
    type?: RepresentativeType;
    documentType?: RepresentativeDocumentType;
    name?: string | null;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDate?: DateOnly | null;
    special?: string | null;
    organizationMappings?: Array<RepresentativeOrganizationMappingModel> | null;
    physicalPersonMappings?: Array<RepresentativePhysicalPersonMappingModel> | null;
}
export namespace RepresentativeModel {
}


