/**
 * BFF
 * Octasoft NestJS service
 *
 * The version of the OpenAPI document: 4.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthUserRoleDto } from './authUserRoleDto';

export interface AuthCurrentUserDto {
  sex?: AuthCurrentUserDto.SexEnum;
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  fullName?: string | null;
  email?: string | null;
  additionalEmails?: Array<string> | null;
  position?: string | null;
  phone?: string | null;
  role?: AuthUserRoleDto;
}
export namespace AuthCurrentUserDto {
  export type SexEnum = 'Male' | 'Female' | 'Undefined';
  export const SexEnum = {
    Male: 'Male' as SexEnum,
    Female: 'Female' as SexEnum,
    Undefined: 'Undefined' as SexEnum,
  };
}
