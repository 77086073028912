import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiToggleModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { EllipsisHintComponent } from '@lib-widgets/core';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveToggleComponent } from './reactive-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveFormsModule,
    ReactiveLabelModule,
    TuiToggleModule,
    EllipsisHintComponent,
    TuiValueChangesModule,
  ],
  declarations: [ReactiveToggleComponent],
  exports: [ReactiveToggleComponent],
})
export class ReactiveToggleModule {}
