import { Component, Input } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Nullable } from '@lib-utils';

@Component({
  selector: 'fnip-request-wrapper',
  template: `
    <ng-container *ngIf="updatedRequest$ | async"></ng-container>
    <ng-content></ng-content>
  `,
})
export class RequestWrapperComponent {
  @Input() set request$(rq$: Nullable<Observable<unknown>>) {
    this.updatedRequest$ = rq$?.pipe(tap((data) => (this.data = data)));
  }

  updatedRequest$: Nullable<Observable<unknown>>;

  data: unknown;
}
