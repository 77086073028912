import { Observable } from 'rxjs';
import { Nullable, SelectOption, SelectOptions } from '@lib-utils';

export interface SetUserDialogContext<T> {
  title: Nullable<string>;
  selectPlaceholder: Nullable<string>;
  actionLabel: Nullable<string>;
  initialValue: Nullable<T>;
  options: Nullable<SelectOptions>;
  initialOptions: Nullable<SelectOption[]>;
  actionCallback$: Nullable<(id: Nullable<number>) => Nullable<Observable<unknown> | void>>;
}
