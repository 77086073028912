/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { ObjectType } from './objectType';
import { CurrencyModel } from './currencyModel';
import { CreditProgramType } from './creditProgramType';
import { BankModel } from './bankModel';
import { CreditProductFormulaModel } from './creditProductFormulaModel';
import { CreditPurpose } from './creditPurpose';
import { DateTime } from '@lib-utils';


export interface CreditProductModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    rate?: number;
    name?: string | null;
    purpose?: Array<CreditPurpose> | null;
    objectType?: Array<ObjectType> | null;
    currencyAlphaCode?: string | null;
    currency?: CurrencyModel;
    bankId?: number | null;
    bank?: BankModel;
    creditProgram?: CreditProgramType;
    isActive?: boolean;
    discount?: boolean;
    code?: string | null;
    nonStandard?: boolean;
    defaultFlexPrice?: number | null;
    isAbsoluteRateAvailable?: boolean;
    isStateSubsidyRequired?: boolean;
    conditions?: Array<CreditProductFormulaModel> | null;
}
export namespace CreditProductModel {
}


