import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiSizeL, TuiSizeM } from '@taiga-ui/core';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-count',
  templateUrl: './reactive-input-count.component.html',
  styleUrls: [`./reactive-input-count.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputCountComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<number>>>;

  @Input() step = 1;
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() postfix = '';
  @Input() hideButtons = false;
  @Input() textfieldIconLeft = '';
  @Input() override textFieldSize: TuiSizeM | TuiSizeL = 'm';
}
