export const LOADER_DEFAULT_ERROR_MESSAGE = 'Что-то пошло не так';

export const LOADER_LOADING_TEXT = 'Загрузка ...';

export const LOADER_RETRY_TEXT = 'Повторить';

export const LoaderAppearance = {
  Inline: 'inline',
  Normal: 'normal',
  Fullscreen: 'fullscreen',
} as const;

export type LoaderAppearance = 'inline' | 'normal' | 'fullscreen';
