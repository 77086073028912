/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { VerificatorType } from './verificatorType';
import { VerificationTaskModel } from './verificationTaskModel';
import { VerificatorStatus } from './verificatorStatus';
import { DateTime } from '@lib-utils';


export interface VerificatorModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    userId?: number;
    user?: UserModel;
    status?: VerificatorStatus;
    statusSetDate?: DateTime;
    tasks?: Array<VerificationTaskModel> | null;
    type?: VerificatorType;
    isActive?: boolean;
}
export namespace VerificatorModel {
}


