import get from 'lodash-es/get';
import { SelectOption } from '../../interfaces/select-option';
import { Nullable } from '../../interfaces/types';
import { OptionsConfig } from './utils';

export function beToFeOptions<T extends { id?: Nullable<number>; name?: Nullable<string> }, TVal = unknown>(
  options: Nullable<T[]>,
): SelectOption<T, TVal>[];
export function beToFeOptions<T extends object, TVal = unknown>(
  options: Nullable<T[]>,
  config: OptionsConfig<T>,
): SelectOption<T, TVal>[];
export function beToFeOptions<T extends object, TVal = unknown>(
  options: Nullable<T[]>,
  config?: OptionsConfig<T>,
): SelectOption<T, TVal>[] {
  return (
    options?.map((o, idx) => {
      const label: string = config?.labelCallback
        ? config.labelCallback(o, idx)
        : get(o, config?.labelPath ?? 'name') ?? '';
      const value = config?.valueCallback ? config.valueCallback(o) : get(o, config?.valuePath ?? 'id');

      return {
        value,
        label,
        ...o,
        disabled: config?.disabledCallback ? config?.disabledCallback(o) : false,
      };
    }) || []
  );
}
