import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SIDEBAR_SERVICE_TOKEN } from '@lib-tokens';
import { SidebarComponent } from './sidebar.component';
import { SidebarDirective } from './sidebar.directive';
import { SidebarService } from './sidebar.service';

@NgModule({
  imports: [CommonModule, TuiLetModule],
  declarations: [SidebarComponent, SidebarDirective],
  exports: [SidebarComponent, SidebarDirective],
})
export class SidebarModule {
  static forRoot(): ModuleWithProviders<SidebarModule> {
    return {
      ngModule: SidebarModule,
      providers: [
        {
          provide: SIDEBAR_SERVICE_TOKEN,
          useClass: SidebarService,
        },
      ],
    };
  }
}
