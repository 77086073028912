import { AutoFocusTarget } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { TemplateRef } from '@angular/core';

export type DialogComponentType<TComponent = unknown> = ComponentType<TComponent> | TemplateRef<TComponent>;
export interface DialogOptions<TContextData> {
  contextData?: TContextData;
  hostOptions?: Partial<DialogHostOptions>;
}

// Опции для отображения контейнера диалога (dialog-host)
export type DialogHostSize = 'fullscreen' | 's' | 'm' | 'l' | 'auto';

export interface DialogHostOptions {
  label?: string;
  size: DialogHostSize;
  isCloseable: boolean;
  isDismissible: boolean;
  autoFocus?: AutoFocusTarget | string | boolean;
}
