import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core';
import { Nullable } from '../../interfaces';

@Directive({
  selector: '[fnipAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input() fnipAutofocus: Nullable<boolean>;

  private readonly el = inject(ElementRef);

  ngAfterViewInit() {
    if (this.fnipAutofocus) setTimeout(() => this.el.nativeElement.focus(), 0);
  }
}
