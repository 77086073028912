import { FeHttpError } from '@lib-utils';

export function isFeHttpError(error: unknown): error is FeHttpError {
  return error instanceof FeHttpError;
}

export const DEFAULT_CONFIG = {
  useValue: {
    showErrors: false,
  },
};
