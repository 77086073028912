/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AddressCompletenessType = 'Complete' | 'NoRegion' | 'NoCity' | 'NoStreet' | 'NoHouse' | 'NoFlat' | 'NotFull' | 'Foreign' | 'PoBox' | 'PossiblyMistake' | 'NoFiasHouse';

export const AddressCompletenessType = {
    Complete: 'Complete' as AddressCompletenessType,
    NoRegion: 'NoRegion' as AddressCompletenessType,
    NoCity: 'NoCity' as AddressCompletenessType,
    NoStreet: 'NoStreet' as AddressCompletenessType,
    NoHouse: 'NoHouse' as AddressCompletenessType,
    NoFlat: 'NoFlat' as AddressCompletenessType,
    NotFull: 'NotFull' as AddressCompletenessType,
    Foreign: 'Foreign' as AddressCompletenessType,
    PoBox: 'PoBox' as AddressCompletenessType,
    PossiblyMistake: 'PossiblyMistake' as AddressCompletenessType,
    NoFiasHouse: 'NoFiasHouse' as AddressCompletenessType
};

