/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthorizerRejectionType = 'SignificantNegativeFromCronos' | 'PrecariousEmployment' | 'SuspicionOfFalsificationOfDocuments' | 'SuspicionOfFraud' | 'SignificantNegativeFromBKI' | 'NegativeFromVerusOrGriffid' | 'SpecificBusinessArea' | 'UnableToVerifyBusinessIncome' | 'AnotherReason';

export const AuthorizerRejectionType = {
    SignificantNegativeFromCronos: 'SignificantNegativeFromCronos' as AuthorizerRejectionType,
    PrecariousEmployment: 'PrecariousEmployment' as AuthorizerRejectionType,
    SuspicionOfFalsificationOfDocuments: 'SuspicionOfFalsificationOfDocuments' as AuthorizerRejectionType,
    SuspicionOfFraud: 'SuspicionOfFraud' as AuthorizerRejectionType,
    SignificantNegativeFromBki: 'SignificantNegativeFromBKI' as AuthorizerRejectionType,
    NegativeFromVerusOrGriffid: 'NegativeFromVerusOrGriffid' as AuthorizerRejectionType,
    SpecificBusinessArea: 'SpecificBusinessArea' as AuthorizerRejectionType,
    UnableToVerifyBusinessIncome: 'UnableToVerifyBusinessIncome' as AuthorizerRejectionType,
    AnotherReason: 'AnotherReason' as AuthorizerRejectionType
};

