import { Injectable } from '@angular/core';
import { Nullable } from '../../../interfaces/types';
import { FilterStateService } from '../filter-state.abstract';
import { DeepPartial, FilterState, FilterStateOrder, Pagination } from '../filter-state.model';

@Injectable()
export class SessionStorageFilterStateService extends FilterStateService {
  getState<TFilters>(defaultPagination: Pagination = { page: 1, perPage: 10 }): FilterState<TFilters> {
    const { filters, pagination, order } = this.getStateFromSessionStorage<TFilters>(this.pageKey) ?? {};

    return {
      filters: (filters ?? {}) as DeepPartial<TFilters>,
      pagination: {
        page: pagination?.page ?? defaultPagination.page,
        perPage: pagination?.perPage ?? defaultPagination.perPage,
      },
      order,
    };
  }

  updateState<TFilters>(
    filters: Nullable<DeepPartial<TFilters>>,
    pagination: Nullable<Pagination>,
    order: Nullable<FilterStateOrder>,
  ): void {
    this.setStateToSessionStorage(this.pageKey, {
      pagination: pagination ?? undefined,
      filters: filters ?? {},
      order: order ?? undefined,
    });
  }

  override resetState() {
    sessionStorage.removeItem(this.pageKey);
  }

  static resetAll() {
    const keys = this.stateKeys;
    for (const key of keys) {
      sessionStorage.removeItem(key);
    }
  }

  private get pageKey() {
    return `filter-state_${window.btoa(this.pageAddress)}`;
  }

  static get stateKeys() {
    return Object.keys(sessionStorage).filter((key) => key.startsWith('filter-state_'));
  }

  private getStateFromSessionStorage<T>(key: string): Partial<FilterState<T>> | undefined {
    const state = sessionStorage.getItem(key);
    try {
      return state ? (JSON.parse(state) as Partial<FilterState<T>>) : undefined;
    } catch (e) {
      console.error('Error while parsing local storage', e);
      // Reset session storage if it is corrupted
      sessionStorage.removeItem(key);
      return undefined;
    }
  }

  private setStateToSessionStorage<T>(key: string, state: Partial<FilterState<T>>) {
    sessionStorage.setItem(key, JSON.stringify(state));
  }
}
