import { CommonModule, formatCurrency, formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputSliderModule, TuiSelectModule } from '@taiga-ui/kit';
import isNil from 'lodash-es/isNil';
import {
  ExecuteWithPipeModule,
  getPercentFromValue,
  getValueFromPercent,
  Nullable,
  reactiveTestAttributesHostDirective,
} from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';

export enum PercentageInputMode {
  Number,
  Percent,
}

@Component({
  selector: 'fnip-reactive-input-number-percentage',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ExecuteWithPipeModule,
    TuiInputSliderModule,
    TuiTextfieldControllerModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiValueChangesModule,
  ],
  templateUrl: './reactive-input-number-percentage.component.html',
  styleUrls: ['./reactive-input-number-percentage.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputNumberPercentageComponent extends AbstractReactive implements OnInit, OnChanges {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<number>>>;
  @Input() percentControl? = new FormControl<Nullable<number>>(0);
  @Input() minPercent = 0;
  @Input() maxPercent = 100;
  @Input() quantum = 0.01;
  @Input() total = 0;
  @Input() currencyHint: 'руб' | '₽' = 'руб';

  // Флаг, отключающий синхронизацию значений control и percentControl при изменении одного из них, а также при изменении total
  @Input() manualSync = false;
  @Input() mode = PercentageInputMode.Number;
  // Флаг, отключающий эмит valueChange control и percentControl при автоматической синхронизации значений
  @Input() disableControlEvents = false;

  @Output() modeChange = new EventEmitter<PercentageInputMode>();
  @Output() percentChange = new EventEmitter<Nullable<number>>(true);

  PercentageInputMode = PercentageInputMode;
  modeMap: Record<PercentageInputMode, string> = {
    [PercentageInputMode.Number]: '₽',
    [PercentageInputMode.Percent]: '%',
  };

  min = 0;

  ngOnInit() {
    if (!this.control || !this.percentControl) return;
    this.percentControl.setValue(getPercentFromValue(this.control.value, this.total), {
      emitEvent: !this.disableControlEvents,
    });
  }

  getNumberLimit = (total: number, limit: number) => (total * limit) / 100;

  disablePercentControlHandler = (disabled: Nullable<boolean>) => {
    if (disabled && this.percentControl?.enabled) this.percentControl.disable();
    if (!disabled && this.percentControl?.disabled) this.percentControl.enable();
  };

  onPercentChange(control: FormControl<Nullable<number>>, percentControl: FormControl<Nullable<number>>) {
    this.percentChange.emit(percentControl.value);

    if (this.mode === PercentageInputMode.Number || this.manualSync) return;

    const value = getValueFromPercent(percentControl.value, this.total);
    control.setValue(value, { emitEvent: !this.disableControlEvents });
    this.valueChange.emit(value);
  }

  onValueChange(control: FormControl<Nullable<number>>, percentControl: FormControl<Nullable<number>>) {
    this.valueChange.emit(control.value);

    if (this.mode === PercentageInputMode.Percent || this.manualSync) return;

    const value = getPercentFromValue(control.value, this.total);
    percentControl.setValue(value, { emitEvent: !this.disableControlEvents });
    this.percentChange.emit(value);
  }

  onTotalChange = (_total: Nullable<number>) => {
    if (!this.control || !this.percentControl || this.manualSync) return;

    this.control.setValue(
      this.getValueInLimitRange(
        this.control?.value,
        this.getNumberLimit(this.total, this.minPercent),
        this.getNumberLimit(this.total, this.maxPercent),
      ),
      { emitEvent: !this.disableControlEvents },
    );

    this.onValueChange(this.control, this.percentControl);
    this.onPercentChange(this.control, this.percentControl);
  };

  onPercentRangeChange = (minPercent: number, maxPercent: number) => {
    if (!this.control || !this.percentControl || this.manualSync) return;

    this.percentControl.setValue(this.getValueInLimitRange(this.percentControl?.value, minPercent, maxPercent), {
      emitEvent: !this.disableControlEvents,
    });
    this.control.setValue(
      this.getValueInLimitRange(
        this.control?.value,
        this.getNumberLimit(this.total, this.minPercent),
        this.getNumberLimit(this.total, this.maxPercent),
      ),
      { emitEvent: !this.disableControlEvents },
    );

    this.onValueChange(this.control, this.percentControl);
    this.onPercentChange(this.control, this.percentControl);
  };

  getValueInLimitRange = (currentValue: Nullable<number>, min: number, max: number) => {
    if (isNil(currentValue)) return;
    return Math.max(min, Math.min(currentValue, max));
  };

  getSteps = (min: number, total: number) => Math.floor(Math.abs(total - min)) / this.quantum;

  getHint = (
    mode: PercentageInputMode,
    controlValue: Nullable<number>,
    percentControlValue: Nullable<number>,
    min: number,
    minPercent: number,
  ) => {
    const hints = [];

    if (mode === PercentageInputMode.Percent) {
      hints.push(formatCurrency(controlValue || 0, 'ru', this.currencyHint, 'symbol', '1.0-2'));
      minPercent && hints.push(`(min ${formatNumber(minPercent, 'ru', '1.0-2')}%)`);
    }

    if (mode === PercentageInputMode.Number) {
      hints.push(`${formatNumber(percentControlValue || 0, 'ru', '1.0-2')}%`);
      min && hints.push(`(min ${formatCurrency(min, 'ru', this.currencyHint, 'symbol', '1.0-2')})`);
    }

    return hints.join(' ');
  };
}
