/**
 * .C - Create
 * .L - List
 * .R - Read (Если есть List, то должен быть. Решили, что проверки на .L достаточно)
 * .U - Update
 * .D - Delete
 */
export const PermissionOperationMap = {
  AllModulesAvailable: ['CABINET.ALL'],

  AdminModuleAvailable: ['CABINET.ADMINISTRATOR'],

  ArchiveModuleAvailable: ['CABINET.ARCHIVE'],

  ordersModuleAvailable: ['CABINET.PARTNER_MANAGER'],

  VerificatorModuleAvailable: ['CABINET.VERIFICATOR'],

  VerificatorManagerModuleAvailable: ['CABINET.VERIFICATOR_ADMINISTRATOR'],

  AuthorizerModuleAvailable: ['ATZ.Read'],

  UnderwriterModuleAvailable: ['UND.Read'],

  CrmCollectionModuleAvailable: ['CABINET.CRM_COLLECTION'],

  LawyerModuleAvailable: ['CABINET.LAWYER'],

  AutoModuleAvailable: ['CABINET.AUTO'],

  AutoRiskModuleAvailable: ['CABINET.SPR.AUTO'],

  AutoAdminModuleAvailable: ['CABINET.ADMIN.AUTO'],

  AutoInspectorModuleAvailable: ['CABINET.INSPECTOR.AUTO'],

  DealDocumentsVerificatorModuleAvailable: ['CABINET.DEAL_DOCUMENTS'],

  /** Получить список "Кредитный договор" */
  crmContractList: ['CRM_COLLECTION.Contract.L'],

  /** Обновить "Коллекшн сегмент" в кредитном договоре */
  crmContractSegmentUpdate: ['CRM_COLLECTION.Contract.Segment.U'],

  /** Получить список "Список обзвона" */
  crmCallListList: ['CRM_COLLECTION.CallList.L'],

  /** Обновить "Список обзвона" */
  crmCallListUpdate: ['CRM_COLLECTION.CallList.U'],

  /** Создать "Список обзвона" */
  crmCallListCreate: ['CRM_COLLECTION.CallList.C'],

  /** Удалить "Список обзвона" */
  crmCallListDelete: ['CRM_COLLECTION.CallList.D'],

  /** Получить список "Шаблон" */
  crmTemplateList: ['CRM_COLLECTION.Template.L'],

  /** Создать "Шаблон" */
  crmTemplateCreate: ['CRM_COLLECTION.Template.C'],

  /** Получить список "Шаблон" или "Стратегия автоинструментов" */
  crmNotificationList: ['CRM_COLLECTION.Template.L', 'CRM_COLLECTION.Strategy.L'],

  /** Обновить "Шаблон" */
  crmTemplateUpdate: ['CRM_COLLECTION.Template.U'],

  /** Удалить "Шаблон" */
  crmTemplateDelete: ['CRM_COLLECTION.Template.D'],

  /** Получить список "Коллекшн сегмент" */
  crmSegmentList: ['CRM_COLLECTION.Segment.L'],

  /** Создать "Коллекшн сегмент" */
  crmSegmentCreate: ['CRM_COLLECTION.Segment.C'],

  /** Обновить "Коллекшн сегмент" */
  crmSegmentUpdate: ['CRM_COLLECTION.Segment.U'],

  /** Удалить "Коллекшн сегмент" */
  crmSegmentDelete: ['CRM_COLLECTION.Segment.D'],

  /** Получить список "Стратегия автоинструментов" */
  crmStrategyList: ['CRM_COLLECTION.Strategy.L'],

  /** Создать "Стратегия автоинструментов" */
  crmStrategyCreate: ['CRM_COLLECTION.Strategy.C'],

  /** Обновить "Стратегия автоинструментов" */
  crmStrategyUpdate: ['CRM_COLLECTION.Strategy.U'],

  /** Удалить "Стратегия автоинструментов" */
  crmStrategyDelete: ['CRM_COLLECTION.Strategy.D'],

  /** Получить список "Уровень взыскания" */
  crmLevelList: ['CRM_COLLECTION.Level.L'],

  /** Создать "Уровень взыскания" */
  crmLevelCreate: ['CRM_COLLECTION.Level.C'],

  /** Обновить "Уровень взыскания" */
  crmLevelUpdate: ['CRM_COLLECTION.Level.U'],

  /** Удалить "Уровень взыскания" */
  crmLevelDelete: ['CRM_COLLECTION.Level.D'],

  /** Получить список "Результат активности" */
  crmResultActivityList: ['CRM_COLLECTION.ResultActivity.L'],

  /** Создать "Результат активности" */
  crmResultActivityCreate: ['CRM_COLLECTION.ResultActivity.C'],

  /** Обновить "Результат активности" */
  crmResultActivityUpdate: ['CRM_COLLECTION.ResultActivity.U'],

  /** Удалить "Результат активности" */
  crmResultActivityDelete: ['CRM_COLLECTION.ResultActivity.D'],

  /** Получить список "Черный список" */
  crmBlackListList: ['CRM_COLLECTION.BlackList.L'],

  /** Создать "Черный список" */
  crmBlackListCreate: ['CRM_COLLECTION.BlackList.C'],

  /** Обновить "Черный список" */
  crmBlackListUpdate: ['CRM_COLLECTION.BlackList.U'],

  /** Удалить "Черный список" */
  crmBlackListDelete: ['CRM_COLLECTION.BlackList.D'],

  /** Получить список "Активность" */
  crmActivityList: ['CRM_COLLECTION.Activity.L'],

  /** Создать "Активность" */
  crmActivityCreate: ['CRM_COLLECTION.Activity.C'],

  /** Обновить "Активность" */
  crmActivityUpdate: ['CRM_COLLECTION.Activity.U'],

  /** Удалить "Активность" */
  crmActivityDelete: ['CRM_COLLECTION.Activity.D'],

  /** Получить список "Документы" */
  crmDocumentList: ['CRM_COLLECTION.Document.L'],

  /** Получить список "Контакт" */
  crmContactList: ['CRM_COLLECTION.Contact.L'],

  /** Получить список "Платежи" */
  crmPaymentList: ['CRM_COLLECTION.Payment.L'],

  /** Получить список "Договор обеспечения" */
  crmCollateralAgreementList: ['CRM_COLLECTION.CollateralAgreement.L'],

  /** Получить список "Счет" */
  crmAccountList: ['CRM_COLLECTION.Account.L'],

  /** Получить список "Банкротство" */
  crmBankruptcyList: ['CRM_COLLECTION.Bankruptcy.L'],

  /** Получить список "Заявки" */
  crmApplicationList: ['CRM_COLLECTION.Application.L'],

  /** Получить список "Исполнительное производство" */
  crmEnforcementProceedingsList: ['CRM_COLLECTION.EnforcementDeal.L'],

  /** Получить список "Судебное производство" */
  crmJudicialProceedingsList: ['CRM_COLLECTION.CourtDeal.L'],

  nonStandardOrderList: ['APL.NonStandard.C'],

  /** Обработка задач андеррайтинга */
  canPerformUnderwriterTask: ['UND.Task.Perform'],

  /** Управление задачами андеррайтинга */
  canManageUnderwriterTask: ['UND.Task.Manage'],

  /** Обработка задач Авторизатора 1 */
  canPerformFirstAuthorizerTask: ['ATZ.F.Task.Perform'],

  /** Управление задачами Авторизатора 1 */
  canManageFirstAuthorizerTask: ['ATZ.F.Task.Manage'],

  /** Обработка задач Авторизатора 2 */
  canPerformSecondAuthorizerTask: ['ATZ.S.Task.Perform'],

  /** Управление задачами Авторизатора 2 */
  canManageSecondAuthorizerTask: ['ATZ.S.Task.Manage'],

  /** Обработка задач Авторизатора */
  canPerformAuthorizerTask: ['ATZ.F.Task.Perform', 'ATZ.S.Task.Perform'],

  /** Получить отчет Cronos */
  canSeeCronosReport: ['REP.SPR.Cronos.R'],

  /** Получить отчет Verus */
  canSeeVerusReport: ['REP.SPR.Verus.R'],

  /** Получить отчет Grifid */
  canSeeGrifidReport: ['REP.SPR.Grifid.R'],

  /** Может создать заявку */
  canCreateOrder: ['APL.Order.C'],

  /** Может видеть кнопки Обращение в РР и УКЭП */
  orderAppealButtons: ['APL.Appeal.R'],
  /** Может создать обращение в РР */
  canGenerateAppeal: ['APL.Appeal.C'],
  /** Может создать УКЭП клиенту */
  canGenerateClientSign: ['CLI.Sign.C'],

  /** Может читать комментарии */
  canReadComments: ['APL.Comments.L'],
  /** Может постить комментарии */
  canPostComments: ['APL.Comments.U'],

  canSpecifyManager: ['APL.Manager.Specify'],

  canSpecifyPersonalManager: ['APL.PersonalManager.Assign'],

  /** Есть возможность просматривать историю изменений заявки */
  canViewOrderHistory: ['APL.Changes.R'],

  /** Может удалить досье, созданное вручную */
  canDeleteManualDossier: ['EA.DossierManual.Delete'],

  /** Может редактировать ФИО клиента в досье  */
  canEditDossierBorrower: ['EA.Dossier.UpdateBorowerName'],

  /** Может видеть поле Сопровождение досье */
  canSeeAcceptedToSupport: ['EA.Dossier.ShowAcceptedForSupportField'],

  /** Показ истории изменений для Верификатора сделки */
  canViewChangesForVerificatorDeal: ['APL.Changes.DealVerification.R'],

  /** Может подтвердить/отклонить задачи в статусе RedirectedToSupervision */
  canReviewTaskInRedirectedToSupervisionStatus: ['VER.Supervision.U'],

  /** Может принимать решения по задаче верификации сделки */
  canApproveDealVerification: ['DEAL_VER.Approve'],

  /** Может ставить задачу верификации на паузу */
  canPauseTaskVerification: ['VER.Task.P'],
};

export type PermissionOperationMapKeys = keyof typeof PermissionOperationMap;
