import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmationDialogType, DialogService } from '@lib-widgets/dialog';
import { PermissionService } from '../../services/permission';
import { CURRENT_USER, getLogout$ } from '../../utils';

export const permissionGuardFn: CanActivateFn = (route) => {
  const router = inject(Router);
  const currentUser = inject(CURRENT_USER);
  const permissionService = inject(PermissionService);
  const dialogService = inject(DialogService);
  const logout$ = getLogout$();

  const previousUrl = router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  const permission = route.data['requiredPermission'];

  if (typeof permission === 'undefined') {
    // Skip check if field is empty
    return true;
  }

  return currentUser.pipe(
    map(() => permissionService.checkPermissionFor(permission)),
    switchMap((hasPermission) => {
      if (hasPermission) return of(true);
      if (previousUrl?.includes('auth/login')) return of(router.createUrlTree(['']));
      return dialogService
        .openConfirmation<boolean>({
          contextData: {
            type: ConfirmationDialogType.Question,
            title: 'Ошибка авторизации',
            text: 'Вы пытаетесь перейти на страницу, к которой у вас нет доступа. Хотите сменить пользователя?',
            okText: 'Сменить пользователя',
            cancelText: 'Перейти на главную',
          },
        })
        .pipe(switchMap((isOk) => (isOk ? logout$(true) : of(router.createUrlTree([''])))));
    }),
  );
};
