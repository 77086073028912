/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BuildingAccreditationStatus = 'Accredited' | 'NotAccredited' | 'Refinement' | 'UnderConsideration' | 'Rework' | 'Archive';

export const BuildingAccreditationStatus = {
    Accredited: 'Accredited' as BuildingAccreditationStatus,
    NotAccredited: 'NotAccredited' as BuildingAccreditationStatus,
    Refinement: 'Refinement' as BuildingAccreditationStatus,
    UnderConsideration: 'UnderConsideration' as BuildingAccreditationStatus,
    Rework: 'Rework' as BuildingAccreditationStatus,
    Archive: 'Archive' as BuildingAccreditationStatus
};

