/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface EmploymentHistoryPageRecognitionDto { 
    fullName?: string | null;
    jobStart?: DateOnly | null;
    sign?: boolean;
    stamp?: boolean;
    fileId?: string | null;
    taskId?: string | null;
    issueDate?: DateOnly | null;
    copyIsRight?: boolean;
    worksToDate?: boolean;
}

