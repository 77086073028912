/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { AddressModel } from './addressModel';
import { BankAccountType } from './bankAccountType';
import { DateTime } from '@lib-utils';
import { OwnerModel } from './ownerModel';


export interface OwnerRequisitesModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    type?: BankAccountType;
    accountNumber?: string | null;
    bankName?: string | null;
    bankBik?: string | null;
    bankAddressId?: number;
    bankAddress?: AddressModel;
    bankCorAccount?: string | null;
    taxNumber?: string | null;
    bankKpp?: string | null;
    letterOfCreditAmount?: number | null;
    ownerId?: number | null;
    owner?: OwnerModel;
}
export namespace OwnerRequisitesModel {
}


