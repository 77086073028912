import {
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  inject,
  input,
  OnChanges,
  output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { Nullable } from '../../interfaces';

@Directive({
  standalone: true,
  selector: '[fnipExpandable]',
  exportAs: 'fnipExpandable',
})
export class ExpandableDirective implements OnChanges {
  name = input<Nullable<string>>();
  isOpen = input(false, { transform: booleanAttribute });

  isOpenChange = output<boolean>();
  readonly openState = signal<boolean>(false);

  private chDRef = inject(ChangeDetectorRef);

  setOpen(value: boolean, skipEvent = false) {
    this.openState.set(value);
    this.chDRef.detectChanges();

    if (!skipEvent) this.isOpenChange.emit(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('isOpen' in changes) {
      this.setOpen(this.isOpen(), changes['isOpen'].isFirstChange());
    }
  }
}
