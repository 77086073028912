import { inject, InjectionToken } from '@angular/core';
import { AuthApiService } from '@lib-bff/api';
import { distinctUntilChanged, map, of, shareReplay, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoggerService } from '@lib-utils';
import { PermissionInfoDto, RoleInfoDto, UserInfoDto } from '@lib-mortgage/api';
import { AuthorizationStorageService, PermissionService } from '../services';
import { TOKEN_STORE, TokenStore } from './token-store.provider';

export type UserInfoBff = UserInfoDto & {
  roles: RoleInfoDto[];
  permissions: PermissionInfoDto[];
};

export type CurrentUser = ReturnType<typeof currentUserFactory>;

export const CURRENT_USER = new InjectionToken<CurrentUser>('CURRENT_USER', {
  providedIn: 'root',
  factory: () => {
    const permissionService = inject(PermissionService);
    const authorizationStorageService = inject(AuthorizationStorageService);
    const authApiService = inject(AuthApiService);
    const tokenStore = inject(TOKEN_STORE);
    const loggerService = inject(LoggerService);

    return currentUserFactory(
      permissionService,
      authorizationStorageService,
      loggerService,
      authApiService,
      tokenStore,
    );
  },
});

export function currentUserFactory(
  permissionService: PermissionService,
  authorizationStorageService: AuthorizationStorageService,
  loggerService: LoggerService,
  authApiService: AuthApiService,
  tokenStore: TokenStore,
) {
  return tokenStore.pipe(
    distinctUntilChanged(),
    switchMap((token) => {
      if (!token) return of(null);

      return authApiService.authControllerCurrentUser().pipe(
        map((res) => res?.data),
        tap((user) => {
          permissionService.setPermissions(user.permissions);
          authorizationStorageService.lastUserData = user;
          loggerService.setActiveUser(user);
        }),
        catchError(() => {
          return of(null);
        }),
      );
    }),
    shareReplay(1),
  );
}
