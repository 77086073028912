import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiExpandModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ButtonModule } from '../button';

@Component({
  selector: 'fnip-hidden-info-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, ExecuteWithPipeModule, TuiExpandModule],
  templateUrl: './hidden-info-button.component.html',
  styleUrls: ['./hidden-info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenInfoButtonComponent {
  @Input() label = 'Показать';
  @Input() openedLabel: Nullable<string>;

  state = false;

  getLabel = (label: string, openedLabel: Nullable<string>, state: boolean) => {
    if (openedLabel && state) return openedLabel;
    return label;
  };

  toggleCb = () => {
    this.state = !this.state;
  };
}
