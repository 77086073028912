import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '../../interfaces';

// Интерфейс как в ипотеке
type Sex = 'Male' | 'Female' | 'Undefined';

// [Male, Female, Undefined]
type GenderEndingMap = [string, string, string] | Record<Sex, string> | Map<Sex, string>;

const GENDER_ARRAY_INDEX: Record<Sex, number> = {
  Male: 0,
  Female: 1,
  Undefined: 2,
};

@Pipe({
  standalone: true,
  name: 'genderEnding',
})
export class GenderEndingPipe implements PipeTransform {
  transform<TGender extends Sex>(value: Nullable<TGender>, genderEndingMap: GenderEndingMap) {
    if (!value) return this.getEndingFromMap('Undefined', genderEndingMap);
    return this.getEndingFromMap(value, genderEndingMap);
  }

  private getEndingFromMap(gender: Sex, map: GenderEndingMap) {
    if (map instanceof Map) return map.get(gender);
    if (Array.isArray(map)) return map[GENDER_ARRAY_INDEX[gender]];
    return map[gender];
  }
}
