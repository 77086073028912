import { ColDef } from '@ag-grid-community/core';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const translateHeader = (
  headerName: string,
  translateService = inject(TranslateService),
): Pick<ColDef, 'headerValueGetter'> => ({
  headerValueGetter: () => translateService.instant(headerName),
});
