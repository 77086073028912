/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MemberInfo } from './memberInfo';
import { DeveloperInfo } from './developerInfo';
import { ObjectInfo } from './objectInfo';


export interface DduModel { 
    contractNumber?: string | null;
    developer?: DeveloperInfo;
    member?: MemberInfo;
    object?: ObjectInfo;
}

