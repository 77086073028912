import { Component, Input, TemplateRef } from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import { reactiveTestAttributesHostDirective, SelectOptionsMap } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';

@Component({
  selector: 'fnip-reactive-text-area',
  templateUrl: './reactive-text-area.component.html',
  styleUrls: [`./reactive-text-area.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveTextAreaComponent<T = unknown> extends AbstractReactiveWithOptions<T> {
  @Input() optionTmp?: TemplateRef<unknown>;
  @Input() expandable = false;
  @Input() minHeight: string | undefined;
  @Input() autoHeight = false;

  isDropDownVisible = false;

  /**
   * Для данного компонента  функция `setControlValue` должена вызываться до вызова `setSelectedOption`
   */
  setControlValue = (value: unknown, optionsMap: SelectOptionsMap<T>) => {
    const option = optionsMap.get(value);
    if (isEqual(this.selectedOption, option)) return;
    this.control?.patchValue(value);
  };
}
