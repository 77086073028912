import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-date-time',
  templateUrl: './reactive-input-date-time.component.html',
  hostDirectives: [reactiveTestAttributesHostDirective],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ReactiveInputDateTimeComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<(TuiDay | TuiTime)[]>>>; // actual type is [TuiDay,  TuiTime]
  @Input() minDateTime: TuiDay | [TuiDay, TuiTime] = new TuiDay(0, 0, 1);
}
