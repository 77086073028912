import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { BffErrorMapper } from '@bff-lib/error-interceptor';
import { AuthApiService, BffConfiguration } from '@lib-bff/api';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, switchMap, take } from 'rxjs';
import { ConfigService } from '@lib-config';
import { COMMON_ERROR_MESSAGES, ErrorInterceptorModule, MortgageLikeErrorMapper } from '@lib-error-interceptor';
import { LocalStorageFilterStateService, preventIfRecent, SessionStorageFilterStateService } from '@lib-utils';
import { MortgageConfiguration, MultiRoleApiService, PermissionApiService, UserApiService } from '@lib-mortgage/api';
import { AuthorizationInterceptorModule } from './interceptors';
import { AuthorizationStorageService } from './services';
import {
  COOKIE_CHANGED,
  CookieChangedType,
  createBearerCredentials,
  CURRENT_USER,
  CurrentUser,
  getLogout$,
  PROVIDE_COOKIE_CHANGED_BY_COOKIE_STORE,
  TOKEN_STORE,
  TokenStore,
} from './utils';

const API = [PermissionApiService, UserApiService, MultiRoleApiService];
const BFF_API = [AuthApiService];

@NgModule({
  imports: [
    HttpClientModule,
    AuthorizationInterceptorModule,
    ErrorInterceptorModule.forRoot({
      messages: [COMMON_ERROR_MESSAGES],
      mappers: [MortgageLikeErrorMapper, BffErrorMapper],
    }),
  ],
  providers: [
    CookieService,
    ...API,
    ...BFF_API,
    PROVIDE_COOKIE_CHANGED_BY_COOKIE_STORE,

    // Для сброса фильтров при выходе (см. getResetAllFilterStates)
    LocalStorageFilterStateService,
    SessionStorageFilterStateService,

    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    {
      provide: BffConfiguration,
      useFactory: () =>
        new BffConfiguration({
          basePath: inject(ConfigService).bffUrl,
          withCredentials: true,
        }),
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [CURRENT_USER],
      useFactory: (currentUser: CurrentUser) => {
        return () => currentUser.pipe(take(1));
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [COOKIE_CHANGED, TOKEN_STORE, AuthorizationStorageService],
      useFactory: (
        cookieChanged: CookieChangedType,
        tokenStore: TokenStore,
        authorizationStorageService: AuthorizationStorageService,
      ) => {
        const logout$ = getLogout$();

        return () => {
          cookieChanged
            .pipe(
              map((cookieToken) => [tokenStore.value, cookieToken] as const),
              // Если токена не было, но он появился, это надо обработать в контексте страницы логина
              filter(([tokenStoreValue]) => !!tokenStoreValue),
              map(([tokenStoreValue, cookieToken]) => {
                // Если токен был удален, то надо выйти
                if (tokenStoreValue && !cookieToken) return true;

                // Если токен изменился, то надо прокинуть новый токен
                if (tokenStoreValue !== cookieToken) {
                  tokenStore.next(cookieToken);
                }

                return false;
              }),
              filter(Boolean),
              preventIfRecent(authorizationStorageService.onLogout$),
              switchMap(() => logout$(true)),
            )
            .subscribe();
        };
      },
    },
  ],
})
export class AuthorizationModule {}
