import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiHorizontalDirection,
  TuiLoaderModule,
  TuiOptionComponent,
  TuiScrollbarModule,
  TuiSizeL,
  TuiSizeM,
  TuiSizeS,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiComboBoxModule, TuiItemsHandlers } from '@taiga-ui/kit';
import { PolymorpheusContent, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import {
  ControlValueAccessorDirective,
  IndexChangeDirective,
  Nullable,
  reactiveTestAttributesHostDirective,
  SelectOption,
} from '@lib-utils';
import { ReactiveFieldErrorModule } from '../../../reactive-field-error';
import { ReactiveLabelModule } from '../../../reactive-label';
import { useWithOptions, WithTermOptions } from '../../options';
import { LoadingState, OptionSortType } from '../../options/options.utils';

@Component({
  standalone: true,
  selector: 'fnip-reactive-select-term-new',
  templateUrl: './reactive-select-term-new.component.html',
  styleUrls: ['./reactive-select-term-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ControlValueAccessorDirective, reactiveTestAttributesHostDirective],
  imports: [
    ReactiveLabelModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    TuiLetModule,
    IndexChangeDirective,
    CdkVirtualForOf,
    NgIf,
    TuiComboBoxModule,
    ReactiveFormsModule,
    TuiScrollbarModule,
    TuiValueChangesModule,
    MaskitoModule,
    ReactiveFieldErrorModule,
    PolymorpheusOutlet,
    TuiLoaderModule,
    FormsModule,
  ],
})
export class ReactiveSelectTermNewComponent<T = unknown> implements AfterViewInit, WithTermOptions {
  @Input({ required: true }) fieldId!: string;
  @Input() label?: Nullable<string>;
  @Input() labelSize: 's' | 'm' | 'l' | 'xl' = 's';
  @Input() hint: Nullable<string>;
  @Input() noBottomHint = false;
  @Input() isLabelBold = false;
  @Input() optionContent: Nullable<PolymorpheusContent>;

  @Input() textFieldSize: TuiSizeS | TuiSizeM | TuiSizeL = 'm';
  @Input() dropdownAlign: TuiHorizontalDirection = 'right';
  @Input() textfieldLabelOutside = true;
  @Input() textfieldCleaner = false;
  @Input() placeholder: Nullable<string>;
  @Input() textfieldCustomContent?: PolymorpheusContent;
  @Input() isReadOnly = false;
  @Input() textMask: Nullable<MaskitoOptions>;
  @Input() pseudoPressed = false;
  @Input() noValidationMark?: boolean;
  @Input() errorLabel?: Nullable<string>;
  @Input() optionHeight = 48;

  @Output() valueChange = new EventEmitter(true);
  @Output() selectedOptionChange = new EventEmitter<Nullable<SelectOption<T>>>();

  @Input() optionListEmptyMessage = 'Ничего не найдено';

  @ViewChildren(TuiOptionComponent) tuiOptions: Nullable<QueryList<TuiOptionComponent>>;
  @ViewChild(CdkVirtualScrollViewport) cdkScroll: Nullable<CdkVirtualScrollViewport>;

  readonly destroyRef = inject(DestroyRef);
  readonly controlValueAccessor = inject(ControlValueAccessorDirective);

  controlValue = this.controlValueAccessor.value;

  term = signal<string | null>(null);
  optionSort = input<OptionSortType>('default');

  selectedOption!: Signal<SelectOption | null>;
  optionLoadingMessage!: Signal<string | null | undefined>;
  optionList!: Signal<SelectOption[]>;
  optionListLoading!: Signal<LoadingState>;
  optionListErrorMessage!: Signal<string | null>;
  termEmptyMessage!: Signal<string | null>;

  setTermByStrategy!: (term: string | null) => void;

  stringifyText!: TuiItemsHandlers<string | number>['stringify'];

  hasRenderedTuiOptions$ = signal(false);

  constructor() {
    /** Options feature */
    useWithOptions(this, true);

    effect(() => this.selectedOptionChange.emit(this.selectedOption() as SelectOption<T>));

    /**
     * При открытии списка опции и наличии выбранной опции и самого списка опций, автоматически скроллим к выбранной опции
     */
    effect(() => {
      const controlValue = this.controlValue();
      const optionList = this.optionList();

      if (!this.hasRenderedTuiOptions$() && controlValue) return;

      const selectedIndex = optionList?.findIndex(({ value }) => value === controlValue);

      if (selectedIndex && selectedIndex > -1) this.cdkScroll?.scrollToIndex(selectedIndex);
    });
  }

  ngAfterViewInit() {
    this.tuiOptions?.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((options: QueryList<TuiOptionComponent>) => this.hasRenderedTuiOptions$.set(options.length > 0));
  }

  /**
   * Небольшой хак, чтобы подгрузились опции при открытии выпадающего списка,
   * Если уже было значение в инпуте, а term пустой
   */
  setTermOnClick() {
    const newTerm = this.selectedOption()?.label;
    if (this.term() || this.optionList().length || !newTerm || this.controlValueAccessor.isDisabled()) return;
    this.term.set(newTerm ?? null);
  }

  resetControl() {
    this.controlValueAccessor.setControlValue(null);
  }

  /**
   * Не даем комбобоксу автоматически выбирать значение, совпадающее с поиском
   */
  noSearchMatcher = () => false;
}
