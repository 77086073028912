<label class="d-flex gap-2" [style.text-align]="labelAlign" [style.width]="inline && labelWidth">
  <tui-svg *ngIf="icon" class="flex-shrink-0" [src]="icon" />
  <fnip-ellipsis-hint
    class="flex-1"
    [content]="label"
    [isDisabled]="disableEllipsis"
    [hintAppearance]="hintAppearance"
  />
</label>

<div class="content" [style.text-align]="contentAlign">
  <ng-container *polymorpheusOutlet="content as content; context: context">
    <p>
      <fnip-ellipsis-hint [content]="content" [isDisabled]="disableEllipsis" [hintAppearance]="hintAppearance" />
    </p>
  </ng-container>

  <p *ngIf="isNil | executeWith: content">—</p>
</div>
