<tui-error
  *ngIf="control"
  [class.absolute-position]="absolutePosition"
  [formControl]="control"
  [error]="toIconErrorMessage | executeWith: iconErrorMessageTmp : ([] | tuiFieldErrorContent)"
/>

<tui-error
  *ngIf="!control && error"
  [class.absolute-position]="absolutePositionWithoutControl"
  [error]="toIconErrorMessage | executeWith: iconErrorMessageTmp : error"
  [class.color-primary]="status === 'primary'"
/>

<ng-template #iconErrorMessageTmp let-content>
  <ng-container *ngIf="onResizeParentElement$ | async" />
  <ng-container *ngIf="onChangeError$ | async" />

  <tui-svg
    *ngIf="!(isNil | executeWith: errorLabel) || errorWidth >= parentWidth"
    src="tuiIconAlertCircle"
    [tuiHint]="content"
  />
  <span>{{ errorLabel ?? content }}</span>
</ng-template>
