import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-toggle',
  templateUrl: './reactive-toggle.component.html',
  styleUrls: ['./reactive-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveToggleComponent extends AbstractReactive {
  @Input() staticToggleLabel: Nullable<string> = '';
  @Input() singleColor = false;
  @Input() hasToggleLabel = true;
  @Input() getToggleLabel: (value: boolean) => string = (value) => (value ? 'Да' : 'Нет');
}
