import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SIDEBAR_ANIMATION_TIME } from './sidebar.constants';
import { injectSidebar } from './sidebar.injector';

@Component({
  selector: 'fnip-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({ width: '*' })),
      state('closed', style({ width: '0px' })),
      transition('open <=> closed', [animate(`${SIDEBAR_ANIMATION_TIME}ms ease-in-out`)]),
    ]),
  ],
})
export class SidebarComponent {
  public readonly sidebarService = injectSidebar();
  public readonly state$ = this.sidebarService.state$;

  public animationFinishedEvent(event: AnimationEvent) {
    const { phaseName, fromState, toState } = event;
    const { context } = this.sidebarService.state;

    if (context?.id && phaseName === 'done' && fromState === 'open' && toState === 'closed') {
      this.sidebarService.closeAfterAnimation$.next(context.id);
    }
  }
}
