import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiButtonModule, TuiErrorModule, TuiHintModule, TuiSvgModule, TuiTooltipModule } from '@taiga-ui/core';
import { TuiFieldErrorPipeModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ReactiveFieldErrorComponent } from './reactive-field-error.component';

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveFormsModule,
    TuiButtonModule,
    TuiFieldErrorPipeModule,
    TuiErrorModule,
    TuiTooltipModule,
    TuiHintModule,
    TuiSvgModule,
  ],
  declarations: [ReactiveFieldErrorComponent],
  exports: [ReactiveFieldErrorComponent],
})
export class ReactiveFieldErrorModule {}
