import { TuiDay, TuiDayRange, TuiTime } from '@taiga-ui/cdk';
import isNil from 'lodash-es/isNil';
import { Nullable } from '../../interfaces/types';
import { toDate } from '../to-date';

type DateInput = Date | TuiDay | string;
type TimeInput = Exclude<DateInput, TuiDay> | TuiTime;

export function toISOString(value: DateInput): string;
export function toISOString(value: Nullable<DateInput>): Nullable<string>;
export function toISOString(value: Nullable<DateInput>) {
  if (!value) return null;
  const tuiDate = toTuiDate(value);
  return tuiDate?.toUtcNativeDate().toISOString();
}

export function toRangeISOString(value: TuiDayRange): string;
export function toRangeISOString(value: Nullable<TuiDayRange>): Nullable<string>;
export function toRangeISOString(value: Nullable<TuiDayRange>) {
  if (!value) return null;
  const { from, to } = value || {};
  if (!from && !to) return;

  return `${toISOString(from)}/${toISOString(to)}`;
}

export function toTuiTime(value: TimeInput): TuiTime;
export function toTuiTime(value: Nullable<TimeInput>): Nullable<TuiTime>;
export function toTuiTime(value: Nullable<TimeInput>) {
  if (!value) return;
  if (value instanceof TuiTime) return value;

  const date = toDate(value);
  return TuiTime.fromLocalNativeDate(date);
}

export function toTuiDate(value: DateInput): TuiDay;
export function toTuiDate(value: Nullable<DateInput>): Nullable<TuiDay>;
export function toTuiDate(value: Nullable<DateInput>) {
  if (!value) return;
  if (value instanceof TuiDay) return value;

  const date = toDate(value);
  return TuiDay.fromLocalNativeDate(date);
}

export function toTuiRange(from: DateInput, to: DateInput): TuiDayRange;
export function toTuiRange(from: Nullable<DateInput>, to: Nullable<DateInput>): Nullable<TuiDayRange>;
export function toTuiRange(from: Nullable<DateInput>, to: Nullable<DateInput>) {
  if (!from || !to) return;

  return new TuiDayRange(toTuiDate(from), toTuiDate(to));
}

export function toTuiDateTime(value: DateInput): [TuiDay, TuiTime];
export function toTuiDateTime(value: Nullable<DateInput>): Nullable<[TuiDay, TuiTime]>;
export function toTuiDateTime(value: Nullable<DateInput>) {
  if (isNil(value)) return null;
  const date = toDate(value);
  return [TuiDay.fromLocalNativeDate(date), TuiTime.fromLocalNativeDate(date)] as [TuiDay, TuiTime];
}

export function fromTuiDateTime(tuiDate: Nullable<TuiDay>, time: Nullable<TuiTime>): Nullable<Date> {
  const date = tuiDate?.toLocalNativeDate();

  if (time && date) {
    date.setHours(time.hours);
    date.setMinutes(time.minutes);
  }
  return date;
}

export function toUtcDate(date: string) {
  return TuiDay.fromLocalNativeDate(new Date(date)).toUtcNativeDate();
}
