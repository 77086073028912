import { AsyncPipe, DOCUMENT, NgIf, ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiScrollService } from '@taiga-ui/cdk';
import { tuiFadeIn } from '@taiga-ui/core';
import { finalize, fromEvent, map } from 'rxjs';
import { ButtonModule } from '../button';

@Component({
  selector: 'fnip-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, ButtonModule],
  providers: [TuiScrollService],
  animations: [tuiFadeIn],
})
export class ScrollToTopButtonComponent {
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly document = inject(DOCUMENT);
  private readonly scrollService = inject(TuiScrollService);

  isVisible$ = fromEvent(this.document, 'scroll').pipe(
    takeUntilDestroyed(),
    map(() => this.viewportScroller.getScrollPosition()[1] > 0),
  );

  scrollToTop$ = () =>
    this.scrollService.scroll$(this.document.defaultView!, 0).pipe(
      // Убеждаемся, что scroll точно дошел до 0 и событие произошло
      finalize(() => this.viewportScroller.scrollToPosition([0, 0])),
    );
}
