/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DaDataCheckType } from './daDataCheckType';
import { AddressCompletenessType } from './addressCompletenessType';


export interface AddressModel { 
    id?: number;
    fiasId?: string | null;
    houseFiasId?: string | null;
    areaFiasId?: string | null;
    regionFiasId?: string | null;
    cityFiasId?: string | null;
    streetFiasId?: string | null;
    fullAddress?: string | null;
    postCode?: string | null;
    region?: string | null;
    regionType?: string | null;
    city?: string | null;
    cityType?: string | null;
    cityDistrict?: string | null;
    cityDistrictType?: string | null;
    settlement?: string | null;
    settlementType?: string | null;
    street?: string | null;
    streetType?: string | null;
    house?: string | null;
    houseType?: string | null;
    block?: string | null;
    blockType?: string | null;
    flat?: string | null;
    flatType?: string | null;
    stead?: string | null;
    steadType?: string | null;
    kladrId?: string | null;
    okato?: string | null;
    country?: string | null;
    countryCode?: string | null;
    resultAddress?: string | null;
    unparsedParts?: string | null;
    checkType?: DaDataCheckType;
    completeness?: AddressCompletenessType;
    fiasLevel?: string | null;
}
export namespace AddressModel {
}


