import { Router } from '@angular/router';
import { DISABLE_BROWSER_CHECK_KEY, SUPPORTED_BROWSERS } from './browser-name';
import { getBrowserName } from './get-browser-name';
import { getBrowserVersion } from './get-browser-version';

export function checkBrowserSupport(router: Router) {
  return () => {
    if (localStorage.getItem(DISABLE_BROWSER_CHECK_KEY)) return;

    const userBrowser = getBrowserName();
    const browserVersion = getBrowserVersion(userBrowser);
    if (!SUPPORTED_BROWSERS[userBrowser] || (browserVersion ?? 0) < (SUPPORTED_BROWSERS[userBrowser]?.version ?? 0)) {
      router.navigate(['/unsupported-browser']);
    }
  };
}
