import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiBooleanHandler } from '@taiga-ui/cdk';
import { reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-year',
  templateUrl: './reactive-input-year.component.html',
  styleUrls: ['./reactive-input-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputYearComponent extends AbstractReactive {
  @Input() minYear = 0;
  @Input() maxYear = 9999;
  @Input() disabledItemHandler: TuiBooleanHandler<number> = () => false;
}
