/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PersonalSubsidiaryPlotInfo = 'RunPsp' | 'RunByOwnPsp' | 'HaveLandForPsp' | 'PspConsideredInHomesteadBook';

export const PersonalSubsidiaryPlotInfo = {
    RunPsp: 'RunPsp' as PersonalSubsidiaryPlotInfo,
    RunByOwnPsp: 'RunByOwnPsp' as PersonalSubsidiaryPlotInfo,
    HaveLandForPsp: 'HaveLandForPsp' as PersonalSubsidiaryPlotInfo,
    PspConsideredInHomesteadBook: 'PspConsideredInHomesteadBook' as PersonalSubsidiaryPlotInfo
};

