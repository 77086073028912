/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhysicalPersonModel } from './physicalPersonModel';
import { RoleModel } from './roleModel';
import { DateTime } from '@lib-utils';


export interface UserModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
    email?: string | null;
    roleId?: number;
    role?: RoleModel;
    position?: string | null;
    passwordHash?: string | null;
    isActive?: boolean;
    additionalEmails?: Array<string> | null;
}

