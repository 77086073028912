/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EncumbranceDto } from './encumbranceDto';
import { MaterialType } from './materialType';
import { LandCategory } from './landCategory';
import { DateOnly } from '@lib-utils';
import { AddressRequest } from './addressRequest';
import { RealEstateType } from './realEstateType';


export interface CreateOrUpdateRealEstateRequest { 
    id?: number;
    buildingAddressId?: number | null;
    numberEstate?: string | null;
    cadastral?: string | null;
    rooms?: string | null;
    floor?: number;
    area?: number;
    materialType?: MaterialType;
    totalFloor?: number;
    dateTransfer?: DateOnly | null;
    dateMortgage?: DateOnly | null;
    isEncumbrance?: boolean;
    address?: AddressRequest;
    year?: number | null;
    encumbrance?: EncumbranceDto;
    dduPrice?: number | null;
    concessionCount?: number | null;
    landCategory?: LandCategory;
    permittedUse?: string | null;
    type?: RealEstateType;
    estimatedValue?: number | null;
}
export namespace CreateOrUpdateRealEstateRequest {
}


