/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualReviewTaskDto } from './manualReviewTaskDto';
import { ApplicationStatusDto } from './applicationStatusDto';
import { CreditPurpose } from './creditPurpose';
import { DateTime } from '@lib-utils';
import { OccupationType } from './occupationType';
import { ProofOfIncome } from './proofOfIncome';


export interface ManualReviewApplicationItemDto { 
    id?: number;
    borrowerFullName?: string | null;
    occupationType?: OccupationType;
    proofOfIncome?: ProofOfIncome;
    creditProductName?: string | null;
    creditPurpose?: CreditPurpose;
    state?: ApplicationStatusDto;
    actualTask?: ManualReviewTaskDto;
    creditAmount?: number;
    created?: DateTime;
    onStateEntered?: DateTime;
    underwriterFullName?: string | null;
    firstAuthorizerFullName?: string | null;
    secondAuthorizerFullName?: string | null;
}
export namespace ManualReviewApplicationItemDto {
}


