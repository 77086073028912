export enum RangeSelect {
  Today,
  Weak,
  Month,
}

export const RangeSelectNameMap: Record<RangeSelect, string> = {
  [RangeSelect.Today]: 'Сегодня',
  [RangeSelect.Weak]: 'Неделя',
  [RangeSelect.Month]: 'Месяц',
};
