/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReasonForChoosing } from './reasonForChoosing';
import { DateOnly } from '@lib-utils';
import { DealDocumentFileDto } from './dealDocumentFileDto';


export interface DealDocumentsDto { 
    creditAgreementName?: string | null;
    agreementDate?: DateOnly | null;
    dealAgreementName?: string | null;
    dealAgreementDate?: DateOnly | null;
    addressOfObjectForCreditDocumentation?: string | null;
    letterOfCreditOpeningDate?: DateOnly | null;
    letterOfCreditEndDate?: DateOnly | null;
    letterOfCreditPeriod?: number;
    letterOfCreditTerms?: Array<string> | null;
    reasonForChoosing?: ReasonForChoosing;
    comment?: string | null;
    isNotStandart?: boolean;
    dealDocumentFiles?: Array<DealDocumentFileDto> | null;
}
export namespace DealDocumentsDto {
}


