/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CurrencySortField = 'NumericCode' | 'AlphaCode' | 'Name';

export const CurrencySortField = {
    NumericCode: 'NumericCode' as CurrencySortField,
    AlphaCode: 'AlphaCode' as CurrencySortField,
    Name: 'Name' as CurrencySortField
};

