/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationTaskType } from './verificationTaskType';


export interface ApplicationChangeVerificationTaskDto { 
    verificationTaskId: number;
    taskType: VerificationTaskType;
    verificatorComment?: string | null;
    documentStatus?: string | null;
}
export namespace ApplicationChangeVerificationTaskDto {
}


