<ng-container *ngIf="cookieChangedLogin$ | async" />

<form
  *tuiLet="isMobile$ | async as isMobile"
  class="auth-form d-flex flex-column gap-10"
  (keyup.enter)="submitButton.onClick()"
>
  <img alt="logo" [class.logo]="!isNew" [class.logo-new]="isNew" [src]="logo" />

  <h2 class="m-auto" [class.font-size-32]="!isMobile" [class.font-size-24]="isMobile">Вход</h2>

  <fnip-reactive-input
    fieldId="login"
    label="E-mail"
    textFieldSize="l"
    [requiredIf]="true"
    [control]="from.controls.login"
    (valueChange)="invalidData = null"
  />

  <div class="d-flex flex-column gap-8">
    <div class="position-relative">
      <fnip-reactive-input-password
        fieldId="password"
        label="Пароль"
        textFieldSize="l"
        [control]="from.controls.password"
        [requiredIf]="true"
        (valueChange)="invalidData = null"
      />

      <fnip-reactive-field-error *ngIf="invalidData" [error]="invalidData" />
    </div>

    <fnip-button label="Забыли пароль?" appearance="link-primary" btnRouterLink="../recover" />
  </div>

  <fnip-button
    #submitButton
    class="ml-auto"
    appearance="primary"
    label="Войти"
    btnSize="l"
    [actionCallback$]="submitFormCallback$"
    [isDisabled]="from.invalid || !!invalidData"
  />
</form>
