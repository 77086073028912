import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiLoaderModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, EllipsisHintComponent } from '@lib-widgets/core';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule, TuiLoaderModule, ExecuteWithPipeModule, ButtonModule, TuiLetModule, EllipsisHintComponent],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
})
export class LoaderModule {}
