<ng-container *tuiLet="currentValue$ | executeWith: $any(control) | async as controlValue">
  <ng-container
    *ngIf="control"
    ngDefaultControl
    [formControl]="$any(control)"
    (tuiValueChanges)="valueChange.emit($event)"
  />
  <tui-badged-content class="d-block" [contentTop]="controlValue?.length">
    <button
      tuiButton
      class="w-100"
      type="button"
      [disabled]="control!.disabled"
      [appearance]="appearance"
      [size]="size"
      [icon]="labelIconAlign === 'left' ? iconTmp : null"
      [iconRight]="labelIconAlign === 'right' ? iconTmp : null"
      [tuiDropdown]="dropdownTmp"
      [tuiDropdownManual]="open"
      [tuiDropdownAlign]="dropdownAlign"
      [tuiDropdownDirection]="dropdownDirection"
      [tuiDropdownLimitWidth]="fixedWidth ? 'fixed' : 'auto'"
      (tuiObscured)="open = $event ? false : open"
      (tuiActiveZoneChange)="open = $event && open"
      (click)="open = !open"
    >
      {{ label }}
    </button>
  </tui-badged-content>

  <ng-template #dropdownTmp>
    <div
      *tuiLet="
        filterLoadedOptions | executeWith: (withLoading$ | executeWith: options | async) : term as filteredOptions
      "
      cdkVirtualScrollingElement
      tuiScrollable
      class="scroll-container"
      [style.min-height.px]="getContainerHeight | executeWith: filteredOptions?.length : optionHeight : hasSearch"
      [style.width]="fixedWidth ? undefined : dropdownWidth"
    >
      <div
        *ngIf="hasSearch"
        class="d-flex align-items-center gap-2 position-sticky"
        [style.z-index]="1"
        [style.top]="0"
      >
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          tuiTextfieldSize="m"
          class="flex-1"
          [style.min-width.px]="250"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="term"
        >
          Поиск
          <input placeholder="Поиск" tuiTextfield />
        </tui-input>
        <button
          tuiIconButton
          icon="tuiIconX"
          size="s"
          tuiHint="Отменить выбор"
          class="mr-2"
          (click)="clearControl()"
        ></button>
      </div>
      <tui-data-list>
        <tui-opt-group
          *tuiLet="isMultiSelected | executeWith: filteredOptions : controlValue as allSelected"
          tuiMultiSelectGroup
        >
          <button
            tuiOption
            fnipTestAttributes
            testLabel="Выбрать все"
            class="content-start"
            [testId]="fieldId + '-all'"
            [disabled]="control!.disabled"
            (click)="onMultiSelectOption(filteredOptions, allSelected)"
          >
            <tui-checkbox size="l" [ngModel]="allSelected" [disabled]="control!.disabled" />
            <span class="ml-3">Выбрать все</span>
          </button>
        </tui-opt-group>
        <tui-opt-group tuiMultiSelectGroup>
          <cdk-virtual-scroll-viewport [itemSize]="optionHeight" [minBufferPx]="300" [maxBufferPx]="400">
            <button
              *cdkVirtualFor="let item of filteredOptions"
              tuiOption
              fnipTestAttributes
              class="content-start w-100"
              [testLabel]="item.label"
              [testId]="fieldId + '-' + item.value"
              [disabled]="item.disabled || control!.disabled"
              (click)="onSelectOption(item.value, controlValue)"
            >
              <tui-checkbox
                size="l"
                [ngModel]="isSelected | executeWith: item.value : controlValue"
                [disabled]="item.disabled || control!.disabled"
              />
              <span class="ml-3">{{ item.label }}</span>
            </button>
          </cdk-virtual-scroll-viewport>
        </tui-opt-group>
      </tui-data-list>
    </div>
  </ng-template>
</ng-container>

<ng-template #iconTmp>
  <tui-svg src="tuiIconChevronDownLarge" class="icon" [class.icon_rotated]="open" />
</ng-template>
