/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { ApplicationTaskPostponeReasonType } from './applicationTaskPostponeReasonType';
import { ApplicationModel } from './applicationModel';
import { ManualReviewDecisionType } from './manualReviewDecisionType';
import { ManualReviewTaskType } from './manualReviewTaskType';
import { AuthorizerRejectionType } from './authorizerRejectionType';
import { DateTime } from '@lib-utils';
import { ManualReviewTaskStatus } from './manualReviewTaskStatus';


export interface ManualReviewTaskModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    applicationId?: number;
    application?: ApplicationModel;
    decisionType?: ManualReviewDecisionType;
    postponeTime?: DateTime | null;
    postponeReasonType?: ApplicationTaskPostponeReasonType;
    isActive?: boolean;
    commentForDecision?: string | null;
    decisionDate?: DateTime | null;
    taskType?: ManualReviewTaskType;
    operatorId?: number | null;
    operator?: UserModel;
    rejectionTypes?: Array<AuthorizerRejectionType> | null;
    status?: ManualReviewTaskStatus;
}
export namespace ManualReviewTaskModel {
}


