/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonalSubsidiaryPlotDataRequest } from './personalSubsidiaryPlotDataRequest';


export interface OutcomeIncomeDataRequest { 
    salary?: number | null;
    pension?: number | null;
    companionIncome?: number | null;
    otherIncome?: number | null;
    credit?: number | null;
    alimony?: number | null;
    otherOutcome?: number | null;
    personalSubsidiaryPlot?: PersonalSubsidiaryPlotDataRequest;
    additionalInfo?: string | null;
}

