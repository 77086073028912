/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { VerificationCallResult } from './verificationCallResult';
import { DateTime } from '@lib-utils';


export interface VerificationCallModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    getThrough?: boolean | null;
    reachedAccounting?: boolean;
    employmentConfirmed?: boolean | null;
    callResult?: VerificationCallResult;
    comment?: string | null;
    employmentPhoneId?: number;
}
export namespace VerificationCallModel {
}


