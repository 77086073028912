import { Directive, HostBinding, Input } from '@angular/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { Nullable } from '../../interfaces/types';
import { TEST_ATTRIBUTE_ID, TEST_ATTRIBUTE_LABEL } from './constants';

/**
 * Общая директива для проставления атрибутов тестирования
 */
@Directive({
  selector: '[fnipTestAttributes]',
  standalone: true,
})
export class TestAttributesDirective {
  @Input() testLabel?: string;

  /**
   * На случай, когда можно совместить input с label
   * При этом, если указан testLabel, то он будет использован
   */
  @Input() defaultTestLabel?: Nullable<PolymorpheusContent>;

  @HostBinding(`attr.${TEST_ATTRIBUTE_ID}`)
  @Input() // TODO: Добавить { required: true } после применения во всех компонентах
  testId?: string;

  @HostBinding(`attr.${TEST_ATTRIBUTE_LABEL}`)
  get testLabelValue() {
    return this.testLabel || this.defaultTestLabel?.toString();
  }
}
