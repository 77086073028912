/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManualReviewApplicationSortFields = 'Id' | 'BorrowerFullName' | 'OccupationType' | 'ProofOfIncome' | 'CreditProductName' | 'CreditPurpose' | 'TaskStatus' | 'State' | 'CreditAmount' | 'Created' | 'OnStateEntered' | 'UnderwriterFullName' | 'FirstAuthorizerFullName' | 'SecondAuthorizerFullName';

export const ManualReviewApplicationSortFields = {
    Id: 'Id' as ManualReviewApplicationSortFields,
    BorrowerFullName: 'BorrowerFullName' as ManualReviewApplicationSortFields,
    OccupationType: 'OccupationType' as ManualReviewApplicationSortFields,
    ProofOfIncome: 'ProofOfIncome' as ManualReviewApplicationSortFields,
    CreditProductName: 'CreditProductName' as ManualReviewApplicationSortFields,
    CreditPurpose: 'CreditPurpose' as ManualReviewApplicationSortFields,
    TaskStatus: 'TaskStatus' as ManualReviewApplicationSortFields,
    State: 'State' as ManualReviewApplicationSortFields,
    CreditAmount: 'CreditAmount' as ManualReviewApplicationSortFields,
    Created: 'Created' as ManualReviewApplicationSortFields,
    OnStateEntered: 'OnStateEntered' as ManualReviewApplicationSortFields,
    UnderwriterFullName: 'UnderwriterFullName' as ManualReviewApplicationSortFields,
    FirstAuthorizerFullName: 'FirstAuthorizerFullName' as ManualReviewApplicationSortFields,
    SecondAuthorizerFullName: 'SecondAuthorizerFullName' as ManualReviewApplicationSortFields
};

