import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiLabelModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiComboBoxModule, TuiSelectModule } from '@taiga-ui/kit';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { IndexChangeDirective } from './index-change.directive';
import { ReactiveSelectComponent } from './reactive-select.component';

const TUI_MODULES = [
  TuiComboBoxModule,
  TuiLetModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiSelectModule,
  TuiDataListModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiDropdownModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    MaskitoModule,
    PolymorpheusOutlet,
    ScrollingModule,
    TUI_MODULES,
    IndexChangeDirective,
    TestAttributesDirective,
  ],
  declarations: [ReactiveSelectComponent],
  exports: [ReactiveSelectComponent],
})
export class ReactiveSelectModule {}
