import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiActiveZoneModule, TuiLetModule, TuiObscuredModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHintModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiBadgedContentModule, TuiInputModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ReactiveButtonDropdownComponent } from './reactive-button-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiLetModule,
    TuiSvgModule,
    TuiBadgedContentModule,
    TuiDropdownModule,
    TuiHintModule,
    TuiInputModule,
    TuiObscuredModule,
    TuiTextfieldControllerModule,
    TuiActiveZoneModule,
    FormsModule,
    ReactiveFormsModule,
    TuiValueChangesModule,
    TestAttributesDirective,
  ],
  declarations: [ReactiveButtonDropdownComponent],
  exports: [ReactiveButtonDropdownComponent],
})
export class ReactiveButtonDropdownModule {}
