import { inject } from '@angular/core';
import { AuthApiService } from '@lib-bff/api';
import { of, skip, switchMap, take } from 'rxjs';
import { CURRENT_USER } from './current-user.provider';
import { TOKEN_STORE } from './token-store.provider';

export const getLogin = () => {
  const authApiService = inject(AuthApiService);
  const tokenStore = inject(TOKEN_STORE);
  const currentUser = inject(CURRENT_USER);

  return (login: string, password: string) =>
    authApiService
      .authControllerLogin({
        email: login,
        password,
      })
      .pipe(
        switchMap((res) => {
          const token = res.data?.token;
          const user = currentUser.pipe(skip(1), take(1));
          tokenStore.next(token ?? null);

          /**
           * Если токен получен, то ждем получения юзера с ролями перед тем, как редиректить на главную страницу
           */
          return token ? user : of(null);
        }),
      );
};
