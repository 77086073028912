import { AsyncPipe, NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiRadioLabeledModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveRadioGroupComponent } from './reactive-radio-group.component';

const TUI_MODULES = [
  TuiRadioLabeledModule,
  TuiLabelModule,
  TuiLetModule,
  TuiTextfieldControllerModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [
    AsyncPipe,
    ExecuteWithPipeModule,
    NgClass,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    ReactiveLabelModule,
    TUI_MODULES,
    TestAttributesDirective,
  ],
  declarations: [ReactiveRadioGroupComponent],
  exports: [ReactiveRadioGroupComponent],
})
export class ReactiveRadioGroupModule {}
