/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Explained } from './explained';
import { Problem } from './problem';


export interface Solution { 
    problems?: Array<Problem> | null;
    explained?: Explained;
    fields?: any | null;
    pdfUrl?: any | null;
    document0?: Array<string> | null;
}

