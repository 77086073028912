/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';
import { PaymentInfoDto } from './paymentInfoDto';
import { NonStandardType } from './nonStandardType';


export interface UpdateCreditInfoRequest { 
    currentAccountNumber?: string | null;
    collateralAccountNumber?: string | null;
    paymentDate?: DateOnly | null;
    firstPaymentInfos?: Array<PaymentInfoDto> | null;
    firstAmount?: number;
    length?: number;
    residentialComplexId?: number | null;
    creditTransferPeriod?: number | null;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
}
export namespace UpdateCreditInfoRequest {
}


