import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@lib-utils';
import { PermissionOperationMapKeys, PermissionService } from '../../services/permission';

/**
 * Проверка на наличие пермишена
 *
 * Usage: *ngIf="'crmActivityCreate' | hasPermission"
 */
@Pipe({ name: 'hasPermission' })
export class HasPermissionPipe implements PipeTransform {
  constructor(private permissionService: PermissionService) {}

  /**
   * @param value Пермишн
   */
  transform(value: Nullable<PermissionOperationMapKeys>) {
    return this.permissionService.checkExactPermissionFor(value);
  }
}
