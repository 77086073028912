import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from '@lib-utils';

@Injectable({ providedIn: 'root' })
export class ToolbarService {
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _hasSidebar$ = new BehaviorSubject<boolean>(false);

  set isOpen(value: boolean) {
    this._isOpen$.next(value);
  }

  get isOpen() {
    return this._isOpen$.value;
  }

  get isOpen$(): Observable<boolean> {
    return this._isOpen$.asObservable();
  }

  set hasSidebar(value: boolean) {
    this._hasSidebar$.next(value);
  }

  get hasSidebar() {
    return this._hasSidebar$.value;
  }

  get hasSidebar$(): Observable<boolean> {
    return this._hasSidebar$.asObservable();
  }

  changeOpenState = (isOpen: Nullable<boolean>) => {
    this.isOpen = !!isOpen;
  };

  changeHasSidebarState = (hasSidebar: Nullable<boolean>) => {
    this.hasSidebar = !!hasSidebar;

    if (!hasSidebar) this.changeOpenState(false);
  };
}
