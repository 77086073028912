import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Nullable, SelectOption, SelectOptions } from '@lib-utils';

/**
 * Для поддержки старого интерфейса, где может быть передан и term и static,
 * игнорирует function составляющую
 */
@Pipe({
  standalone: true,
  name: 'toStaticOptions',
})
export class ToStaticOptionsPipe implements PipeTransform {
  transform<T = unknown, TVal = unknown>(
    value: Nullable<SelectOptions<T, TVal>>,
  ): Nullable<SelectOption<T, TVal>[] | Observable<SelectOption<T, TVal>[]>> {
    if (typeof value === 'function') return null;
    return value;
  }
}
