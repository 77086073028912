import { formatDate } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  TUI_DEFAULT_ERROR_MESSAGE,
  TUI_MONTHS,
  TUI_NOTHING_FOUND_MESSAGE,
  TUI_SANITIZER,
  TUI_SHORT_WEEK_DAYS,
  TUI_SPIN_TEXTS,
} from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@taiga-ui/dompurify';
import { TUI_ENGLISH_LANGUAGE, TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE, TuiLanguage } from '@taiga-ui/i18n';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { toDate } from 'date-fns';
import { map, startWith } from 'rxjs';
import { PATTERN_MESSAGE_MAP } from '@lib-utils';

const TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP: Record<string, TuiLanguage> = {
  ru: TUI_RUSSIAN_LANGUAGE,
  en: TUI_ENGLISH_LANGUAGE,
};

@NgModule({
  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    {
      provide: TUI_LANGUAGE,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang]),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_MONTHS,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang].months),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_SHORT_WEEK_DAYS,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang].shortWeekDays),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_NOTHING_FOUND_MESSAGE,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang].nothingFoundMessage),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_DEFAULT_ERROR_MESSAGE,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang].defaultErrorMessage),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_SPIN_TEXTS,
      useFactory: (translateService: TranslateService) =>
        translateService.onLangChange.pipe(
          startWith(null),
          map(() => TRANSLATE_LANGUAGE_TO_TUI_LANGUAGE_MAP[translateService.currentLang].spinTexts),
        ),
      deps: [TranslateService],
    },
    {
      provide: TUI_VALIDATION_ERRORS,
      useFactory: (translateService: TranslateService) => ({
        // ReactiveForms.Validators box validators:
        required: () => translateService.stream('BASE.ERRORS.REQUIRED'),
        minlength: (e: ValidationErrors) =>
          translateService.stream('BASE.ERRORS.MIN_LENGTH', { requiredLength: e.requiredLength }),
        maxlength: (e: ValidationErrors) =>
          translateService.stream('BASE.ERRORS.MAX_LENGTH', { requiredLength: e.requiredLength }),
        min: (e: ValidationErrors) => translateService.stream('BASE.ERRORS.MIN', { min: e.min }),
        max: (e: ValidationErrors) => translateService.stream('BASE.ERRORS.MAX', { max: e.max }),
        email: () => translateService.stream('BASE.ERRORS.EMAIL'),
        pattern: (e: ValidationErrors) =>
          // TODO: Определить все `PATTERN_MESSAGE_MAP` в переводах `BASE.PATTERN_MESSAGE.{{KEY}}` и адаптировать под динамическую смену локали
          PATTERN_MESSAGE_MAP[e.requiredPattern] || translateService.stream('BASE.ERRORS.PATTERN'),
        // Custom validators:
        minWords: (e: ValidationErrors) => translateService.stream('BASE.ERRORS.MIN_WORDS', { min: e.min }),
        maxWords: (e: ValidationErrors) => translateService.stream('BASE.ERRORS.MAX_WORDS', { length: e.length }),
        dateMax: (e: ValidationErrors) =>
          translateService.stream('BASE.ERRORS.DATE_MAX', {
            max: formatDate(toDate(e.max), 'dd.MM.yyyy', translateService.currentLang),
          }),
        dateMin: (e: ValidationErrors) =>
          translateService.stream('BASE.ERRORS.DATE_MIN', {
            min: formatDate(toDate(e.min), 'dd.MM.yyyy', translateService.currentLang),
          }),
        fraction: () => translateService.stream('BASE.ERRORS.FRACTION'),
        // TODO: Определить все `customMessage` в переводах `BASE.CUSTOM_ERRORS.{{KEY}}` и адаптировать под динамическую смену локали
        customMessage: (message: string) => message,
      }),
      deps: [TranslateService],
    },
  ],
})
export class TaigaUiProvidersTranslateModule {}
