/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NameSuggestRequestType = 'FullName' | 'FirstName' | 'MiddleName' | 'LastName';

export const NameSuggestRequestType = {
    FullName: 'FullName' as NameSuggestRequestType,
    FirstName: 'FirstName' as NameSuggestRequestType,
    MiddleName: 'MiddleName' as NameSuggestRequestType,
    LastName: 'LastName' as NameSuggestRequestType
};

