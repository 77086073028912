/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MaterialType = 'Panel' | 'Brick' | 'Monolith';

export const MaterialType = {
    Panel: 'Panel' as MaterialType,
    Brick: 'Brick' as MaterialType,
    Monolith: 'Monolith' as MaterialType
};

