/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobType } from './jobType';
import { OccupationType } from './occupationType';


export interface EmploymentSopdVerificationTaskDto { 
    position?: string | null;
    income?: number | null;
    companyName?: string | null;
    employerTaxNumber?: string | null;
    occupationType?: OccupationType;
    jobType?: JobType;
}
export namespace EmploymentSopdVerificationTaskDto {
}


