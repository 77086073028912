<div *tuiLet="currentValue$ | executeWith: control | async as value" class="d-flex h-100 gap-4 align-items-center">
  <ng-container
    *ngIf="control"
    ngDefaultControl
    [formControl]="$any(control)"
    (tuiValueChanges)="valueChange.emit($event)"
  />
  <fnip-button
    *ngFor="let range of ranges"
    btnSize="s"
    [testId]="fieldId + '-' + range"
    [testLabel]="RangeSelectNameMap[range]"
    [label]="RangeSelectNameMap[range]"
    [appearance]="(isActive | executeWith: range : value) ? 'secondary' : 'secondary-light-grey'"
    [actionCallback$]="setRange | executeWith: range : control"
  />
</div>
