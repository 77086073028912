/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprClientCalculationResultDto } from './sprClientCalculationResultDto';
import { CalculationResultDto } from './calculationResultDto';


export interface LoanCalculationResultDto { 
    calculationResult?: CalculationResultDto;
    pti?: number;
    manualPti?: number | null;
    clients?: Array<SprClientCalculationResultDto> | null;
}

