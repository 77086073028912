/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManualReviewDepartmentDto } from './manualReviewDepartmentDto';
import { UserInfoDto } from './userInfoDto';
import { ManualReviewPartnerInfoDto } from './manualReviewPartnerInfoDto';


export interface ManualReviewApplicationSourceDto { 
    partner?: ManualReviewPartnerInfoDto;
    creator?: UserInfoDto;
    department?: ManualReviewDepartmentDto;
}

