/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectType } from './objectType';
import { InitialPaymentType } from './initialPaymentType';
import { CalculationType } from './calculationType';
import { InsuranceForm } from './insuranceForm';
import { RealEstateRegion } from './realEstateRegion';
import { MaternalCapitalType } from './maternalCapitalType';
import { OnDecisionClientCaclulationRequest } from './onDecisionClientCaclulationRequest';
import { CreditPurpose } from './creditPurpose';


export interface OnDecisionCalculationRequest { 
    calculationType?: CalculationType;
    calculationValue?: number;
    objectType?: ObjectType;
    creditPurpose?: CreditPurpose;
    insuranceForm?: InsuranceForm;
    creditPeriod?: number;
    initialPaymentSumType?: InitialPaymentType;
    initialPaymentSum?: number;
    maternalCapitalType?: MaternalCapitalType;
    maternalCapital?: number | null;
    stateSubsidy?: number | null;
    flexPrice?: number | null;
    absoluteRate?: number | null;
    residentialComplexId?: number | null;
    realEstateRegion?: RealEstateRegion;
    clients?: Array<OnDecisionClientCaclulationRequest> | null;
}
export namespace OnDecisionCalculationRequest {
}


