/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceForm } from './insuranceForm';
import { InfoForInsuranceCalculationData } from './infoForInsuranceCalculationData';
import { InsuranceDto } from './insuranceDto';


export interface ApplicationInsuranceDto { 
    applicationId?: number;
    insuranceAmount?: number | null;
    insurance?: InsuranceForm;
    infoForInsuranceCalculation?: InfoForInsuranceCalculationData;
    incomes?: { [key: string]: number; } | null;
    insurances?: Array<InsuranceDto> | null;
}
export namespace ApplicationInsuranceDto {
}


