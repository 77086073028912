/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserApplicationAction = 'Unknown' | 'SendToBank' | 'LoadDocumentsForVerification' | 'MoveToRealEstate' | 'ResendToInternalSourceCheck';

export const UserApplicationAction = {
    Unknown: 'Unknown' as UserApplicationAction,
    SendToBank: 'SendToBank' as UserApplicationAction,
    LoadDocumentsForVerification: 'LoadDocumentsForVerification' as UserApplicationAction,
    MoveToRealEstate: 'MoveToRealEstate' as UserApplicationAction,
    ResendToInternalSourceCheck: 'ResendToInternalSourceCheck' as UserApplicationAction
};

