<ng-container
  [ngTemplateOutlet]="item.children?.length ? submenuTmp : menuItemTmp"
  [ngTemplateOutletContext]="{
    item,
    isActive: isRootItemActive | executeWith: url : item : expanded,
  }"
/>

<ng-template #submenuTmp let-item="item" let-isActive="isActive">
  <div class="d-flex flex-column flex-1 gap-1">
    <tui-hosted-dropdown
      tuiDropdownHover
      tuiDropdownAlign="right"
      [tuiDropdownOffset]="10"
      [content]="content"
      [sided]="true"
      [canOpen]="!(isSomeChildrenActive | executeWith: url : item) || !expanded"
      [(open)]="dropdownOpened"
    >
      <div tuiHostedDropdownHost (click)="closeDropdown()">
        <ng-container [ngTemplateOutlet]="menuItemTmp" [ngTemplateOutletContext]="{ item, isActive }" />
      </div>

      <ng-template #content>
        <div class="submenu-label p-3">{{ item.label }}</div>
        <div class="d-flex flex-column gap-1 m-1">
          <ng-container *ngFor="let subItem of item.children">
            <fnip-button
              *fnipAccess="subItem.permission"
              appearance="menu-item-light"
              btnSize="s"
              class="w-100"
              [btnActive]="urlStartWith | executeWith: url : subItem.link"
              [btnRouterLink]="subItem.link"
              [label]="subItem.label"
              [actionCallback$]="closeDropdown"
            >
            </fnip-button>
          </ng-container>
        </div>
      </ng-template>
    </tui-hosted-dropdown>

    <ng-container *ngIf="expanded && (isSomeChildrenActive | executeWith: url : item)">
      <ng-container *ngFor="let subItem of item.children">
        <ng-container
          *fnipAccess="subItem.permission"
          [ngTemplateOutlet]="menuItemTmp"
          [ngTemplateOutletContext]="{
            item: subItem,
            isActive: urlStartWith | executeWith: url : subItem.link,
            isChild: true,
          }"
        />
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #menuItemTmp let-item="item" let-isActive="isActive" let-isChild="isChild">
  <fnip-button
    appearance="menu-item"
    class="w-100"
    [btnActive]="isActive"
    [btnHint]="expanded || !!item.children?.length ? null : item.label"
    [btnHintAppearance]="'onDark'"
    [btnRouterLink]="item.link || item.children?.[0]?.link"
  >
    <tui-svg *ngIf="!isChild" class="menu-item-icon mr-2" [src]="item.icon" />
    <fnip-ellipsis-hint *ngIf="expanded" class="flex-1 text-start" [class.pl-7]="isChild" [content]="item.label" />
  </fnip-button>
</ng-template>
