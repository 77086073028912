import { InjectionToken, Provider } from '@angular/core';
import { ValueOrFactoryProvider } from '../../interfaces';

export function createProvider(
  provide: InjectionToken<unknown>,
  fromConfig: ValueOrFactoryProvider | undefined,
  defaultProvider: ValueOrFactoryProvider,
): Provider {
  if (fromConfig) return { provide, ...fromConfig };
  return { provide, ...defaultProvider };
}
