import { NgClass, NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input, Input } from '@angular/core';
import { TuiHintModule, TuiSizeS, TuiSvgModule } from '@taiga-ui/core';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { ExpandableDirective, Nullable, TestAttributesDirective } from '@lib-utils';

@Component({
  selector: 'fnip-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TuiAccordionModule, TuiHintModule, TuiSvgModule, NgIf, NgClass],
  hostDirectives: [
    {
      directive: TestAttributesDirective,
      inputs: ['defaultTestLabel: title', 'testLabel', 'testId'],
    },
    {
      directive: ExpandableDirective,
      inputs: ['isOpen', 'name: header'],
      outputs: ['isOpenChange'],
    },
  ],
})
export class AccordionItemComponent {
  @Input() formValid: Nullable<boolean> = true;
  @Input() itemId: Nullable<string>;
  @Input() header: Nullable<string>;
  @Input() subheader: Nullable<string>;
  @Input({ transform: booleanAttribute }) isReadOnly: Nullable<boolean> = false;
  @Input() size: Nullable<TuiSizeS> = 'm';
  @Input() borders: 'all' | 'top-bottom' | null = 'all';
  @Input() validIcon: Nullable<PolymorpheusContent> = 'tuiIconCheckCircleLarge';
  @Input() invalidIcon: Nullable<PolymorpheusContent> = 'tuiIconAlertCircleLarge';
  @Input({ transform: booleanAttribute }) stickyHeader = false;
  @Input() isCollapsible = true;
  @Input() accordionClass: Nullable<string>;
  @Input() invalidMessage: Nullable<PolymorpheusContent>;

  borderColor = input<string>();

  expandable = inject(ExpandableDirective);
}
