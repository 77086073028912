import { booleanAttribute, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaskitoOptions, maskitoTransform } from '@maskito/core';
import { TuiHorizontalDirection } from '@taiga-ui/core';
import { tap } from 'rxjs/operators';
import { CyrillicInputDirective, Nullable, reactiveTestAttributesHostDirective, SelectOption } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';
import { NumberInputDirective } from './directives/number-input';

@Component({
  selector: 'fnip-reactive-input',
  templateUrl: './reactive-input.component.html',
  styleUrls: [`./reactive-input.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputComponent<TOption = unknown> extends AbstractReactiveWithOptions<TOption, Nullable<string>> {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<string>>>;

  @Input({ transform: booleanAttribute }) isCyrillicInput = false;

  @Input({ transform: booleanAttribute }) isUpperCaseInput = false;

  @Input() hasAutoFocus = false;

  @Input() icon: string | null = null;

  @Input() iconAlign: TuiHorizontalDirection = 'right';

  @Input({ transform: booleanAttribute }) isNumberInput = false; // Только числовые значения

  @Input() maxLength: Nullable<number>;

  @Input() controlHint: Nullable<string>;

  @Input() unmaskValue: (value: string) => string = (value) => value;

  getLabels(options: Nullable<SelectOption[]>): string[] | null {
    return options?.map((option) => option.label) ?? null;
  }

  getValueChange$ = (control: typeof this.control) =>
    control?.valueChanges.pipe(
      /**
       * Поддержка isNumberInput
       * При вставке текста с содержанием других символов, кроме числовых, очищаем от них строку
       * Директива isNumberInput выключает сочетания с ctrlKey и вызывает preventDefault для других символов,
       * но всегда остается вариант заполнения через контекстное меню, автозаполнение и тд
       */
      tap((value) => {
        if (!this.isNumberInput || NumberInputDirective.matchPattern(value)) return;
        control.patchValue(NumberInputDirective.transformToPattern(value));
      }),
      /**
       * Поддержка isCyrillicInput
       */
      tap((value) => {
        if (!this.isCyrillicInput || CyrillicInputDirective.matchPattern(value)) return;
        control.patchValue(CyrillicInputDirective.transformToPattern(value));
      }),
    );

  /**
   * Эта функция позволяет обновить модель контрола отформатированным значением передаваемой `textMask.mask`
   * при первом вызове `textMask.mask`, когда контрол еще не тронут пользователем
   */
  checkMask = (textMask: Nullable<MaskitoOptions>, unmask: (value: string) => string, value: Nullable<string>) => {
    if (!value || !textMask?.mask || this.control?.dirty) return;
    const currentValue = value.toString();
    const conformedValue = unmask(maskitoTransform(currentValue, textMask));

    if (conformedValue !== currentValue) this.control?.patchValue(conformedValue);
  };
}
