import { Nullable } from '../../interfaces/types';
import { BrowserName } from './browser-name';

const BROWSER_VERSION_MATCHER: Partial<Record<BrowserName, RegExp>> = {
  [BrowserName.Chrome]: /Chrome\/?\s*(\d+)/, // между Chrome и версией допускаем наличие / и произвольного числа пробелов
  [BrowserName.Edge]: /Edge?\/?\s*(\d+)/, // Edg или Edge
  [BrowserName.Firefox]: /Firefox\/?\s*(\d+)/,
  [BrowserName.Opera]: /(OPR|Opera)\/?\s*(\d+)/,
  [BrowserName.Yandex]: /YaBrowser\/?\s*(\d+)/,
  [BrowserName.Safari]: /version\/(\d+)/i,
};

export function getBrowserVersion(browser: BrowserName): Nullable<number> {
  const userAgent = window.navigator.userAgent;
  const matcher = BROWSER_VERSION_MATCHER[browser];
  if (!matcher) return null;
  return +(userAgent.match(matcher)?.[0].match(/\d+/)?.[0] ?? 0);
}
