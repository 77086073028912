/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RealEstateRegion = 'Other' | 'Moscow' | 'MoscowRegion' | 'SaintPetersburg' | 'LeningradRegion';

export const RealEstateRegion = {
    Other: 'Other' as RealEstateRegion,
    Moscow: 'Moscow' as RealEstateRegion,
    MoscowRegion: 'MoscowRegion' as RealEstateRegion,
    SaintPetersburg: 'SaintPetersburg' as RealEstateRegion,
    LeningradRegion: 'LeningradRegion' as RealEstateRegion
};

