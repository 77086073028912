import { Env } from './config.model';

export function getCurrentEnv(hostName: string): Env {
  const domains = hostName.split('.');
  if (domains.length === 0) {
    return Env.prod;
  }

  switch (domains[0]) {
    case 'localhost':
      return Env.localhost;
    case 'stage':
      return Env.stage;
    case 'preprod':
      return Env.preprod;
    case 'dev':
      return Env.dev;
    default:
      return Env.prod;
  }
}
