/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupCompaniesModel } from './groupCompaniesModel';
import { RepresentativeOrganizationMappingModel } from './representativeOrganizationMappingModel';
import { LegalEntityType } from './legalEntityType';
import { AddressModel } from './addressModel';
import { DeveloperModel } from './developerModel';


export interface OrganizationModel { 
    id?: number;
    name?: string | null;
    ogrn?: string | null;
    inn?: string | null;
    kpp?: string | null;
    addressId?: number | null;
    address?: AddressModel;
    groupCompaniesId?: number | null;
    groupCompanies?: GroupCompaniesModel;
    representativeMappings?: Array<RepresentativeOrganizationMappingModel> | null;
    isActive?: boolean;
    developer?: DeveloperModel;
    legalEntityType?: LegalEntityType;
}
export namespace OrganizationModel {
}


