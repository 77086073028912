/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobType } from './jobType';
import { CompanyHeadDto } from './companyHeadDto';
import { EmploymentIncomeDto } from './employmentIncomeDto';
import { CompanyBeneficiarDto } from './companyBeneficiarDto';
import { IncomeSource } from './incomeSource';
import { OccupationType } from './occupationType';


export interface ManualReviewEmploymentDto { 
    id?: number;
    name?: string | null;
    occupationType?: OccupationType;
    position?: string | null;
    inn?: string | null;
    phone?: string | null;
    address?: string | null;
    status?: string | null;
    companyHead?: CompanyHeadDto;
    companyBeneficiars?: Array<CompanyBeneficiarDto> | null;
    recognizedIncome?: number | null;
    isEmployerIncome?: boolean | null;
    sprIncomeSource?: IncomeSource;
    excludeReason?: string | null;
    jobType?: JobType;
    incomes?: Array<EmploymentIncomeDto> | null;
}
export namespace ManualReviewEmploymentDto {
}


