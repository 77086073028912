/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OccupationType = 'Commercial' | 'Budget' | 'OwnBusiness' | 'Employment' | 'Pensioner' | 'SoleProprietor' | 'SelfEmployed' | 'IsNotIncomePart';

export const OccupationType = {
    Commercial: 'Commercial' as OccupationType,
    Budget: 'Budget' as OccupationType,
    OwnBusiness: 'OwnBusiness' as OccupationType,
    Employment: 'Employment' as OccupationType,
    Pensioner: 'Pensioner' as OccupationType,
    SoleProprietor: 'SoleProprietor' as OccupationType,
    SelfEmployed: 'SelfEmployed' as OccupationType,
    IsNotIncomePart: 'IsNotIncomePart' as OccupationType
};

