/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealVerificationApplicationDto } from './dealVerificationApplicationDto';
import { PageResult } from './pageResult';


export interface DealVerificationApplicationsPagedResultDto { 
    data?: Array<DealVerificationApplicationDto> | null;
    page?: PageResult;
    statusesCount?: { [key: string]: number | null; } | null;
}

