/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealVerificationDocumentStatus = 'New' | 'InProgress' | 'Rework' | 'Complete';

export const DealVerificationDocumentStatus = {
    New: 'New' as DealVerificationDocumentStatus,
    InProgress: 'InProgress' as DealVerificationDocumentStatus,
    Rework: 'Rework' as DealVerificationDocumentStatus,
    Complete: 'Complete' as DealVerificationDocumentStatus
};

