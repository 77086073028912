import { Injectable, Pipe, PipeTransform } from '@angular/core';

enum RoundEnum {
  One = 1,
  Ten = 10,
  OneHundred = 100,
}

@Injectable()
@Pipe({ name: 'numberRound' })
export class NumberRoundPipe implements PipeTransform {
  /**
   * Округление до заданного разряда
   *
   * @example
   * {{ 123 | numberRound : 1 }} -> 123
   * {{ 123 | numberRound : 10 }} -> 120
   * {{ 123 | numberRound : 100 }} -> 100
   *
   * @param value Входное число
   * @param round Разряд
   */
  transform(value: number, round: number = RoundEnum.One): number {
    return Math.round(value / round) * round;
  }
}
