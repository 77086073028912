import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollableElement,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiActiveZoneModule, TuiLetModule, TuiObscuredModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHintModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiBadgedContentModule, TuiCheckboxModule, TuiInputModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ReactiveButtonMultiDropdownComponent } from './reactive-button-multi-dropdown.component';

const TUI_MODULES = [
  TuiDropdownModule,
  TuiHintModule,
  TuiButtonModule,
  TuiSvgModule,
  TuiDataListModule,
  TuiLetModule,
  TuiCheckboxModule,
  TuiBadgedContentModule,
  TuiObscuredModule,
  TuiScrollbarModule,
  TuiActiveZoneModule,
  TuiInputModule,
  TuiValueChangesModule,
  TuiTextfieldControllerModule,
];

@NgModule({
  imports: [
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollableElement,
    CdkVirtualScrollViewport,
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveFormsModule,
    FormsModule,
    TestAttributesDirective,
    TUI_MODULES,
  ],
  declarations: [ReactiveButtonMultiDropdownComponent],
  exports: [ReactiveButtonMultiDropdownComponent],
})
export class ReactiveButtonMultiDropdownModule {}
