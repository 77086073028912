import { DomSanitizer } from '@angular/platform-browser';
import { FileInfo } from '@lib-utils';

export const getPreviewFileUrl = <T extends string | number = string | number>(
  { cacheBlob }: Pick<FileInfo<T>, 'cacheBlob'>,
  sanitizer: DomSanitizer,
  disableToolbar = false,
) => {
  if (!cacheBlob?.body) return '';
  return sanitizer.bypassSecurityTrustResourceUrl(
    URL.createObjectURL(cacheBlob?.body) + (disableToolbar ? '#toolbar=0&navpanes=0' : ''),
  );
};
