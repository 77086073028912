import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { tuiHintOptionsProvider } from '@taiga-ui/core';
import sumBy from 'lodash-es/sumBy';
import { FileInfo, FileInputAppearance, Nullable } from '@lib-utils';
import '../../interfaces';
import { ACCEPT_FILE_TYPES, getAcceptCaption, getAcceptFileTypeString } from '../../utils';

@Component({
  selector: 'fnip-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    tuiHintOptionsProvider({
      icon: 'tuiIconHelpCircleLarge',
    }),
  ],
})
export class DragNDropComponent {
  @Input()
  @HostBinding('class')
  appearance: FileInputAppearance = 'default';

  @Input() label?: string;

  @Input() accept?: ACCEPT_FILE_TYPES[];

  @Input() multiple?: boolean;

  @Input() max?: number;

  @Input() maxSizeMb?: number;

  @Input() fileList: FileInfo[] = [];

  // Remote files may not be available yet, no upload allowed
  @Input() isPending = false;

  @Input() isDisabled = false;

  @Input() isMobile: Nullable<boolean>;

  @Input() showAcceptCaption = false;

  @Output() fileDropped = new EventEmitter<File>();

  @Output() maxLimitReached = new EventEmitter<void>();

  @Output() maxSizeReached = new EventEmitter<void>();

  getAcceptFileTypeString = getAcceptFileTypeString;

  isLimitReached = (fileList: FileInfo[], max: number | undefined) => {
    if (typeof max === 'undefined') return false;
    return fileList.length >= max;
  };

  onFilesDropped(files: File[]) {
    if (this.isDisabled) return;
    // If limit is set and file length reached it
    if (typeof this.max !== 'undefined' && this.fileList.length + files.length > this.max) {
      this.maxLimitReached.emit();
      return;
    }
    // If total size limit is set and reached
    if (
      typeof this.maxSizeMb !== 'undefined' &&
      sumBy(this.fileList, 'file.size') + sumBy(files, 'size') > 1024 * 1024 * this.maxSizeMb
    ) {
      this.maxSizeReached.emit();
      return;
    }
    files.forEach((file) => this.fileDropped.emit(file));
  }

  onFileChange(files: FileList | null) {
    if (!files) return;
    this.onFilesDropped(Array.from(files));
  }

  getAcceptCaption = (accept: Nullable<ACCEPT_FILE_TYPES[]>, showAcceptCaption: boolean) => {
    if (!showAcceptCaption || !accept) return null;
    return getAcceptCaption(accept);
  };
}
