<label [class.gap-1]="label" [tuiLabel]="labelTemplate">
  <ng-content></ng-content>
  <p *ngIf="hint && !noBottomHint && hintPosition === 'bottom'" class="bottom-hint font-small" [ngStyle]="hintStyle">
    {{ hint }}
  </p>
</label>

<ng-template #labelTemplate>
  <div *ngIf="label" data-testid="label-container" class="required-field__label" [class.bold]="isBold">
    {{ label }}
    <span
      *ngIf="hasRequiredValidator"
      data-testid="label-required-indicator"
      class="required-field__asterisk tui-required"
    >
    </span>
    <span
      *ngIf="hint && hintPosition === 'top'"
      data-testid="label-hint"
      class="required-field__hint"
      [ngStyle]="hintStyle"
    >
      {{ hint }}
    </span>
  </div>
</ng-template>
