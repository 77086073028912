/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OldPassport } from './oldPassport';
import { AddressData } from './addressData';
import { Problem } from './problem';
import { DateTime } from '@lib-utils';


export interface Passport { 
    errors?: Array<Problem> | null;
    present?: boolean | null;
    gender?: string | null;
    issuer?: string | null;
    number?: string | null;
    series?: string | null;
    registrationPageNumbers?: string | null;
    lastName?: string | null;
    birthDate?: string | null;
    firstName?: string | null;
    issueDate?: string | null;
    birthPlace?: string | null;
    issuerCode?: string | null;
    middleName?: string | null;
    registrationDateTime?: DateTime | null;
    registrationStatus?: string | null;
    registrationAddress?: AddressData;
    oldPassports?: Array<OldPassport> | null;
}

