/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LegalEntityType } from './legalEntityType';
import { AddressRequest } from './addressRequest';


export interface CreateOrUpdateBuilderInfoRequest { 
    id?: number | null;
    name?: string | null;
    inn?: string | null;
    address?: AddressRequest;
    legalEntityType?: LegalEntityType;
}
export namespace CreateOrUpdateBuilderInfoRequest {
}


