<ng-container *ngIf="control">
  <fnip-reactive-label
    *tuiLet="withLoading$ | executeWith: options : term : initialOptions | async as loadedOptions"
    [hasRequiredValidator]="hasRequiredValidator"
    [label]="label"
    [size]="labelSize"
    [hint]="hint"
    [noBottomHint]="noBottomHint"
    [isBold]="isLabelBold"
  >
    <tui-multi-select
      [nativeId]="fieldId"
      [formControl]="$any(control)"
      [editable]="hasSearch"
      [expandable]="isExpandable"
      [stringify]="isOptionsLoading ? getLoadingValuePlaceholder : (getDefaultValueContent | executeWith: optionsMap)"
      [tuiTextfieldLabelOutside]="textfieldLabelOutside"
      [tuiTextfieldSize]="textFieldSize"
      [valueContent]="valueContent"
      [tuiTextfieldCustomContent]="textfieldCustomContent"
      [(search)]="term!"
      (tuiValueChanges)="valueChange.emit($event)"
    >
      {{ placeholder }}

      <ng-template *ngIf="!useVirtualScroll" tuiDataList>
        <tui-data-list
          *ngIf="!isOptionsLoading; else loadingTmp"
          tuiMultiSelectGroup
          [emptyContent]="!hasSearch || (term && term.length >= minTermLength) ? null : emptyTermMessage"
        >
          <button
            *ngFor="let item of filterLoadedOptions | executeWith: loadedOptions : term"
            tuiOption
            fnipTestAttributes
            [testId]="fieldId + '-' + item.value"
            [testLabel]="item.label"
            [disabled]="!!item.disabled"
            [value]="item.value"
          >
            {{ item.label }}
          </button>
        </tui-data-list>
      </ng-template>

      <ng-container *ngIf="useVirtualScroll">
        <ng-container *ngIf="!isOptionsLoading; else loadingTmp">
          <cdk-virtual-scroll-viewport
            *tuiDataList
            tuiScrollable
            class="virtual-scroll"
            [itemSize]="44"
            (fnipIndexChange)="list.handleFocusLossIfNecessary()"
          >
            <tui-data-list #list tuiMultiSelectGroup [emptyContent]="!hasSearch || term ? null : emptyTermMessage">
              <button
                *cdkVirtualFor="let item of filterLoadedOptions | executeWith: loadedOptions : term"
                tuiOption
                fnipTestAttributes
                [testId]="fieldId + '-' + item.value"
                [testLabel]="item.label"
                [disabled]="!!item.disabled"
                [value]="item.value"
              >
                {{ item.label }}
              </button>
            </tui-data-list>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
    </tui-multi-select>
    <fnip-reactive-field-error
      *ngIf="!noValidationMark"
      [control]="$any(control)"
      [errorLabel]="errorLabel"
      (isErrorShow)="noBottomHint = $event"
    />
  </fnip-reactive-label>
</ng-container>
<ng-template #loadingTmp>
  <div class="loader">
    <tui-loader />
    Опции загружаются
  </div>
</ng-template>
