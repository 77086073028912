import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDay } from '@taiga-ui/cdk';
import { DateOnly, Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-date',
  templateUrl: './reactive-input-date.component.html',
  styleUrls: [`./reactive-input-date.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputDateComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<DateOnly | Date | TuiDay>>>;

  @Input() minDate = new TuiDay(0, 0, 1);
  @Input() maxDate = new TuiDay(9999, 11, 31);
  @Input() hasToDateDirective = false;
  @Input() isUtcDate = false;
}
