/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationChangeType = 'StatusChange' | 'Created' | 'FileUploaded' | 'FileDeleted' | 'FileRestored' | 'FileRecognized' | 'FileTypeChanged' | 'VerificationTaskCreated' | 'VerificationTaskCommented' | 'CreatedRelations' | 'RelationAdded' | 'RelationDeleted' | 'RelationReplaced' | 'AssignDealVerificationTask' | 'ApproveDealVerification' | 'ApproveDealVerificationFileCheck' | 'VerificationTaskChanged' | 'AssignTaskToVerificator' | 'RecognitionStatusChanges';

export const ApplicationChangeType = {
    StatusChange: 'StatusChange' as ApplicationChangeType,
    Created: 'Created' as ApplicationChangeType,
    FileUploaded: 'FileUploaded' as ApplicationChangeType,
    FileDeleted: 'FileDeleted' as ApplicationChangeType,
    FileRestored: 'FileRestored' as ApplicationChangeType,
    FileRecognized: 'FileRecognized' as ApplicationChangeType,
    FileTypeChanged: 'FileTypeChanged' as ApplicationChangeType,
    VerificationTaskCreated: 'VerificationTaskCreated' as ApplicationChangeType,
    VerificationTaskCommented: 'VerificationTaskCommented' as ApplicationChangeType,
    CreatedRelations: 'CreatedRelations' as ApplicationChangeType,
    RelationAdded: 'RelationAdded' as ApplicationChangeType,
    RelationDeleted: 'RelationDeleted' as ApplicationChangeType,
    RelationReplaced: 'RelationReplaced' as ApplicationChangeType,
    AssignDealVerificationTask: 'AssignDealVerificationTask' as ApplicationChangeType,
    ApproveDealVerification: 'ApproveDealVerification' as ApplicationChangeType,
    ApproveDealVerificationFileCheck: 'ApproveDealVerificationFileCheck' as ApplicationChangeType,
    VerificationTaskChanged: 'VerificationTaskChanged' as ApplicationChangeType,
    AssignTaskToVerificator: 'AssignTaskToVerificator' as ApplicationChangeType,
    RecognitionStatusChanges: 'RecognitionStatusChanges' as ApplicationChangeType
};

