import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiDataListModule } from '@taiga-ui/core';
import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { NotificationListItemComponent } from '../notification-list-item';
import { FnipNotification } from '../notification-list.models';

@Component({
  selector: 'fnip-notification-history',
  standalone: true,
  imports: [TuiDataListModule, ButtonModule, NotificationListItemComponent, NgForOf, NgIf],
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationHistoryComponent<TID extends string | number, TBeData = unknown> {
  @Input() notificationList: FnipNotification<TID, TBeData>[] = [];
  @Input() hasLoadMoreButton = false;
  @Input() loadMoreHistory$: Nullable<() => Nullable<Observable<unknown> | void>>;

  @Output() markAsRead = new EventEmitter<FnipNotification<TID, TBeData>>();

  protected trackByFn(_index: number, item: FnipNotification<TID, TBeData>) {
    return item.id;
  }
}
