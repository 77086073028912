/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventDto } from './eventDto';
import { DateTime } from '@lib-utils';
import { NotificationUserDto } from './notificationUserDto';


export interface NotificationDto { 
    id?: number;
    event?: EventDto;
    notificationText?: string | null;
    dataType?: string | null;
    data?: string | null;
    created?: DateTime;
    notificationUsers?: Array<NotificationUserDto> | null;
}

