import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { enableTranslateOnDev, TranslateModule } from '@lib-features/translate';
import { TuiRootModule } from '@taiga-ui/core';
import { InitConfigModule, TaigaUiProvidersTranslateModule } from '@lib-config';
import { SidebarModule } from '@lib-widgets/sidebar';
import { AuthorizationModule } from '@lib-mortgage/features/authorization';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

registerLocaleData(ru);

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterOutlet,
    SidebarModule,
    TuiRootModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthorizationModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
    TaigaUiProvidersTranslateModule,
    InitConfigModule.forRoot('Единый продуктовый кабинет', environment.frontVersion),
    TranslateModule.forRoot({
      modules: ['base'],
      isEnabled: enableTranslateOnDev,
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ru' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
