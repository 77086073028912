/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AgreementType } from './agreementType';
import { JobType } from './jobType';
import { StaffNumber } from './staffNumber';
import { EmploymentPhoneDto } from './employmentPhoneDto';
import { OrganizationLifetime } from './organizationLifetime';
import { DateOnly } from '@lib-utils';
import { AddressDto } from './addressDto';
import { IncomeSource } from './incomeSource';
import { OccupationType } from './occupationType';
import { EmployeeBankRelation } from './employeeBankRelation';
import { IndustryType } from './industryType';


export interface EmploymentDto { 
    id?: number;
    jobType?: JobType;
    companyType?: string | null;
    industryType?: IndustryType;
    taxNumber?: string | null;
    name?: string | null;
    regNumber?: string | null;
    juristicAddress?: AddressDto;
    factualAddress?: AddressDto;
    phone?: string | null;
    position?: string | null;
    salary?: number | null;
    occupationType?: OccupationType;
    businessPercent?: number | null;
    businessComment?: string | null;
    agreementType?: AgreementType;
    startWorkingAgreement?: DateOnly | null;
    endWorkingAgreement?: DateOnly | null;
    staffNumber?: StaffNumber;
    organizationLifetime?: OrganizationLifetime;
    site?: string | null;
    startDate?: DateOnly | null;
    salaryBank?: string | null;
    salaryCardNumber?: string | null;
    bankRelation?: EmployeeBankRelation;
    totalWorkExperience?: number | null;
    isEmployerIncome?: boolean | null;
    sprIncome?: number | null;
    sprIncomeSource?: IncomeSource;
    phones?: Array<EmploymentPhoneDto> | null;
}
export namespace EmploymentDto {
}


