<div class="content-wrapper">
  <div (click)="dismissCb()" class="backdrop"></div>

  <div class="content" [class]="sizeClass">
    <div class="white-bg">
      <span
        *ngIf="dialogHostOptions.label"
        class="d-block tui-text_h4 font-weight-bold"
        [style.margin-bottom.em]="0.75"
      >
        {{ dialogHostOptions.label }}
      </span>

      <ng-container #dialogContentView />
    </div>
  </div>

  <fnip-button
    *ngIf="!!dialogHostOptions.isCloseable"
    icon="tuiIconClose"
    appearance="icon"
    btnSize="s"
    class="close-btn"
    [class.close-btn_fullscreen]="isFullscreen"
    [actionCallback$]="closeCb"
  />
</div>
