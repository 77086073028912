/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateOrUpdateRealEstateRequest } from './createOrUpdateRealEstateRequest';
import { ReceivingType } from './receivingType';
import { DateOnly } from '@lib-utils';
import { TransferType } from './transferType';
import { DateTime } from '@lib-utils';
import { DealRegistrationType } from './dealRegistrationType';


export interface DealInfoRequest { 
    transferType: TransferType;
    receivingType: ReceivingType;
    agreementType: DealRegistrationType;
    date?: DateTime | null;
    contractNumber?: string | null;
    conclusionContractOfSalePlanningDate?: DateOnly | null;
    realEstates?: Array<CreateOrUpdateRealEstateRequest> | null;
}
export namespace DealInfoRequest {
}


