import { Directive, HostBinding, Input } from '@angular/core';
import { Nullable } from '../../interfaces/types';
import { TEST_ATTRIBUTE_ID, TEST_ATTRIBUTE_LABEL } from './constants';

/**
 * Директива для проставления атрибутов тестирования в реактивных компонентах
 * Которая учитывает label и placeholder
 */
@Directive({
  standalone: true,
})
export class ReactiveTestAttributesDirective {
  @Input() label: Nullable<string>;
  @Input() placeholder: Nullable<string>;

  /**
   * На случай, когда лайбл не передан явно в компонент,
   * Но технически он есть в другом элементе и мы можем его передать для тестирования
   *
   * Например, чекбокс в калькуляторе для Flex Price
   */
  @Input() testLabel?: string;

  @HostBinding(`attr.${TEST_ATTRIBUTE_ID}`)
  @Input({ required: true })
  fieldId?: string;

  @HostBinding(`attr.${TEST_ATTRIBUTE_LABEL}`)
  get testLabelValue() {
    return this.testLabel || this.label || this.placeholder;
  }
}
