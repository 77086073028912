<ng-container *ngIf="control">
  <ng-container *ngIf="getValueChange$ | executeWith: control | async" />
  <ng-container *ngIf="checkMask | executeWith: textMask : unmaskValue : control.value" />

  <fnip-reactive-label
    *tuiLet="withLoading$ | executeWith: options : control.value | async as loadedOptions"
    [class.inactive]="isInactive"
    [hasRequiredValidator]="hasRequiredValidator"
    [label]="label"
    [size]="labelSize"
    [hint]="hint"
    [hintPosition]="hintPosition"
    [noBottomHint]="noBottomHint"
    [hintStyle]="hintStyle"
    [isBold]="isLabelBold"
  >
    <ng-container *ngIf="setSelectedOption | executeWith: control.value : optionsMap" />
    <tui-input
      [formControl]="$any(control)"
      [tuiAutoFocus]="hasAutoFocus"
      [tuiHintContent]="controlHint"
      [tuiTextfieldLabelOutside]="textfieldLabelOutside"
      [tuiTextfieldSize]="textFieldSize"
      [tuiTextfieldCustomContent]="textfieldCustomContent"
      [maskito]="$any(textMask)"
      [fnipCyrillicInput]="isCyrillicInput"
      [fnipUppercaseInput]="isUpperCaseInput"
      [tuiTextfieldIconLeft]="iconAlign === 'left' ? icon : null"
      [tuiTextfieldIcon]="iconAlign === 'right' ? icon : null"
      [fnipNumberInput]="isNumberInput"
      [readOnly]="isReadOnly"
      [tuiTextfieldCleaner]="textfieldCleaner"
      [tuiUnmaskHandler]="unmaskValue"
      [ngClass]="inputClass"
      (tuiValueChanges)="valueChange.emit($event)"
    >
      {{ placeholder }}
      <input
        tuiTextfield
        autocomplete="off"
        [attr.maxLength]="maxLength"
        [id]="fieldId"
        [style.pointer-events]="control.disabled ? 'all' : 'auto'"
      />
      <ng-container *ngIf="options">
        <tui-data-list-wrapper *tuiDataList [items]="getLabels | executeWith: loadedOptions"></tui-data-list-wrapper>
      </ng-container>
    </tui-input>
    <fnip-reactive-field-error
      *ngIf="!noValidationMark"
      [control]="$any(control)"
      [errorLabel]="errorLabel"
      (isErrorShow)="noBottomHint = $event"
    />
  </fnip-reactive-label>
</ng-container>
