/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealVerificationErrorCodeModel } from './dealVerificationErrorCodeModel';
import { ApplicationFileCheckModel } from './applicationFileCheckModel';


export interface DealVerificationErrorCodeMappingModel { 
    id?: number;
    applicationFileCheckId?: number;
    applicationFile?: ApplicationFileCheckModel;
    dealVerificationErrorCodeId?: number;
    comment?: string | null;
    isActive?: boolean;
    dealVerificationErrorCode?: DealVerificationErrorCodeModel;
}

