/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LandCategory = 'AgriculturalLand' | 'PopulatedLand' | 'IndustrialLand' | 'ProtectedLand' | 'ForestLand' | 'WaterLand' | 'ReserveLand';

export const LandCategory = {
    AgriculturalLand: 'AgriculturalLand' as LandCategory,
    PopulatedLand: 'PopulatedLand' as LandCategory,
    IndustrialLand: 'IndustrialLand' as LandCategory,
    ProtectedLand: 'ProtectedLand' as LandCategory,
    ForestLand: 'ForestLand' as LandCategory,
    WaterLand: 'WaterLand' as LandCategory,
    ReserveLand: 'ReserveLand' as LandCategory
};

