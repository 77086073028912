import { inject, InjectionToken } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '@lib-config';

export type TokenStore = ReturnType<typeof tokenStoreFactory>;

export const TOKEN_STORE = new InjectionToken<TokenStore>('TOKEN_STORE', {
  providedIn: 'root',
  factory: () => {
    const configService = inject(ConfigService);
    const cookieService = inject(CookieService);
    return tokenStoreFactory(configService, cookieService);
  },
});

export function tokenStoreFactory(configService: ConfigService, cookieService: CookieService) {
  const cookieName = configService.tokenCookieName;
  const cookieToken = cookieService.check(cookieName) ? cookieService.get(cookieName) : null;

  return new BehaviorSubject(cookieToken);
}
