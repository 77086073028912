/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConditionType = 'Common' | 'Seller' | 'Buyer';

export const ConditionType = {
    Common: 'Common' as ConditionType,
    Seller: 'Seller' as ConditionType,
    Buyer: 'Buyer' as ConditionType
};

