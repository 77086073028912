import { inject, Injectable, signal } from '@angular/core';
import { LocaleApiService, LocaleEnum } from '@lib-bff/api';
import { TranslateService } from '@ngx-translate/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, EMPTY, filter, Observable, of, switchMap } from 'rxjs';
import { Nullable } from '@lib-utils';
import { TRANSLATE_CODE_TO_TUI_LANGUAGE_CODE } from './translate.utils';

const COOKIE_NAME = 'locale';

@Injectable()
export class TranslateStateService {
  readonly cookieService = inject(CookieService);
  readonly translateService = inject(TranslateService);
  readonly localeApiService = inject(LocaleApiService);

  private _currentCountryCode = signal<Nullable<TuiCountryIsoCode>>(null);

  get currentCountryCode() {
    return this._currentCountryCode.asReadonly();
  }

  constructor() {
    this.cookieChanged$()
      .pipe(
        filter((value) => this.translateService.currentLang !== value),
        switchMap((value) => this.setLanguage(value, false)),
      )
      .subscribe();
  }

  setLanguage(language: LocaleEnum, setCookie = true) {
    const countryCode = TRANSLATE_CODE_TO_TUI_LANGUAGE_CODE.get(language);
    if (this.currentCountryCode() === countryCode) return of(null);

    this._currentCountryCode.set(countryCode);
    if (!setCookie) return this.translateService.use(language);

    return combineLatest([
      this.translateService.use(language),
      this.localeApiService.localeControllerSetLocale(language),
    ]);
  }

  getCurrentLanguageFromStorage() {
    const currentLang = this.translateService.currentLang;
    const language = (
      (this.cookieService.check(COOKIE_NAME) ? this.cookieService.get(COOKIE_NAME) : null) ||
      this.translateService.getBrowserLang() ||
      LocaleEnum.Ru
    ).toLocaleLowerCase();

    return (currentLang || language || LocaleEnum.Ru) as LocaleEnum;
  }

  private cookieChanged$() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cookieStoreApi = (window as never)['cookieStore'] as any;
    if (!cookieStoreApi) return EMPTY;

    return new Observable<LocaleEnum>((subscriber) => {
      const changeCb = () => {
        const currentCookieValue = this.cookieService.check(COOKIE_NAME) ? this.cookieService.get(COOKIE_NAME) : null;

        if (!currentCookieValue) return;
        subscriber.next(currentCookieValue as LocaleEnum);
      };

      cookieStoreApi.addEventListener('change', changeCb);
      return () => cookieStoreApi.removeEventListener('change', changeCb);
    });
  }
}
