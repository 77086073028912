/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CalculationValidationTarget = 'CreditProduct' | 'InterestRate' | 'InitialPaymentSum' | 'InitialPaymentOwnFunds' | 'CreditAmount' | 'CreditPeriod' | 'RealEstatePrice' | 'MonthlyPayment';

export const CalculationValidationTarget = {
    CreditProduct: 'CreditProduct' as CalculationValidationTarget,
    InterestRate: 'InterestRate' as CalculationValidationTarget,
    InitialPaymentSum: 'InitialPaymentSum' as CalculationValidationTarget,
    InitialPaymentOwnFunds: 'InitialPaymentOwnFunds' as CalculationValidationTarget,
    CreditAmount: 'CreditAmount' as CalculationValidationTarget,
    CreditPeriod: 'CreditPeriod' as CalculationValidationTarget,
    RealEstatePrice: 'RealEstatePrice' as CalculationValidationTarget,
    MonthlyPayment: 'MonthlyPayment' as CalculationValidationTarget
};

