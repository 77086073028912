import { Directive, HostListener } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Directive({
  selector: '[fnipHandleHint][tuiHint]',
  standalone: true,
  exportAs: 'fnipHandleHint',
})
export class HandleHintDirective {
  private _showHint$ = new BehaviorSubject<boolean>(false);
  private HINT_DELAY = 400;

  showHint$ = this._showHint$.asObservable().pipe(debounceTime(this.HINT_DELAY));

  @HostListener('mouseover')
  mouseIn() {
    this._showHint$.next(true);
  }

  @HostListener('mouseout')
  mouseOut() {
    this._showHint$.next(false);
  }

  @HostListener('click')
  mouseClick() {
    this._showHint$.next(false);
  }
}
