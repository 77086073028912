/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserInfoDto } from './userInfoDto';
import { ManualReviewTaskType } from './manualReviewTaskType';
import { AuthorizerRejectionType } from './authorizerRejectionType';


export interface DecisionHistoryManualReviewTaskDto { 
    taskType?: ManualReviewTaskType;
    user?: UserInfoDto;
    rejectionTypes?: Array<AuthorizerRejectionType> | null;
}
export namespace DecisionHistoryManualReviewTaskDto {
}


