/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PhoneVerificationServiceResolution = 'NEGATIVE' | 'RISK' | 'POSITIVE';

export const PhoneVerificationServiceResolution = {
    Negative: 'NEGATIVE' as PhoneVerificationServiceResolution,
    Risk: 'RISK' as PhoneVerificationServiceResolution,
    Positive: 'POSITIVE' as PhoneVerificationServiceResolution
};

