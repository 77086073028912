import { DialogModule as CdkDialogModule } from '@angular/cdk/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgModule, NgModuleRef } from '@angular/core';
import { DialogOverlayContainer } from './dialog-overlay-container';
import { getBodyScrollLock$ } from './dialog.utils';

@NgModule({
  imports: [CdkDialogModule],
  exports: [CdkDialogModule],
  providers: [
    {
      provide: OverlayContainer,
      useClass: DialogOverlayContainer,
    },
  ],
})
export class FnipDialogModule {
  constructor(ref: NgModuleRef<FnipDialogModule>) {
    const bodyLockSub = getBodyScrollLock$().subscribe();
    ref.onDestroy(() => bodyLockSub.unsubscribe());
  }
}
