import { HttpErrorResponse } from '@angular/common/http';
import isNil from 'lodash-es/isNil';
import { from, Observable, of, throwError } from 'rxjs';
import { Nullable } from '../../interfaces/types';

export interface CopyTextOption {
  successMessage: string;
  errorMessage: string;
  copyHintMessage?: string;
}

export const copyText$ = (text: Nullable<number | string>, copyOptions: CopyTextOption) => {
  if (isNil(text)) return throwError(() => new HttpErrorResponse({ error: { message: copyOptions.errorMessage } }));

  const observable: Observable<unknown> = navigator.clipboard
    ? from(navigator.clipboard.writeText(text.toString()))
    : of(fallbackClipboardCopy(text.toString()));

  return observable;
};

/**
 * Копирование в буфер обмена в случае невозможности работы с Clipboard API
 * @param text
 */
const fallbackClipboardCopy = (text: string) => {
  const textarea = document.createElement('textarea');

  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();

  try {
    // eslint-disable-next-line deprecation/deprecation
    return document.execCommand('copy');
  } finally {
    document.body.removeChild(textarea);
  }
};
