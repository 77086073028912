import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveSelectModule } from '@lib-widgets/reactive-fields';
import { injectDialogContext } from '../../dialog.utils';
import { SetUserDialogContext } from './set-user-dialog.interfaces';

@Component({
  selector: 'fnip-set-user-dialog',
  templateUrl: './set-user-dialog.component.html',
  styleUrls: ['./set-user-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveSelectModule, ButtonModule],
})
export class SetUserDialogComponent<T extends { id?: Nullable<number>; name?: Nullable<string> }> {
  readonly dialogContext = injectDialogContext<boolean, SetUserDialogContext<T>>();

  title = this.dialogContext.data?.title ?? 'Укажите пользователя';
  selectPlaceholder = this.dialogContext.data?.selectPlaceholder ?? 'Выберите пользователя';
  actionLabel = this.dialogContext.data?.actionLabel ?? 'Выбрать';
  initialUser = this.dialogContext.data?.initialValue;
  initialOptions = this.dialogContext.data?.initialOptions;
  options = this.dialogContext.data?.options;

  userControl = new FormControl<Nullable<number>>(this.initialUser?.id, [Validators.required]);

  close = () => this.dialogContext.ref?.close(false);

  setUser$ = () => {
    const userId = this.userControl.value;
    if (!userId) return null;
    return this.dialogContext.data?.actionCallback$?.(userId)?.pipe(tap(() => this.dialogContext.ref?.close(true)));
  };
}
