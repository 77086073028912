/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationTaskPostponeReasonType } from './applicationTaskPostponeReasonType';
import { ManualReviewDecisionType } from './manualReviewDecisionType';
import { UnderwriterCalculationRequest } from './underwriterCalculationRequest';
import { DateTime } from '@lib-utils';


export interface UnderwriterTaskDecisionRequest { 
    decisionType?: ManualReviewDecisionType;
    commentForDecision?: string | null;
    postponeTime?: DateTime | null;
    postponeReasonType?: ApplicationTaskPostponeReasonType;
    calculationRequest?: UnderwriterCalculationRequest;
}
export namespace UnderwriterTaskDecisionRequest {
}


