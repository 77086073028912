/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeSpan } from './timeSpan';
import { VerificatorType } from './verificatorType';
import { VerificatorStatus } from './verificatorStatus';


export interface VerificatorInfoDto { 
    id?: number;
    fullName?: string | null;
    status?: VerificatorStatus;
    timeLeft?: TimeSpan;
    isActive?: boolean;
    type?: VerificatorType;
    tasksCount?: number;
}
export namespace VerificatorInfoDto {
}


