import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserConfig, Faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { Observer } from 'rxjs';
import { omitNullable } from '../../functions';
import { Nullable } from '../../interfaces';

// Дублируем необходимую часть UserInfoDto из @lib-mortgage/api чтобы не породить циклическую зависимость utils и api
interface UserInfoDto {
  id?: number;
  fullName?: Nullable<string>;
  email?: Nullable<string>;
  role?: {
    name?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  faro?: Faro;

  initialize(config: BrowserConfig) {
    this.faro = initializeFaro({
      ...config,
      instrumentations: [...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] })],
    });
  }

  log(...messages: string[]) {
    this.faro?.api.pushLog(messages);
  }

  error(error: Error | HttpErrorResponse) {
    this.faro?.api.pushError(error, {
      context: 'url' in error ? { url: error.url || '' } : undefined,
      skipDedupe: true,
    });
  }

  event(event: string, attributes: Record<string, string>) {
    this.faro?.api.pushEvent(event, attributes);
  }

  onError = <T>(): Partial<Observer<T>> => ({
    error: (error: HttpErrorResponse) => this.error(error),
  });

  setActiveUser(user: Nullable<UserInfoDto>) {
    this.faro?.api.setUser(
      omitNullable({
        email: user?.email,
        id: user?.id?.toString(),
        username: user?.fullName,
        attributes: {
          role: user?.role?.name,
        },
      }),
    );
  }

  clearUser() {
    this.faro?.api.resetUser();
  }
}
