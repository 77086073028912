/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StaffNumber } from './staffNumber';


export interface CompanyInfoData { 
    companyType?: string | null;
    shortName?: string | null;
    fullName?: string | null;
    taxNumber?: string | null;
    regNumber?: string | null;
    juristicAddress?: string | null;
    employeeCount?: number | null;
    staffNumber?: StaffNumber;
    possibleWebsite?: string | null;
    phoneNumber?: string | null;
}
export namespace CompanyInfoData {
}


