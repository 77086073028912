<fnip-reactive-label
  *ngIf="control"
  [class.inactive]="isInactive"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [hintPosition]="hintPosition"
  [hintStyle]="hintStyle"
  [isBold]="isLabelBold"
>
  <tui-input-slider
    [nativeId]="fieldId"
    [formControl]="$any(control)"
    [max]="max"
    [min]="min"
    [readOnly]="isReadOnly"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    [prefix]="prefix"
    [postfix]="postfix"
    [segments]="segments"
    [quantum]="quantum"
    [steps]="steps"
    (tuiValueChanges)="valueChange.emit($event)"
  ></tui-input-slider>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
