/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationChangeUserDto } from './applicationChangeUserDto';
import { ApplicationChangesDealVerificationTaskDataDto } from './applicationChangesDealVerificationTaskDataDto';
import { ApplicationChangeFileDto } from './applicationChangeFileDto';
import { ApplicationChangeVerificationTaskDto } from './applicationChangeVerificationTaskDto';


export interface ApplicationChangeAdditionalDataDto { 
    oldStatus?: string | null;
    newStatus?: string | null;
    reasonForArchivingDescription?: string | null;
    reasonForArchivingComment?: string | null;
    timeOnOldStatus?: string | null;
    files?: Array<ApplicationChangeFileDto> | null;
    tasksList?: Array<ApplicationChangeVerificationTaskDto> | null;
    dealVerificationTaskData?: ApplicationChangesDealVerificationTaskDataDto;
    recognitionTaskId?: number | null;
    recognitionSuccessful?: boolean | null;
    recognitionResponse?: string | null;
    verificator?: ApplicationChangeUserDto;
    isAutoRecognitionEnabled?: boolean | null;
}

