import { randomIntNumber } from './random-number';

const CHAR_SET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function randomString(lengthFrom = 0, lengthTo = 30, charSet: string = CHAR_SET): string {
  let result = '';
  const length = randomIntNumber(lengthFrom, lengthTo);
  for (let i = 0; i < length; ++i) {
    result += charSet.charAt(randomIntNumber(0, charSet.length - 1));
  }
  return result;
}

export function randomText(minWords = 0, maxWords = 100): string {
  let result = '';
  const words = randomIntNumber(minWords, maxWords);
  for (let i = 0; i < words; ++i) {
    result += `${randomString()} `;
  }
  return result;
}
