<tui-island class="module m-6 p-6 d-flex flex-column gap-3" [hoverable]="true">
  <div class="d-flex gap-4 content-space-between">
    <span class="font-weight-bold font-size-20 line-height-28 line-height-28" [style.text-transform]="'uppercase'">
      {{ hasTranslation() ? (name() | translate) : name() }}
    </span>

    @if (isFailedToLoad()) {
      <tui-svg
        class="color-warn"
        src="tuiIconCloseCircleLarge"
        [tuiHint]="
          hasTranslation()
            ? ('BASE.SECTION_ERROR' | translate)
            : 'Ошибка загрузки: модуль будет открыт как отдельное приложение'
        "
      />
    }
  </div>
  <fnip-divider />
  <p class="color-gray">{{ hasTranslation() ? (description() | translate) : description() }}</p>
</tui-island>
