<ng-container *ngIf="(showContent || (!isLoading && !error)) && appearance === 'fullscreen'">
  <ng-container *ngTemplateOutlet="contentTmp" />
</ng-container>

<div
  class="loader w-100"
  [class.inline]="appearance === 'inline'"
  [class.fullscreen]="appearance === 'fullscreen'"
  [class.loading]="isLoading"
  [class.error]="!!error"
>
  <div *ngIf="error; else loaderTmp" class="error-wrapper">
    <h2 *ngIf="error?.status as status">Ошибка: {{ status }}</h2>

    <h4 class="my-3">
      <fnip-ellipsis-hint [content]="errorMessage" [isDisabled]="appearance !== 'inline'" />
    </h4>

    <div *tuiLet="getButtonSize | executeWith: appearance as btnSize" class="actions">
      <fnip-button
        *ngIf="backButtonCallback$"
        appearance="outline"
        label="Назад"
        [btnSize]="btnSize"
        [actionCallback$]="backButtonCallback$"
      />

      <fnip-button appearance="primary" [label]="retryText" [btnSize]="btnSize" [actionCallback$]="retryCallback" />
    </div>
  </div>

  <ng-template #loaderTmp>
    <tui-loader
      class="animated fadeIn w-100"
      [size]="getLoaderSize | executeWith: appearance"
      [textContent]="customLoadingText ?? loadingText"
      [showLoader]="!!isLoading"
      [inheritColor]="true"
      [overlay]="true"
    >
      <ng-container *ngIf="showContent && appearance !== 'fullscreen'">
        <ng-container *ngTemplateOutlet="contentTmp" />
      </ng-container>
    </tui-loader>
  </ng-template>
</div>

<ng-template #contentTmp><ng-content /></ng-template>
