/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReasonForArchivingDescription } from './reasonForArchivingDescription';
import { ApplicationStatus } from './applicationStatus';
import { DateTime } from '@lib-utils';


export interface ReasonForArchivingModel { 
    id?: number;
    applicationId?: number;
    userId?: number;
    archiverFullName?: string | null;
    date?: DateTime;
    description?: ReasonForArchivingDescription;
    comment?: string | null;
    oldState?: ApplicationStatus;
    isActive?: boolean;
}
export namespace ReasonForArchivingModel {
}


