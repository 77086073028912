import { Observable } from 'rxjs';

/**
 * Возвращает Observable, отслеживающий пересечение элемента с вьюпортом
 * @param target
 * @param options
 */
export function intersectionObservable(target: Element, options: IntersectionObserverInit): Observable<boolean> {
  return new Observable((subscriber) => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      subscriber.next(isIntersecting);
    }, options);

    intersectionObserver.observe(target);

    return {
      unsubscribe() {
        intersectionObserver.disconnect();
      },
    };
  });
}
