import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TuiAppearance, TuiHorizontalDirection } from '@taiga-ui/core';
import { TuiDropdownWidth } from '@taiga-ui/core/types';
import isEqual from 'lodash-es/isEqual';
import { startWith } from 'rxjs/operators';
import { Nullable, reactiveTestAttributesHostDirective, SelectOptionsMap } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';

@Component({
  selector: 'fnip-reactive-button-dropdown',
  templateUrl: './reactive-button-dropdown.component.html',
  styleUrls: ['./reactive-button-dropdown.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveButtonDropdownComponent<T = unknown> extends AbstractReactiveWithOptions<T> {
  @Input() dropdownAlign: TuiHorizontalDirection = 'right';
  @Input() dropdownLimitWidth: TuiDropdownWidth = 'auto';
  @Input() appearance: string = TuiAppearance.Outline;
  @Input() labelIconAlign: 'left' | 'right' = 'right';
  @Input() showSelectedValue = true;
  @Input() optionTmp: Nullable<TemplateRef<unknown>>;
  @Input() defaultValue?: unknown;
  @Input() showBadge = true;
  @Output() dropdownStateChange = new EventEmitter<boolean>();

  open = false;

  readonly currentValue$ = (control: Nullable<AbstractControl>) => control?.valueChanges.pipe(startWith(control.value));

  /**
   * Для данного компонента  функция `setControlValue` должена вызываться до вызова `setSelectedOption`
   */
  setControlValue = (value: unknown, optionsMap: SelectOptionsMap<T>) => {
    const option = optionsMap?.get(value);
    if (isEqual(this.selectedOption, option)) return;
    this.control?.patchValue(value);
    this.control?.markAsDirty();
  };

  clearControl() {
    this.control?.reset();
  }
}
