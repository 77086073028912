/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ObjectType = 'Flat' | 'Apartments' | 'Townhouse' | 'PrivateHouse' | 'CommercialRooms' | 'ParkingPlace' | 'PantryAndOther' | 'Refinancing' | 'Perspective';

export const ObjectType = {
    Flat: 'Flat' as ObjectType,
    Apartments: 'Apartments' as ObjectType,
    Townhouse: 'Townhouse' as ObjectType,
    PrivateHouse: 'PrivateHouse' as ObjectType,
    CommercialRooms: 'CommercialRooms' as ObjectType,
    ParkingPlace: 'ParkingPlace' as ObjectType,
    PantryAndOther: 'PantryAndOther' as ObjectType,
    Refinancing: 'Refinancing' as ObjectType,
    Perspective: 'Perspective' as ObjectType
};

