import { formatDate } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import {
  TUI_DEFAULT_ERROR_MESSAGE,
  TUI_MONTHS,
  TUI_NOTHING_FOUND_MESSAGE,
  TUI_SANITIZER,
  TUI_SHORT_WEEK_DAYS,
  TUI_SPIN_TEXTS,
} from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@taiga-ui/dompurify';
import { TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { toDate } from 'date-fns';
import { of } from 'rxjs';
import { PATTERN_MESSAGE_MAP } from '@lib-utils';

@NgModule({
  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: TUI_LANGUAGE, useValue: of(TUI_RUSSIAN_LANGUAGE) },
    { provide: TUI_MONTHS, useValue: of(TUI_RUSSIAN_LANGUAGE.months) },
    { provide: TUI_SHORT_WEEK_DAYS, useValue: of(TUI_RUSSIAN_LANGUAGE.shortWeekDays) },
    { provide: TUI_NOTHING_FOUND_MESSAGE, useValue: of(TUI_RUSSIAN_LANGUAGE.nothingFoundMessage) },
    { provide: TUI_DEFAULT_ERROR_MESSAGE, useValue: of(TUI_RUSSIAN_LANGUAGE.defaultErrorMessage) },
    { provide: TUI_SPIN_TEXTS, useValue: of(TUI_RUSSIAN_LANGUAGE.spinTexts) },
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        // ReactiveForms.Validators box validators:
        required: () => `Поле обязательно для заполнения`,
        minlength: (e: ValidationErrors) => `Укажите значение не менее ${e.requiredLength} символов`,
        maxlength: (e: ValidationErrors) => `Укажите значение не более ${e.requiredLength} символов`,
        min: (e: ValidationErrors) => `Укажите значение не менее ${e.min}`,
        max: (e: ValidationErrors) => `Укажите значение не более ${e.max}`,
        email: () => 'Укажите корректный email',
        pattern: (e: ValidationErrors) => PATTERN_MESSAGE_MAP[e.requiredPattern] || 'Поле заполнено неверно',
        // Custom validators:
        minWords: (e: ValidationErrors) => `Должно содержать минимум ${e.min} слова`,
        maxWords: (e: ValidationErrors) => `Может содержать максимум ${e.length} слова`,
        dateMax: (e: ValidationErrors) => `Укажите дату не позднее ${formatDate(toDate(e.max), 'dd.MM.yyyy', 'ru')}`,
        dateMin: (e: ValidationErrors) => `Укажите дату не ранее ${formatDate(toDate(e.min), 'dd.MM.yyyy', 'ru')}`,
        customMessage: (message: string) => message,
        fraction: () => 'Укажите целое число',
      },
    },
  ],
})
export class TaigaUiProvidersModule {}
