import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiDropdownModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, EllipsisHintComponent } from '@lib-widgets/core';
import { AccessModule } from '@lib-mortgage/features/authorization';
import { MenuItemComponent } from './menu-item.component';

@NgModule({
  declarations: [MenuItemComponent],
  exports: [MenuItemComponent],
  imports: [
    CommonModule,
    EllipsisHintComponent,
    TuiLetModule,
    TuiDropdownModule,
    TuiHostedDropdownModule,
    TuiSvgModule,
    ButtonModule,
    ExecuteWithPipeModule,
    AccessModule,
  ],
})
export class MenuItemModule {}
