import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveButtonCheckboxComponent } from './reactive-button-checkbox.component';

@NgModule({
  declarations: [ReactiveButtonCheckboxComponent],
  exports: [ReactiveButtonCheckboxComponent],
  imports: [
    ButtonModule,
    CommonModule,
    ReactiveFormsModule,
    ExecuteWithPipeModule,
    ReactiveFieldErrorModule,
    TuiValueChangesModule,
  ],
})
export class ReactiveButtonCheckboxModule {}
