import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDecimal, TuiHorizontalDirection } from '@taiga-ui/core';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-number',
  templateUrl: './reactive-input-number.component.html',
  styleUrls: ['./reactive-input-number.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputNumberComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<number>>>;

  @Input() precision = 2;
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() decimal: TuiDecimal = 'not-zero';
  @Input() postfix = '';

  @Input() icon: string | null = null;
  @Input() iconAlign: TuiHorizontalDirection = 'right';
}
