import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Nullable } from '../../interfaces';

/**
 * Помечает все контролы во всех уровнях вложенности в форме как dirty
 * @param form форма
 */
export function markAllAsDirty(form: Nullable<AbstractControl>) {
  if (!form) return;
  form.markAsDirty();
  if (form instanceof FormGroup) {
    Object.values(form.controls).forEach(markAllAsDirty);
  }
  if (form instanceof FormArray) {
    form.controls.forEach(markAllAsDirty);
  }
}
