/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprEmploymentCalculationResultDto } from './sprEmploymentCalculationResultDto';
import { ClientAdditionalIncomesInfoDto } from './clientAdditionalIncomesInfoDto';
import { ClientExpensesInfoDto } from './clientExpensesInfoDto';
import { CreditObligationDto } from './creditObligationDto';


export interface SprClientCalculationResultDto { 
    id?: number;
    sprIsIncomePart?: boolean;
    sprIncome?: number | null;
    sprMonthlyPaymentMax?: number | null;
    sprMonthlyPaymentPtiMax?: number | null;
    sprMonthlyPaymentMaxTemp?: number | null;
    creditObligations?: Array<CreditObligationDto> | null;
    additionalIncomes?: ClientAdditionalIncomesInfoDto;
    expenses?: ClientExpensesInfoDto;
    employments?: Array<SprEmploymentCalculationResultDto> | null;
}

