import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputDateRangeModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputDateRangeComponent } from './reactive-input-date-range.component';

const TUI_MODULES = [TuiInputDateRangeModule, TuiLabelModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  declarations: [ReactiveInputDateRangeComponent],
  exports: [ReactiveInputDateRangeComponent],
})
export class ReactiveInputDateRangeModule {}
