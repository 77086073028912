import { computed, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TuiItemsHandlers } from '@taiga-ui/kit';
import isNil from 'lodash-es/isNil';
import uniqBy from 'lodash-es/uniqBy';
import { of, switchMap } from 'rxjs';
import { Nullable, SelectOption } from '@lib-utils';
import { ResetWhenNoOptionsDirective } from './directives';
import { OptionsProvider, OptionsProviderTerm, OptionsProviderType } from './options.model';
import { SELECT_OPTIONS } from './options.token';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_MIN_TERM_LENGTH,
  getOptionsProvider,
  getStaticOptionsSignal,
  getTermOptionsSignal,
  LoadingState,
  OptionSortType,
} from './options.utils';

export interface WithOptions<T = unknown> {
  controlValue: Signal<T>;
  resetControl: () => void;

  selectedOption: Signal<SelectOption | null>;
  optionLoadingMessage: Signal<string | null | undefined>;

  optionList: Signal<SelectOption[]>;
  optionListLoading: Signal<LoadingState>;
  optionListErrorMessage: Signal<string | null>;

  stringifyText: TuiItemsHandlers<string | number>['stringify'];
}

export interface WithTermOptions extends WithOptions {
  optionSort: Signal<OptionSortType>;
  term: WritableSignal<string | null>;
  setTermByStrategy: (term: string | null) => void;
  termEmptyMessage: Signal<string | null>;
}

export function useWithOptions(component: WithOptions, useTerm?: false): void;
export function useWithOptions(component: WithTermOptions, useTerm: true): void;
export function useWithOptions(component: WithOptions | WithTermOptions, useTerm?: boolean) {
  const isTermComponent = (c: WithOptions | WithTermOptions): c is WithTermOptions => useTerm ?? false;

  const optionProviders = inject<OptionsProvider[]>(SELECT_OPTIONS, { optional: true, self: true }) ?? [];
  const isResetWhenNoOption = !!inject(ResetWhenNoOptionsDirective, { optional: true, self: true });
  const termSettings = getOptionsProvider(optionProviders, OptionsProviderType.Term) ?? undefined;
  const term = isTermComponent(component) ? component.term : undefined;
  const optionSort = isTermComponent(component) ? component.optionSort : undefined;

  const optionLoadingMessage = getLoadingOptionsMessage(termSettings);
  const isTermHasMinLength = getIsTermHasMinLength(termSettings, term);

  /**
   * Выбираем откуда брать опции, в зависимости от поиска, значения и предоставленных опций
   */
  const selectedProviderStrategy = computed<OptionsProviderType>(() => {
    if (!!termSettings?.options && isTermHasMinLength()) return OptionsProviderType.Term;
    return OptionsProviderType.Static;
  });

  const optionListError = signal<unknown | null>(null);
  const optionListTermLoading = signal<LoadingState>('idle');
  const optionListStaticLoading = signal<LoadingState>('idle');
  const { staticOptions, exactOptions, termOptions } = getAllOptionsProviders(
    optionProviders,
    optionListError,
    optionListTermLoading,
    optionListStaticLoading,
    selectedProviderStrategy,
    term,
  );

  const optionListLoading = computed(() => {
    const states = [optionListTermLoading(), optionListStaticLoading()];
    if (states.some((state) => state === 'error')) return 'error';
    return states.some((state) => state === 'loading') ? 'loading' : 'idle';
  });

  const optionListErrorMessage = computed<string | null>(() => {
    const error = optionListError();
    if (!error) return null;
    const message = typeof error === 'object' && 'message' in error ? error.message : null;
    return message ? `Ошибка загрузки: ${message}` : 'Ошибка загрузки';
  });

  const optionList = computed<SelectOption[]>(() => {
    let allOptions: SelectOption[] = [];
    const termValue = term?.();
    const strategy = selectedProviderStrategy();
    const optionSortCb = optionSort?.() === 'termFirst' ? termFirstSort(termValue) : null;
    const exactList = exactOptions();

    // Если есть выбранная опция и она находится в списке exactOptions, то выводим их
    // TODO: внести эту логику в блок для strategy === OptionsProviderType.Term,
    // убедиться, что запрос на бэк из termOptions не происходит при выполнении условия
    if (
      isTermComponent(component) &&
      component.controlValue() &&
      exactList?.some(({ value }) => value === component.controlValue())
    )
      return exactList;

    if (strategy === OptionsProviderType.Term) {
      allOptions = termOptions() ?? [];
    } else {
      // Не вызываем staticOptions, пока не проверили, что стратегия не Term
      allOptions = (staticOptions() ?? []).filter((item) => {
        if (!termValue) return true;
        return item.label.toLowerCase().includes(termValue.toLowerCase());
      });
    }

    // Сортируем список при указанной стратегии
    if (optionSortCb) allOptions.sort(optionSortCb);

    return uniqBy(
      allOptions
        // Фильтруем скрытые опции
        .filter((option) => !option.hide),
      'value',
    );
  });

  const selectedOption = computed(
    () => {
      const value = component.controlValue();
      if (isNil(value)) return null;

      let selectedOptionValue: SelectOption | null = null;
      // Всегда ищем в exactOptions, если есть
      selectedOptionValue = exactOptions()?.find((option) => option.value === value) ?? null;

      // Если не нашли в exactOptions, то ищем в optionList, будет выполнен запрос, если staticOptions - observable
      if (!selectedOptionValue) {
        selectedOptionValue = optionList()?.find((option) => option.value === value) ?? null;
      }

      return selectedOptionValue;
    },
    { equal: (a, b) => a?.value === b?.value },
  );

  component.selectedOption = selectedOption;
  component.optionLoadingMessage = optionLoadingMessage;
  component.optionList = optionList;
  component.optionListLoading = optionListLoading;
  component.optionListErrorMessage = optionListErrorMessage;

  if (isTermComponent(component)) {
    component.termEmptyMessage = getTermEmptyMessage(selectedOption, termSettings, isTermHasMinLength, component.term);
    // Фиск для RAUTO-295: [F] При очищении тайпахэда не обновляется поиск
    component.setTermByStrategy = (searchTerm: string | null) => {
      if (selectedProviderStrategy() === OptionsProviderType.Static) component.term.set(searchTerm);
      else if (searchTerm) component.term.set(searchTerm);
    };
  }

  component.stringifyText = () => {
    const loadingState = optionListLoading();
    if (loadingState === 'error') return 'Ошибка загрузки';
    if (loadingState === 'loading') return 'Загрузка...';

    if (component.controlValue() && selectedOption()) {
      return selectedOption()?.label ?? '(Не удалось загрузить значение)';
    }

    return 'Выберите значение';
  };

  effect(
    () => {
      const controlValue = component.controlValue();
      const options = optionList();

      if (
        !isResetWhenNoOption ||
        optionListLoading() ||
        (options.length && options.some(({ value }) => value === controlValue))
      )
        return;

      component.resetControl();
    },
    { allowSignalWrites: true },
  );
}

export function getAllOptionsProviders(
  optionProviders: OptionsProvider[],
  isOptionsError: WritableSignal<unknown | null>,
  isOptionsTermLoading: WritableSignal<LoadingState>,
  isOptionsStaticLoading: WritableSignal<LoadingState>,
  selectedProviderStrategy: Signal<OptionsProviderType>,
  term: Signal<string | null> = signal<string | null>(null),
) {
  if (!optionProviders.length) {
    throw new Error(
      'Для работы select необходимо указать одну из директив получения опций (fnipOptionsExact, fnipOptionsFromTerm, fnipOptionsStatic) или прокинуть провайдер SELECT_OPTIONS',
    );
  }

  const exactOptions = toSignal(
    (getOptionsProvider(optionProviders, OptionsProviderType.Exact)?.options ?? of(of([]))).pipe(
      switchMap((options) => options),
    ),
  );

  const staticOptions = getStaticOptionsSignal(selectedProviderStrategy, optionProviders, (loadingState, error) => {
    isOptionsStaticLoading.set(loadingState);
    isOptionsError.set(error ?? null);
  });

  const termOptions = getTermOptionsSignal(selectedProviderStrategy, term, optionProviders, (loadingState, error) => {
    isOptionsTermLoading.set(loadingState);
    isOptionsError.set(error ?? null);
  });

  return {
    staticOptions,
    exactOptions,
    termOptions,
  };
}

function getIsTermHasMinLength(termSettings: OptionsProviderTerm | undefined, term?: Signal<string | null>) {
  if (!term || !termSettings) return signal(false);
  const minTermLength = getMinTermLength(termSettings);

  return computed(() => {
    const termValue = term();
    return !!termValue && termValue.length >= minTermLength();
  });
}

function getMinTermLength(termSettings: OptionsProviderTerm | undefined) {
  return termSettings?.minTermLength ?? signal(DEFAULT_MIN_TERM_LENGTH);
}

function getLoadingOptionsMessage(termSettings: OptionsProviderTerm | undefined) {
  const optionLoadingMessage = termSettings?.loadingMessage ?? signal(DEFAULT_LOADING_MESSAGE);
  return computed(() => {
    return optionLoadingMessage() ?? DEFAULT_LOADING_MESSAGE;
  });
}

function getTermEmptyMessage(
  selectedOption: Signal<unknown | null>,
  termSettings: OptionsProviderTerm | undefined,
  isTermHasMinLength: Signal<boolean>,
  term?: Signal<string | null>,
) {
  const messageValue = termSettings?.termEmptyMessage ?? signal(null);
  const minTermLength = getMinTermLength(termSettings);
  return computed(() => {
    if (!termSettings || !term) return null;
    const message = messageValue() ?? `Введите не менее ${minTermLength()} символов`;

    if (!isTermHasMinLength() && !selectedOption()) return message;
    return null;
  });
}

/**
 * Сортируем исходя из позиции подстроки в названии опции
 * Опции с одинаковой позицией сортируем лексикографически
 * @param term
 * @returns
 */
function termFirstSort(term: Nullable<string>) {
  if (!term) return null;
  const termLower = term.toLocaleLowerCase();
  return (a: SelectOption, b: SelectOption) => {
    const aTermPosition = a.label.toLocaleLowerCase().indexOf(termLower);
    const bTermPosition = b.label.toLocaleLowerCase().indexOf(termLower);
    if (aTermPosition < bTermPosition) return -1;
    if (aTermPosition > bTermPosition) return 1;
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  };
}
