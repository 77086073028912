/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DueDiligenceTaskStatus } from './dueDiligenceTaskStatus';
import { DueDiligenceInfoDto } from './dueDiligenceInfoDto';
import { DueDiligenceTaskType } from './dueDiligenceTaskType';
import { DateTime } from '@lib-utils';


export interface DueDiligenceTaskDto { 
    id: number;
    applicationId: number;
    created: DateTime;
    updated: DateTime;
    clientName: string;
    dueDiligenceUserId?: number | null;
    dueDiligenceUserName?: string | null;
    type: DueDiligenceTaskType;
    info: DueDiligenceInfoDto;
    status: DueDiligenceTaskStatus;
    comment?: string | null;
}
export namespace DueDiligenceTaskDto {
}


