/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationChangeType } from './applicationChangeType';
import { ApplicationChangeUserDto } from './applicationChangeUserDto';
import { ApplicationChangeAdditionalDataDto } from './applicationChangeAdditionalDataDto';
import { DateTime } from '@lib-utils';


export interface ApplicationChangeDto { 
    id: number;
    type: ApplicationChangeType;
    applicationId: number;
    time: DateTime;
    user: ApplicationChangeUserDto;
    additionalData?: ApplicationChangeAdditionalDataDto;
}
export namespace ApplicationChangeDto {
}


