/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdditionalCondition = 'Promotion' | 'MaternalInitialContribution' | 'Other';

export const AdditionalCondition = {
    Promotion: 'Promotion' as AdditionalCondition,
    MaternalInitialContribution: 'MaternalInitialContribution' as AdditionalCondition,
    Other: 'Other' as AdditionalCondition
};

