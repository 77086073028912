/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReasonForChoosing = 'BuyingHouseWithLand' | 'NonResident' | 'GoodDeal' | 'SellerCmif' | 'ClientEmployeeOfTheBank' | 'Other';

export const ReasonForChoosing = {
    BuyingHouseWithLand: 'BuyingHouseWithLand' as ReasonForChoosing,
    NonResident: 'NonResident' as ReasonForChoosing,
    GoodDeal: 'GoodDeal' as ReasonForChoosing,
    SellerCmif: 'SellerCmif' as ReasonForChoosing,
    ClientEmployeeOfTheBank: 'ClientEmployeeOfTheBank' as ReasonForChoosing,
    Other: 'Other' as ReasonForChoosing
};

