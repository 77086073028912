import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@lib-bff/api';
import { tap } from 'rxjs/operators';
import { LoggerService, NotificationService, resetAllFilterStates } from '@lib-utils';
import { AuthorizationStorageService } from '../services/authorization';

export const getLogout$ = () => {
  const router = inject(Router);
  const notificationService = inject(NotificationService);
  const authorizationStorageService = inject(AuthorizationStorageService);
  const loggerService = inject(LoggerService);
  const authApiService = inject(AuthApiService);

  return (saveCurrentPath: boolean = false) => {
    authorizationStorageService.onLogout$.next();

    if (saveCurrentPath) authorizationStorageService.saveReturnPath();

    resetAllFilterStates();

    notificationService.allClose();
    loggerService.clearUser();

    return authApiService.authControllerLogout().pipe(tap(() => void router.navigate(['auth', 'login'])));
  };
};
