/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressDetails } from './addressDetails';


export interface AddressData { 
    key?: string;
    label?: string | null;
    fiasId?: string | null;
    address?: string | null;
    content?: string | null;
    kladrId?: string | null;
    details?: AddressDetails;
}

