import { RemoteKeys } from '@lib-config';
import { RoleType } from '@lib-utils';
import { GetterFn } from '@lib-mortgage/widgets/default';

export const ApplicationModules = {
  Admin: 'Admin',
  Archive: 'Archive',
  Orders: 'Orders',
  Verificator: 'Verificator',
  VerificatorManager: 'VerificatorManager',
  Authorizer: 'Authorizer',
  Underwriter: 'Underwriter',
  CrmCollection: 'CrmCollection',
  Lawyer: 'Lawyer',
  Auto: 'Auto',
  AutoRisks: 'AutoRisks',
  AutoInspector: 'AutoInspector',
  AutoAdmin: 'AutoAdmin',
  DealDocumentsVerificator: 'DealDocumentsVerificator',
  Gozo: 'Gozo',
} as const;

export type ApplicationModules = (typeof ApplicationModules)[keyof typeof ApplicationModules];

export const LAWYER_SPECIAL_ROLES: RoleType[] = [RoleType.Lawyer, RoleType.LawyerSupervisor];

export function getRemoteLink(remoteKey: RemoteKeys, path?: string): GetterFn<string> {
  return ({ configService }) => {
    const remoteAppUrl = configService.getRemoteUrl(remoteKey);
    if (!remoteAppUrl) {
      // eslint-disable-next-line no-console
      console.warn(
        `Не найден адрес для удаленного модуля ${remoteKey}, создание ссылки "{${remoteKey}}/${path}" невозможно`,
      );

      return '#';
    }

    return `${remoteAppUrl}/${path || ''}`;
  };
}
