<div
  *tuiLet="state$ | async as state"
  [class.closing]="!state?.visible"
  [@openClose]="state?.visible ? 'open' : 'closed'"
  class="fnip-sidebar"
  (@openClose.done)="animationFinishedEvent($event)"
>
  <div class="content" *ngIf="state?.context as context">
    <ng-container *ngTemplateOutlet="context.view!; context: context.context!" />
  </div>
</div>
