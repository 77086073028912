@if (setLanguageObservable$ | async) {}

@if (isTranslateEnabled) {
  <fnip-reactive-select-new
    fieldId="languageFc"
    [style.width.px]="90"
    [optionContent]="languageOptionTmpl"
    [selectedOptionContent]="languageOptionTmpl"
    [fnipOptionsStatic]="getLanguageOptions$"
    [formControl]="languageFc"
    (valueChange)="setLanguage($event)"
  ></fnip-reactive-select-new>
}

<ng-template #languageOptionTmpl let-option>
  @if (option) {
    <img class="flag" [alt]="option.label" [src]="option.value | tuiFlag" [title]="option.label" />
  }
</ng-template>
