import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputPasswordModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputPasswordComponent } from './reactive-input-password.component';

const TUI_MODULES = [TuiInputPasswordModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ReactiveLabelModule, ReactiveFieldErrorModule, TUI_MODULES],
  declarations: [ReactiveInputPasswordComponent],
  exports: [ReactiveInputPasswordComponent],
})
export class ReactiveInputPasswordModule {}
