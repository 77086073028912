<fnip-reactive-label
  [hasRequiredValidator]="controlValueAccessor.isRequired"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-select
    [ngModel]="controlValue()"
    (ngModelChange)="controlValueAccessor.setControlValue($event)"
    [stringify]="stringifyText"
    [class.disabled-copy]="controlValueAccessor.isDisabled()"
    [disabled]="controlValueAccessor.isDisabled()"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiDropdownLimitWidth]="dropdownWidth"
    [tuiDropdownAlign]="dropdownAlign"
    [valueContent]="selectedOptionTemplate"
    [readOnly]="isReadOnly"
    [pseudoActive]="pseudoPressed"
    [pseudoInvalid]="controlValueAccessor.control.touched && controlValueAccessor.control.invalid"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    {{ placeholder }}
    <ng-container *tuiDataList>
      <cdk-virtual-scroll-viewport
        *tuiLet="optionListLoading() !== 'idle' ? [] : optionList() as items"
        tuiScrollable
        class="scroll"
        [itemSize]="optionHeight"
        [style.height.px]="items.length * optionHeight"
        [style.min-height.px]="items.length === 0 && textFieldSize === 's' ? 40 : undefined"
        (fnipIndexChange)="list.handleFocusLossIfNecessary()"
      >
        <tui-data-list
          #list
          [emptyContent]="
            optionListLoading() === 'loading'
              ? loadingTemplate
              : optionListLoading() === 'error'
                ? errorTemplate
                : emptyTemplate
          "
        >
          <ng-container *cdkVirtualFor="let item of items">
            <button *ngIf="!item.hide" tuiOption [value]="item.value" [disabled]="!!item.disabled">
              <ng-container *polymorpheusOutlet="optionContent ?? item.label as content; context: { $implicit: item }">
                {{ content }}
              </ng-container>
            </button>
          </ng-container>
        </tui-data-list>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </tui-select>

  <fnip-reactive-field-error
    *ngIf="!noValidationMark && optionListLoading() !== 'error'"
    [control]="controlValueAccessor.control"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = !!$event"
  ></fnip-reactive-field-error>

  <fnip-reactive-field-error
    *ngIf="optionListLoading() === 'error'"
    absolutePositionWithoutControl
    [error]="optionListErrorMessage()"
    (isErrorShow)="noBottomHint = !!$event"
  ></fnip-reactive-field-error>
</fnip-reactive-label>

<ng-template #emptyTemplate>
  {{ optionListEmptyMessage }}
</ng-template>

<ng-template #errorTemplate> Ошибка загрузки опций </ng-template>

<ng-template #loadingTemplate>
  <div class="d-flex p-1 gap-3 align-items-center">
    <tui-loader></tui-loader>
    <span class="font-size-18">{{ optionLoadingMessage() }}</span>
  </div>
</ng-template>

<ng-template #selectedOptionTemplate>
  <ng-container
    *polymorpheusOutlet="
      selectedOptionContent ?? selectedOption()?.label as content;
      context: { $implicit: selectedOption() }
    "
  >
    {{ content }}
  </ng-container>
</ng-template>
