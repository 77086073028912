/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalIncomeCalculationRequest } from './additionalIncomeCalculationRequest';
import { ExpenseCalculationRequest } from './expenseCalculationRequest';
import { UnderwriterEmploymentCalculationRequest } from './underwriterEmploymentCalculationRequest';


export interface UnderwriterClientCalculationRequest { 
    id?: number;
    additionalIncomes?: Array<AdditionalIncomeCalculationRequest> | null;
    expenses?: Array<ExpenseCalculationRequest> | null;
    employments?: Array<UnderwriterEmploymentCalculationRequest> | null;
}

