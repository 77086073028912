import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fnip-divider',
  template: '',
  styleUrls: ['divider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DividerComponent {
  @Input()
  set flexMode(value: boolean | '') {
    this._flexMode = value === '' || value ? true : value;
  }

  @HostBinding('class.fnip-divider__flex')
  _flexMode = false;
}
