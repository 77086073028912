import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class DialogOverlayContainer extends OverlayContainer {
  protected override _createContainer() {
    const container = document.createElement('div');
    container.classList.add('cdk-overlay-container');

    // Находим и создаем контейнер внутри tui-root для правильной работы стилей тайги
    const parent = document.querySelector('.t-root-content') || document.body;

    parent.appendChild(container);
    this._containerElement = container;
  }
}
