/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';
import { FileInfoDto } from './fileInfoDto';


export interface PassportVerificationTaskDto { 
    fileIds?: Array<number> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    verificatorId?: number | null;
    firstName?: string | null;
    middleName?: string | null;
    secondName?: string | null;
    sex?: Sex;
    birthday?: DateOnly | null;
    birthplace?: string | null;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDepCode?: string | null;
    issueDate?: DateOnly | null;
    birthCountryCode?: string | null;
    birthCountry?: string | null;
}
export namespace PassportVerificationTaskDto {
}


