/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';


export interface CreateUserRequest { 
    roleId?: number | null;
    roleCode?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    readonly fullName?: string | null;
    position?: string | null;
    password?: string | null;
    sex?: Sex;
    additionalEmails?: Array<string> | null;
}
export namespace CreateUserRequest {
}


