import { FromGeneratedEnum } from '../../interfaces';

export enum RoleType {
  SuperAdmin = 'SuperAdmin',
  Partner = 'Partner',
  PartnerManager = 'PartnerManager',
  PartnerSigner = 'PartnerSigner',
  Bank = 'Bank',
  BankManager = 'BankManager',
  BankSigner = 'BankSigner',
  Client = 'Client',
  Coborrower = 'Coborrower',
  System = 'System',
  Operator = 'Operator',
  OperatorAdmin = 'OperatorAdmin',
  VerifierHead = 'VerifierHead',
  VerifierSupervisor = 'VerifierSupervisor',
  Verificator = 'Verificator',
  AdminCalculator = 'AdminCalculator',
  Lawyer = 'Lawyer',
  LawyerSupervisor = 'LawyerSupervisor',
  VerificatorDeal = 'VerificatorDeal',
  GozoEstimator = 'GozoEstimator',
  GozoEstimatorSupervisor = 'GozoEstimatorSupervisor',
  EaSupport = 'Ea.Support',
  EaMlc = 'Ea.Mlc',
  EaAdmin = 'Ea.Admin',
  EaAutoCurator = 'Ea.Auto.Curator',
  EaAutoSupport = 'Ea.Auto.Support',
  SupervisorCrmCollection = 'SupervisorCrmCollection',
  ManagerCrmCollection = 'ManagerCrmCollection',
  DirectorCIK = 'DirectorCIK',
  MultiRole = 'MultiRole',
  ManagerVerificatorDeal = 'ManagerVerificatorDeal',
  AuthorizerOne = 'AuthorizerOne',
  AuthorizerSecond = 'AuthorizerSecond',
  ManagerAuthorizersOne = 'ManagerAuthorizersOne',
  ManagerAuthorizersSecond = 'ManagerAuthorizersSecond',
}

// TODO: Типы определены только на стороне FE. Уточнить у BE возможность типизировать `RoleType` на их стороне
export const RoleTypeMap: FromGeneratedEnum<RoleType> = {
  [RoleType.SuperAdmin]: 'BASE.ROLE_TYPE.SUPER_ADMIN',
  [RoleType.Partner]: 'BASE.ROLE_TYPE.PARTNER',
  [RoleType.PartnerManager]: 'BASE.ROLE_TYPE.PARTNER_MANAGER',
  [RoleType.PartnerSigner]: 'BASE.ROLE_TYPE.PARTNER_SIGNER',
  [RoleType.Bank]: 'BASE.ROLE_TYPE.BANK',
  [RoleType.BankManager]: 'BASE.ROLE_TYPE.BANK_MANAGER',
  [RoleType.BankSigner]: 'BASE.ROLE_TYPE.BANK_SIGNER',
  [RoleType.Client]: 'BASE.ROLE_TYPE.CLIENT',
  [RoleType.Coborrower]: 'BASE.ROLE_TYPE.COBORROWER',
  [RoleType.System]: 'BASE.ROLE_TYPE.SYSTEM',
  [RoleType.Operator]: 'BASE.ROLE_TYPE.OPERATOR',
  [RoleType.OperatorAdmin]: 'BASE.ROLE_TYPE.OPERATOR_ADMIN',
  [RoleType.VerifierSupervisor]: 'BASE.ROLE_TYPE.MANAGER_VERIFICATOR',
  [RoleType.Verificator]: 'BASE.ROLE_TYPE.VERIFICATOR',
  [RoleType.AdminCalculator]: 'BASE.ROLE_TYPE.ADMIN_CALCULATOR',
  [RoleType.Lawyer]: 'BASE.ROLE_TYPE.LAWYER',
  [RoleType.LawyerSupervisor]: 'BASE.ROLE_TYPE.LAWYER_SUPERVISOR',
  [RoleType.VerificatorDeal]: 'BASE.ROLE_TYPE.VERIFICATOR_DEAL',
  [RoleType.GozoEstimator]: 'BASE.ROLE_TYPE.GOZO_ESTIMATOR',
  [RoleType.GozoEstimatorSupervisor]: 'BASE.ROLE_TYPE.GOZO_ESTIMATOR_SUPERVISOR',
  [RoleType.EaSupport]: 'BASE.ROLE_TYPE.EA_SUPPORT',
  [RoleType.EaMlc]: 'BASE.ROLE_TYPE.EA_MLC',
  [RoleType.EaAdmin]: 'BASE.ROLE_TYPE.EA_ADMIN',
  [RoleType.EaAutoCurator]: 'BASE.ROLE_TYPE.EA_AUTO_CURATOR',
  [RoleType.EaAutoSupport]: 'BASE.ROLE_TYPE.EA_AUTO_SUPPORT',
  [RoleType.SupervisorCrmCollection]: 'BASE.ROLE_TYPE.SUPERVISOR_CRM_COLLECTION',
  [RoleType.ManagerCrmCollection]: 'BASE.ROLE_TYPE.MANAGER_CRM_COLLECTION',
  [RoleType.DirectorCIK]: 'BASE.ROLE_TYPE.DIRECTOR_CIK',
  [RoleType.MultiRole]: 'BASE.ROLE_TYPE.MULTI_ROLE',
  [RoleType.ManagerVerificatorDeal]: 'BASE.ROLE_TYPE.MANAGER_VERIFICATOR_DEAL',
  [RoleType.AuthorizerOne]: 'BASE.ROLE_TYPE.AUTHORIZER_ONE',
  [RoleType.AuthorizerSecond]: 'BASE.ROLE_TYPE.AUTHORIZER_SECOND',
  [RoleType.ManagerAuthorizersOne]: 'BASE.ROLE_TYPE.MANAGER_AUTHORIZERS_ONE',
  [RoleType.ManagerAuthorizersSecond]: 'BASE.ROLE_TYPE.MANAGER_AUTHORIZERS_SECOND',
};
