/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipType } from './ownershipType';
import { UserModel } from './userModel';
import { RealEstateModel } from './realEstateModel';
import { DateTime } from '@lib-utils';
import { OwnerModel } from './ownerModel';


export interface OwnerRealEstateMappingModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    realEstateId?: number;
    realEstate?: RealEstateModel;
    ownershipType?: OwnershipType;
    numerator?: number | null;
    denominator?: number | null;
    isActive?: boolean;
    acquisitionYear?: number | null;
    ownerId?: number;
    owner?: OwnerModel;
}
export namespace OwnerRealEstateMappingModel {
}


