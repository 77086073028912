import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import isNumber from 'lodash-es/isNumber';
import { Nullable } from '../../interfaces';

@Pipe({
  name: 'localizedNumber',
  pure: false,
  standalone: true,
})
export class LocalizedNumberPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Nullable<string | number>, digitsInfo?: string): string | null {
    if (!isNumber(value)) return null;

    const locale = this.translateService.currentLang;

    return formatNumber(value, locale, digitsInfo);
  }
}
