import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  finalize,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  FileInfo,
  FileInputAppearance,
  NotificationService,
  Nullable,
  reactiveTestAttributesHostDirective,
} from '@lib-utils';
import { GetFileBlobCallback } from '@lib-widgets/file-list';
import { GetFileListCallback, GetFileThumbnailCallback, RemoveFileCallback, UploadFileCallback } from './interfaces';
import { ACCEPT_FILE_TYPES, checkFileType, FileInfoUtils, processFile } from './utils';

@Component({
  selector: 'fnip-reactive-file-input',
  templateUrl: 'reactive-file-input.component.html',
  styleUrls: ['reactive-file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveFileInputComponent<TFileId extends string | number = string | number>
  implements OnDestroy, OnChanges
{
  @Input() control?:
    | AbstractControl<Nullable<FileInfo<TFileId> | FileInfo<TFileId>[]>>
    | FormControl<Nullable<FileInfo<TFileId> | FileInfo<TFileId>[]>>;
  @Input() label?: PolymorpheusContent;
  @Input() text?: string;
  @Input() accept?: ACCEPT_FILE_TYPES[];
  @Input() multiple = false;
  @Input() showAcceptCaption = false;
  @Input() showSize = false;
  @Input() isRequired: Nullable<boolean>;
  @Input() warningMessage?: Nullable<string>;
  @Input() isMobile: Nullable<boolean>;

  @Input() set isDisabled(value: boolean) {
    this._disabled = value;

    if (value) this.control?.disable();
    else this.control?.enable();
  }

  get isDisabled() {
    return this._disabled || (this.control?.disabled ?? false);
  }
  /**
   * Enables drug&drop zone without uploadFile$, removeFile$, getFileList$
   * Usable in forms with single post request
   */
  @Input() set isLocalUsage(value: boolean) {
    if (!value) return;
    this.uploadFile$ = (file: File) =>
      of({
        id: Math.random() as TFileId,
        name: file.name,
        cacheBlob: {
          headers: new Headers({ 'content-disposition': `filename=${encodeURIComponent(file.name)}` }),
          body: file,
        } as unknown as HttpResponse<Blob>,
      });
    this.getFileBlob$ = () => EMPTY;
    this.removeFile$ = () => of(null);
    this.getFileList$ = () => {
      if (this.control?.value instanceof Array) return of(this.control.value);
      if (this.control?.value) return of([this.control.value]);
      return of([]);
    };
  }

  loadingMessage = 'Загрузка...';

  isRemoteLoaded$ = new BehaviorSubject<boolean>(true);

  remoteFiles$: Nullable<Observable<Partial<FileInfo<TFileId>>[]>>;

  private _remoteFilesRq$: Nullable<GetFileListCallback<TFileId>>;

  get remoteFilesRq$(): Nullable<GetFileListCallback<TFileId>> {
    return this._remoteFilesRq$;
  }

  @Input() set getFileList$(getFileListRq$: Nullable<GetFileListCallback<TFileId>>) {
    this._remoteFilesRq$ = getFileListRq$;
    this.remoteFiles$ = this.getUpdatedRemoteFileList$().pipe(
      map((files) => files.filter((file): file is FileInfo<TFileId, unknown> => !isNil(file))),
    );
  }

  @Input() getFileBlob$: Nullable<GetFileBlobCallback<TFileId>>;
  @Input() removeFile$: Nullable<RemoveFileCallback<TFileId>>;
  @Input() uploadFile$: Nullable<UploadFileCallback<TFileId>>;
  @Input() getFileThumbnail$: Nullable<GetFileThumbnailCallback<TFileId>>;
  @Input() max?: number;
  @Input() maxSizeMb?: number;
  @Input() allowDownload = false;
  @Input() allowPreview = false;
  @Input() removeLocalFileOnReject = false;

  @Input()
  @HostBinding('class')
  appearance: FileInputAppearance = 'default';

  @Output() fileWasRejected = new EventEmitter<FileInfo<TFileId>>();
  @Output() maxLimitReached = new EventEmitter<void>();
  @Output() maxSizeReached = new EventEmitter<void>();
  @Output() fileRemovingFailed = new EventEmitter<FileInfo<TFileId>>();

  private _thumbnailCache = new Map<TFileId, Blob>();
  private _activeThumbnailRq$: Nullable<Observable<Blob[]>>;
  private _activeThumbnailIds: Nullable<TFileId>[] = [];

  private _disabled = false;

  removeFileAction$ = new Subject<FileInfo<TFileId>>();

  files$ = new BehaviorSubject<FileInfo<TFileId>[]>([]);

  fileList$ = this.files$.pipe(
    map((files) => [...files]),
    tap((files) => (this.multiple ? this.control?.setValue(files) : this.control?.setValue(files[0]))),
  );

  removeFileRequest$ = this.removeFileAction$.pipe(
    mergeMap((fileInfo) => {
      // Remove from local files
      if (!fileInfo.id) {
        this.removeFileFromList(fileInfo);
        return EMPTY;
      }

      // Find remote file
      const remoteFile = this.getFileFromList(fileInfo);
      if (remoteFile?.id && this.removeFile$) {
        // If there is remote file, we need to remove it from remote
        remoteFile.isPending = true;
        this.updateFileList();
        return this.removeFile$(remoteFile.id).pipe(
          // After that remove it from list
          tap({
            next: () => this.removeFileFromList(remoteFile),
            error: () => {
              remoteFile.isPending = false;
              this.fileRemovingFailed.emit(remoteFile);
            },
          }),
        );
      }

      return EMPTY;
    }),
  );

  private tasks = new Subscription();

  controlValueChanges$?: Observable<unknown>;

  constructor(private readonly notificationService: NotificationService) {}

  get hasRequiredValidator() {
    if (!this.control?.validator) return false;
    return this.control?.hasValidator(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) return;
    if (('isRequired' in changes || 'control' in changes) && !isNil(this.isRequired)) {
      if (this.isRequired) this.control.addValidators(Validators.required);
      else this.control.removeValidators(Validators.required);
      this.control.updateValueAndValidity();
    }

    if (('isDisabled' in changes || 'control' in changes) && !isNil(this.isDisabled)) {
      const isDisabled = this.isDisabled || this.control.parent?.disabled;
      if (isDisabled && this.control.enabled) this.control.disable({ emitEvent: false, onlySelf: true });
      if (!isDisabled && this.control.disabled) this.control.enable({ emitEvent: false, onlySelf: true });
    }
  }

  ngOnDestroy() {
    if (this.isRequired) {
      this.control?.removeValidators(Validators.required);
      this.control?.updateValueAndValidity();
    }
    this.tasks.unsubscribe();
  }

  onFileDropped(file: File) {
    const processedFile = processFile(file);
    const newLocalFile = FileInfoUtils.makeLocalFileInfo<TFileId>(processedFile);
    this.uploadLocalFile(newLocalFile, processedFile);
  }

  onFileRemoved(fileInfo: FileInfo<TFileId>) {
    this.removeFileAction$.next(fileInfo);
  }

  isLabelShown = (appearance: FileInputAppearance, max: Nullable<number>) => {
    if (appearance === 'default') return of(true);
    const maxFiles = this.multiple ? max ?? Infinity : 1;
    return this.fileList$.pipe(map((files) => files.length < maxFiles));
  };

  fetchFilesThumbnails$ = (
    getFileThumbnail$: Nullable<GetFileThumbnailCallback<TFileId>>,
    fileList: Nullable<FileInfo<TFileId>[]>,
  ) => {
    if (!getFileThumbnail$ || !fileList) return;
    // Если есть активный запрос по такому же списку файлов, то возвращаем его
    // Опираемся на то, что ссылки на объекты FileInfo остаются теми же, поэтому логика tap старых запросов отработает корректно
    const fileIds = fileList.map(({ id }) => id);
    if (!isEqual(this._activeThumbnailIds, fileIds) || !this._activeThumbnailRq$) {
      this._activeThumbnailIds = fileIds;
      this._activeThumbnailRq$ = combineLatest(
        fileList.map((file) => {
          if (!file.id || file.thumbnailSrc) return EMPTY;
          const fromCache = this._thumbnailCache.get(file.id);

          return (fromCache ? of(fromCache) : getFileThumbnail$(file.id)).pipe(
            tap((blob) => {
              file.thumbnailSrc = URL.createObjectURL(blob);
              if (!isNil(file.id)) {
                this._thumbnailCache.set(file.id, blob);
              }
              this.updateFileList();
            }),
          );
        }),
      ).pipe(
        finalize(() => {
          this._activeThumbnailIds = [];
          this._activeThumbnailRq$ = null;
        }),
      );
    }

    return this._activeThumbnailRq$;
  };

  private uploadLocalFile(localFileInfo: FileInfo<TFileId>, fileData: File) {
    if (!this.uploadFile$) return;

    if (!checkFileType(fileData, this.accept)) {
      this.notificationService.showWarning(`Неверный формат файла ${localFileInfo.name}`);
      return;
    }

    this.files$.next([localFileInfo, ...this.files$.value]);

    this.tasks.add(
      this.uploadFile$(fileData)
        .pipe(
          // Cancel if file was removed by user
          takeUntil(this.removeFileAction$.pipe(filter((removedFileInfo) => removedFileInfo === localFileInfo))),
          switchMap((fileOrEvent): Observable<Partial<FileInfo>> => {
            if (!fileOrEvent) {
              this.removeFileFromList(localFileInfo);
              return EMPTY;
            }

            if ('type' in fileOrEvent) {
              if (fileOrEvent.type === HttpEventType.UploadProgress)
                return of({ progress: fileOrEvent.progress, state: 'uploading' });
              if (fileOrEvent.type === HttpEventType.Response) return of(fileOrEvent.body);
            }

            return of(fileOrEvent);
          }),
          catchError((): Observable<Partial<FileInfo<TFileId>>> => {
            if (this.removeLocalFileOnReject) this.removeFileFromList(localFileInfo);
            this.fileWasRejected.emit(localFileInfo);
            localFileInfo.state = 'rejected';
            return of(localFileInfo);
          }),
          map(
            (uploadResult): FileInfo<TFileId> =>
              Object.assign(localFileInfo, {
                ...uploadResult,
                state: uploadResult.state ?? 'ready',
              }),
          ),
          switchMap((fileInfo) => {
            // file can be rejected by server
            if (fileInfo.id) {
              return this.getUpdatedRemoteFileList$();
            }

            this.updateFileList();
            return EMPTY;
          }),
          tap(() => this.removeFileFromList(localFileInfo)),
        )
        .subscribe(),
    );
  }

  getUpdatedRemoteFileList$() {
    if (!this.remoteFilesRq$) return EMPTY;

    this.isRemoteLoaded$.next(false);
    return this.remoteFilesRq$().pipe(
      tap((remoteFiles) => {
        const files = remoteFiles.map((item) => item && FileInfoUtils.makeRemoteFileInfo(item));
        this.files$.next([
          ...this.files$.value.filter((file) => !file.id),
          ...files.filter((item): item is FileInfo<TFileId> => !isNil(item)),
        ]);
      }),
      tap(() => this.isRemoteLoaded$.next(true)),
    );
  }

  private updateFileList() {
    this.files$.next([...this.files$.value]);
  }

  private removeFileFromList(file: FileInfo<TFileId>) {
    this.files$.next([...this.files$.value.filter((item) => (file.id && file.id !== item.id) || file !== item)]);
  }

  private getFileFromList(file: FileInfo<TFileId>) {
    return this.files$.value.find((item) => (file.id && file.id === item.id) || file === item);
  }
}
