import { Observable } from 'rxjs';
import {
  FnipNotification,
  FnipNotificationPaginationParams,
  FnipPaginatedNotificationList,
} from '../notification-list.models';

/**
 * Протокол списка нотификаций для работы с BE
 */
export abstract class NotificationSource<TBeData = unknown, TID extends string | number = number> {
  /**
   * Получение списка уведомлений, используя пагинацию, которые были до текущего момента
   * Предполагается, что список будет отсортирован по дате создания, от нового к старому
   */
  public abstract getNotificationListBefore(
    date: Date,
    params: FnipNotificationPaginationParams,
  ): Observable<FnipPaginatedNotificationList<TID, TBeData>>;

  /**
   * Получение списка новых уведомлений, с указанием времени после которого они пришли
   * Предполагается:
   * 1. Что список будет отсортирован по дате создания, от нового к старому
   * 2. Что список не будет содержать много элементов (так как обновляется каждые 10 секунд с передачей даты последнего полученного уведомления)
   */
  public abstract getNotificationsAfter(date: Date): Observable<FnipNotification<TID, TBeData>[]>;

  /**
   * Пометка о прочтении нотификации
   */
  public abstract markNotificationAsRead(notification: FnipNotification<TID, TBeData>): Observable<unknown>;
}
