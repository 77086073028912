/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditProductSortField = 'Name' | 'CurrencyAlphaCode' | 'CreditMin' | 'CreditMax' | 'PeriodMin' | 'PeriodMax';

export const CreditProductSortField = {
    Name: 'Name' as CreditProductSortField,
    CurrencyAlphaCode: 'CurrencyAlphaCode' as CreditProductSortField,
    CreditMin: 'CreditMin' as CreditProductSortField,
    CreditMax: 'CreditMax' as CreditProductSortField,
    PeriodMin: 'PeriodMin' as CreditProductSortField,
    PeriodMax: 'PeriodMax' as CreditProductSortField
};

