/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobType } from './jobType';
import { PensionFundIncomesDto } from './pensionFundIncomesDto';


export interface PensionFundOrganizationIncomesDto { 
    id?: number;
    jobType: JobType;
    organizationName: string;
    employmentId: number;
    incomes?: Array<PensionFundIncomesDto> | null;
}
export namespace PensionFundOrganizationIncomesDto {
}


