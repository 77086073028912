/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalIncomeType } from './additionalIncomeType';


export interface AdditionalIncomeDto { 
    id?: number;
    applicationId?: number;
    clientId?: number;
    value?: number;
    type?: AdditionalIncomeType;
    name?: string | null;
}
export namespace AdditionalIncomeDto {
}


