/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentType = 'BirthCertificate' | 'PassportRu' | 'DriverLicense' | 'ForeignPassport' | 'MilitaryId' | 'PreviousPassportRu' | 'PreviousPassportRu2' | 'PreviousPassportRu3' | 'EmploymentHistory';

export const DocumentType = {
    BirthCertificate: 'BirthCertificate' as DocumentType,
    PassportRu: 'PassportRu' as DocumentType,
    DriverLicense: 'DriverLicense' as DocumentType,
    ForeignPassport: 'ForeignPassport' as DocumentType,
    MilitaryId: 'MilitaryId' as DocumentType,
    PreviousPassportRu: 'PreviousPassportRu' as DocumentType,
    PreviousPassportRu2: 'PreviousPassportRu2' as DocumentType,
    PreviousPassportRu3: 'PreviousPassportRu3' as DocumentType,
    EmploymentHistory: 'EmploymentHistory' as DocumentType
};

