<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-input-date-time
    timeMode="HH:MM"
    [formControl]="$any(control)"
    [min]="minDateTime"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    <input
      tuiTextfield
      placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
      [id]="fieldId"
      [style.pointer-events]="control.disabled ? 'all' : 'auto'"
    />
  </tui-input-date-time>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
