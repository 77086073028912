/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationTaskPostponeReasonType } from './applicationTaskPostponeReasonType';
import { AuthorizerCalculationRequest } from './authorizerCalculationRequest';
import { ManualReviewTextSpecialConditionRequest } from './manualReviewTextSpecialConditionRequest';
import { ManualReviewDecisionType } from './manualReviewDecisionType';
import { AuthorizerRejectionType } from './authorizerRejectionType';
import { DateTime } from '@lib-utils';


export interface AuthorizerTaskDecisionRequest { 
    decisionType?: ManualReviewDecisionType;
    commentForDecision?: string | null;
    postponeTime?: DateTime | null;
    postponeReasonType?: ApplicationTaskPostponeReasonType;
    calculationRequest?: AuthorizerCalculationRequest;
    rejectionTypes?: Array<AuthorizerRejectionType> | null;
    textSpecialConditions?: Array<ManualReviewTextSpecialConditionRequest> | null;
}
export namespace AuthorizerTaskDecisionRequest {
}


