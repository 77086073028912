import { FileInfo } from '@lib-utils';

export class FileInfoUtils {
  public static makeLocalFileInfo<T extends string | number = string | number>(file: File): FileInfo<T> {
    return {
      size: file.size,
      name: file.name,
      state: 'uploading',
      file,
    };
  }

  public static makeRemoteFileInfo<T extends string | number = string | number>(
    remoteFileInfo: Partial<FileInfo<T>>,
  ): FileInfo<T> {
    return {
      name: '',
      state: 'ready',
      size: 0,
      ...remoteFileInfo,
    };
  }
}
