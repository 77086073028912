/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaskSource = 'Verificator' | 'Underwriter' | 'Authorizer1' | 'Authorizer2';

export const TaskSource = {
    Verificator: 'Verificator' as TaskSource,
    Underwriter: 'Underwriter' as TaskSource,
    Authorizer1: 'Authorizer1' as TaskSource,
    Authorizer2: 'Authorizer2' as TaskSource
};

