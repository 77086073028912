<ng-container *tuiLet="withLoading$ | executeWith: options | async as optionsList">
  <ng-container *tuiLet="currentValue$ | executeWith: control | async as controlValue">
    <ng-container ngDefaultControl [formControl]="$any(control)" (tuiValueChanges)="valueChange.emit($event)" />
    <tui-badged-content
      class="d-block"
      [colorTop]="showBadge && controlValue && controlValue !== defaultValue ? 'var(--tui-primary)' : ''"
    >
      <button
        tuiButton
        class="w-100"
        type="button"
        [disabled]="control!.disabled"
        [appearance]="appearance"
        [size]="size"
        [icon]="labelIconAlign === 'left' ? iconTmp : null"
        [iconRight]="labelIconAlign === 'right' ? iconTmp : null"
        [tuiDropdown]="dropdownTmp"
        [tuiDropdownManual]="open"
        [tuiDropdownAlign]="dropdownAlign"
        [tuiDropdownLimitWidth]="dropdownLimitWidth"
        (tuiObscured)="open = $event ? false : open"
        (tuiActiveZoneChange)="open = $event && open"
        (click)="open = !open"
      >
        <div>
          <span *ngIf="showSelectedValue">
            {{ getDefaultValueContent | executeWith: optionsMap | executeWith: $any(controlValue) }}
          </span>
          <ng-content />
        </div>
      </button>
    </tui-badged-content>

    <!-- Порядок вызыва важен 1 - setControlValue(item.value, optionsMap); 2 - setSelectedOption(item.value, optionsMap)" -->
    <ng-template #dropdownTmp>
      <div
        *ngIf="hasSearch"
        class="d-flex align-items-center gap-2 position-sticky"
        [style.z-index]="1"
        [style.top]="0"
      >
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          tuiTextfieldSize="m"
          class="flex-1"
          [style.min-width.px]="250"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="term"
        >
          Поиск
          <input placeholder="Поиск" tuiTextfield />
        </tui-input>
        <button
          tuiIconButton
          icon="tuiIconX"
          size="s"
          tuiHint="Отменить выбор"
          class="mr-2"
          (click)="clearControl()"
        ></button>
      </div>
      <tui-data-list>
        <tui-opt-group>
          <button
            *ngFor="let item of filterLoadedOptions | executeWith: optionsList : term"
            tuiOption
            fnipTestAttributes
            [disabled]="!!item.disabled"
            [testId]="fieldId + '-' + item.value"
            [testLabel]="item.label"
            (click)="setControlValue(item.value, optionsMap); setSelectedOption(item.value, optionsMap); open = false"
          >
            <ng-container
              *ngIf="optionTmp"
              [ngTemplateOutlet]="optionTmp"
              [ngTemplateOutletContext]="{ $implicit: item }"
            />

            <ng-container *ngIf="!optionTmp">
              <div class="d-flex w-100 content-space-between align-items-center">
                <span>{{ item.label }}</span>
                <tui-svg *ngIf="item.value === control?.value" src="tuiIconCheck" /></div
            ></ng-container>
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #iconTmp>
  <tui-svg src="tuiIconChevronDownLarge" class="icon" [class.icon_rotated]="open" />
</ng-template>
