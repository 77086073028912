<div
  class="d-flex gap-2 mb-4"
  [class.flex-column]="!isMobile"
  [class.d-none]="isLimitReached | executeWith: fileList : max"
>
  <label
    class="file-input flex-1"
    [class.mobile]="isMobile"
    [class.pending]="isPending"
    [class.disabled]="isDisabled"
    (fnipFileDnd)="onFilesDropped($event)"
    (change)="onFileChange(inputRef.files); inputRef.value = ''"
  >
    <span>{{ isMobile ? 'Добавить файлы' : label }}</span>
    <input
      #inputRef
      type="file"
      [accept]="getAcceptFileTypeString | executeWith: accept"
      [multiple]="multiple"
      [max]="max"
      [disabled]="isDisabled"
    />
  </label>
  <tui-tooltip *ngIf="isMobile" appearance="onDark" class="align-self-center" [content]="hintTmp" />
  <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="hintTmp"></ng-container>
</div>

<ng-template #hintTmp>
  <div class="d-flex flex-column gap-2 text-dark-grey font-small">
    <span *ngIf="multiple && max">
      Одновременно можно загрузить не более {{ max }} {{ max | plural: ['файла', 'файлов', 'файлов'] }}
    </span>
    <span *ngIf="getAcceptCaption | executeWith: accept : showAcceptCaption as acceptCaption"
      >Поддерживаемые форматы: {{ acceptCaption }}</span
    >
    <span *ngIf="maxSizeMb">Общий размер файлов не должен превышать {{ maxSizeMb }} Мб</span>
  </div>
</ng-template>
