/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { OrganizationModel } from './organizationModel';
import { AddressModel } from './addressModel';
import { DateOnly } from '@lib-utils';
import { ResidentialComplexModel } from './residentialComplexModel';
import { DateTime } from '@lib-utils';
import { BuildingAccreditationStatus } from './buildingAccreditationStatus';


export interface BuildingAddressModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    resComplexId?: number;
    residentialComplex?: ResidentialComplexModel;
    addressId?: number | null;
    address?: AddressModel;
    cadastralNumber?: string | null;
    isActive?: boolean;
    accreditationStatus?: BuildingAccreditationStatus;
    coefficient?: number | null;
    originalAddress?: string | null;
    stopFactors?: Array<string> | null;
    readinessDate?: DateOnly | null;
    readinessSetBy?: string | null;
    constructionReadiness?: number | null;
    actualDeliveryDate?: DateOnly | null;
    numberOfFlatsToCredit?: number | null;
    readiness?: number | null;
    estimatedDeliveryDate?: DateOnly | null;
    comment?: string | null;
    externalId?: number | null;
    organizationId?: number | null;
    organization?: OrganizationModel;
}
export namespace BuildingAddressModel {
}


