/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationDocumentStatus = 'Pending' | 'ForRevision' | 'Processed' | 'ApplicationArchived' | 'Archived' | 'RedirectedToSupervisor' | 'Postponed' | 'Pause';

export const VerificationDocumentStatus = {
    Pending: 'Pending' as VerificationDocumentStatus,
    ForRevision: 'ForRevision' as VerificationDocumentStatus,
    Processed: 'Processed' as VerificationDocumentStatus,
    ApplicationArchived: 'ApplicationArchived' as VerificationDocumentStatus,
    Archived: 'Archived' as VerificationDocumentStatus,
    RedirectedToSupervisor: 'RedirectedToSupervisor' as VerificationDocumentStatus,
    Postponed: 'Postponed' as VerificationDocumentStatus,
    Pause: 'Pause' as VerificationDocumentStatus
};

