/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { EmploymentSopdVerificationTaskDto } from './employmentSopdVerificationTaskDto';
import { AddressRequest } from './addressRequest';
import { EmployerPhoneInfoDto } from './employerPhoneInfoDto';
import { ClientPreviousNameDto } from './clientPreviousNameDto';
import { DateTime } from '@lib-utils';
import { FileInfoDto } from './fileInfoDto';


export interface SopdVerificationTaskDto { 
    fileIds?: Array<number> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    verificatorId?: number | null;
    secondName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    birthdate?: DateTime | null;
    birthplace?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssuedBy?: string | null;
    passportIssueDepCode?: string | null;
    passportIssueDate?: DateTime;
    registrationAddress?: AddressRequest;
    factualAddress?: AddressRequest;
    phone?: string | null;
    email?: string | null;
    previousName?: ClientPreviousNameDto;
    employersInfo?: Array<EmploymentSopdVerificationTaskDto> | null;
    employerPhonesInfo?: Array<EmployerPhoneInfoDto> | null;
    proofOfIncome?: string | null;
}

