import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputDateTimeModule } from '@taiga-ui/kit';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveInputDateTimeComponent } from './reactive-input-date-time.component';

const TUI_MODULES = [TuiInputDateTimeModule, TuiLabelModule, TuiTextfieldControllerModule, TuiValueChangesModule];

@NgModule({
  imports: [CommonModule, ReactiveLabelModule, ReactiveFieldErrorModule, ReactiveFormsModule, TUI_MODULES],
  declarations: [ReactiveInputDateTimeComponent],
  exports: [ReactiveInputDateTimeComponent],
})
export class ReactiveInputDateTimeModule {}
