/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { DateOnly } from '@lib-utils';
import { PreviousPassportVerificationTaskPassportDto } from './previousPassportVerificationTaskPassportDto';
import { FileInfoDto } from './fileInfoDto';


export interface PreviousPassportVerificationTaskDto { 
    fileIds?: Array<number> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    verificatorId?: number | null;
    previousPassports?: Array<PreviousPassportVerificationTaskPassportDto> | null;
    activePassportSeries?: string | null;
    activePassportNumber?: string | null;
    series?: string | null;
    number?: string | null;
    hasPreviousDocumentRecord?: boolean;
    documentComment?: string | null;
    currentPassportIssueDate?: DateOnly | null;
    birthday?: DateOnly | null;
}

