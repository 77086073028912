/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { FileType } from './fileType';
import { ApplicationFileModel } from './applicationFileModel';
import { DateTime } from '@lib-utils';


export interface DealDocumentFileModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    dealDocumentsId?: number;
    fileId?: number;
    fileContentType?: string | null;
    fileType?: FileType;
    relatedFileId?: number | null;
    file?: ApplicationFileModel;
}
export namespace DealDocumentFileModel {
}


