/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { MaritalStatus } from './maritalStatus';
import { AddressModel } from './addressModel';
import { DateTime } from '@lib-utils';


export interface PhysicalPersonInfoModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    physicalPersonId?: number;
    birthplace?: string | null;
    crmId?: string | null;
    registrationAddressId?: number | null;
    registrationAddress?: AddressModel;
    factualAddressId?: number | null;
    factualAddress?: AddressModel;
    residence?: string | null;
    phone?: string | null;
    email?: string | null;
    maritalStatus?: MaritalStatus;
    birthCountryCode?: string | null;
    birthCountry?: string | null;
}
export namespace PhysicalPersonInfoModel {
}


