import { HttpStatusCode } from '@angular/common/http';
import { ExceptionCodes } from '@lib-utils';
import { BEErrorInfo } from '../error-interceptor.interfaces';
import { ErrorCheckMessage } from '../error-interceptor.models';

export const MORTGAGE_ERROR_MESSAGES: ErrorCheckMessage<BEErrorInfo>[] = [
  {
    check: (_url, feError) => feError?.error?.code === ExceptionCodes.FileNotFound,
    message: 'Ошибка при загрузке данных',
  },
  {
    check: (_url, feError) => feError?.error?.code === ExceptionCodes.VerificationTaskNotFound,
    message: 'Задача уже обработана или не найдена. Пожалуйста, обновите страницу для получения следующей задачи',
  },
  {
    check: (url) =>
      !!url &&
      /api\/Client\/\d+/.test(url) &&
      !(url.includes('employmentsInfo') || url.includes('personal') || url.includes('sign')),
    message: 'Ошибка при сохранении данных',
  },
  {
    check: (url) => !!url && /api\/Client\/\d+\/documentInfo/.test(url),
    message: 'Ошибка при сохранении данных',
  },
  {
    check: (url, feError) =>
      !!url &&
      /api\/Client\/\d+\/employmentsInfo/.test(url) &&
      feError?.status === HttpStatusCode.BadRequest &&
      feError?.error?.numericCode === -130,
    message: 'Заполните все обязательные поля',
  },
  {
    check: (url) => !!url && /api\/ElectronicAchive\/\d+/.test(url),
    message: 'Не удалось отправить заявку в архив',
  },
  {
    check: (_url, feError) => feError?.exceptionCode === ExceptionCodes.EntityNotFound,
    message: 'Данные не найдены',
  },
];
