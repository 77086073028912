import { ActivatedRoute, Data } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { Breadcrumb, Nullable } from '@lib-utils';

export const createBreadcrumb = (title: string, url?: string) => ({ breadcrumb: { title, url } });
export const createNullBreadcrumb = () => ({ breadcrumb: null });

export const getBreadcrumbData = ({ breadcrumb }: Data): Nullable<Breadcrumb> => breadcrumb;
export const getBreadcrumbItem = (breadcrumb: Nullable<Breadcrumb>, currentUrl: string): Nullable<Breadcrumb> => {
  if (isNil(breadcrumb)) return null;
  return {
    title: breadcrumb.title,
    url: breadcrumb.url ?? currentUrl,
  };
};

export const mapRouteChildren = (route: Nullable<ActivatedRoute>, url = '', items: Breadcrumb[] = []): Breadcrumb[] => {
  if (isNil(route)) return items;
  const currentUrl = [url, ...route.snapshot.url.map(({ path }) => path)].join('/');

  const breadcrumbData = getBreadcrumbItem(getBreadcrumbData(route.snapshot.data), currentUrl);
  const breadcrumbs = isNil(breadcrumbData) ? [] : [breadcrumbData];

  return mapRouteChildren(route.firstChild, currentUrl, [...items, ...breadcrumbs]);
};
