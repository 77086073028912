/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationSortFields = 'Created' | 'EventType' | 'EventName' | 'DataType' | 'IsReaded';

export const NotificationSortFields = {
    Created: 'Created' as NotificationSortFields,
    EventType: 'EventType' as NotificationSortFields,
    EventName: 'EventName' as NotificationSortFields,
    DataType: 'DataType' as NotificationSortFields,
    IsReaded: 'IsReaded' as NotificationSortFields
};

