/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmploymentContract } from './employmentContract';
import { Snils } from './snils';
import { IncomeStatementBankFormat } from './incomeStatementBankFormat';
import { Passport } from './passport';
import { MarriageCertificate } from './marriageCertificate';
import { EmploymentHistory } from './employmentHistory';
import { ExplainedPresence } from './explainedPresence';
import { Inn } from './inn';
import { DigitalEmploymentHistory } from './digitalEmploymentHistory';
import { DriversLicense } from './driversLicense';
import { ForeignPassport } from './foreignPassport';
import { Ndfl2Solution } from './ndfl2Solution';


export interface Explained { 
    passport?: Passport;
    inn?: Inn;
    snils?: Snils;
    ndfl2?: Ndfl2Solution;
    employmentHistoryBasisDto?: EmploymentHistory;
    digitalEmploymentHistoryStdRBasisDto?: DigitalEmploymentHistory;
    digitalEmploymentHistoryStdPfrBasisDto?: DigitalEmploymentHistory;
    marriageCertificate?: MarriageCertificate;
    incomeStatementBankFormat?: IncomeStatementBankFormat;
    employmentContract?: EmploymentContract;
    passportMainPage?: ExplainedPresence;
    passportRegistrationPage?: ExplainedPresence;
    passportPages6_7?: ExplainedPresence;
    passportPages8_9?: ExplainedPresence;
    passportPages10_11?: ExplainedPresence;
    passportPages12_13?: ExplainedPresence;
    passportPages14_15?: ExplainedPresence;
    passportPages16_17?: ExplainedPresence;
    passportPages18_19?: ExplainedPresence;
    driversLicense?: DriversLicense;
    foreignPassport?: ForeignPassport;
}

