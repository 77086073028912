/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationDocumentFalsificationFlag } from './verificationDocumentFalsificationFlag';
import { VerificationDocumentResolution } from './verificationDocumentResolution';
import { RedirectConfirmation } from './redirectConfirmation';


export interface SupervisorUpdateTaskRequest { 
    confirmation?: RedirectConfirmation;
    documentResolutions?: Array<VerificationDocumentResolution> | null;
    falsificationFlags?: Array<VerificationDocumentFalsificationFlag> | null;
}
export namespace SupervisorUpdateTaskRequest {
}


