/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RealEstateDto } from './realEstateDto';
import { BuyerInfoDto } from './buyerInfoDto';
import { ReceivingType } from './receivingType';
import { OwnerDto } from './ownerDto';
import { DateOnly } from '@lib-utils';
import { EvaluateReportDto } from './evaluateReportDto';
import { CreateOrUpdateBuilderInfoRequest } from './createOrUpdateBuilderInfoRequest';
import { TransferType } from './transferType';
import { DealRegistrationType } from './dealRegistrationType';


export interface UpdateDealOnRealEstateStageRequest { 
    dealId?: number;
    agreementType?: DealRegistrationType;
    receivingType?: ReceivingType;
    transferType?: TransferType;
    contractOfSaleCost?: number | null;
    conclusionContractOfSalePlanningDate?: DateOnly | null;
    evaluateReport?: EvaluateReportDto;
    builder?: CreateOrUpdateBuilderInfoRequest;
    owners?: Array<OwnerDto> | null;
    realEstates?: Array<RealEstateDto> | null;
    buyers?: Array<BuyerInfoDto> | null;
}
export namespace UpdateDealOnRealEstateStageRequest {
}


