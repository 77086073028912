import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@lib-utils';
import { MenuItem } from './menu.item';

@Component({
  selector: 'fnip-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input({ required: true }) item!: MenuItem;

  @Input() url: Nullable<string>;

  @Input() expanded: Nullable<boolean>;

  dropdownOpened = false;

  closeDropdown = () => {
    this.dropdownOpened = false;
  };

  urlStartWith = (url: Nullable<string>, link: Nullable<string>) => !!(url && link && url.startsWith(link));

  isSomeChildrenActive = (url: Nullable<string>, item: MenuItem) => {
    return item.children?.map((i) => this.urlStartWith(url, i.link)).some(Boolean);
  };

  isRootItemActive = (url: Nullable<string>, item: MenuItem, expanded: Nullable<boolean>) => {
    if (!item.children?.length) return this.urlStartWith(url, item.link);
    return !expanded && this.isSomeChildrenActive(url, item);
  };
}
