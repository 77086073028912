/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepresentativeOrganizationMappingDto } from './representativeOrganizationMappingDto';
import { GroupCompaniesDto } from './groupCompaniesDto';
import { LegalEntityType } from './legalEntityType';
import { AddressDto } from './addressDto';


export interface OrganizationDto { 
    id?: number | null;
    name?: string | null;
    ogrn?: string | null;
    inn?: string | null;
    kpp?: string | null;
    addressId?: number | null;
    address?: AddressDto;
    groupCompanies?: GroupCompaniesDto;
    representativeMappings?: Array<RepresentativeOrganizationMappingDto> | null;
    legalEntityType?: LegalEntityType;
}
export namespace OrganizationDto {
}


