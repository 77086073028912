import { Pipe, PipeTransform } from '@angular/core';
import { Nullable, SelectOptions } from '@lib-utils';
import { OptionsProviderTermFn } from '../options.model';

/**
 * Для поддержки старого интерфейса, где может быть передан и term и static,
 * использует только function составляющую
 */
@Pipe({
  standalone: true,
  name: 'toTermOptions',
})
export class ToTermOptionsPipe implements PipeTransform {
  transform<T = unknown, TVal = unknown>(
    value: Nullable<SelectOptions<T, TVal>>,
  ): Nullable<OptionsProviderTermFn<T, TVal>> {
    if (typeof value !== 'function') return null;
    return value;
  }
}
