import { InjectionToken, Provider } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ConfigService } from '@lib-config';

export type CookieChangedType = Subject<string | null>;

export const COOKIE_CHANGED = new InjectionToken<CookieChangedType>('COOKIE_CHANGED');

export const PROVIDE_COOKIE_CHANGED_BY_COOKIE_STORE: Provider = {
  provide: COOKIE_CHANGED,
  deps: [CookieService, ConfigService],
  useFactory: (cookieService: CookieService, configService: ConfigService): CookieChangedType => {
    const tokenCookieChanged = new Subject<string | null>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cookieStoreApi = (window as never)['cookieStore'] as any;
    if (!cookieStoreApi) return tokenCookieChanged;

    const cookieName = configService.tokenCookieName;
    cookieStoreApi.addEventListener('change', () => {
      const cookieToken = cookieService.check(cookieName) ? cookieService.get(cookieName) : null;
      tokenCookieChanged.next(cookieToken);
    });

    return tokenCookieChanged;
  },
};
