/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventUserDto } from './eventUserDto';
import { EventType } from './eventType';
import { RecipientType } from './recipientType';


export interface EventDto { 
    id?: number;
    name?: string | null;
    recipientType?: RecipientType;
    eventType?: EventType;
    template?: string | null;
    description?: string | null;
    eventUsers?: Array<EventUserDto> | null;
}
export namespace EventDto {
}


