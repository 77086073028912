<div class="menu px-1" *tuiLet="url$ | async as url" [@expandCollapse]="expanded ? 'expanded' : 'collapsed'">
  <ng-container *ngFor="let item of items; trackBy: trackByLink">
    <fnip-menu-item *fnipAccess="item.permission" class="mb-1" [url]="url" [expanded]="expanded" [item]="item" />
  </ng-container>
  <fnip-button
    appearance="menu-item"
    class="expand-button"
    [label]="expanded ? 'Свернуть' : null"
    [icon]="expanded ? 'tuiIconChevronLeft' : 'tuiIconChevronRight'"
    [actionCallback$]="changeMode"
    [btnHint]="expanded ? null : 'Развернуть'"
    [btnHintAppearance]="'onDark'"
  />
</div>
