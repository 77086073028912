import { Nullable } from './nullable';

export interface FileInfoScan<T> {
  type: FileInfoScanTypes;
  fileType: Nullable<string>;
  value: string;
  values?: string[];
  fileId?: T;
  fileName?: Nullable<string>;
}

export enum FileInfoScanTypes {
  Image = 'image',
  Tif = 'tif',
  Pdf = 'pdf',
  Other = 'other',
}
