export enum ApplicationStatus {
  /** Черновик */
  Draft = 'Draft',

  /** Верификация */
  Verification = 'Verification',

  /** Доработка анкеты */
  FinalizationOfForm = 'FinalizationOfForm',

  /** Доработка одобренной анкеты */
  FinalizationOfApprovedForm = 'FinalizationOfApprovedForm',

  /** В работе */
  CompleteFormState = 'CompleteFormState',

  /** Доработка документов */
  FinalizationOfDocuments = 'FinalizationOfDocuments',

  /** Документы в порядке */
  DocumentsApproved = 'DocumentsApproved',

  /** Проверки внутренних источников */
  InternallSourceChecks = 'InternallSourceChecks',

  /** Прескоринг */
  PreScoring = 'PreScoring',

  /** Анализ кредитной истории */
  AnalyzeCreditHistory = 'AnalyzeCreditHistory',

  /** Расчет среднемесячного дохода */
  CalculatePerMonthPayment = 'CalculatePerMonthPayment',

  /** Расчет максимальной суммы кредита */
  CalculateMaxSumOfCredit = 'CalculateMaxSumOfCredit',

  /** Отклонено */
  Deny = 'Deny',

  /** В обработке */
  ProcessingFinal = 'ProcessingFinal',

  /** Направление на рассмотрение */
  CompleteInProgressState = 'CompleteInProgressState',

  /** Скоринг */
  Scoring = 'Scoring',

  /** Проверки документов, подтверждающих трудовую занятость и доход */
  CheckDocumentsThatApproveEmploymentsAndIncome = 'CheckDocumentsThatApproveEmploymentsAndIncome',

  /** Верификация телефонов и документов работодателя */
  VerificationsPhonesAndDocsOfEmployee = 'VerificationsPhonesAndDocsOfEmployee',

  /** Проверка черных списков */
  CheckBlacklists = 'CheckBlacklists',

  /** Подтверждение занятости и дохода */
  ApproveEmploymentAndIncome = 'ApproveEmploymentAndIncome',

  /** Проведение финансового анализа */
  MakeFinancialAnalyze = 'MakeFinancialAnalyze',

  /** Анализ результатов проверок Верификаторов и Андеррайтеров */
  AnalyzeResultOfVerificatorsAndUnderwritersChecks = 'AnalyzeResultOfVerificatorsAndUnderwritersChecks',

  /** Расчет ПДН */
  CalculatePnd = 'CalculatePnd',

  /** Проверки Авторизатора 1 */
  CheckAuthorizerOne = 'CheckAuthorizerOne',

  /** Проверки Авторизатора 2 */
  CheckAuthorizerTwo = 'CheckAuthorizerTwo',

  /** Одобрено */
  Approve = 'Approve',

  /** Направление на поиск объекта */
  CompleteOnDecisionState = 'CompleteOnDecisionState',

  /** Поиск объекта */
  SearchRealEstate = 'SearchRealEstate',

  /** Получение ЕГРН */
  GetEGRN = 'GetEGRN',

  /** ЕГРН получен */
  EGRNIsGetting = 'EGRNIsGetting',

  /** Доработка решения */
  FinalizeDecision = 'FinalizeDecision',

  /** Доработка решения и объекта */
  FinalizeDecisionAndRealEstate = 'FinalizeDecisionAndRealEstate',

  /** Доработка объекта */
  FinalizeRealEstate = 'FinalizeRealEstate',

  /** Скоринг продавца(ов) ФЛ */
  ScoringSellerCompany = 'ScoringSellerCompany',

  /** Юридическая экспертиза */
  JuristicInvestigation = 'JuristicInvestigation',

  /** Запрошен УПРИД */
  RequestUPRID = 'RequestUPRID',

  /** Проверка УПРИД */
  CheckUPRID = 'CheckUPRID',

  /** Оценка ГОЗО */
  CheckGozo = 'CheckGozo',

  /** Подготовка документов */
  PrepareDocuments = 'PrepareDocuments',

  /** Страхование */
  Insurance = 'Insurance',

  /** Доработка документов */
  FinalizeDocuments = 'FinalizeDocuments',

  /** Верифицирована */
  Verified = 'Verified',

  /** Доработка сделки */
  FinalizeDeal = 'FinalizeDeal',

  /** Верификация документов */
  VerificationDocumentsForDeal = 'VerificationDocumentsForDeal',

  /** Запрос отчета об оценке */
  RequestAssessmentReport = 'RequestAssessmentReport',

  /** Отчет об оценке получен */
  AssessmentReportReceive = 'AssessmentReportReceive',

  /** Закладная подписана */
  EncumbranceSigned = 'EncumbranceSigned',

  /** Запрос выписки из ЕГРН */
  RequestEGRN = 'RequestEGRN',

  /** Автоматическая проверка обременения */
  AutomaticCheckEncumbrance = 'AutomaticCheckEncumbrance',

  /** Ручная проверка обременения */
  ManualCheckEncumbrance = 'ManualCheckEncumbrance',

  /** Обременение зарегистрировано */
  EncumbranceRegistared = 'EncumbranceRegistared',

  /** Выдан */
  DeliveredCredits = 'DeliveredCredits',

  /** Архивирована */
  UndeliveredCredits = 'UndeliveredCredits',

  /** Запрос в Verus/Grifid */
  PhoneVerificationServiceRequest = 'PhoneVerificationServiceRequest',
}
