/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserFilterType = 'FirstName' | 'LastName' | 'MiddleName' | 'Email' | 'RoleId' | 'Position' | 'FullName' | 'RoleCode' | 'MultiRoleRoleCodes';

export const UserFilterType = {
    FirstName: 'FirstName' as UserFilterType,
    LastName: 'LastName' as UserFilterType,
    MiddleName: 'MiddleName' as UserFilterType,
    Email: 'Email' as UserFilterType,
    RoleId: 'RoleId' as UserFilterType,
    Position: 'Position' as UserFilterType,
    FullName: 'FullName' as UserFilterType,
    RoleCode: 'RoleCode' as UserFilterType,
    MultiRoleRoleCodes: 'MultiRoleRoleCodes' as UserFilterType
};

