/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InsuranceForm = 'Kss' | 'KssAndKssPlus' | 'Ass' | 'WithoutInsurance';

export const InsuranceForm = {
    Kss: 'Kss' as InsuranceForm,
    KssAndKssPlus: 'KssAndKssPlus' as InsuranceForm,
    Ass: 'Ass' as InsuranceForm,
    WithoutInsurance: 'WithoutInsurance' as InsuranceForm
};

