<fnip-reactive-label
  *ngIf="control"
  [class.inactive]="isInactive"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [hintPosition]="hintPosition"
  [hintStyle]="hintStyle"
  [isBold]="isLabelBold"
>
  <tui-input-number
    [formControl]="$any(control)"
    [tuiTextfieldCleaner]="textfieldCleaner"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    [tuiTextfieldIconLeft]="iconAlign === 'left' ? icon : null"
    [tuiTextfieldIcon]="iconAlign === 'right' ? icon : null"
    [precision]="precision"
    [min]="min"
    [max]="max"
    [decimal]="decimal"
    [postfix]="postfix"
    [readOnly]="isReadOnly"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    {{ placeholder }}
    <input tuiTextfield [id]="fieldId" [style.pointer-events]="control.disabled ? 'all' : 'auto'" [id]="fieldId" />
  </tui-input-number>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
