import { ExceptionCodes } from '@lib-utils';
import { ErrorCheckMessage } from '../error-interceptor.models';

export const COMMON_ERROR_MESSAGES: ErrorCheckMessage[] = [
  {
    check: (_url, feError) => feError?.exceptionCode === ExceptionCodes.UserNotFound,
    message: 'Пользователь с таким адресом электронной почты не найден',
  },
  {
    check: (_url, feError) => feError?.exceptionCode === ExceptionCodes.UserAlreadyExist,
    message: 'Пользователь с таким адресом электронной почты уже существует',
  },
];
