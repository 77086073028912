import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiTextAreaModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ReactiveFieldErrorModule } from '../reactive-field-error';
import { ReactiveLabelModule } from '../reactive-label';
import { ReactiveTextAreaComponent } from './reactive-text-area.component';

const TUI_MODULES = [
  TuiTextAreaModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiHostedDropdownModule,
  TuiDropdownModule,
  TuiDataListModule,
  TuiLetModule,
  TuiValueChangesModule,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveLabelModule,
    ReactiveFieldErrorModule,
    ReactiveFormsModule,
    ExecuteWithPipeModule,
    TUI_MODULES,
  ],
  declarations: [ReactiveTextAreaComponent],
  exports: [ReactiveTextAreaComponent],
})
export class ReactiveTextAreaModule {}
