/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PtiInfoDto } from './ptiInfoDto';


export interface ScoringInfoDto { 
    applicationScore?: number | null;
    okbScore?: number | null;
    nbkiScore?: number | null;
    equifaxScore?: number | null;
    pdScore?: number | null;
    consolidatedCreditReportUrl?: string | null;
    ptiInfo?: PtiInfoDto;
    maxCreditSumMedianPaymentsReportUrl?: string | null;
}

