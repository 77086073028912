import { booleanAttribute, Directive, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Nullable } from '../../interfaces/types';

@Directive({
  standalone: true,
  selector: '[fnipRequiredIf]',
})
export class RequiredIfDirective implements OnChanges, OnDestroy {
  @Input({ transform: booleanAttribute }) fnipRequiredIf = false;
  @Input({ required: true }) control: Nullable<AbstractControl>;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) return;

    if ('fnipRequiredIf' in changes || 'control' in changes) {
      if (this.fnipRequiredIf) this.control.addValidators(Validators.required);
      else this.control.removeValidators(Validators.required);
      this.control.updateValueAndValidity({ emitEvent: !changes?.['requiredIf']?.firstChange, onlySelf: false });
    }
  }

  ngOnDestroy(): void {
    if (this.fnipRequiredIf) {
      this.control?.removeValidators(Validators.required);
      this.control?.updateValueAndValidity();
    }
  }
}
