import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiContextWithImplicit } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';

@Component({
  selector: 'fnip-reactive-multi-select',
  templateUrl: './reactive-multi-select.component.html',
  styleUrls: ['./reactive-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveMultiSelectComponent<T = unknown> extends AbstractReactiveWithOptions<T> {
  @Input() valueContent: PolymorpheusContent<TuiContextWithImplicit<readonly string[]>>;
  @Input() isExpandable = true;
  @Input() useVirtualScroll = false;
}
