/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationDecisionType = 'Approval' | 'Rejection' | 'MovingToManagerRevision' | 'MovingToUnderwriterRevision' | 'RedirectingToAnotherAuthorizer';

export const ApplicationDecisionType = {
    Approval: 'Approval' as ApplicationDecisionType,
    Rejection: 'Rejection' as ApplicationDecisionType,
    MovingToManagerRevision: 'MovingToManagerRevision' as ApplicationDecisionType,
    MovingToUnderwriterRevision: 'MovingToUnderwriterRevision' as ApplicationDecisionType,
    RedirectingToAnotherAuthorizer: 'RedirectingToAnotherAuthorizer' as ApplicationDecisionType
};

