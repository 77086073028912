import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fnipFileDnd]',
})
export class FileDndDirective {
  @HostBinding('class.active') active = false;

  @Output('fnipFileDnd') fileDropped = new EventEmitter<File[]>();

  // Dragover listener
  @HostListener('dragover')
  onDragOver(): boolean {
    this.active = true;
    return false; // preventDefault
  }

  // Dragleave listener
  @HostListener('dragleave')
  onDragLeave(): boolean {
    this.active = false;
    return false; // preventDefault
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  onDrop(event: MouseEvent & { dataTransfer: DataTransfer }) {
    this.active = false;
    const { files } = event.dataTransfer;
    if (files.length > 0) {
      this.fileDropped.emit(Array.from(files));
    }

    return false; // preventDefault
  }
}
