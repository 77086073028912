import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@lib-utils';

@Component({
  selector: 'fnip-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PrivacyPolicyComponent {
  @Input() color: 'white' | 'grey' = 'grey';

  @Input() appName: Nullable<string>;

  @Input() version: Nullable<string>;

  currentYear = new Date().getFullYear();
}
