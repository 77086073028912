/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { AddressRequest } from './addressRequest';


export interface CreateOrUpdatePhysicalPersonInfoRequest { 
    id?: number;
    factualAddress?: AddressRequest;
    registrationAddress?: AddressRequest;
    birthplace?: string | null;
    residence?: string | null;
    phone?: string | null;
    email?: string | null;
    maritalStatus?: MaritalStatus;
}
export namespace CreateOrUpdatePhysicalPersonInfoRequest {
}


