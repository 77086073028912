/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmploymentDataRequest } from './employmentDataRequest';
import { SpouseSocialStatus } from './spouseSocialStatus';
import { Education } from './education';
import { MaritalContract } from './maritalContract';
import { ReasonForResidence } from './reasonForResidence';
import { Citizenship } from './citizenship';
import { EmploymentStatus } from './employmentStatus';
import { InsurancePackage } from './insurancePackage';
import { CreateOrUpdatePhysicalPersonRequest } from './createOrUpdatePhysicalPersonRequest';


export interface ClientDataRequest { 
    physicalPerson?: CreateOrUpdatePhysicalPersonRequest;
    employmentStatus?: EmploymentStatus;
    citizenship?: Citizenship;
    homePhone?: string | null;
    isPhoneConfirmed?: boolean;
    hasNoRegistration?: boolean | null;
    communalApartment?: boolean | null;
    reasonForResidence?: ReasonForResidence;
    education?: Education;
    educationOther?: string | null;
    maritalContract?: MaritalContract;
    spouseSocialStatus?: SpouseSocialStatus;
    children?: number | null;
    childrenAges?: string | null;
    familyMembers?: number | null;
    dependents?: number | null;
    isPublicOfficial?: boolean | null;
    isBankrupt?: boolean | null;
    insuranceType?: InsurancePackage;
    employments?: Array<EmploymentDataRequest> | null;
}
export namespace ClientDataRequest {
}


