import {
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs/operators';
import { intersectionObservable } from '../../functions';

@Directive({
  selector: '[fnipIntersectionObserver]',
  standalone: true,
})
export class IntersectionObserverDirective implements OnInit {
  @Input({ transform: numberAttribute }) threshold = 0;
  @Input({ transform: numberAttribute }) debounceTime = 0;
  @Output() intersected = new EventEmitter<boolean>();

  private readonly elementRef = inject(ElementRef);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    intersectionObservable(this.elementRef.nativeElement, {
      threshold: this.threshold,
    })
      .pipe(debounceTime(this.debounceTime), takeUntilDestroyed(this.destroyRef))
      .subscribe((intersected) => this.intersected.emit(intersected));
  }
}
