/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { FileType } from './fileType';
import { ApplicationFileCheckModel } from './applicationFileCheckModel';
import { SourceType } from './sourceType';
import { ApplicationModel } from './applicationModel';
import { DateTime } from '@lib-utils';


export interface ApplicationFileModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    name?: string | null;
    objectName?: string | null;
    type?: FileType;
    isDeleted?: boolean;
    size?: number;
    contentType?: string | null;
    applicationId?: number;
    application?: ApplicationModel;
    fileChecks?: Array<ApplicationFileCheckModel> | null;
    sourceType?: SourceType;
}
export namespace ApplicationFileModel {
}


