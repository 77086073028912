import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Nullable } from '@lib-utils';

@Directive({
  selector: '[fnipNumberInput]',
})
export class NumberInputDirective {
  @Input() set fnipNumberInput(isNumberInput: boolean | string) {
    this.isNumberInput = typeof isNumberInput === 'boolean' ? isNumberInput : true;
  }

  private isNumberInput = true;

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  static matchPattern(str: Nullable<string>) {
    return !str || /^\d+$/.test(str);
  }

  static transformToPattern(str: Nullable<string>): Nullable<string> {
    return str?.replace(/\D/g, '');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isNumberInput || this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value || '';
    const next: string = current.concat(event.key);
    if (!event.ctrlKey && !NumberInputDirective.matchPattern(next)) {
      event.preventDefault();
    }
  }
}
