/**
 * Определяет количество столбцов в CSS гриде
 * @param element HTML элемент с указанным display: grid
 * @returns количество столбцов в гриде
 */
export function getCssGridColumnsCount(element: HTMLElement) {
  const computedStyles = window.getComputedStyle(element);
  if (computedStyles.display !== 'grid') throw new Error('You must set the element display css property to "grid"');
  return computedStyles.getPropertyValue('grid-template-columns').replace(' 0px', '').split(' ').length;
}
