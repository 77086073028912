import { booleanAttribute, Component, Input } from '@angular/core';
import { Nullable, reactiveTestAttributesHostDirective, SelectOption } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-radio-block',
  templateUrl: './reactive-radio-block.component.html',
  styleUrls: ['./reactive-radio-block.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveRadioBlockComponent<T = unknown> extends AbstractReactive {
  @Input({ required: true }) options: Nullable<SelectOption<unknown, T>[]>;
  @Input() btnSize: 's' | 'm' | 'l' = 'm';
  @Input() hideRadio = true;
  @Input() contentAlign: 'left' | 'right' = 'right';
  @Input() radioWidth: Nullable<number>;
  @Input({ transform: booleanAttribute }) canClear = false;
}
