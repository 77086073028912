/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { SpouseSocialStatus } from './spouseSocialStatus';
import { Education } from './education';
import { MaritalContract } from './maritalContract';


export interface ClientSocialInfoDto { 
    education?: Education;
    educationOther?: string | null;
    maritalStatus?: MaritalStatus;
    maritalContract?: MaritalContract;
    spouseSocialStatus?: SpouseSocialStatus;
    children?: number | null;
    childrenAges?: Array<string> | null;
    familyMembers?: number | null;
    dependents?: number | null;
}
export namespace ClientSocialInfoDto {
}


