/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationStatus } from './applicationStatus';


export interface ApplicationActionResultDto { 
    isSuccessful: boolean;
    message?: string | null;
    readonly internalErrorCode?: number | null;
    status?: ApplicationStatus;
}

