import { randomIntNumber } from './random-number';

const DAY_IN_MS = 1000 * 60 * 60 * 24;
const RANGE = 365;

export function randomDate(from?: Date, to?: Date): Date {
  const min = from?.getTime() ?? new Date().getTime();
  const max = to?.getTime() ?? min + RANGE * DAY_IN_MS;
  return new Date(randomIntNumber(min, max));
}

export function randomDateString(from?: Date, to?: Date): string {
  return randomDate(from, to).toString();
}
