<ng-container *tuiLet="withLoading$ | executeWith: options | async as optionsList">
  <fnip-reactive-label
    *ngIf="control"
    [hasRequiredValidator]="hasRequiredValidator"
    [label]="label"
    [size]="labelSize"
    [hint]="hint"
    [noBottomHint]="noBottomHint"
    [isBold]="isLabelBold"
  >
    <ng-container *ngIf="setCheckboxOptions | executeWith: optionsList : control.value" />
    <ng-container ngDefaultControl [formControl]="$any(control)" (tuiValueChanges)="valueChange.emit($event)" />
    <div *ngIf="checkboxOptions?.length" class="d-flex flex-column gap-4">
      <tui-checkbox-labeled
        fnipTestAttributes
        *ngIf="hasSelectAll"
        [nativeId]="fieldId + 'all'"
        [testId]="fieldId + 'all'"
        [testLabel]="selectAllLabel"
        [size]="size"
        [(ngModel)]="selectAll"
        (ngModelChange)="selectAllChange($event)"
      >
        <span class="font-weight-bold label">{{ selectAllLabel }}</span>
      </tui-checkbox-labeled>
      <tui-checkbox-labeled
        fnipTestAttributes
        *ngFor="let option of checkboxOptions; trackBy: trackByValue"
        [nativeId]="fieldId + option.value"
        [testId]="fieldId + '-' + option.value"
        [testLabel]="option.label"
        [size]="size"
        [(ngModel)]="option.checked"
        (ngModelChange)="optionChange(option, $event)"
      >
        <span class="label">{{ option.label }}</span>
      </tui-checkbox-labeled>
    </div>
  </fnip-reactive-label>
</ng-container>
