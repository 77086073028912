<div class="page-container">
  <div class="logo-text-container">
    <div class="logo-container"></div>
    <div class="text-container">
      <h2>Вы перешли на несуществующую страницу</h2>
      <button tuiButton type="button" appearance="outline" class="tui-space_right-3" (click)="onBack()">Назад</button>
      <a tuiButton type="button" appearance="accent" [routerLink]="['/']">Перейти на главную</a>
    </div>
  </div>
</div>
