/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PensionFundModel } from './pensionFundModel';
import { UserModel } from './userModel';
import { ClientModel } from './clientModel';
import { FileHashModel } from './fileHashModel';
import { ClientFilesSignsMappingModel } from './clientFilesSignsMappingModel';
import { FileType } from './fileType';
import { Ndfl2Model } from './ndfl2Model';
import { EmploymentModel } from './employmentModel';
import { BankStatementModel } from './bankStatementModel';
import { DateTime } from '@lib-utils';


export interface ClientFileModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    name?: string | null;
    objectName?: string | null;
    type?: FileType;
    isDeleted?: boolean;
    size?: number;
    contentType?: string | null;
    clientId?: number;
    client?: ClientModel;
    employmentId?: number | null;
    employment?: EmploymentModel;
    replacedFile?: ClientFileModel;
    fileHash?: FileHashModel;
    replacedFileId?: number | null;
    externalId?: string;
    version?: number;
    ndfl2s?: Array<Ndfl2Model> | null;
    bankStatements?: Array<BankStatementModel> | null;
    pensionFund?: PensionFundModel;
    clientFilesSignsMappingId?: number | null;
    clientFilesSignsMapping?: ClientFilesSignsMappingModel;
}
export namespace ClientFileModel {
}


