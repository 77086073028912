import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subject } from 'rxjs';
import { SidebarContext, SidebarId, SidebarState } from './sidebar.interfaces';

@Injectable()
export class SidebarService {
  private readonly registeredContexts = new Map<SidebarId, SidebarContext>();

  public readonly closeAfterAnimation$ = new Subject<SidebarId>();

  private readonly _state$ = new BehaviorSubject<SidebarState>({
    context: null,
    visible: false,
  });

  public get closed$(): Observable<SidebarId> {
    return this._state$.pipe(
      filter(({ visible, context }) => !visible && !!context),
      map(({ context }) => context?.id ?? ''),
      distinctUntilChanged(),
    );
  }

  public set state(newState: SidebarState) {
    this._state$.next(newState);
  }

  public get state() {
    return this._state$.value;
  }

  public get state$() {
    return this._state$.asObservable();
  }

  register(options: SidebarContext): void {
    this.registeredContexts.set(options.id, options);
  }

  unregister(id: SidebarId): void {
    this.registeredContexts.delete(id);
  }

  sidebarVisible$(id: SidebarId) {
    return this.state$.pipe(
      map((state) => state.context?.id === id && state.visible),
      distinctUntilChanged(),
    );
  }

  sidebarVisible(id: SidebarId) {
    return this.state.context?.id === id && this.state.visible;
  }

  open(id: SidebarId): void {
    const context = this.registeredContexts.get(id);
    if (!context) return;

    this.state = {
      context,
      visible: true,
    };
  }

  close(id: SidebarId): void {
    if (this.state?.context?.id === id) {
      this.closeCurrent();
    }
  }

  closeCurrent(): void {
    this.state = {
      ...this.state,
      visible: false,
    };
  }
}
