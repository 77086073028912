// eslint-disable-next-line max-classes-per-file
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'executeWith' })
export class ExecuteWithPipe implements PipeTransform {
  /* eslint-disable */

  /**
   * Пайп для выполнения функции, при изменении аргументов
   *
   * @param callback Коллбек функция, которая будет выполнена
   * @param args Аргументы этой функции
   */
  transform<F extends (...fArgs: any[]) => any>(callback: F, ...args: Parameters<F>): ReturnType<F> {
    return callback?.(...args);
  }
  /* eslint-enable */
}

@NgModule({
  declarations: [ExecuteWithPipe],
  exports: [ExecuteWithPipe],
})
export class ExecuteWithPipeModule {}
