import { Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOption } from '@lib-utils';

export const enum OptionsProviderType {
  Term, // Список по введенному значению
  Static, // Статичные свойства, которые хранятся на фронте
  Exact, // Не будет показано в списке, исключительно для отображения информации по выбранному value
}

export type OptionsProviderTermFn<T = unknown, TVal = unknown> = (term: string) => Observable<SelectOption<T, TVal>[]>;
export interface OptionsProviderTerm {
  type: OptionsProviderType.Term;
  options: OptionsProviderTermFn;
  minTermLength?: Signal<number>;
  termEmptyMessage?: Signal<string | null>;
  loadingMessage?: Signal<string | null>;
}

export type OptionsProviderIdFn = (id: unknown) => Observable<SelectOption | null>;

export interface OptionsProviderStatic {
  type: OptionsProviderType.Static;
  // Для подписки на изменения обзервабла
  options: Observable<Observable<SelectOption[]>>;
}

export interface OptionsProviderExact {
  type: OptionsProviderType.Exact;
  // Для подписки на изменения обзервабла
  options: Observable<Observable<SelectOption[]>>;
}

export type OptionsProvider = OptionsProviderTerm | OptionsProviderStatic | OptionsProviderExact;
