/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppraisalCompanyStatus = 'Accredited' | 'AccreditedWithCondition' | 'NotAccredited' | 'Other';

export const AppraisalCompanyStatus = {
    Accredited: 'Accredited' as AppraisalCompanyStatus,
    AccreditedWithCondition: 'AccreditedWithCondition' as AppraisalCompanyStatus,
    NotAccredited: 'NotAccredited' as AppraisalCompanyStatus,
    Other: 'Other' as AppraisalCompanyStatus
};

