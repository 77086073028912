import { Injectable } from '@angular/core';
import { Nullable } from '../../interfaces/types';
import { DeepPartial, FilterState, FilterStateOrder, Pagination } from './filter-state.model';

@Injectable()
export abstract class FilterStateService<TGFilters = unknown> {
  abstract updateState(
    filters: Nullable<DeepPartial<TGFilters>>,
    pagination: Nullable<Pagination>,
    order: Nullable<FilterStateOrder>,
  ): void;

  abstract resetState(): void;

  /*
  Следить за преобразованием obj.toString() и объектов которые как-то иначе реализуют метод toString().
  Например, `TuiDay`.
  Необходимо приведение типа при патче формы фильтров из-за потери оригинального типа в `FilterState<TGFilters>`
   */
  abstract getState(defaultPagination?: Pagination): FilterState<TGFilters>;

  // Unique string identifier for the filter state
  // This is used to store the filter state in local storage or session storage
  protected get pageAddress() {
    return location.pathname;
  }
}
