import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiBadgedContentModule, TuiInputNumberModule, TuiLineClampModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveInputModule } from '../reactive-input';
import { ReactiveInputNumberModule } from '../reactive-input-number';
import { ReactiveButtonDropdownInputRangeComponent } from './reactive-button-dropdown-input-range.component';

const TUI_MODULES = [
  TuiHostedDropdownModule,
  TuiButtonModule,
  TuiSvgModule,
  TuiInputNumberModule,
  TuiLineClampModule,
  TuiLetModule,
  TuiBadgedContentModule,
];

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ReactiveInputModule,
    ReactiveInputNumberModule,
    TUI_MODULES,
    ExecuteWithPipeModule,
  ],
  exports: [ReactiveButtonDropdownInputRangeComponent],
  declarations: [ReactiveButtonDropdownInputRangeComponent],
})
export class ReactiveButtonDropdownInputRangeModule {}
