import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tuiFormatNumber } from '@taiga-ui/core';
import { TuiNumberFormatSettings } from '@taiga-ui/core/interfaces';
import isNil from 'lodash-es/isNil';
import merge from 'lodash-es/merge';
import { Nullable } from '../../interfaces';

const TUI_NUMBER_FORMAT_SETTINGS_MAP: Record<string, TuiNumberFormatSettings> = {
  en: {
    decimalLimit: Infinity,
    decimalSeparator: '.',
    rounding: 'ceil',
    thousandSeparator: ',',
    zeroPadding: true,
  },
  ru: {
    decimalLimit: Infinity,
    decimalSeparator: ',',
    rounding: 'ceil',
    thousandSeparator: ' ',
    zeroPadding: true,
  },
};

@Pipe({
  name: 'tuiLocalizedNumber',
  pure: false,
  standalone: true,
})
export class TuiLocalizedNumberPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Nullable<number>, settings?: Partial<TuiNumberFormatSettings>): string | null {
    if (isNil(value)) return null;

    const locale = this.translateService.currentLang;
    const defaultSettings = TUI_NUMBER_FORMAT_SETTINGS_MAP[locale];

    return tuiFormatNumber(value, settings ? merge(settings, defaultSettings) : defaultSettings);
  }
}
