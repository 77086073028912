/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AccreditionStatus = 'Accredited' | 'Refusal' | 'UnderConsideration' | 'Refinement' | 'New' | 'Kkrb';

export const AccreditionStatus = {
    Accredited: 'Accredited' as AccreditionStatus,
    Refusal: 'Refusal' as AccreditionStatus,
    UnderConsideration: 'UnderConsideration' as AccreditionStatus,
    Refinement: 'Refinement' as AccreditionStatus,
    New: 'New' as AccreditionStatus,
    Kkrb: 'Kkrb' as AccreditionStatus
};

