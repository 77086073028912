import { Directive, Input, signal } from '@angular/core';
import { of } from 'rxjs';
import { Nullable } from '@lib-utils';
import { OptionsProviderTerm, OptionsProviderTermFn, OptionsProviderType } from '../options.model';
import { SELECT_OPTIONS } from '../options.token';
import { DEFAULT_MIN_TERM_LENGTH } from '../options.utils';

@Directive({
  standalone: true,
  selector: '[fnipOptionsFromTerm]',
  providers: [
    {
      provide: SELECT_OPTIONS,
      multi: true,
      deps: [OptionsFromTermDirective],
      useFactory: (dir: OptionsFromTermDirective): OptionsProviderTerm => ({
        type: OptionsProviderType.Term,
        options: (term) => dir.fnipOptionsFromTerm(term),
        minTermLength: dir.minTermLength$,
        termEmptyMessage: dir.termEmptyMessage$,
        loadingMessage: dir.loadingMessage$,
      }),
    },
  ],
})
export class OptionsFromTermDirective {
  @Input({ required: true, transform: (value: Nullable<OptionsProviderTermFn>) => value ?? (() => of([])) })
  fnipOptionsFromTerm!: OptionsProviderTermFn;

  minTermLength$ = signal<number>(DEFAULT_MIN_TERM_LENGTH);
  termEmptyMessage$ = signal<string | null>(null);
  loadingMessage$ = signal<string | null>(null);

  @Input()
  set loadingMessage(value: string) {
    this.loadingMessage$.set(value);
  }

  @Input()
  set minTermLength(value: number) {
    this.minTermLength$.set(value);
  }

  @Input()
  set termEmptyMessage(value: string) {
    this.termEmptyMessage$.set(value);
  }
}
