/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaternalCapitalType } from './maternalCapitalType';


export interface MaternalCapitalOptionDto { 
    isAllowed?: boolean;
    defaultValue?: number;
    types?: Array<MaternalCapitalType> | null;
}

