import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiDropdownModule, TuiHostedDropdownModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule, getUrl$, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { AccessModule } from '@lib-mortgage/features/authorization';
import { MenuItem, MenuItemModule } from './menu-item';

@Component({
  selector: 'fnip-expandable-menu',
  standalone: true,
  imports: [
    AccessModule,
    ButtonModule,
    CommonModule,
    TuiLetModule,
    ExecuteWithPipeModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    MenuItemModule,
  ],
  templateUrl: './expandable-menu.component.html',
  styleUrls: ['./expandable-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ width: '300px' })),
      state('collapsed', style({ width: '52px' })),
      transition('expanded <=> collapsed', [animate('250ms ease-in-out')]),
    ]),
  ],
})
export class ExpandableMenuComponent {
  @Input() items: Nullable<MenuItem[]>;

  url$ = getUrl$();

  expanded = false;

  changeMode = () => {
    this.expanded = !this.expanded;
  };

  trackByLink = (_index: number, item: MenuItem) => item.link;
}
