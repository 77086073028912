import { inject, Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { Nullable, RoleType, SessionStorageService } from '@lib-utils';
import { RoleInfoDto } from '@lib-mortgage/api';
import { UserInfoBff } from '../../utils/current-user.provider';
import { TOKEN_STORE } from '../../utils/token-store.provider';

export const AUTHORIZATION_RETURN_PATH_KEY = 'authorizationLogoutReturnPathStore';

@Injectable({ providedIn: 'root' })
export class AuthorizationStorageService {
  sessionStorageService = inject(SessionStorageService);

  lastUserData: UserInfoBff | null = null;

  token$ = inject(TOKEN_STORE);
  authorized$ = this.token$.pipe(map((token) => Boolean(token)));

  readonly onLogout$ = new Subject<void>();

  get userRoleType() {
    return this.lastUserData?.role?.code as Nullable<RoleType>;
  }

  get userRoles() {
    return this.lastUserData?.roles as (RoleInfoDto & { code: RoleType })[];
  }

  get user() {
    return this.lastUserData ?? undefined;
  }

  get returnPath() {
    return this.sessionStorageService.getFromSessionStorage<string>(AUTHORIZATION_RETURN_PATH_KEY);
  }

  /**
   * Проверка на наличие ХОТЯБЫ ОДНОЙ роли из переданных
   *
   * Пример:
   * У пользователя есть роли: Role1, Role2
   *
   * ```
   * hasRoles([Rol3, Role2]) -> true
   * hasRoles([Rol3, Role4]) -> false
   * hasRoles([Role1]) -> true
   * hasRoles([Role1, Role2]) -> true
   * ```
   */
  hasRoles = (userRoles: Nullable<RoleType[]>) => {
    if (!userRoles || !this.userRoleType) return false;

    return (
      userRoles.includes(this.userRoleType) ||
      (this.userRoleType === RoleType.MultiRole && this.userRoles?.some(({ code }) => userRoles.includes(code)))
    );
  };

  saveReturnPath = () => {
    if (location.pathname.startsWith('/auth')) return;
    this.sessionStorageService.setToSessionStorage<string>(AUTHORIZATION_RETURN_PATH_KEY, location.pathname);
  };

  resetReturnPath = () => {
    this.sessionStorageService.removeFromSessionStorage(AUTHORIZATION_RETURN_PATH_KEY);
  };
}
