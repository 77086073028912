import { Injectable } from '@angular/core';
import { Nullable } from '../../../interfaces/types';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  getFromSessionStorage = <T>(key: string): Nullable<T> => {
    const item = sessionStorage.getItem(key);
    try {
      return item ? (JSON.parse(item) as T) : undefined;
    } catch (e) {
      console.error('Error while parsing session storage', e);
      sessionStorage.removeItem(key);
      return undefined;
    }
  };

  setToSessionStorage = <T>(key: string, value: T) => sessionStorage.setItem(key, JSON.stringify(value));

  removeFromSessionStorage = (key: string) => sessionStorage.removeItem(key);
}
