/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SpouseSocialStatus } from './spouseSocialStatus';
import { CreditObligationSpecialConditionModel } from './creditObligationSpecialConditionModel';
import { PhysicalPersonModel } from './physicalPersonModel';
import { MaritalContract } from './maritalContract';
import { ReasonForResidence } from './reasonForResidence';
import { Citizenship } from './citizenship';
import { EmploymentStatus } from './employmentStatus';
import { InsurancePackage } from './insurancePackage';
import { DateTime } from '@lib-utils';
import { AdditionalIncomeModel } from './additionalIncomeModel';
import { UserModel } from './userModel';
import { PersonalSubsidiaryPlotModel } from './personalSubsidiaryPlotModel';
import { ClientPreviousNameModel } from './clientPreviousNameModel';
import { Education } from './education';
import { ClientFileModel } from './clientFileModel';
import { EmploymentModel } from './employmentModel';
import { ExpenseModel } from './expenseModel';


export interface ClientModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
    employmentStatus?: EmploymentStatus;
    citizenship?: Citizenship;
    homePhone?: string | null;
    isPhoneConfirmed?: boolean;
    hasNoRegistration?: boolean | null;
    communalApartment?: boolean | null;
    reasonForResidence?: ReasonForResidence;
    education?: Education;
    educationOther?: string | null;
    maritalContract?: MaritalContract;
    spouseSocialStatus?: SpouseSocialStatus;
    children?: number | null;
    childrenAges?: string | null;
    familyMembers?: number | null;
    dependents?: number | null;
    personalSubsidiaryPlotId?: number | null;
    personalSubsidiaryPlot?: PersonalSubsidiaryPlotModel;
    isPublicOfficial?: boolean | null;
    isBankrupt?: boolean | null;
    insuranceType?: InsurancePackage;
    smartDealSignatureRequestId?: number | null;
    sprIsIncomePart?: boolean;
    sprIncome?: number | null;
    sprMonthlyPaymentMax?: number | null;
    sprMonthlyPaymentPtiMax?: number | null;
    sprMonthlyPaymentMaxTemp?: number | null;
    sprDeclaredIncome?: number | null;
    employments?: Array<EmploymentModel> | null;
    files?: Array<ClientFileModel> | null;
    previousName?: ClientPreviousNameModel;
    creditObligationSpecialConditions?: Array<CreditObligationSpecialConditionModel> | null;
    additionalIncomes?: Array<AdditionalIncomeModel> | null;
    expenses?: Array<ExpenseModel> | null;
}
export namespace ClientModel {
}


