/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientModel } from './clientModel';
import { ApplicationModel } from './applicationModel';


export interface GuarantorModel { 
    id?: number;
    applicationId?: number;
    application?: ApplicationModel;
    clientId?: number;
    client?: ClientModel;
    isActive?: boolean;
    relationWithBorrower?: string | null;
}

