import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';

export class ToUtcDateTransformer implements AbstractTuiValueTransformer<TuiDay | undefined, Date | undefined> {
  fromControlValue(controlValue: Date | undefined): TuiDay | undefined {
    if (controlValue) {
      const date = controlValue instanceof Date ? controlValue : new Date(controlValue);
      return controlValue && TuiDay.fromUtcNativeDate(date);
    }

    return undefined;
  }

  toControlValue(componentValue: TuiDay | undefined): Date | undefined {
    return componentValue?.toUtcNativeDate();
  }
}
