import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiAppearance, TuiHintModule } from '@taiga-ui/core';
import { PolymorpheusContent, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { debounceTime, map, Observable } from 'rxjs';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';

@Component({
  selector: 'fnip-ellipsis-hint',
  standalone: true,
  styleUrls: ['./ellipsis-hint.component.scss'],
  templateUrl: './ellipsis-hint.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TuiHintModule, ExecuteWithPipeModule, PolymorpheusOutlet],
})
export class EllipsisHintComponent {
  @Input() isDisabled = false;
  @Input() content: Nullable<PolymorpheusContent>;

  @Input() hintAppearance: TuiAppearance.Primary | 'onDark' = TuiAppearance.Primary;

  isTooltipShown = (offsetWidth: number, scrollWidth: number) => scrollWidth > offsetWidth;

  observeResize = (element: HTMLElement) => {
    if (!element) return;

    return new Observable((subscriber) => {
      const resizeObserver = new ResizeObserver(() => subscriber.next());
      resizeObserver.observe(element);
      // on unsubscribe
      subscriber.add(() => resizeObserver.disconnect());
    }).pipe(
      debounceTime(500),
      map(() => element.offsetWidth),
    );
  };
}
