/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { AddressModel } from './addressModel';
import { DateOnly } from '@lib-utils';
import { DateTime } from '@lib-utils';


export interface EvaluateReportModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    isActive?: boolean;
    appraisalCompanyId?: number | null;
    appraisalCompanyName?: string | null;
    taxNumber?: string | null;
    addressId?: number | null;
    address?: AddressModel;
    evaluateValue?: number | null;
    reportDate?: DateOnly | null;
    evaluationDate?: DateOnly | null;
    deviation?: number | null;
    reportNumber?: string | null;
    gozoValue?: number | null;
    reportCost?: number | null;
}

