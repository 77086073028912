/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';
import { RepresentativeType } from './representativeType';
import { RepresentativeDocumentType } from './representativeDocumentType';
import { CreateOrUpdatePhysicalPersonRequest } from './createOrUpdatePhysicalPersonRequest';


export interface CreateOrUpdateRepresentativeRequest { 
    id?: number;
    physicalPerson?: CreateOrUpdatePhysicalPersonRequest;
    type?: RepresentativeType;
    documentType?: RepresentativeDocumentType;
    name?: string | null;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDate?: DateOnly | null;
    special?: string | null;
}
export namespace CreateOrUpdateRepresentativeRequest {
}


