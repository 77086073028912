/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StageExecutionResultType } from './stageExecutionResultType';
import { DateTime } from '@lib-utils';


export interface StageExecutionDto { 
    end?: DateTime | null;
    resultType: StageExecutionResultType;
    approximatedEnd?: DateTime | null;
}
export namespace StageExecutionDto {
}


