import { CommonModule } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule, TuiValueChangesModule } from '@taiga-ui/cdk';
import { TuiCheckboxLabeledModule } from '@taiga-ui/kit';
import {
  calculateSelectAllValue,
  ExecuteWithPipeModule,
  Nullable,
  reactiveTestAttributesHostDirective,
  SelectOption,
  TestAttributesDirective,
} from '@lib-utils';
import { AbstractReactiveWithOptions } from '../abstract-reactive-with-options';
import { ReactiveLabelModule } from '../reactive-label';

@Component({
  selector: 'fnip-reactive-checkbox-list',
  standalone: true,
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    FormsModule,
    ReactiveLabelModule,
    TuiCheckboxLabeledModule,
    TuiLetModule,
    TuiValueChangesModule,
    ReactiveFormsModule,
    TestAttributesDirective,
  ],
  templateUrl: './reactive-checkbox-list.component.html',
  styleUrls: ['./reactive-checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveCheckboxListComponent<T = unknown, TVal = unknown> extends AbstractReactiveWithOptions<T, TVal> {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<TVal[]>>>;

  @Input() selectAllLabel = 'Выбрать все';

  @Input({ transform: booleanAttribute }) hasSelectAll = false;

  checkboxOptions: Nullable<SelectOption<T & { checked: Nullable<boolean> }, TVal>[]>;

  selectAll: Nullable<boolean> = false;

  setCheckboxOptions = (options: Nullable<SelectOption<T, TVal>[]>, controlValue: Nullable<TVal[]>) => {
    this.checkboxOptions = options?.map((option) => ({ ...option, checked: controlValue?.includes(option.value) }));
    this.selectAll = calculateSelectAllValue(this.checkboxOptions?.map(({ checked }) => checked));
  };

  selectAllChange(value: Nullable<boolean>) {
    this.control?.setValue(value ? this.checkboxOptions?.map((o) => o.value) : []);
  }

  optionChange(option: SelectOption<T & { checked: Nullable<boolean> }, TVal>, checked: Nullable<boolean>) {
    if (!this.control) return;
    const value = this.control.value ?? [];
    this.control.setValue(checked ? [...value, option.value] : value.filter((v: unknown) => v !== option.value));
  }

  trackByValue = (_i: number, value: SelectOption) => value.value;
}
