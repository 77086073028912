import isFunction from 'lodash-es/isFunction';
import { isObservable, Observable, of, switchMap, timer } from 'rxjs';
import { DEBOUNCE_TIME_DEFAULT } from '../../constants';
import { SelectOption, SelectOptions } from '../../interfaces/select-option';
import { Nullable } from '../../interfaces/types';

export function getSelectOptions$<T = unknown>(
  options: SelectOptions<T>,
  term: Nullable<string>,
  minTermLength = 1,
  initialOptions: Nullable<SelectOption<T>[]> = null,
  isPristine = false,
): Observable<SelectOption<T>[]> {
  if (!term && initialOptions && isPristine) return of(initialOptions);
  if (isObservable(options)) return options;
  if (isFunction(options))
    return (term?.length ?? 0) >= minTermLength
      ? timer(DEBOUNCE_TIME_DEFAULT).pipe(switchMap(() => options(term!)))
      : of([]);
  return of(options);
}
