/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManualPti = 'FortyFive' | 'Fifty' | 'FiftyFive' | 'Sixty' | 'SixtyFive';

export const ManualPti = {
    FortyFive: 'FortyFive' as ManualPti,
    Fifty: 'Fifty' as ManualPti,
    FiftyFive: 'FiftyFive' as ManualPti,
    Sixty: 'Sixty' as ManualPti,
    SixtyFive: 'SixtyFive' as ManualPti
};

