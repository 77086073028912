/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentRecognitionErrorDto } from './documentRecognitionErrorDto';
import { Ndfl2IncomeRecognitionDto } from './ndfl2IncomeRecognitionDto';
import { DateOnly } from '@lib-utils';


export interface Ndfl2RecognitionDto { 
    errors?: Array<DocumentRecognitionErrorDto> | null;
    fullName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    secondName?: string | null;
    birthDate?: DateOnly | null;
    inn?: string | null;
    sign?: string | null;
    year?: number | null;
    oktmo?: string | null;
    phone?: string | null;
    stamp?: string | null;
    number?: string | null;
    incomes1?: Array<Ndfl2IncomeRecognitionDto> | null;
    incomes2?: Array<Ndfl2IncomeRecognitionDto> | null;
    incomes3?: Array<Ndfl2IncomeRecognitionDto> | null;
    issueDate?: DateOnly | null;
    nameAgent?: string | null;
    countryCode?: string | null;
    digitalSign?: string | null;
    innEmployer?: string | null;
    kppEmployer?: string | null;
    documentCode?: number | null;
    hasCorrections?: boolean | null;
    numberPassport?: string | null;
    statusTaxPayer?: number | null;
    incomesTaxBase1?: number | null;
    incomeTable1Rate?: number | null;
    incomeTable2Rate?: number | null;
    incomeTable3Rate?: number | null;
    incomeTable2Exists?: boolean | null;
    incomeTable3Exists?: boolean | null;
    reorganizationCode?: string | null;
    incomesTotalIncome1?: number | null;
    innKppReorganization?: string | null;
    incomeTable1LastMonth?: number | null;
    incomeTable2LastMonth?: number | null;
    incomeTable3LastMonth?: number | null;
    incomeTable1FirstMonth?: number | null;
    incomeTable2FirstMonth?: number | null;
    incomeTable3FirstMonth?: number | null;
    incomesCalculationTaxAmount1?: number | null;
}

