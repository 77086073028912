import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import isEqual from 'lodash-es/isEqual';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-button-checkbox',
  templateUrl: './reactive-button-checkbox.component.html',
  styleUrls: ['./reactive-button-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveButtonCheckboxComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<boolean>>>;

  setValue = (value: boolean) => () => {
    if (this.isReadOnly || isEqual(this.control?.value, value)) return;
    this.control?.setValue(value);
  };
}
