/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventDto } from './eventDto';
import { UserInfoDto } from './userInfoDto';


export interface EventUserDto { 
    id?: number;
    recipientUser?: UserInfoDto;
    event?: EventDto;
    receiveByEmail?: boolean;
    receiveOnSite?: boolean;
}

