import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiHintModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { DividerModule } from '../divider';

@Component({
  selector: 'a[fnip-application-module-card]',
  standalone: true,
  templateUrl: './application-module-card.component.html',
  styleUrl: './application-module-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TuiIslandModule, DividerModule, TuiSvgModule, TuiHintModule, TranslateModule],
})
export class ApplicationModuleCardComponent {
  name = input.required<string>();
  description = input.required<string>();

  isFailedToLoad = input(false, { transform: booleanAttribute });
  hasTranslation = input(false, { transform: booleanAttribute });
}
