/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';
import { DateTime } from '@lib-utils';
import { DocumentModel } from './documentModel';
import { PhysicalPersonInfoModel } from './physicalPersonInfoModel';
import { RepresentativePhysicalPersonMappingModel } from './representativePhysicalPersonMappingModel';


export interface PhysicalPersonModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    sex?: Sex;
    taxNumber?: string | null;
    birthday?: DateOnly | null;
    snils?: string | null;
    readonly fullName?: string | null;
    physicalPersonInfo?: PhysicalPersonInfoModel;
    documents?: Array<DocumentModel> | null;
    readonly passports?: Array<DocumentModel> | null;
    representativeMappings?: Array<RepresentativePhysicalPersonMappingModel> | null;
    readonly age?: number | null;
    isActive?: boolean;
}
export namespace PhysicalPersonModel {
}


