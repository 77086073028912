/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationFileCheckType } from './applicationFileCheckType';
import { ApplicationFileModel } from './applicationFileModel';
import { DealVerificationErrorCodeMappingModel } from './dealVerificationErrorCodeMappingModel';


export interface ApplicationFileCheckModel { 
    id?: number;
    applicationFileId?: number;
    applicationFile?: ApplicationFileModel;
    checkType?: ApplicationFileCheckType;
    comment?: string | null;
    errorCodeMappings?: Array<DealVerificationErrorCodeMappingModel> | null;
    isActive?: boolean;
    dealVerificationTaskId?: number | null;
}
export namespace ApplicationFileCheckModel {
}


