<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-checkbox-labeled
    *ngIf="!isReadOnly && checkboxLabel"
    [nativeId]="fieldId"
    [readOnly]="isReadOnly"
    [formControl]="$any(control)"
    [size]="size"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    <span [class.font-weight-bold]="isLabelBold">{{ checkboxLabel }}</span>
  </tui-checkbox-labeled>

  <tui-checkbox
    *ngIf="!isReadOnly && !checkboxLabel"
    [nativeId]="fieldId"
    [readOnly]="isReadOnly"
    [formControl]="$any(control)"
    [size]="size"
    (tuiValueChanges)="valueChange.emit($event)"
  />

  <!-- TODO: in taiga-ui readonly param for checkbox wrapped by tuiLabel is doesn't work -->
  <!-- use primitive checkbox for workaround-->
  <tui-primitive-checkbox
    *ngIf="isReadOnly"
    [value]="control.value ?? false"
    [size]="size"
    [disabled]="!!control?.disabled"
  ></tui-primitive-checkbox>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
