import { Component, Input } from '@angular/core';
import { reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-slider',
  templateUrl: './reactive-input-slider.component.html',
  styleUrls: ['./reactive-input-slider.component.scss'],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputSliderComponent extends AbstractReactive {
  @Input({ required: true }) min = -Infinity;
  @Input({ required: true }) max = Infinity;
  @Input() steps = 0;
  @Input() quantum = 1;
  @Input() segments = 1;
  @Input() prefix = '';
  @Input() postfix = '';
}
