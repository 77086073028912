<ng-container *ngIf="action$ | async" />

<button
  *ngIf="(label || !icon) && !(btnRouterLink || btnRouterLink === '') && !btnHref"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiButton
  type="button"
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [icon]="isIconLoader && isLoading ? loaderTmp : icon"
  [iconRight]="iconRight"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [ngStyle]="btnStyle"
  [showLoader]="isLoading && !isIconLoader"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [fnipAutofocus]="isAutofocus"
  [disabled]="!!(isDisabled || isLoading)"
  (click)="onClick($event)"
>
  <ng-container *polymorpheusOutlet="label as content">
    <fnip-ellipsis-hint *ngIf="content" class="line-clamp" [content]="content" [hintAppearance]="btnHintAppearance" />
  </ng-container>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</button>

<button
  *ngIf="!label && icon && !(btnRouterLink || btnRouterLink === '') && !btnHref"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiIconButton
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [icon]="isIconLoader && isLoading ? loaderTmp : icon"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [ngStyle]="btnStyle"
  [showLoader]="isLoading && !isIconLoader"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [disabled]="!!(isDisabled || isLoading)"
  (click)="onClick($event)"
>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</button>

<a
  *ngIf="(label || !icon) && (btnRouterLink || btnRouterLink === '')"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiButton
  type="button"
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [icon]="isIconLoader && isLoading ? loaderTmp : icon"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [ngStyle]="btnStyle"
  [showLoader]="isLoading && !isIconLoader"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [disabled]="!!(isDisabled || isLoading)"
  [routerLink]="isDisabled ? undefined : btnRouterLink"
  [queryParams]="btnRouterQueryParams"
  [fragment]="btnRouterFragment"
  [target]="btnLinkTarget"
  [fnipAutofocus]="isAutofocus"
  (click)="onClick($event, false)"
>
  <ng-container *polymorpheusOutlet="label as content">
    <fnip-ellipsis-hint *ngIf="content" class="line-clamp" [content]="content" [hintAppearance]="btnHintAppearance" />
  </ng-container>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</a>

<a
  *ngIf="!label && icon && (btnRouterLink || btnRouterLink === '')"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiIconButton
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [icon]="isIconLoader && isLoading ? loaderTmp : icon"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [ngStyle]="btnStyle"
  [showLoader]="isLoading && !isIconLoader"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [disabled]="!!(isDisabled || isLoading)"
  [routerLink]="isDisabled ? undefined : btnRouterLink"
  [queryParams]="btnRouterQueryParams"
  [fragment]="btnRouterFragment"
  [target]="btnLinkTarget"
  [fnipAutofocus]="isAutofocus"
  (click)="onClick($event, false)"
>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</a>

<a
  *ngIf="label && btnHref"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiButton
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [href]="btnHref"
  [target]="btnLinkTarget"
  [icon]="icon"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [disabled]="!!isDisabled"
  [fnipAutofocus]="isAutofocus"
>
  <ng-container *polymorpheusOutlet="label as content">
    <fnip-ellipsis-hint class="line-clamp" [content]="content" [hintAppearance]="btnHintAppearance" />
  </ng-container>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</a>

<a
  *ngIf="!label && btnHref"
  #hintHandler="fnipHandleHint"
  fnipHandleHint
  tuiIconButton
  [class]="btnClass"
  [class.tui-skeleton]="isSkeleton"
  [class.tui-skeleton_rounded]="isSkeleton"
  [href]="btnHref"
  [target]="btnLinkTarget"
  [icon]="icon"
  [shape]="shape"
  [appearance]="appearance"
  [pseudoActive]="btnActive ?? null"
  [size]="btnSize"
  [tuiHint]="btnHint"
  [tuiHintAppearance]="btnHintAppearance"
  [tuiHintManual]="(hintHandler.showHint$ | async) ?? false"
  [disabled]="!!isDisabled"
  [fnipAutofocus]="isAutofocus"
>
  <ng-container [ngTemplateOutlet]="contentTmp" />
</a>

<ng-template #contentTmp> <ng-content /> </ng-template>

<ng-template #loaderTmp>
  <tui-loader size="s" [inheritColor]="true" [showLoader]="true" [style.width.px]="30" [style.height.px]="30" />
</ng-template>
