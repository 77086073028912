/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';
import { DocumentDto } from './documentDto';
import { PhysicalPersonInfoDto } from './physicalPersonInfoDto';
import { DateOnly } from '@lib-utils';
import { RepresentativePhysicalPersonMappingDto } from './representativePhysicalPersonMappingDto';


export interface PhysicalPersonDto { 
    id?: number | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    sex?: Sex;
    birthday?: DateOnly | null;
    snils?: string | null;
    taxNumber?: string | null;
    readonly fullName?: string | null;
    physicalPersonInfo?: PhysicalPersonInfoDto;
    documents?: Array<DocumentDto> | null;
    representativeMappings?: Array<RepresentativePhysicalPersonMappingDto> | null;
}
export namespace PhysicalPersonDto {
}


