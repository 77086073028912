/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipType } from './ownershipType';
import { ClientModel } from './clientModel';
import { DealInfoModel } from './dealInfoModel';


export interface BuyerInfoModel { 
    id?: number;
    dealInfoId?: number;
    dealInfo?: DealInfoModel;
    clientId?: number;
    client?: ClientModel;
    ownershipType?: OwnershipType;
    numerator?: number;
    denominator?: number;
    buyersMarried?: boolean;
    isActive?: boolean;
}
export namespace BuyerInfoModel {
}


