import { BeApiEnum } from '../../enums';
import { Nullable } from './../../interfaces/types/nullable';

const ALL_APIS: BeApiEnum[] = [
  BeApiEnum.Auto,
  BeApiEnum.AutoLookup,
  BeApiEnum.AutoSpr,
  BeApiEnum.Mortgage,
  BeApiEnum.Archive,
  BeApiEnum.Collection,
  BeApiEnum.Verification,
  BeApiEnum.Bff,
];

/**
 * Определяет модуль АПИ по url запроса
 * @param url
 * @param configService не передаем тип ConfigService, чтобы не получить циклическую зависимость
 * @returns BeApiEnum
 */
export function getBeApiModule(
  url: Nullable<string>,
  configService: { getApiUrlByName: (module: BeApiEnum) => string },
): BeApiEnum | null {
  return ALL_APIS.find((module) => url?.startsWith(configService.getApiUrlByName(module))) ?? null;
}
