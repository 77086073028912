/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { OwnerType } from './ownerType';
import { PhysicalPersonModel } from './physicalPersonModel';
import { OrganizationModel } from './organizationModel';
import { DocumentSigningType } from './documentSigningType';
import { ApplicationModel } from './applicationModel';
import { DateTime } from '@lib-utils';


export interface OwnerModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    isActive?: boolean;
    applicationId?: number;
    application?: ApplicationModel;
    ownerType?: OwnerType;
    physicalPersonId?: number | null;
    physicalPerson?: PhysicalPersonModel;
    organizationId?: number | null;
    organization?: OrganizationModel;
    documentSigningType?: DocumentSigningType;
}
export namespace OwnerModel {
}


