<fnip-reactive-label
  *ngIf="control"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [hint]="hint"
  [noBottomHint]="noBottomHint"
  [isBold]="isLabelBold"
>
  <tui-input-date
    [fnipToDate]="hasToDateDirective"
    [isUtcDate]="isUtcDate"
    [formControl]="$any(control)"
    [tuiTextfieldCleaner]="textfieldCleaner"
    [tuiTextfieldLabelOutside]="textfieldLabelOutside"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCustomContent]="textfieldCustomContent"
    [min]="minDate"
    [max]="maxDate"
    [readOnly]="isReadOnly"
    [ngClass]="inputClass"
    (tuiValueChanges)="valueChange.emit($event)"
  >
    {{ placeholder }}
    <input tuiTextfield [id]="fieldId" [style.pointer-events]="control.disabled ? 'all' : 'auto'" />
  </tui-input-date>
  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="$any(control)"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
