/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepresentativeModel } from './representativeModel';
import { PhysicalPersonModel } from './physicalPersonModel';


export interface RepresentativePhysicalPersonMappingModel { 
    id?: number;
    representativeId?: number;
    representative?: RepresentativeModel;
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
}

