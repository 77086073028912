/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipType } from './ownershipType';
import { RealEstateDto } from './realEstateDto';


export interface RealEstateOwnerDto { 
    id?: number | null;
    realEstateId?: number | null;
    realEstate?: RealEstateDto;
    ownershipType?: OwnershipType;
    numerator?: number | null;
    denominator?: number | null;
    acquisitionYear?: number | null;
    ownerId?: number | null;
}
export namespace RealEstateOwnerDto {
}


