import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RequestWrapperComponent } from './request-wrapper.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RequestWrapperComponent],
  exports: [RequestWrapperComponent],
})
export class MockRequestWrapperModule {}
