import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Nullable, reactiveTestAttributesHostDirective, SharedValidators } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-phone',
  templateUrl: './reactive-input-phone.component.html',
  styleUrls: [`./reactive-input-phone.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputPhoneComponent extends AbstractReactive {
  @Input() placeholderIcon: string | null = null;

  setPhoneNumberValue = (value: Nullable<string>) => {
    if (value?.startsWith('8') && value.length === 11) {
      this.control?.setValue(`+7${value.substring(1)}`);
    }
  };

  setPhoneNumberValidator = (control: AbstractControl, countryCode: string, mask: string) => {
    const numberLength = mask.split('').filter((c) => c === '#').length;
    control.addValidators(SharedValidators.phoneNumber(countryCode, numberLength));
  };
}
