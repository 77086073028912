import { DestroyRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResolveEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { Nullable } from '@lib-utils';
import { injectSidebar } from './sidebar.injector';

@Directive({ selector: 'ng-template[fnipSidebar]', exportAs: 'sidebar' })
export class SidebarDirective implements OnInit, OnDestroy {
  @Input() sidebarId: string | symbol = Symbol('sidebarId');

  @Input() noCloseOnRouteChange: Nullable<boolean>;

  @Output() sidebarClose = new EventEmitter<void>();

  @Output() sidebarCloseAfterAnimation = new EventEmitter<void>();

  private readonly sidebarService = injectSidebar();

  public get visible() {
    return this.sidebarService.sidebarVisible(this.sidebarId);
  }

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.sidebarService.register({
      id: this.sidebarId,
      view: this.templateRef,
      context: { $implicit: this.sidebarService },
    });

    this.sidebarService.closed$
      .pipe(
        filter((id) => id === this.sidebarId),
        tap(() => this.sidebarClose.next()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.sidebarService.closeAfterAnimation$
      .pipe(
        filter((id) => id === this.sidebarId),
        tap(() => this.sidebarCloseAfterAnimation.next()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    if (!this.noCloseOnRouteChange) {
      this.router.events
        .pipe(
          filter((event) => event instanceof ResolveEnd),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.close());
    }
  }

  ngOnDestroy(): void {
    this.sidebarService.unregister(this.sidebarId);
    this.close();
  }

  public open = () => this.sidebarService.open(this.sidebarId);

  public close = () => this.sidebarService.close(this.sidebarId);
}
