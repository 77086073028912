import { ExtendedRoute, IPOTEKA_VIDEO_INSTRUCTION_URL, NEW_LOGO_SRC } from '@lib-utils';
import { NoPageComponent } from '@lib-widgets/core';
import { ApplicationModulesAbsoluteMetaMap } from '@lib-host/utils';
import { authorizationGuardFn, getAuthorizationRoutes } from '@lib-mortgage/features/authorization';
import { DefaultComponent, DefaultPageData } from '@lib-mortgage/widgets/default';
import { environment } from '../environments/environment';

export const appRoutes: ExtendedRoute<DefaultPageData>[] = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [authorizationGuardFn],
    component: DefaultComponent,
    data: {
      moduleMeta: ApplicationModulesAbsoluteMetaMap,
      videoInstructionUrl: IPOTEKA_VIDEO_INSTRUCTION_URL,
      logo: NEW_LOGO_SRC,
      hasTranslation: true,
      useLinks: true,
    },
  },
  {
    path: 'auth',
    children: getAuthorizationRoutes(environment.frontVersion, 'Единый продуктовый кабинет', true),
  },
  {
    path: 'unsupported-browser',
    loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
  },
  {
    path: '**',
    component: NoPageComponent,
  },
];
