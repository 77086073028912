/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';
import { DateTime } from '@lib-utils';


export interface DealDocumentFileDto { 
    dealDocumentsId?: number;
    id?: number;
    created?: DateTime;
    fileContentType?: string | null;
    fileType?: FileType;
    relatedFileId?: number | null;
}
export namespace DealDocumentFileDto {
}


