/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManualReviewTaskTab = 'All' | 'PrimaryAuthorization' | 'SecondaryAuthorization' | 'Underwriting' | 'InProgress' | 'Suspended' | 'DepartureToClient';

export const ManualReviewTaskTab = {
    All: 'All' as ManualReviewTaskTab,
    PrimaryAuthorization: 'PrimaryAuthorization' as ManualReviewTaskTab,
    SecondaryAuthorization: 'SecondaryAuthorization' as ManualReviewTaskTab,
    Underwriting: 'Underwriting' as ManualReviewTaskTab,
    InProgress: 'InProgress' as ManualReviewTaskTab,
    Suspended: 'Suspended' as ManualReviewTaskTab,
    DepartureToClient: 'DepartureToClient' as ManualReviewTaskTab
};

