import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import { Nullable, reactiveTestAttributesHostDirective } from '@lib-utils';
import { AbstractReactive } from '../abstract-reactive';

@Component({
  selector: 'fnip-reactive-input-date-range',
  templateUrl: './reactive-input-date-range.component.html',
  styleUrls: [`./reactive-input-date-range.component.scss`],
  hostDirectives: [reactiveTestAttributesHostDirective],
})
export class ReactiveInputDateRangeComponent extends AbstractReactive {
  @Input({ required: true }) override control: Nullable<FormControl<Nullable<TuiDayRange>>>;

  @Input() minDate = new TuiDay(0, 0, 1);
  @Input() maxDate = new TuiDay(9999, 11, 31);
}
