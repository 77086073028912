/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectType } from './objectType';
import { CreditProgramType } from './creditProgramType';
import { CreditPurpose } from './creditPurpose';


export interface CreditProductDto { 
    id?: number;
    name?: string | null;
    rate?: number;
    purpose?: Array<CreditPurpose> | null;
    objectType?: Array<ObjectType> | null;
    currencyAlphaCode?: string | null;
    bankId?: number | null;
    creditProgram?: CreditProgramType;
    discount?: boolean;
    code?: string | null;
    nonStandard?: boolean;
    defaultFlexPrice?: number | null;
    isAbsoluteRateAvailable?: boolean;
    isStateSubsidyRequired?: boolean;
}
export namespace CreditProductDto {
}


