<fnip-request-wrapper class="auth-form" [request$]="checkToken$" [backButtonCallback$]="navigateBack">
  <form class="d-flex flex-column gap-10" (keyup.enter)="submitButton.onClick()">
    <img alt="logo" [class.logo]="!isNew" [class.logo-new]="isNew" [src]="logo" />

    <h2 class="m-auto">Восстановить пароль</h2>

    <fnip-reactive-input-password
      fieldId="newPassword"
      label="Новый пароль"
      textFieldSize="l"
      [control]="form.controls.newPassword"
      [requiredIf]="true"
    />

    <fnip-reactive-input-password
      fieldId="repeatPassword"
      label="Повторите новый пароль"
      textFieldSize="l"
      [control]="form.controls.repeatPassword"
      [requiredIf]="true"
    />

    <fnip-button
      #submitButton
      class="ml-auto"
      appearance="primary"
      label="Сохранить"
      btnSize="l"
      [actionCallback$]="submitFormCallback$"
      [isDisabled]="form.invalid"
    />
  </form>
</fnip-request-wrapper>
