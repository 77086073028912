/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReasonForArchivingDescription = 'Unknown' | 'RefusedToBuyApartment' | 'PostponedApartmentPurchase' | 'Other' | 'ChoseOtherBank_OurBankHasHigherInterestRate' | 'ChoseOtherBank_OurBankApprovedLowerSum' | 'ChoseOtherBank_OurBankRequiresBiggerFirstPayment' | 'ChoseOtherBank_OurBankTookLongerToConsiderTheApplication' | 'ChoseOtherBank_OurBankInsuranceIsMoreExpensive' | 'ChoseOtherBank_OurBankDidntApproveTheProperty' | 'ChoseOtherBank_OurBankHasMoreComplicatedDealmaking' | 'ChoseOtherBank_CustomerIsAClientOfOtherBank' | 'ChoseOtherBank_Other';

export const ReasonForArchivingDescription = {
    Unknown: 'Unknown' as ReasonForArchivingDescription,
    RefusedToBuyApartment: 'RefusedToBuyApartment' as ReasonForArchivingDescription,
    PostponedApartmentPurchase: 'PostponedApartmentPurchase' as ReasonForArchivingDescription,
    Other: 'Other' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankHasHigherInterestRate: 'ChoseOtherBank_OurBankHasHigherInterestRate' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankApprovedLowerSum: 'ChoseOtherBank_OurBankApprovedLowerSum' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankRequiresBiggerFirstPayment: 'ChoseOtherBank_OurBankRequiresBiggerFirstPayment' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankTookLongerToConsiderTheApplication: 'ChoseOtherBank_OurBankTookLongerToConsiderTheApplication' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankInsuranceIsMoreExpensive: 'ChoseOtherBank_OurBankInsuranceIsMoreExpensive' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankDidntApproveTheProperty: 'ChoseOtherBank_OurBankDidntApproveTheProperty' as ReasonForArchivingDescription,
    ChoseOtherBankOurBankHasMoreComplicatedDealmaking: 'ChoseOtherBank_OurBankHasMoreComplicatedDealmaking' as ReasonForArchivingDescription,
    ChoseOtherBankCustomerIsAClientOfOtherBank: 'ChoseOtherBank_CustomerIsAClientOfOtherBank' as ReasonForArchivingDescription,
    ChoseOtherBankOther: 'ChoseOtherBank_Other' as ReasonForArchivingDescription
};

