import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExecuteWithPipeModule, Nullable, SelectOptions } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import {
  ReactiveInputModule,
  ReactiveMultiSelectModule,
  ReactiveSelectModule,
  ReactiveTextAreaModule,
} from '@lib-widgets/reactive-fields';
import { injectDialogContext } from '../../dialog.utils';
import { ConfirmationDialogData, ConfirmationDialogType } from './confirmation-dialog.model';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    ReactiveTextAreaModule,
    ReactiveSelectModule,
    ExecuteWithPipeModule,
    NgSwitchCase,
    ReactiveInputModule,
    ButtonModule,
    ReactiveMultiSelectModule,
  ],
})
export class ConfirmationDialogComponent<T, TVal> {
  readonly dialogContext = injectDialogContext<Nullable<TVal>, ConfirmationDialogData<T, TVal>>();

  readonly ConfirmationDialogType = ConfirmationDialogType;

  readonly control = new FormControl<Nullable<TVal>>(this.dialogContext.data?.value);

  getInputControl = (control: FormControl<Nullable<TVal>>) => control as FormControl<Nullable<string>>;

  getInputControlOptions = (options: Nullable<SelectOptions<T, TVal>>) =>
    options as Nullable<SelectOptions<T, Nullable<string>>>;

  cancel() {
    this.dialogContext.ref?.close(null);
  }

  reset() {
    this.control.setValue(this.dialogContext.data?.resetValue ?? null);
  }

  confirm() {
    if (this.dialogContext.data?.type === ConfirmationDialogType.Question)
      return this.dialogContext.ref?.close(true as Nullable<TVal>);

    this.dialogContext.ref?.close(this.control.value);
  }
}
