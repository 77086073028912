/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceScheme } from './insuranceScheme';
import { InsuranceType } from './insuranceType';


export interface CreateOrUpdateInsuranceRequest { 
    id?: number;
    applicationId: number;
    type: InsuranceType;
    schema: InsuranceScheme;
    companyId: number;
    rate?: number;
    amount?: number;
    insurantId?: number | null;
    isKssPlus: boolean;
}
export namespace CreateOrUpdateInsuranceRequest {
}


