/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Citizenship } from './citizenship';
import { EmploymentStatus } from './employmentStatus';
import { ClientPreviousNameDto } from './clientPreviousNameDto';
import { CreateOrUpdatePhysicalPersonRequest } from './createOrUpdatePhysicalPersonRequest';


export interface ClientPersonalDataRequest { 
    employmentStatus?: EmploymentStatus;
    citizenship?: Citizenship;
    physicalPerson?: CreateOrUpdatePhysicalPersonRequest;
    previousName?: ClientPreviousNameDto;
}
export namespace ClientPersonalDataRequest {
}


